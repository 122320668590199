/* eslint-disable react/button-has-type */
import * as React from 'react';
import {
  DetailsList,
  DetailsListLayoutMode
} from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Nav } from 'react-bootstrap';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import addOnboardBOB from '../../../assets/images/ic_add.png';
import editOnboardBOB from '../../../assets/images/ic_edit.png';
import withErrorHandling from '../../hoc/withErrorHandling';
import ManageOnboardedBOBPopUp from '../manageOnBoardedBOB/ManageOnboardedBOB';
import * as bobOnboardModel from '../../models/model/onboardBOBModel';
import MetricConfigurationModal from '../metricConfiguration/MetricConfiguration';
import * as supplierOnboardModel from '../../models/model/SupplierOnboardingModel';
import * as BOBActions from '../../../redux/actions/onboardedBOBActions';
import DeleteDialogPopUp from '../../common/DeleteDialog';

export class OnboardedBOBGrid extends React.Component {
  static buildColumns() {
    return [
      {
        key: '11',
        type: 'button',
        name: 'Add new Bob',
        fieldName: '',
        isIconOnly: true,
        iconClassName: 'addImg',
        minWidth: 50,
        maxWidth: 80,
        isResizable: false,
        alt: 'addImg',
        src: addOnboardBOB
      },
      {
        key: '01',
        name: 'BOOK OF BUSINESS NAME',
        fieldName: 'bobName',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 250,
        isResizable: false,
        isSortedDescending: false,
        isMultiline: true,
        tabIndex: '0'
      },
      {
        key: '2',
        name: 'CATEGORY DETAILS',
        fieldName: 'standardCategoryGroup',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 200,
        isResizable: false,
        isSortedDescending: false,
        tabIndex: '0'
      },
      {
        key: '3',
        name: 'EXEC ORG',
        fieldName: 'execOrg',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 200,
        isResizable: false,
        isSortedDescending: false,
        tabIndex: '0'
      },
      {
        key: '4',
        name: 'CONTRACT ID',
        fieldName: 'contractIds',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 70,
        isResizable: false,
        isSortedDescending: false,
        isMultiline: true,
        tabIndex: '0'
      },
      {
        key: '5',
        name: 'PO#',
        fieldName: 'purchaseOrders',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 70,
        isResizable: false,
        isSortedDescending: false,
        isMultiline: true,
        tabIndex: '0'
      },
      {
        key: '6',
        name: 'BOB OWNER',
        fieldName: 'bobOwner',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 200,
        isResizable: false,
        isSortedDescending: false,
        tabIndex: '0'
      },
      {
        key: '7',
        name: 'REPORTING CADENCE',
        fieldName: 'reportingCadence',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 75,
        isResizable: false,
        isSortedDescending: false,
        tabIndex: '0'
      },
      {
        key: '8',
        name: 'ANNUAL CONTRACT VALUE(USD)',
        fieldName: 'annualContractValue',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 115,
        isResizable: false,
        isSortedDescending: false,
        tabIndex: '0'
      },
      {
        key: '9',
        name: 'WEIGHT',
        fieldName: 'weight',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 80,
        isResizable: false,
        isSortedDescending: false,
        tabIndex: '0'
      },
      {
        key: '10',
        name: 'EFFECTIVE PERIOD',
        fieldName: 'effectivePeriod',
        iconClassName: 'sortAsc',
        minWidth: 50,
        maxWidth: 65,
        isResizable: false,
        isSortedDescending: false,
        tabIndex: '0'
      }
    ];
  }

  constructor(props) {
    super(props);
    this.onColumnClick = this.onColumnClick.bind(this);
    this.onRenderItemColumn = this.onRenderItemColumn.bind(this);
    this.onEditOnboardedBOB = this.onEditOnboardedBOB.bind(this);
    this.getSelectedBobName = this.getSelectedBobName.bind(this);
    this.onDeleteSupplier = this.onDeleteSupplierBOB.bind(this);
    this.onItemInvoked = this.onItemInvoked.bind(this);
    this.onCollapseBOB = this.onCollapseBOB.bind(this);
    this.onLoadMetricData = this.onLoadMetricData.bind(this);
  }

  state = {
    columns: OnboardedBOBGrid.buildColumns(),
    onboardBOB: new bobOnboardModel.OnboardedBOBModel(),
    isManageOnboardedPopupVisible: false,
    scorecardBOBId: 0,
    shouldExpandBOB: false,
    primaryOwner: new supplierOnboardModel.LeadModel(),
    secondaryOwner: new supplierOnboardModel.LeadModel(),
    isLoading: false,
    hideDialog: true,
    selectedIndex: null,
    hasAddAccess: false,
    hasEditAccess: false,
    hasDeleteAccess: false,
    dialogClickInProgress: false
  };

  componentDidMount() {
    const { loggedInUser, onboardSupplier, resetMessageBar } = this.props;
    const { hasAddAccess, columns } = this.state;
    resetMessageBar();
    if ((loggedInUser.isAdmin || loggedInUser.alias.split('@')[0].toLowerCase() === onboardSupplier.lead1Alias.toLowerCase() || loggedInUser.alias.split('@')[0].toLowerCase() === onboardSupplier.lead2Alias.toLowerCase()) && !loggedInUser.isReadOnly) {
      this.setState({
        hasAddAccess: true,
        hasEditAccess: true
      });
    } else if (loggedInUser.isBobOwner && !loggedInUser.isReadOnly) {
      this.setState({
        hasEditAccess: true
      });
    }
    if (loggedInUser.isAdmin) {
      this.setState({
        hasDeleteAccess: true
      });
    }
    if (!hasAddAccess) {
      this.setState({
        columns: columns.map(col => {
          const currentCol = col;
          if (col.key === '11') {
            currentCol.iconClassName = 'addImg fadeOut';
          }
          return currentCol;
        })
      });
    }
  }

  onColumnClick(event, column) {
    const { columns, isManageOnboardedPopupVisible, hasAddAccess } = this.state;
    const { BOBList, sortedOnboardSupplierBOBs } = this.props;
    const flag = !column.isSortedDescending;

    if ((column === undefined || column.key === '11') && hasAddAccess) {
      this.setState({
        onboardBOB: new bobOnboardModel.OnboardedBOBModel(),
        scorecardBOBId: 0,
        isManageOnboardedPopupVisible: !isManageOnboardedPopupVisible,
        primaryOwner: new supplierOnboardModel.LeadModel(),
        secondaryOwner: new supplierOnboardModel.LeadModel()
      });
    }

    if (column.key !== '11') {
      const newSupplierBOBs = [...BOBList].sort((firstRow, secondRow) => {
        let returnVal;
        if (flag) {
          if (
            column.fieldName === 'contractid' ||
            column.fieldName === 'po' ||
            column.fieldName === 'weight'
          ) {
            returnVal =
              firstRow[column.fieldName] - secondRow[column.fieldName];
          } else if (firstRow[column.fieldName] < secondRow[column.fieldName]) {
            returnVal = 1;
          } else if (firstRow[column.fieldName] > secondRow[column.fieldName]) {
            returnVal = -1;
          } else {
            returnVal = 0;
          }
        } else if (
          column.fieldName === 'contractid' ||
          column.fieldName === 'po' ||
          column.fieldName === 'weight'
        ) {
          returnVal = secondRow[column.fieldName] - firstRow[column.fieldName];
        } else if (firstRow[column.fieldName] > secondRow[column.fieldName]) {
          returnVal = 1;
        } else if (firstRow[column.fieldName] < secondRow[column.fieldName]) {
          returnVal = -1;
        } else {
          returnVal = 0;
        }
        return returnVal;
      });
      sortedOnboardSupplierBOBs(newSupplierBOBs);

      this.setState({
        columns:
          columns &&
          columns.map(col => {
            const currentCol = col;
            if (col.key === column.key) {
              currentCol.isSortedDescending = flag;
              currentCol.iconClassName = flag ? 'sortAsc' : 'sortDesc';
            }
            return currentCol;
          })
      });
    }
  }

  onRenderItemColumn(item, index, column) {
    const { hasEditAccess, hasDeleteAccess } = this.state;
    switch (column.key) {
      case '01':
        return (
          <div
            id={item.bobId}
            onClick={item.isActive && this.showMetricPopup.bind(this)}
            title={item.bobName}
            alt="bobname"
            role="presentation"
            className="linkbutton set-focus"
            //  eslint-disable-next-line
            tabIndex="0"
          >
            <Nav.Link href="#" className="pt0" disabled={!item.isActive}>
              {item.bobName}
            </Nav.Link>
          </div>
        );
      case '2':
        return (
          <span
            title={`Group: ${item.standardCategoryGroup} \nName: ${item.standardCategory}`}
          >
            <b>Group:</b> {item.standardCategoryGroup} <br /> <b>Name:</b>{' '}
            {item.standardCategory}
          </span>
        );
      case '3':
        return (

          <span
            title={`Summary: ${item.execOrgSummary} \nOrg: ${item.execOrg}`}
          >
            <b>Summary:</b> {item.execOrgSummary} <br /> <b>Org:</b>{' '}
            {item.execOrg}
          </span>
        );
      case '4':
        return <span title={item.contractIds}>{item.contractIds}</span>;
      case '5':
        return <span title={item.purchaseOrders}>{item.purchaseOrders}</span>;
      case '6':
        return (
          <>
            {item.bobPrimaryOwnerDisplayName !== null &&
              item.bobSecondaryOwnerDisplayName !== null ? (
                <span
                  title={`${item.bobPrimaryOwnerDisplayName} (${item.bobPrimaryOwnerAlias}) \n ${item.bobSecondaryOwnerDisplayName} (${item.bobSecondaryOwnerAlias})`}
                >
                  {item.bobPrimaryOwnerDisplayName} ({item.bobPrimaryOwnerAlias})
                  <br />
                  {item.bobSecondaryOwnerDisplayName} (
                  {item.bobSecondaryOwnerAlias})
                </span>
              ) : (
                <span
                  title={`${item.bobPrimaryOwnerAlias} \n${item.bobSecondaryOwnerAlias}`}
                >
                  {item.bobPrimaryOwnerAlias} <br /> {item.bobSecondaryOwnerAlias}
                </span>
              )}
          </>
        );
      case '7':
        return (
          <span title={item.reportingCadence}>{item.reportingCadence}</span>
        );
      case '8':
        return (
          <span
            title={`$${item.annualContractValue.toLocaleString(
              navigator.language,
              {
                minimumFractionDigits: 2
              }
            )}`}
          >
            $
            {item.annualContractValue.toLocaleString(navigator.language, {
              minimumFractionDigits: 2
            })}
          </span>
        );
      case '9':
        return <span title={`${item.weight}%`}>{item.weight}%</span>;
      case '10':
        return <span title={`${item.effectivePeriod}`}>{item.effectivePeriod}</span>;
      case '11':
        return (
          <>
            <span>
              <input
                type="image"
                tabIndex="0"
                // eslint-disable-next-line react/no-unknown-property
                iconName="Edit"
                title="Edit"
                id={`editBob:${index}`}
                src={editOnboardBOB}
                disabled={!(hasEditAccess && item?.isActive)}
                className={hasEditAccess && item?.isActive ? 'editIcon' : 'editIcon fadeOut'}
                alt="edit row"
                onClick={this.onEditOnboardedBOB.bind(this)}
              />
            </span>
            <span>
              {hasDeleteAccess &&
              (<>
               <button
                 onClick={this.showDialog.bind(this)}
                 style={{
                  'box-shadow': 'none',
                  background: 'transparent',
                  'border-width': 'initial',
                  'border-style': 'none',
                  'border-color': 'initial',
                  'border-image': 'initial',
                }}
                 aria-label={item?.isActive ? 'delete bob entry' : 'activate bob entry'}
                 title={item?.isActive ? 'delete bob entry' : 'activate bob entry'}
                 disabled={!hasDeleteAccess}
                 id={`deleteBob:${index}`}
               >
                {
                  item?.isActive ? <Icon
                    iconName="Delete"
                    title="Delete"
                    alt="delete row"
                    role="presentation"
                    className={
                    hasDeleteAccess
                      ? 'deleteIcon'
                      : 'deleteIcon fadeOut'
                  }
                  /> :
                <Icon
                  iconName="Sync"
                  title="Reactivate Bob"
                  alt="activate row"
                  role="presentation"
                  className={
                    hasDeleteAccess
                      ? 'deleteIcon'
                      : 'deleteIcon fadeOut'
                  }
                />
                }
               </button>
               </>)}
            </span>
          </>
        );
      default:
        break;
    }

    return item[column.fieldName];
  }

  async onItemInvoked(item) {
    this.setState({
      selectedBOBId: item.bobId
    });
  }

  onCollapseBOB() {
    this.setState({
      shouldExpandBOB: false,
      isLoading: false
    });
  }

  onLoadMetricData() {
    this.setState({
      isLoading: false
    });
  }

  onEditOnboardedBOB = data => {
    this.setState({
      isLoading: true
    });
    const { BOBList } = this.props;
    const index = data.currentTarget.id.split(':')[1];
    const editOnboardedBOBdata = [...BOBList].filter(
      (BOB, currentIndex) => currentIndex.toString() === index
    );
    this.manageEditOnboardedBOBdetails(editOnboardedBOBdata[0]);
  };

  onClosePopup = () => {
    const { isManageOnboardedPopupVisible } = this.state;
    this.setState({
      onboardBOB: new bobOnboardModel.OnboardedBOBModel(),
      isManageOnboardedPopupVisible: !isManageOnboardedPopupVisible,
      scorecardBOBId: 0
    });
  };

  getSelectedBobName() {
    const { selectedIndex } = this.state;
    const { BOBList } = this.props;
    return BOBList[selectedIndex]?.bobName ? BOBList[selectedIndex]?.bobName : '';
  }

  showDialog = (data) => {
    const { hasAddAccess } = this.state;
    if (hasAddAccess) {
      this.setState({
        hideDialog: false,
        dialogClickInProgress: false,
        selectedIndex: data.currentTarget.id.split(':')[1]
      });
    }
  };

  closeDialog = () => {
    this.setState({ hideDialog: true });
  };

  manageEditOnboardedBOBdetails = async editOnboardedBOBdata => {
    const { isManageOnboardedPopupVisible } = this.state;
    let editOnboardedBOB = { ...editOnboardedBOBdata };
    let primaryOwnerAliasId = editOnboardedBOB.bobPrimaryOwnerAlias;
    let secondaryOwnerAliasId = editOnboardedBOB.bobSecondaryOwnerAlias;

    const primaryOwnerAlias = editOnboardedBOBdata.bobPrimaryOwnerAlias;
    const secondaryOwnerAlias = editOnboardedBOBdata.bobSecondaryOwnerAlias;

    primaryOwnerAliasId = `${editOnboardedBOBdata.bobPrimaryOwnerDisplayName} (${primaryOwnerAlias})`;
    secondaryOwnerAliasId = `${editOnboardedBOBdata.bobSecondaryOwnerDisplayName} (${secondaryOwnerAlias})`;

    editOnboardedBOB = {
      ...editOnboardedBOB,
      reportingCadence: {
        key: editOnboardedBOB.reportingCadenceId,
        text: editOnboardedBOB.reportingCadence
      },
      standardCategoryGroup: {
        key: editOnboardedBOB.standardCategoryGroupId,
        text: editOnboardedBOB.standardCategoryGroup
      },
      execOrg: {
        key: editOnboardedBOB.execOrgId,
        text: editOnboardedBOB.execOrg
      },
      standardCategory: {
        key: editOnboardedBOB.standardCategoryId,
        text: editOnboardedBOB.standardCategory
      },
      execOrgDetails: {
        key: editOnboardedBOB.execOrgDetailsId,
        text: editOnboardedBOB.execOrgDetails
      },
      execOrgSummary: {
        key: editOnboardedBOB.execOrgSummaryId,
        text: editOnboardedBOB.execOrgSummary
      },
      bobPrimaryOwnerDisplayName:
        editOnboardedBOBdata.bobPrimaryOwnerDisplayName !== ''
          ? editOnboardedBOBdata.bobPrimaryOwnerDisplayName
          : null,
      bobSecondaryOwnerDisplayName:
        editOnboardedBOBdata.bobSecondaryOwnerDisplayName !== ''
          ? editOnboardedBOBdata.bobSecondaryOwnerDisplayName
          : null
    };

    this.setState({
      onboardBOB: editOnboardedBOB,
      scorecardBOBId: editOnboardedBOB.bobId,
      isManageOnboardedPopupVisible: !isManageOnboardedPopupVisible,
      isLoading: false,
      primaryOwner: new supplierOnboardModel.LeadModel(
        primaryOwnerAliasId,
        primaryOwnerAlias
      ),
      secondaryOwner: new supplierOnboardModel.LeadModel(
        secondaryOwnerAliasId,
        secondaryOwnerAlias
      )
    });
  };

  onDeleteSupplierBOB = async () => {
    try {
      const { selectedIndex } = this.state;
      const {
            deleteSupplierBOB, scorecardSupplierId, BOBList, supplierProcurementType,
            loadSupplierBOBList
            } = this.props;
      this.setState({ dialogClickInProgress: true });
      const supplierBOBId = BOBList[selectedIndex].bobId;
      await deleteSupplierBOB(scorecardSupplierId, supplierBOBId, supplierProcurementType);
      await loadSupplierBOBList(scorecardSupplierId, supplierProcurementType);
      this.setState({
        hideDialog: true,
        selectedIndex: null
      });
    } catch (err) {
      this.setState({ dialogClickInProgress: false });
    }
  };

  onReactivateSupplierBob = async () => {
    try {
      const { selectedIndex } = this.state;
      const {
            activateSupplierBob, scorecardSupplierId, BOBList, supplierProcurementType,
            loadSupplierBOBList
            } = this.props;
      this.setState({ dialogClickInProgress: true });
      const supplierBOBId = BOBList[selectedIndex].bobId;
      await activateSupplierBob(scorecardSupplierId, supplierBOBId, supplierProcurementType);
      await loadSupplierBOBList(scorecardSupplierId, supplierProcurementType);
      this.setState({
        hideDialog: true,
        selectedIndex: null
      });
    } catch (err) {
      this.setState({ dialogClickInProgress: false });
    }
  }

  async showMetricPopup(item) {
    this.setState({
      selectedBOBId: parseInt(item.currentTarget.id, 10),
      shouldExpandBOB: true,
      isLoading: true
    });
  }

  render() {
    const {
      scorecardSupplierId,
      cadence,
      uvpAnnualSpend,
      loggedInUser,
      BOBList,
      onboardSupplier,
      supplierProcurementType
    } = this.props;

    const {
      columns,
      isManageOnboardedPopupVisible,
      scorecardBOBId,
      onboardBOB,
      shouldExpandBOB,
      selectedBOBId,
      primaryOwner,
      secondaryOwner,
      isLoading,
      hideDialog,
      selectedIndex,
      dialogClickInProgress
    } = this.state;
    return (
      <>
        {isLoading ? <Spinner className="spinner" /> : null}
        <DetailsList
          items={
            BOBList.length && scorecardSupplierId === BOBList[0].supplierId
              ? [...BOBList]
              : []
          }
          columns={supplierProcurementType === 2 ? columns.filter(
            (column) => ['01', '2', '6', '7', '9', '10', '11'].includes(column.key)
          ) : columns}
          setKey="set"
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          compact="false"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          isHeaderVisible="true"
          selectionMode={SelectionMode.none}
          className="BOBDefaultListCss"
          onColumnHeaderClick={this.onColumnClick}
          onRenderItemColumn={this.onRenderItemColumn}
          onItemInvoked={this.onItemInvoked}
        />

        {shouldExpandBOB === true && (
          <MetricConfigurationModal
            key={selectedBOBId}
            showModal={shouldExpandBOB}
            onCloseModal={this.onCollapseBOB}
            selectedBOBId={selectedBOBId}
            onLoadData={this.onLoadMetricData}
            procurementTypeId={supplierProcurementType}
          />
        )}
        {isManageOnboardedPopupVisible ? (
          <>
            <ManageOnboardedBOBPopUp
              triggerparentUpdate={this.onColumnClick}
              scorecardSupplierId={scorecardSupplierId}
              supplierProcurementType={supplierProcurementType}
              cadence={{ value: cadence, label: cadence }}
              uvpAnnualSpend={uvpAnnualSpend}
              loggedInUser={loggedInUser}
              onClosePopup={this.onClosePopup}
              onboardBOB={onboardBOB}
              scorecardBOBId={scorecardBOBId}
              primaryOwner={primaryOwner}
              secondaryOwner={secondaryOwner}
              onboardSupplier={onboardSupplier}
              BOBList={BOBList}
              bobFunctionalUsers={onboardBOB.functionalUsers}
            />
          </>
        ) : null}
        {!hideDialog ? (
          <>
            <DeleteDialogPopUp
              hideDialog={hideDialog}
              reactivatePopUp={!BOBList[selectedIndex]?.isActive}
              onCancel={this.closeDialog}
              clickInProgress={dialogClickInProgress}
              dialogButtonHandler={!BOBList[selectedIndex]?.isActive ?
                                        this.onReactivateSupplierBob :
                                        this.onDeleteSupplierBOB}
              itemName={this.getSelectedBobName()}
            />
          </>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sortedOnboardSupplierBOBs: bindActionCreators(
      BOBActions.sortedOnboardSupplierBOBs,
      dispatch
    ),
    loadSupplierBOBList: bindActionCreators(
      BOBActions.getSupplierBOBs,
      dispatch
    ),
    deleteSupplierBOB: bindActionCreators(
      BOBActions.deleteOnboardedSupplierBOB,
      dispatch
    ),
    activateSupplierBob: bindActionCreators(
      BOBActions.activateOnboardedSupplierBOB,
      dispatch
    ),
  };
}

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(OnboardedBOBGrid)
);

OnboardedBOBGrid.propTypes = {
  BOBList: PropTypes.array.isRequired,
  scorecardSupplierId: PropTypes.number.isRequired,
  supplierProcurementType: PropTypes.number.isRequired,
  cadence: PropTypes.string.isRequired,
  uvpAnnualSpend: PropTypes.number.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  sortedOnboardSupplierBOBs: PropTypes.func.isRequired,
  onboardSupplier: PropTypes.object.isRequired,
  deleteSupplierBOB: PropTypes.func.isRequired,
  activateSupplierBob: PropTypes.func.isRequired,
  resetMessageBar: PropTypes.func.isRequired,
  loadSupplierBOBList: PropTypes.func.isRequired
};
