/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import '../../myactions/scorecardFilterPanel/ScorecardFilterPanel.css';
import './DownloadWordReport.css';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import * as reportFilterPanelReduxActions from '../../../redux/actions/downloadReportActions';
import {
  SupplierModel,
  ScoreCardingPeriodModel
} from '../../models/model/ScorecardFilterPanelModel';
import { ReportingFilterModel } from '../../models/model/ReportingFilterModel';

export class DownloadWordReport extends Component {
  state = {
    isSubmitDisable: true,
    selectedSupplier: null,
    selectedPeriod: null,
    isSubmitLoading: false,
    isCadenceDisable: false,
    reportingFilterModel: new ReportingFilterModel(
      new SupplierModel(),
      new ScoreCardingPeriodModel()
    )
  };

  comboBoxStyles = {
    container: {
        display: 'flex',
        label: { fontSize: '14px !important', margin: '0 10px' }
      },
    optionsContainerWrapper: { maxHeight: '350px' },
    root: {
      '::after': {
      borderRadius: '4px'
      }
    }
  }

  async componentDidMount() {
    const { loadReportingSuppliers } = this.props;
    await loadReportingSuppliers();
  }

  async onChangeSupplier(event, selectedOption) {
    const { loadReportingPeriods } = this.props;
    if (selectedOption) {
      this.setState({
        reportingFilterModel: new ReportingFilterModel(
          new SupplierModel(selectedOption.key, selectedOption.text),
          new ScoreCardingPeriodModel(null, null)
        ),
        selectedSupplier: selectedOption,
        selectedPeriod: {
          text: '',
          key: 0
        },
        isSubmitDisable: false
      });
      this.setState({ isCadenceDisable: true });
      await loadReportingPeriods(selectedOption.key, 0);
      this.setState({ isCadenceDisable: false });
    }
  }

  onChangePeriod(event, selectedOption) {
    if (selectedOption !== undefined || selectedOption !== null) {
      const { reportingFilterModel, selectedSupplier } = this.state;
      if (reportingFilterModel.supplier !== undefined) {
        this.setState(prevState => ({
          reportingFilterModel: new ReportingFilterModel(
            new SupplierModel(
              prevState.reportingFilterModel.supplier.id,
              prevState.reportingFilterModel.supplier.name
            ),
            new ScoreCardingPeriodModel(
              selectedOption.key,
              selectedOption.text
            )
          ),
          selectedPeriod: selectedOption,
          isSubmitDisable: true
        }));
      } else {
        this.setState(() => ({
          reportingFilterModel: new ReportingFilterModel(
            new SupplierModel(0, selectedSupplier?.text),
            new ScoreCardingPeriodModel(
              selectedOption.key,
              selectedOption.text
            )
          ),
          selectedPeriod: selectedOption,
          isSubmitDisable: true
        }));
      }
    }
  }

  async onReportingFilterSubmit() {
    const { selectedSupplier, selectedPeriod } = this.state;
    const { loadReport } = this.props;
    this.setState({ isSubmitLoading: true });
    try {
      if ((selectedSupplier?.text && selectedPeriod?.text) !== null) {
        await loadReport(selectedSupplier, selectedPeriod);
      }
      this.setState({ isSubmitLoading: false });
    } catch (err) {
      // log telemetry
      this.setState({ isSubmitLoading: false });
    }
  }

  isNullOrUndefined = variable => {
    if (variable === null || variable === '' || variable === undefined) {
      return true;
    }
    return false;
  };

  render() {
    const {
      selectedSupplier,
      selectedPeriod,
      isSubmitDisable,
      isSubmitLoading,
      isCadenceDisable
    } = this.state;

    const { reportingSuppliers, reportingPeriods } = this.props;
    return (
      <div>
        {isSubmitLoading ? (
          <div>
            <Spinner className="spinner" />
          </div>
        ) : null}
        <div className="reportingPage">
        <div className="reportingLayout">
          <div className="col-md-17 col-md-17">
            <div className="row hdspec scorecardFilter-selections">
              <div className="scorecardFilter-selector supplierCombo">
                  <ComboBox
                    label="Supplier"
                    selectedKey={selectedSupplier?.key}
                    onChange={this.onChangeSupplier.bind(this)}
                    options={
                      !this.isNullOrUndefined(reportingSuppliers)
                        ? reportingSuppliers.map(supplier => ({
                            key: supplier.supplierId,
                            text: supplier.supplierName
                          }))
                        : null
                    }
                    useAriaLabelAsText
                    allowFreeInput
                    useComboBoxAsMenuWidth
                    autoComplete="on"
                    iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                    styles={this.comboBoxStyles}
                    comboBoxOptionStyles={{ maxHeight: '100px' }}
                    calloutProps={{ doNotLayer: true }}
                    placeholder="Select"
                    ariaLabel="select supplier"
                    disabled={this.isNullOrUndefined(reportingSuppliers)}
                  />
              </div>
              <div className="scorecardFilter-selector periodCombo">
                  <ComboBox
                    selectedKey={selectedPeriod?.key}
                    onChange={this.onChangePeriod.bind(this)}
                    label="Period"
                    options={
                      reportingPeriods.cadences
                        ? reportingPeriods.cadences
                            .map(info => info)
                            .map(per => ({
                              key: per.cadenceId,
                              text: per.cadenceName
                            }))
                        : null
                    }
                    useAriaLabelAsText
                    allowFreeInput
                    useComboBoxAsMenuWidth
                    autoComplete="on"
                    iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                    styles={this.comboBoxStyles}
                    comboBoxOptionStyles={{ maxHeight: '100px' }}
                    calloutProps={{ doNotLayer: true }}
                    ariaLabel="select cadence period"
                    placeholder="Select"
                    disabled={
                      reportingPeriods.cadences === 'undefined' ||
                      reportingPeriods.cadences === null ||
                      reportingPeriods.cadences.length === 0 ||
                      isCadenceDisable
                    }
                  />
              </div>
            </div>
          </div>
          <p />
          <div className="row hdspec2 scorecardFilter-selections">
            <div className="textsize14">
              {isSubmitDisable && selectedSupplier && !isCadenceDisable ? (
                <PrimaryButton
                  allowDisabledFocus={false}
                  onClick={this.onReportingFilterSubmit.bind(this)}
                  className="submitReport "
                  type="Submit"
                  text="Download"
                />
              ) : (
                <PrimaryButton
                  allowDisabledFocus={false}
                  onClick={this.onReportingFilterSubmit.bind(this)}
                  className="submitReport disabledSubmitButton"
                  type="Submit"
                  text="Download"
                />
              )}
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportingSuppliers: state.reportingSuppliers,
    reportingPeriods: state.reportingPeriods
  };
}

const mapDispatchToProps = {
  loadReportingSuppliers: reportFilterPanelReduxActions.getReportingSuppliers,
  loadReportingPeriods: reportFilterPanelReduxActions.getReportingPeriods,

  loadReport: reportFilterPanelReduxActions.getDownloadableReport
};

DownloadWordReport.defaultProps = {
  loadReportingSuppliers: null,
  loadReport: null
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadWordReport);

DownloadWordReport.propTypes = {
  loadReportingSuppliers: PropTypes.func,
  loadReportingPeriods: PropTypes.func.isRequired,
  reportingSuppliers: PropTypes.array.isRequired,
  reportingPeriods: PropTypes.object.isRequired,
  loadReport: PropTypes.func
};
