const actionPlanStatus = Object.freeze([
    {
        StatusId: 1,
        Status: 'Not Started'
    },
    {
        StatusId: 2,
        Status: 'In Progress'
    },
    {
        StatusId: 3,
        Status: 'Completed'
    },
    {
        StatusId: 4,
        Status: 'Overdue'
    }
]);
  export default actionPlanStatus;
