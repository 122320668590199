export const ActionsFilters = {
  All: 'All',
  InProgress: 'Inprogress',
  Completed: 'Completed'
};

export const ActionTypes = {
  BoBEntry: 'bobEntry',
  SupplierLeadEntry: 'SupplierLeadEntry'
};

export const SupplierMetric = {
  ContractualAdherence: 'Contractual Adherence'
};

export const MetricCategory = {
  Performance: 'Performance',
  Relationship: 'Relationship',
  Value: 'Value',
  Risk: 'Risk'
};
