import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SamEntryActionCard.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ActionStatus from '../../../common/consts/actionStatus';
import ActionFilters from '../../../common/consts/actionFilters';
import * as samReduxActions from '../../../redux/actions/samPortalActions';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

export class SamEntryActionCard extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleClick() {
    const { action, setSelectedSamEntryAction, politeAnnouncement } = this.props;
    setSelectedSamEntryAction(action);
    politeAnnouncement(`${action.supplier.name} selected`);
  }

  handleKeyDown(key) {
    if (key.keyCode === 13) this.handleClick();
  }

  render() {
    const { action, selectedSamEntry } = this.props;
    let actionStatusName;
    const statuses = Object.values(ActionFilters);
    for (let i = 0; i < statuses.length; i += 1) {
      const value = statuses[i];
      if (value.id === action.statusId) actionStatusName = value.name;
    }
    return (
        <div
          className={`actionCard${
          action.statusId === ActionStatus.Completed
            ? ' bbCompleted'
            : ' bbInCompleted'
        }`}
          role="listitem"
        >
        <div
          className={classNames('supplierLeadDiv', {
            actionselected:
              selectedSamEntry != null &&
              selectedSamEntry.supplierScoreCardId === action.supplierScoreCardId
          })}
          role="button"
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          tabIndex="-1"
          data-is-focusable
        >
          <div className="row">
            <span className="bbdetails ellipse">{action.supplier.name}</span>
          </div>
          <div className="row">
            <span
              className={
                action.statusId === ActionStatus.Completed
                  ? 'dot dotCompletionStatus'
                  : 'dot dotInCompletionStatus'
              }
            />
            <p className="mt10">
              <span className="bbstatus"> {actionStatusName}</span>
            </p>
            <p className="ml-auto">
              <span className="bbperdetails">{action.percentage}%</span>
            </p>
          </div>
        </div>
        </div>
    );
  }
}

SamEntryActionCard.defaultProps = {
  selectedSamEntry: null,
  setSelectedSamEntryAction: null,
  politeAnnouncement: null
};

SamEntryActionCard.propTypes = {
  action: PropTypes.object.isRequired,
  selectedSamEntry: PropTypes.object,
  setSelectedSamEntryAction: PropTypes.func,
  politeAnnouncement: PropTypes.func
};

export function mapStateToProps(state) {
  return {
    selectedSamEntry: state.samActionPlanData.selectedSamEntry
  };
}

const mapDispatchToProps = {
    setSelectedSamEntryAction: samReduxActions.setSelectedSamEntryAction,
    politeAnnouncement: PoliteAnnouncement,
  };


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SamEntryActionCard);
