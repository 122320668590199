// @ts-check
import * as types from './actionTypes';

export const PoliteAnnouncement = message => ({
  payload: message,
  type: types.POLITE_ANNOUNCEMENT,
});
export const AssertiveAnnouncement = message => ({
  payload: message,
  type: types.ASSERTIVE_ANNOUNCEMENT,
});
export const ClearAnnouncement = message => ({
  payload: message,
  type: types.CLEAR_ANNOUNCEMENT,
});
