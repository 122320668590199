import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './SupplierLeadEntryActionCard.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import ActionStatus from '../../../common/consts/actionStatus';
import * as myActionsReduxActions from '../../../redux/actions/myActionsActions';
import SupplierScorecardBobSummary from '../supplierScorecardSummary/supplierScorecardSummary';

export class SupplierLeadEntryActionCard extends Component {
  state = {
    isBobSummaryExpanded: false
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleBobSummaryToggle = this.handleBobSummaryToggle.bind(this);
    this.handleScorecardBobClick = this.handleScorecardBobClick.bind(this);
  }

  handleClick() {
    const { action, setSelectedSupplierLeadEntryAction } = this.props;
    setSelectedSupplierLeadEntryAction(action);
  }

  handleScorecardBobClick(bob) {
    const { setSelectedBobEntry, action } = this.props;
    setSelectedBobEntry({
      bobScoreCardId: bob.bobScoreCardId,
      supplier: { ...action.supplier },
      bob,
      cadence: { ...action.cadence }
    });
  }

  handleKeyDown(key) {
    if (key.keyCode === 13) this.handleClick();
  }

  handleBobSummaryToggle(event) {
    event.stopPropagation();
    this.setState(prevState => ({
      isBobSummaryExpanded: !prevState.isBobSummaryExpanded
    }));
  }

  render() {
    const { action, selectedSupplierLeadEntry } = this.props;
    const { isBobSummaryExpanded } = this.state;

    return (
      <div
        className={classNames('supplierLeadDiv', {
        actionselected:
          selectedSupplierLeadEntry != null &&
          selectedSupplierLeadEntry.supplierScorecardId ===
            action.supplierScorecardId
      })}
        role="listitem"
        data-is-focusable
      >

      <div
        className={`actionCard${
          action.statusId === ActionStatus.Completed
            ? ' bbCompleted'
            : ' bbInCompleted'
        }`}
        role="button"
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        tabIndex="-1"
      >

          <div className="row">
            <span className="bbdetails ellipse">{action.supplier ? action.supplier.name : null}</span>
          </div>
          <div className="row">
          {action.procurementTypeId === 2
           ? (<span className="bbname ellipse">
              {action.completedBobCount} of {action.totalBobCount} BOBs
              scored.
              </span>)
           : (<span className="bbname ellipse">
              {action.completedBobCount} of {action.totalBobCount} BOBs
              complete.
              </span>)}
          </div>
          <div className="row">
            <span className="bobStatusTitle">
              <Toggle
                className="bobSummaryToggle"
                defaultChecked={false}
                inlineLabel
                offAriaLabel="BOB Status Off"
                onAriaLabel="BOB Status On"
                onText="BOB Status"
                offText="BOB Status"
                onChange={this.handleBobSummaryToggle}
              />
              {/* <Button
                className="bobStatusToggleBtn"
                variant="link"
                onClick={this.handleBobSummaryToggle}
              >
                {isBobSummaryExpanded ? '-' : '+'}
              </Button>
              <span className="bbstatus">BOB Status</span> */}
            </span>

            <p className="ml-auto">
              <span className="bbperdetails">{action.percentage}%</span>
            </p>
          </div>
          <div>
            {isBobSummaryExpanded ? (
              <SupplierScorecardBobSummary
                supplierLeadEntryAction={action}
                onBobClick={this.handleScorecardBobClick}
              />
            ) : (
              <></>
            )}
          </div>
      </div>
      </div>
    );
  }
}

SupplierLeadEntryActionCard.defaultProps = {
  selectedSupplierLeadEntry: null
};

SupplierLeadEntryActionCard.propTypes = {
  action: PropTypes.object.isRequired,
  selectedSupplierLeadEntry: PropTypes.object,
  setSelectedSupplierLeadEntryAction: PropTypes.func.isRequired,
  setSelectedBobEntry: PropTypes.func.isRequired
};

export function mapStateToProps(state) {
  return {
    selectedSupplierLeadEntry: state.myActions.selectedSupplierLeadEntry
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedSupplierLeadEntryAction: bindActionCreators(
      myActionsReduxActions.setSelectedSupplierLeadEntryAction,
      dispatch
    ),
    setSelectedBobEntry: bindActionCreators(
      myActionsReduxActions.setSelectedBobEntryAction,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierLeadEntryActionCard);
