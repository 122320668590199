import React, { Component } from 'react';
import './SupplierOnboardingPage.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import OnboardedSuppliersGrid from './onboardedSuppliersGrid/OnboardedSuppliersGrid';
import withErrorHandling from '../hoc/withErrorHandling';
import * as suppliersActions from '../../redux/actions/onboardedSuppliersActions';
import resetMessagebarAction from '../../redux/actions/messageBarActions';

export class SupplierOnboardingPage extends Component {
  state = {
    onboardedSupplierloading: true
  };

  async componentDidMount() {
    const {
      loadOnboardedSuppliers,
      loadDomains,
      loadCadence,
      loadSupplierTypes,
      loadProcurementTypes,
      onError,
      resetMessageBar
    } = this.props;
    try {
      resetMessageBar();
      await loadOnboardedSuppliers();
      loadDomains();
      loadCadence();
      loadSupplierTypes();
      loadProcurementTypes();

      this.setState({
        onboardedSupplierloading: false
      });
    } catch (err) {
      this.setState({
        onboardedSupplierloading: false
      });
      onError('Unable to fetch onboarded suppliers.');
    }
  }

  render() {
    const { onboardedSupplierloading } = this.state;
    const {
      onboardedSuppliers,
      suppliers,
      domains,
      cadences,
      supplierTypes,
      procurementTypes,
      leads,
      loggedInUser,
      resetMessageBar
    } = this.props;
    return (
      <div>
        <div className="zoom">
          {onboardedSupplierloading ? (
            <>
              <div>
                {' '}
                <Spinner className="spinner" />
              </div>
            </>
          ) : (
            <>
              <OnboardedSuppliersGrid
                onboardedSuppliers={onboardedSuppliers}
                suppliers={suppliers}
                domains={domains}
                cadences={cadences}
                supplierTypes={supplierTypes}
                procurementTypes={procurementTypes}
                leads={leads}
                loggedInUser={loggedInUser}
                resetMessageBar={resetMessageBar}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    onboardedSuppliers:
      state.onboardedSuppliers === null || state.onboardedSuppliers.length === 0
        ? []
        : state.onboardedSuppliers,
    suppliers:
      state.suppliers === null || state.suppliers.length === 0
        ? []
        : state.suppliers,
    domains:
      state.domains === null || state.domains.length === 0 ? [] : state.domains,
    cadences:
      state.cadences === null || state.cadences.length === 0
        ? []
        : state.cadences,
    supplierTypes: state.supplierTypes === null ? [] : state.supplierTypes,
    procurementTypes:
      state.procurementTypes === null ? [] : state.procurementTypes,
    leads: state.leads === null || state.leads.length === 0 ? [] : state.leads,
    loggedInUser: state.loggedInUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadOnboardedSuppliers: bindActionCreators(
      suppliersActions.loadOnboardedSuppliers,
      dispatch
    ),
    loadDomains: bindActionCreators(suppliersActions.loadDomains, dispatch),
    loadCadence: bindActionCreators(suppliersActions.loadCadence, dispatch),
    loadSupplierTypes: bindActionCreators(
      suppliersActions.loadSupplierTypes,
      dispatch
    ),
    loadProcurementTypes: bindActionCreators(
      suppliersActions.loadProcurementTypes,
      dispatch
    ),
    resetMessageBar: bindActionCreators(resetMessagebarAction, dispatch)
  };
}

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SupplierOnboardingPage)
);

SupplierOnboardingPage.propTypes = {
  onboardedSuppliers: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired,
  cadences: PropTypes.array.isRequired,
  supplierTypes: PropTypes.array.isRequired,
  procurementTypes: PropTypes.array.isRequired,
  leads: PropTypes.array.isRequired,
  loadOnboardedSuppliers: PropTypes.func.isRequired,
  loadDomains: PropTypes.func.isRequired,
  loadCadence: PropTypes.func.isRequired,
  loadSupplierTypes: PropTypes.func.isRequired,
  loadProcurementTypes: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  resetMessageBar: PropTypes.func.isRequired
};
