/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';

export function addNewSubMetric(parentMetricInfoId) {
  return async dispatch => {
    dispatch({
      type: types.ADD_NEWSUBMETRIC_SUCCESS,
      parentMetricInfoId
    });
  };
}

export function editSubMetric(subMetricInfoIdToBeEdit, parentMetricInfoId) {
  return async dispatch => {
    dispatch({
      type: types.EDIT_SUBMETRIC_SUCCESS,
      subMetricInfoIdToBeEdit,
      parentMetricInfoId
    });
  };
}

export function saveSubMetric(subMetricInfoToBeSave, parentMetricInfoId) {
  return async dispatch => {
    dispatch({
      type: types.SAVE_SUBMETRIC_SUCCESS,
      subMetricInfoToBeSave,
      parentMetricInfoId
    });
  };
}

export function deleteSubMetric(subMetricInfoToBeDelete, parentMetricInfoId) {
  return async dispatch => {
    dispatch({
      type: types.DELETE_SUBMETRIC_SUCCESS,
      subMetricInfoToBeDelete,
      parentMetricInfoId
    });
  };
}

export function undoSubMetric(subMetricInfoToBeUndo, parentMetricInfoId) {
  return async dispatch =>
    dispatch({
      type: types.UNDO_SUBMETRIC_SUCCESS,
      subMetricInfoToBeUndo,
      parentMetricInfoId
    });
}
