import { combineReducers } from 'redux';
import loggedInUser from './userLoginReducer';
import myActions from './myActionsReducer';
import suppliers from './supplierReducer';
import {
  BOBReducer,
  StdCategoryGroupReducer,
  StdCategoryNameReducer,
  ExecOrgReducer,
  ExecOrgDetailsReducer,
  ExecOrgSummaryReducer,
  OwnerReducer,
  ReportingCadenceListReducer
} from './BOBReducer';
import {
  ScorecardSuppliersReducer,
  ScorecardBOBsReducer,
  ScorecardPeriodsReducer,
  ScorecardSupplierPeriodsReducer
} from './scorecardFilterPanelReducer';
import {
  onboardedSuppliers,
  cadenceReducer,
  domainReducer,
  supplierTypeReducer,
  procurementTypeReducer,
  leadReducer
} from './onboardedSuppliersReducer';
import MetricConditionsReducer from './metricConditionsReducer';
import reportsReducer from './reportsReducer';
import resourceReducer from './resourceReducer';
import ScoreDefinitionListReducer from './scoreDefinitionListReducer';
import MetricConfigurationReducer from './metricConfigurationReducer';
import CorrectiveActionPlanHistoryReducer from './correctiveActionPlanHistoryReducer';
import CommentHistoryReducer from './commentsHistoryReducer';
import myActionsScorecard from './scorecardReducer';
import MessageReducer from './messageReducer';
import pageTour from './pageTourReducer';
import {
  ReportingSuppliersReducer,
  ReportingPeriodsReducer
} from './downloadReportReducer';
import samportalReducer from './samPortalReducer';
import announcementReducer from './announcementReducer';

const rootReducer = combineReducers({
  loggedInUser,
  myActions,
  suppliers,
  myActionsScorecard,
  cadences: cadenceReducer,
  domains: domainReducer,
  supplierTypes: supplierTypeReducer,
  procurementTypes: procurementTypeReducer,
  leads: leadReducer,
  onboardedSuppliers,
  boblist: BOBReducer,
  stdcategorygroups: StdCategoryGroupReducer,
  stdcategorynames: StdCategoryNameReducer,
  execorgs: ExecOrgReducer,
  execorgdetails: ExecOrgDetailsReducer,
  execorgSummary: ExecOrgSummaryReducer,
  owners: OwnerReducer,
  reportingCadenceList: ReportingCadenceListReducer,
  scorecardsuppliers: ScorecardSuppliersReducer,
  scoreDefinitionList: ScoreDefinitionListReducer,
  scorecardbobs: ScorecardBOBsReducer,
  scorecardperiods: ScorecardPeriodsReducer,
  scorecardSupplierPeriods: ScorecardSupplierPeriodsReducer,
  metricConditions: MetricConditionsReducer,
  configuredMetrics: MetricConfigurationReducer,
  reports: reportsReducer,
  resource: resourceReducer,
  capHistory: CorrectiveActionPlanHistoryReducer,
  comments: CommentHistoryReducer,
  reportingSuppliers: ReportingSuppliersReducer,
  reportingPeriods: ReportingPeriodsReducer,
  message: MessageReducer,
  pageTour,
  samActionPlanData: samportalReducer,
  announcement: announcementReducer,
});

export default rootReducer;
