/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import PropTypes from 'prop-types';
import './metricsRatingControl.css';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

export default class MetricsRatingPresentation extends React.Component {
  constructor(props) {
    super(props);
    this.selectedItemChanged = this.selectedItemChanged.bind(this);
    this.uniqueId = Math.random().toString(16);
  }


 async selectedItemChanged(e) {
    const { onChange } = this.props;
    const buttonId = e.currentTarget.id;
    await onChange(Number(e.currentTarget.value));
    if (buttonId && document.getElementById(buttonId).checked) {
      document.getElementById(buttonId).focus();
    }
  }

  render() {
    const {
      isSelectable,
      controlid,
      isPerformanceMetricEnabled,
      scoreDefinitionList,
      name,
      selected,
      disabled
    } = this.props;
    let styleName = null;
    if (isPerformanceMetricEnabled || disabled) {
      styleName = 'perfMetric';
    } else if (isSelectable && !isPerformanceMetricEnabled) {
      styleName = 'selectable';
    } else {
      styleName = null;
    }
    return (
      <>
          <section className="outer" aria-labelledby={`metricHeader-sd-${controlid}`}>
          {scoreDefinitionList ?
            scoreDefinitionList.map((item, index) => (
              <label className={`${styleName}`} key={item.scoreDefinitionId}>
                {isSelectable && (
                  <>
                    <input
                      type="radio"
                      className={isPerformanceMetricEnabled || disabled ? 'fadeOutMetric' : ''}
                      onChange={this.selectedItemChanged}
                      name={`${name}-${this.uniqueId}`}
                      value={item.score}
                      checked={selected === item.score}
                      defaultChecked={selected === item.score}
                      disabled={disabled}
                      id={`${index}-${this.uniqueId}`}
                    />
                    <Icon
                      className={isPerformanceMetricEnabled || disabled ? 'radio-icon radio-off fadeOutMetric' : 'radio-icon radio-off'}
                      iconName="RadioBtnOff"
                    />
                    <Icon
                      className={isPerformanceMetricEnabled || disabled ? 'radio-icon radio-on fadeOutMetric' : 'radio-icon radio-on'}
                      iconName="RadioBtnOn"
                    />
                  </>
                )}
                <span className={isPerformanceMetricEnabled || disabled ? `rating rating-${item.score} fadeOutMetric` : `rating rating-${item.score}`}>
                  <span className="rating-text">{item.score}</span>
                </span>
                <span className={isPerformanceMetricEnabled || disabled ? 'rating-description' : 'rating-description'}>{item.description}</span>
              </label>
            )) : ''}
          </section>
      </>
    );
  }
}

MetricsRatingPresentation.defaultProps = {
  isPerformanceMetricEnabled: false,
  scoreDefinitionList: [],
  controlid: '',
  selected: null,
  name: 'metric',
  onChange: null,
  disabled: false
};
MetricsRatingPresentation.propTypes = {
  isSelectable: PropTypes.bool.isRequired,
  isPerformanceMetricEnabled: PropTypes.bool,
  scoreDefinitionList: PropTypes.array,
  selected: PropTypes.number,
  controlid: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};
