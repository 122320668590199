/* eslint-disable import/prefer-default-export */
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import MetricPostCallRequest from '../../components/models/model/MetricPostCallRequest';
import { Message } from '../../common/consts/validationMessages';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function loadConfiguredPerfMetricsSuccess(metricsByCategory, procurementTypeId) {
  return { type: types.LOAD_PERFORMANCEMETRICS_SUCCESS, metricsByCategory, procurementTypeId };
}

function updateMetricCategory(categoryId) {
  return { type: types.UPDATE_METRIC_CATEGORY, categoryId };
}

function loadConfiguredPerfMetricsFailure() {
  return { type: types.LOAD_PERFORMANCEMETRICS_FAILURE };
}

function updateConfiguredPerfMetricsSuccess(response) {
  return { type: types.SUBMET_METRIC_SUCCESS, response };
}

function updateConfiguredPerfMetricsFailure(response) {
  return { type: types.SUBMET_METRIC_FAILURE, response };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

function getAllMetricsToBeSumitted(metricsInfo, bobId, metricCategoryId) {
  const metrics = [];
  metricsInfo.forEach(eachMetricInfo => {
    // eslint-disable-next-line prefer-destructuring
    const metric = eachMetricInfo.metric;
    eachMetricInfo.metric.subMetrics.forEach(eachSubMetricInfo => {
      if (
        !eachSubMetricInfo.isProcessed &&
        (eachSubMetricInfo.metric.isDeleted ||
          eachSubMetricInfo.metric.isNew ||
          eachSubMetricInfo.metric.isUpdated)
      ) {
        const subMetric = eachSubMetricInfo.metric;
        metrics.push(
          new MetricPostCallRequest(
            bobId,
            metricCategoryId,
            subMetric.metricId,
            subMetric.metricName,
            subMetric.metricDescription,
            subMetric.isTargetApplicable,
            subMetric.metricTarget,
            subMetric.metricConditionTypeId,
            metric.metricId,
            parseFloat(subMetric.metricWeight),
            subMetric.orderNumber,
            subMetric.createdBy,
            subMetric.isDeleted,
            subMetric.isUpdated,
            subMetric.isNew,
            subMetric.key
          )
        );
      }
    });
    if (
      !eachMetricInfo.isProcessed &&
      (metric.isDeleted || metric.isNew || metric.isUpdated)
    ) {
      metrics.push(
        new MetricPostCallRequest(
          bobId,
          metricCategoryId,
          metric.metricId,
          metric.metricName,
          metric.metricDescription,
          metric.isTargetApplicable,
          metric.metricTarget,
          metric.metricConditionTypeId,
          null,
          parseFloat(metric.metricWeight),
          metric.orderNumber,
          metric.createdBy,
          metric.isDeleted,
          metric.isUpdated,
          metric.isNew,
          metric.key
        )
      );
    }
  });
  return metrics;
}

export function manageMetrics(metricsInfo, bobId, metricCategoryId) {
  return async dispatch => {
    try {
      const metrics = getAllMetricsToBeSumitted(
        metricsInfo,
        bobId,
        metricCategoryId
      );
      const response = await performanceApi.manageMetrics(metrics);
      if (response.data.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(updateConfiguredPerfMetricsSuccess(response.data));
      } else {
        const responseObj = {
          errormessage: Message.FailedToProcessRequest
        };
        dispatch(updateConfiguredPerfMetricsFailure(responseObj));
        Logger.getInstance().logValidationError(response.data.validationResult);
      }
    } catch (exception) {
      dispatch(updateConfiguredPerfMetricsFailure(exception));
      Logger.getInstance().logException(exception);
    }
  };
}

export function changeMetricCategory(categoryId) {
  return async dispatch => {
    dispatch(updateMetricCategory(categoryId));
  };
}

export function retrieveMetrics(bobId, procurementTypeId) {
  return async dispatch => {
    try {
      const response = await performanceApi.retrieveMetrics(bobId);
      if (response.validationResult.isValid) {
        if (response.data && response.data.metricsByCategory.length > 0) {
          dispatch(loadConfiguredPerfMetricsSuccess(
            response.data.metricsByCategory,
            procurementTypeId
          ));
        } else {
          dispatch(loadConfiguredPerfMetricsFailure());
          dispatch(
            handleValidationError([
              { errorMessage: Message.FailedToLoadMetrics }
            ])
          );
          Logger.getInstance().logValidationError(response.validationResult);
        }
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        dispatch(loadConfiguredPerfMetricsFailure());
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      dispatch(loadConfiguredPerfMetricsFailure());
      Logger.getInstance().logException(error);
    }
  };
}
