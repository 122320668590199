/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import {
 Route, Switch, Redirect, withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import './App.css';
import PropTypes from 'prop-types';
import config from './config/appConfig';
import withErrorHandling from './components/hoc/withErrorHandling';
import ScoreCardHeader from './components/common/Header';
import MyActionsPageComponent from './components/myactions/MyActionsPage';
import * as signinactions from './redux/actions/userLoginActions';
import SAMPortalComponent from './components/samPortal/samPortalPage';
import SupplierOnboardingPageComponent from './components/supplieronboarding/SupplierOnboardingPage';
import ReportsPage from './components/reports/ReportsPage';
import ResourcesPage from './components/resources/ResourcesPage';
import UnauthorizedPage from './components/unauthorized/UnauthorizedPage';
import * as getScoredefinitionactions from './redux/actions/scoreDefinitionActions';
import Footer from './components/common/Footer';
import { Logger } from './infra/logging/telemetryLogger';
import authContext from './infra/auth/aadAuth';
import * as pagetourService from './api/pagetourService';
import * as pageTourActions from './redux/actions/pageTourActions';
import Announcement from './components/common/announcement/announcement.component';

export class App extends Component {
  constructor(props) {
    super(props);
    this.pageTourCallback = this.pageTourCallback.bind(this);
    this.mainRegion = React.createRef();
  }

  async componentDidMount() {
    const {
      signInUser,
      getScoredefinition,
      onError,
      getPageTours,
      history
    } = this.props;
    try {
      await signInUser();
    } catch (err) {
      onError(
        'Unable to authorize user. If problem persists, contact scorecard support.'
      );
    }
    const pagetourConfig = config.api.pageTour;
    const token = await authContext.acquireTokenSilent(
      pagetourConfig.resourceId
    );
    pagetourService.pageTourInit(token, this.pageTourCallback, pagetourConfig);
    // eslint-disable-next-line no-unused-vars
    this.unlisten = history.listen((location, action) => {
      getPageTours();
    });

    const { loggedInUser } = this.props;
    if (loggedInUser) {
    getScoredefinition();
    let isPageTourEnabled = false;

      if (pagetourConfig.pageTourUsers) {
        isPageTourEnabled =
          pagetourConfig.pageTourUsers.find(
            (user) => user.toLowerCase() === loggedInUser.alias.toLowerCase()
          ) !== undefined;
      }
if (isPageTourEnabled) {
    getPageTours();
}
    this.autoplayTour();
  }
  }

  componentDidUpdate() {
    const { loggedInUser } = this.props;
    if (loggedInUser) {
      Logger.initialize(loggedInUser);
      this.autoplayTour();
    }
  }

  focusMainRegion = () => {
    this.mainRegion.current.focus();
  };

  autoplayTour() {
    const { tours } = this.props;

    const autoplayableTour = tours.find(
      (tour) => !tour.isviewed && tour.isautoplayenabled
    );

    if (autoplayableTour) pagetourService.playTour(autoplayableTour);
  }

  pageTourCallback(context) {
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;

    const url = `${window.location.origin}${context.url}`;
    if (window.location.href.startsWith(url)) {
      return;
    }
    const newUrl = `${window.location.protocol}//${window.location.host}${context.url}`;
    if (window.location.href !== newUrl) {
      history.push(context.url);
    }
  }


  render() {
    const { loggedInUser } = this.props;

    return (
      <div className="App">
        <Announcement />
        <button
          type="button"
          className="skipToMainRegionButton"
          onClick={this.focusMainRegion}
        >
          Skip to main region
        </button>
        {loggedInUser === null ? (
          <>
            <ScoreCardHeader loggedInUser={{}} />
            <h2>Authenticating...</h2>
          </>
        ) : (
          <>
            <ScoreCardHeader loggedInUser={loggedInUser} onNavLinkClicked={this.focusMainRegion} />
                        {loggedInUser.isAdmin ||
                            loggedInUser.isSupplierLead ||
                            loggedInUser.isBobOwner ? (
                                <main className="bodysection" tabIndex="-1" ref={this.mainRegion}>
                                    <Switch>
                                        <Route
                                          exact
                                          path="/"
                                          render={() => <Redirect to="/actions" />}
                                        />
                                        <Route path="/actions" component={MyActionsPageComponent} />
                                        <Route path="/samPortal" component={SAMPortalComponent} />
                                        <Route path="/supplieronboarding" component={SupplierOnboardingPageComponent} />
                                        <Route path="/reports" component={ReportsPage} />
                                        <Route path="/resources" component={ResourcesPage} />
                                    </Switch>
                                </main>
                            ) :
                            (

                                loggedInUser.isFunctionalUser ? (
                                    <main className="bodysection" tabIndex="-1" ref={this.mainRegion}>
                                        <Switch>
                                            <Route
                                              exact
                                              path="/"
                                              render={() => <Redirect to="/actions" />}
                                            />
                                            <Route path="/actions" component={MyActionsPageComponent} />
                                            <Route path="/reports" component={ReportsPage} />
                                            <Route path="/resources" component={ResourcesPage} />
                                        </Switch>
                                    </main>
                                ) : (
                                        <UnauthorizedPage />
                                    ))
                        }
            <Footer />
          </>
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  tours: state.pageTour.pageTours,
});

const mapDispatchToProps = {
  signInUser: signinactions.signInUser,
  getScoredefinition: getScoredefinitionactions.getScoredefinition,
  getPageTours: pageTourActions.getPageTours,
};

App.propTypes = {
  signInUser: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object,
  getScoredefinition: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getPageTours: PropTypes.func.isRequired,
  tours: PropTypes.array,
};

App.defaultProps = {
  loggedInUser: null,
  tours: [],
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
);
