export class SupplierActionPlanModel {
    constructor(supplierMetricScorecardId, actionPlanId,
         actionPlan, dueCadenceId, status, isApplicable, isActive, subMetricId,
         supplierId, cadenceId) {
      this.supplierMetricScorecardId = supplierMetricScorecardId;
      this.actionPlanId = actionPlanId;
      this.actionPlan = actionPlan;
      this.dueCadenceId = dueCadenceId;
      this.status = status;
      this.isApplicable = isApplicable;
      this.isActive = isActive;
      this.subMetricId = subMetricId;
      this.supplierId = supplierId;
      this.cadenceId = cadenceId;
    }
}

export class BobActionPlanModel {
    constructor(bobMetricCategoryScorecardId, actionPlanId,
        actionPlan, dueCadenceId, status, isApplicable, isActive, isValueActionPlan) {
     this.bobMetricCategoryScorecardId = bobMetricCategoryScorecardId;
     this.actionPlanId = actionPlanId;
     this.actionPlan = actionPlan;
     this.dueCadenceId = dueCadenceId;
     this.status = status;
     this.isApplicable = isApplicable;
     this.isActive = isActive;
     this.isValueActionPlan = isValueActionPlan;
   }
}

export class AddSupplierMetricActionPlanOptionModel {
  constructor(id, score, name) {
    this.id = id;
    this.score = score;
    this.name = name;
  }
}

export class AddBobMetricActionPlanOptionModel {
  constructor(id, score, name, isBobMetricId) {
    this.id = id;
    this.score = score;
    this.name = name;
    this.isBobMetricId = isBobMetricId;
  }
}
