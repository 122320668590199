/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';

function resetMessage() {
  return async dispatch => {
    dispatch({
      type: types.RESET_MESSAGE_SUCCESS
    });
  };
}

export default resetMessage;
