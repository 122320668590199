import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function ScoreDefinitionListReducer(
  state = initialState.scoreDefinitionList,
  action
) {
  switch (action.type) {
    case types.LOAD_SCOREDEFINITION_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
