import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function ScorecardSuppliersReducer(state = initialState.scorecardsuppliers, action) {
  switch (action.type) {
    case types.LOAD_SCORECARDSUPPLIERS_SUCCESS:
      return action.scorecardsuppliers;
    default:
      return state;
  }
}

export function ScorecardBOBsReducer(state = initialState.scorecardbobs, action) {
  switch (action.type) {
    case types.LOAD_SCORECARDBOBS_SUCCESS:
      return action.scorecardbobs;
    default:
      return state;
  }
}

export function ScorecardPeriodsReducer(state = initialState.scorecardperiods, action) {
  switch (action.type) {
    case types.LOAD_SCORECARDPERIODS_SUCCESS:
      return action.scorecardperiods;
    default:
      return state;
  }
}

export function ScorecardSupplierPeriodsReducer(state = initialState.scorecardperiods, action) {
  switch (action.type) {
    case types.LOAD_SCORECARDSUPPLIERPERIODS_SUCCESS:
      return action.scorecardSupplierPeriods;
    default:
      return state;
  }
}
