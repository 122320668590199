import ActionFilters from '../../common/consts/actionFilters';
import ActionModes from '../../common/consts/actionModes';
import MessageType from '../../common/consts/messageType';

export default {
  loggedInUser: null,
  myActions: {
    selectedBobEntry: null,

    currentCadence: null,
    futureCadence: [],
    selectedSupplierLeadEntry: null,
    actionFilter: ActionFilters.All,
    bobEntryActions: { isloaded: false, actions: [], deletedBOBId: 0 },
    supplierLeadEntryActions: { isloaded: false, actions: [], deletedBOBId: 0 },
    selectedActionMode: ActionModes.BoBEntry,
  },
  samActionPlanData: {
    selectedSupplier: null,
    selectedSamEntry: null,
    actionFilter: ActionFilters.All,
    samEntryActions: { isloaded: false, actions: [] },
    samSupplierScorecardDetails: null,
    samSelectedScorecardCategory: null,
    prevSamSelectedScorecardCategoryForSupplier: null,
    prevSamSelectedScorecardCategoryForBob: null,
    samSummaryDetailsLoading: false,
    actionPlanDetails: {
      bobActionPlans: null,
      supplierMetricActionPlans: null,
      actionPlanParentScorecardId: null,
      category: null,
      isLoading: false,
      isReadOnly: false,
      isCompleted: false,
      samActionPlanHistory: [],
    },
    actionPlanFutureCadences: null,
    actionPlanFutureCadencesForValueActionPlan: null,
    samSupplierPlanMetricDetails: {
      supplierMetrics: null,
      monitorSupplierMetrics: [],
      isSamSupplierMetricsLoading: false
    },
    samDefaultSupplierMetricsDetails: {
      supplierDefaultMetrics: null
    },
    samSelectedSupplierPlanMetricDetails: {
      selectedSupplierMetricId: null,
      subMetrics: null
    },
    samBobPlanMetricDetails: {
      bobMetrics: null,
      isSamBobMetricsLoading: false
    }
  },
  selectedBoBEntry: null,
  selectedSupplier: null,
  suppliers: [],
  onboardedSuppliers: [],
  cadences: [],
  domains: [],
  supplierTypes: [],
  procurementTypes: [],
  leads: [],
  boblist: [],
  stdcategorygroups: [],
  stdcategorynames: [],
  execorgs: [],
  execorgdetails: [],
  execorgSummary: [],
  owners: [],
  reportingCadenceList: [],
  scorecardsuppliers: [],
  scorecardbobs: {
    bobs: [],
  },
  scorecardperiods: { cadences: [] },
  scorecardSupplierPeriods: { cadences: [] },
  metricConditions: [],
  scoreDefinitionList: [],
  pageTour: {
    pageTours: [],
    showPagetourdialog: false,
  },
  reportingSuppliers: [],
  reportingPeriods: { cadences: [] },
  configuredMetrics: {
    metricsByCategory: [],
    metricCategoryId: 0,
    metricsInfo: [],
    isDefaultMetric: false,
    submitResponse: null,
    shouldRenderModal: true,
  },
  reports: null,
  resource: null,
  capHistory: [],
  comments: [],
  bobEntryAction: [],
  BobScorecardPanelTabs: [],
  SupplierScorecardPanelTabs: [],
  myActionsScorecard: {
    categoryDetailsLoading: false,
    summaryDetailsLoading: false,
    selectedScorecardCategory: null,
    bobScorecardDetails: null,
    supplierScorecardDetails: null,
    bobScorecardCategoryDetails: null,
    supplierScorecardCategoryDetails: null,
    bobScorecardMetricHistory: [],
    supplierScorecardMetricHistory: [],
    bobScorecardCategoryHistory: [],
    supplierScorecardCategoryHistory: [],
    supplierScorecardCategoryAvgBobScoreHistory: [],
  },
  message: {
    shouldShowMessage: false,
    type: MessageType.None,
    displayMessage: '',
    tcv: '',
    isSuccessSavingData: false,
    isErrorSavingData: false
  },
  announcement: {
    polite: '',
    assertive: '',
  },
};
