import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import withErrorHandling from '../hoc/withErrorHandling';
import './MyActionsPage.css';
import ScorecardFilterComponent from './scorecardFilterPanel/ScorecardFilterPanel';
import MetricsPanel from './metricsContainerPanel/MetricsContainerPanel';
import ActionsTabPanel from './actionsPanel/ActionsPanel';
import resetMessagebarAction from '../../redux/actions/messageBarActions';
import ErrorDiv from '../hoc/errordiv';

export class MyActionsPage extends Component {
  componentDidMount() {
    const { resetMessageBar } = this.props;
    resetMessageBar();
  }


test() {
  const {
    selectedBobEntry,
    selectedBobEntryAction,
    selectedSupplierLeadEntryAction
  } = this.props;

    if (selectedBobEntry) {
      if (selectedBobEntryAction.bobEntryActions) {
     return selectedBobEntryAction.bobEntryActions.deletedBOBId === selectedBobEntry.bob.id;
     }

     if (selectedSupplierLeadEntryAction.supplierLeadEntryActions) {
      return selectedSupplierLeadEntryAction.supplierLeadEntryActions.deletedBOBId
      === selectedBobEntry.bob.id;
    }
  }
    return false;
  }

  render() {
    const { summaryDetailsLoading } = this.props;

    const test = this.test();
    return (
      <div className="zoom">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="row">
            <ActionsTabPanel />
            <div className="mt-3 col-md-9 col-lg-9 col-sm-9">
              <div>
                <ScorecardFilterComponent />
              </div>
              <div>
                  {/* eslint-disable-next-line no-nested-ternary */ }
                {summaryDetailsLoading ? (
                  <Spinner className="spinner" />
                ) : test
                    ?
                    (<ErrorDiv
                      error="Selected BOB has been deleted, Please refresh the page."
                      hideRetry
                    />) : (
                  <MetricsPanel isSamPortalRequest={false} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    summaryDetailsLoading: state.myActionsScorecard.summaryDetailsLoading,
    selectedBobEntryAction: state.myActions,
    selectedBobEntry: state.myActions.selectedBobEntry,
    selectedSupplierLeadEntryAction: state.myActions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetMessageBar: bindActionCreators(resetMessagebarAction, dispatch)
  };
}

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyActionsPage)
);

MyActionsPage.defaultProps = {
  selectedBobEntry: null
};

MyActionsPage.propTypes = {
  summaryDetailsLoading: PropTypes.bool.isRequired,
  resetMessageBar: PropTypes.func.isRequired,
  selectedBobEntry: PropTypes.object,
  selectedBobEntryAction: PropTypes.object.isRequired,
  selectedSupplierLeadEntryAction: PropTypes.object.isRequired
};
