export class MetricsInfoModel {
  constructor(metricInfoId, isEditing, hasSave, isProcessed, metric) {
    this.isEditing = isEditing;
    this.hasSave = hasSave;
    this.metric = metric;
    this.isProcessed = isProcessed;
    this.metricInfoId = metricInfoId;
  }
}

export class MetricModal {
  constructor(
    metricId,
    metricName,
    metricDesc,
    targetValue,
    metricConditionTypeId,
    isTargetApplicable,
    metricWeight,
    subMetrics,
    isDeleted,
    orderNumber,
    createdBy,
    isUpdated,
    isNew,
    key
  ) {
    this.metricId = metricId;
    this.metricName = metricName;
    this.metricDescription = metricDesc;
    this.metricTarget = targetValue;
    this.metricConditionTypeId = metricConditionTypeId;
    this.isTargetApplicable = isTargetApplicable;
    this.metricWeight = metricWeight;
    this.subMetrics = subMetrics;
    this.isDeleted = isDeleted;
    this.orderNumber = parseInt(orderNumber, 10);
    this.createdBy = createdBy;
    this.isUpdated = isUpdated;
    this.isNew = isNew;
    this.key = key;
  }
}
