/* eslint-disable no-unused-vars */
import * as Msal from 'msal';
import config from '../../config/appConfig';

const aadConfig = config.aad;

function acquireTokenRedirectCallBack(errorDesc, token, error, tokenType) {}

const authContext = new Msal.UserAgentApplication(
  aadConfig.clientID,
  aadConfig.authority,
  acquireTokenRedirectCallBack,
  {
    navigateToLoginRequestUrl: false,
    storeAuthStateInCookie: aadConfig.storeAuthStateInCookie,
    cacheLocation: aadConfig.cacheLocation,
    redirectUri: aadConfig.redirectUri
  }
);

export default authContext;
