import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function ReportingSuppliersReducer(
  state = initialState.reportingSuppliers,
  action
) {
  switch (action.type) {
    case types.LOAD_REPORTINGSUPPLIERS_SUCCESS:
      return action.reportingSuppliers;
    default:
      return state;
  }
}

export function ReportingPeriodsReducer(
  state = initialState.reportingPeriods,
  action
) {
  switch (action.type) {
    case types.LOAD_REPORTINGPERIODS_SUCCESS:
      return action.reportingPeriods;
    default:
      return state;
  }
}
