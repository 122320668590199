export default class MetricPostCallRequest {
  constructor(
    bobId,
    categoryId,
    metricId,
    metricName,
    metricDescription,
    isTargetApplicable,
    metricTarget,
    metricConditionTypeId,
    parentMetricId,
    metricWeight,
    orderNumber,
    createdBy,
    isDeleted,
    isUpdated,
    isNew,
    key
  ) {
    this.bobId = bobId;
    this.metricCategoryId = categoryId;
    this.metricId = metricId;
    this.metricName = metricName;
    this.metricDescription = metricDescription;
    this.isTargetApplicable = isTargetApplicable;
    this.metricTarget = metricTarget;
    this.metricConditionTypeId = metricConditionTypeId;
    this.parentMetricId = parentMetricId;
    this.metricWeight = metricWeight;
    this.orderNumber = orderNumber;
    this.createdBy = createdBy;
    this.isDeleted = isDeleted;
    this.isUpdated = isUpdated;
    this.isNew = isNew;
    this.key = key;
  }
}
