export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const LOAD_ACTIONS_SUCCESS = 'LOAD_ACTIONS_SUCCESS';
export const LOAD_SUPPLIERBOB_SUCCESS = 'LOAD_SUPPLIERBOB_SUCCESS';
export const LOAD_STANDARDCATEGORYGROUP_SUCCESS =
  'LOAD_STANDARDCATEGORYGROUP_SUCCESS';
export const LOAD_STANDARDCATEGORYNAME_SUCCESS =
  'LOAD_STANDARDCATEGORYNAME_SUCCESS';
export const LOAD_EXECORG_SUCCESS = 'LOAD_EXECORG_SUCCESS';
export const LOAD_EXECORGSUMMARYDETAILS_SUCCESS = 'LOAD_EXECORGSUMMARYDETAILS_SUCCESS';
export const LOAD_EXECORGDETAILS_SUCCESS = 'LOAD_EXECORGDETAILS_SUCCESS';
export const LOAD_OWNER_SUCCESS = 'LOAD_OWNER_SUCCESS';
export const BOBENTRY_SELECTED = 'BOBENTRY_SELECTED';
export const LOAD_ONBOARDEDSUPPLIERS_SUCCESS =
  'LOAD_ONBOARDEDSUPPLIERS_SUCCESS';
export const LOAD_SUPPLIERS_SUCCESS = 'LOAD_SUPPLIERS_SUCCESS';
export const LOAD_DOMAINS_SUCCESS = 'LOAD_DOMAINS_SUCCESS';
export const LOAD_LEADS_SUCCESS = 'LOAD_LEADS_SUCCESS';
export const LOAD_CADENCE_SUCCESS = 'LOAD_CADENCE_SUCCESS';
export const LOAD_FUTURECADENCE_SUCCESS = 'LOAD_FUTURECADENCE_SUCCESS';
export const LOAD_FUTURECADENCEFORVALUEACTIONPLAN_SUCCESS = 'LOAD_FUTURECADENCEFORVALUEACTIONPLAN_SUCCESS';
export const LOAD_SUPPLIERTYPES_SUCCESS = 'LOAD_SUPPLIERTYPES_SUCCESS';
export const LOAD_PROCUREMENTTYPES_SUCCESS = 'LOAD_PROCUREMENTTYPES_SUCCESS';
export const ADD_NEWBOB_SUCCESS = 'ADD_NEWBOB_SUCCESS';
export const LOAD_REPORTINGCADENCE_SUCCESS = 'LOAD_REPORTINGCADENCE_SUCCESS';
export const ADD_ONBOARDSUPPLIER_SUCCESS = 'ADD_ONBOARDSUPPLIER_SUCCESS';
export const UPDATE_ONBOARDEDSUPPLIER_SUCCESS =
  'UPDATE_ONBOARDEDSUPPLIER_SUCCESS';
export const LOAD_METRICCONDITIONS_SUCCESS = 'LOAD_METRICCONDITIONS_SUCCESS';
export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS';
export const LOAD_RESOURCE_SUCCESS = 'LOAD_RESOURCE_SUCCESS';
export const UPDATE_ONBOARDEDBOB_SUCCESS = 'UPDATE_ONBOARDEDBOB_SUCCESS';
export const SET_ACTIONS_FILTER = 'SET_ACTIONS_FILTER';
export const LOAD_BOBENTRYACTIONS_SUCCESS = 'LOAD_BOBENTRYACTIONS_SUCCESS';
export const SET_SELECTED_BOBENTRYACTION = 'SET_SELECTED_BOBENTRYACTION';
export const SET_SELECTED_SUPPLIERENTRY = 'SET_SELECTED_SUPPLIERENTRY';
export const LOAD_SUPPLIERLEAD_ENTRYACTIONS_SUCCESS =
  'LOAD_SUPPLIERLEAD_ENTRYACTIONS_SUCCESS';
export const SET_SELECTED_SUPPLIERLEADENTRYACTION =
  'SET_SELECTED_SUPPLIERLEADENTRYACTION';
export const SET_SELECTED_ACTIONMODE = 'SET_SELECTED_ACTIONMODE';
export const LOAD_SUPPLIERSCORECARD_SUMMARY_SUCCESS =
  'LOAD_SUPPLIERSCORECARD_SUMMARY_SUCCESS';
export const LOAD_SCOREDEFINITION_SUCCESS = 'LOAD_SCOREDEFINITION_SUCCESS';
export const DELETE_ONBOARDEDSUPPLIER_SUCCESS =
  'DELETE_ONBOARDEDSUPPLIER_SUCCESS';
export const LOAD_SCORECARDSUPPLIERS_SUCCESS =
  'LOAD_SCORECARDSUPPLIERS_SUCCESS';
export const LOAD_SCORECARDBOBS_SUCCESS = 'LOAD_SCORECARDBOBS_SUCCESS';
export const LOAD_SCORECARDPERIODS_SUCCESS = 'LOAD_SCORECARDPERIODS_SUCCESS';
export const LOAD_SCORECARDSUPPLIERPERIODS_SUCCESS =
  'LOAD_SCORECARDSUPPLIERPERIODS_SUCCESS';
export const LOAD_PERFORMANCEMETRICS_SUCCESS =
  'LOAD_PERFORMANCEMETRICS_SUCCESS';
export const UPDATE_METRIC_CATEGORY = 'UPDATE_METRIC_CATEGORY';
export const ADD_NEWMETRIC_SUCCESS = 'ADD_NEWMETRIC_SUCCESS';
export const EDIT_METRIC_SUCCESS = 'EDIT_METRIC_SUCCESS';
export const SAVE_METRIC_SUCCESS = 'SAVE_METRIC_SUCCESS';
export const DELETE_METRIC_SUCCESS = 'DELETE_METRIC_SUCCESS';
export const SUBMET_METRIC_SUCCESS = 'SUBMET_METRIC_SUCCESS';
export const ADD_NEWSUBMETRIC_SUCCESS = 'ADD_NEWSUBMETRIC_SUCCESS';
export const EDIT_SUBMETRIC_SUCCESS = 'EDIT_SUBMETRIC_SUCCESS';
export const SAVE_SUBMETRIC_SUCCESS = 'SAVE_SUBMETRIC_SUCCESS';
export const DELETE_SUBMETRIC_SUCCESS = 'DELETE_SUBMETRIC_SUCCESS';
export const UNDO_METRIC_SUCCESS = 'UNDO_METRIC_SUCCESS';
export const UNDO_SUBMETRIC_SUCCESS = 'UNDO_SUBMETRIC_SUCCESS';
export const LOAD_BOBSCORECARDCAPHISTORY_SUCCESS =
  'LOAD_BOBSCORECARDCAPHISTORY_SUCCESS';
export const LOAD_SUPPLIERSCORECARDCAPHISTORY_SUCCESS =
  'LOAD_SUPPLIERSCORECARDCAPHISTORY_SUCCESS';
export const LOAD_BOBSCORECARDCOMMENTHISTORY_SUCCESS =
  'LOAD_BOBSCORECARDCOMMENTHISTORY_SUCCESS';
export const LOAD_SUPPLIERSCORECARDCOMMENTHISTORY_SUCCESS =
  'LOAD_SUPPLIERSCORECARDCOMMENTHISTORY_SUCCESS';
export const LOAD_BOBSCORECARDDETAILS_SUCCESS =
  'BobScorecardDetails/LOAD/SUCCESS';
export const LOAD_SUPPLIERSCORECARDDETAILS_SUCCESS =
  'SupplierScorecardDetails/LOAD/SUCCESS';
export const LOAD_BOBSCORECARD_CATEGORYDETAILS_SUCCESS =
  'BobScorecardCategoryDetails/LOAD/SUCCESS';
export const LOAD_SUPPLIERSCORECARD_CATEGORYDETAILS_SUCCESS =
  'SupplierScorecardCategoryDetails/LOAD/SUCCESS';
export const UPDATE_SELECTED_CATEGORY = 'SelectedCategory/UPDATE';
export const LOAD_BOBSCORECARD_METRICHISTORY_SUCCESS =
  'LOAD_BOBSCORECARD_METRICHISTORY_SUCCESS';
export const LOAD_SUPPLIERSCORECARD_METRICHISTORY_SUCCESS =
  'LOAD_SUPPLIERSCORECARD_METRICHISTORY_SUCCESS';
export const SUBMET_METRIC_FAILURE = 'SUBMET_METRIC_FAILURE';
export const METRIC_APPLICABILITY_CHANGE = 'MetricApplicability/Update';
export const METRIC_APPLICABILITY_ALL_CHANGE = 'MetricAllApplicability/AllUpdate';
export const METRIC_RATING_CHANGE = 'MetricRating/UPDATE';
export const FETCH_CATEGORYDATA_INIT = 'CategoryData/FETCH/INIT';
export const FETCH_SUMMARYDATA_INIT = 'scorecardSummary/FETCH/INIT';
export const UPDATE_BOBSCORECARD_METRICS_SUCCESS =
  'bobScorecardMetrics/Update/Success';
export const UPDATE_CAP_DETAILS = 'capDetails/Update';
export const CATEGORY_COMMENTS_CHANGE = 'myActionScorecardComments/UPDATE';
export const LOAD_SUPPLIERSCORECARD_CATEGORYHISTORY_SUCCESS =
  'LOAD_SUPPLIERSCORECARD_CATEGORYHISTORY_SUCCESS';
export const LOAD_BOBSCORECARD_CATEGORYHISTORY_SUCCESS =
  'LOAD_BOBSCORECARD_CATEGORYHISTORY_SUCCESS';
export const LOAD_SUPPLIERSCORECARD_CATEGORY_AVGBOBSCOREHISTORY_SUCCESS =
  'LOAD_SUPPLIERSCORECARD_CATEGORY_AVGBOBSCOREHISTORY_SUCCESS';
export const SORTED_ONBOARDEDSUPPLIERS_SUCCESS =
  'SORTED_ONBOARDEDSUPPLIERS_SUCCESS';
export const SORTED_ONBOARDEDSUPPLIERBOBS_SUCCESS =
  'SORTED_ONBOARDEDSUPPLIERBOBS_SUCCESS';
export const ADD_PROCESSEDMESSAGE_SUCCESS = 'ADD_PROCESSEDMESSAGE_SUCCESS';
export const ADD_UNPROCESSEDMESSAGE_SUCCESS = 'ADD_UNPROCESSEDMESSAGE_SUCCESS';
export const RESET_MESSAGE_SUCCESS = 'RESET_MESSAGE_SUCCESS';
export const UPDATE_SUPPLIERSCORECARD_METRICS_SUCCESS =
  'supplierScorecardMetrics/Update/Success';
export const LOAD_PERFORMANCEMETRICS_FAILURE =
  'LOAD_PERFORMANCEMETRICS_FAILURE';
export const UPDATE_SUPPLIERONBOARDEDBOB_SUCCESS =
  'UPDATE_SUPPLIERONBOARDEDBOB_SUCCESS';
export const LOAD_PAGETOUR_SUCCESS = 'pageTour/FETCH/SUCCESS';
export const SHOW_PAGETOUR_DIALOG = 'pageTour/HIDE/';

export const LOAD_CURRENTCADENCEPERIOD_SUCCESS =
  'LOAD_CURRENTCADENCEPERIOD_SUCCESS';
export const DELETE_ONBOARDEDSUPPLIER_BOB_SUCCESS =
  'DELETE_ONBOARDEDSUPPLIER_BOB_SUCCESS';
export const ACTIVATE_ONBOARDEDSUPPLIER_BOB_SUCCESS =
  'ACTIVATE_ONBOARDEDSUPPLIER_BOB_SUCCESS';
export const DELETE_ONBOARDEDSUPPLIERBOB_FROM_BOBENTRYACTION_SUCCESS =
  'DELETE_ONBOARDEDSUPPLIERBOB_FROM_BOBENTRYACTION_SUCCESS';
export const ACTIVATE_ONBOARDEDSUPPLIERBOB_FROM_BOBENTRYACTION_SUCCESS =
  'ACTIVATE_ONBOARDEDSUPPLIERBOB_FROM_BOBENTRYACTION_SUCCESS';
export const DELETE_ONBOARDEDSUPPLIERBOB_FROM_SUPPLIERLEADENTRYACTION_SUCCESS =
  'DELETE_ONBOARDEDSUPPLIERBOB_FROM_SUPPLIERLEADENTRYACTION_SUCCESS';
export const ACTIVATE_ONBOARDEDSUPPLIERBOB_FROM_SUPPLIERLEADENTRYACTION_SUCCESS =
  'ACTIVATE_ONBOARDEDSUPPLIERBOB_FROM_SUPPLIERLEADENTRYACTION_SUCCESS';
export const LOAD_REPORTINGSUPPLIERS_SUCCESS =
  'LOAD_REPORTINGSUPPLIERS_SUCCESS';
export const LOAD_REPORTINGPERIODS_SUCCESS = 'LOAD_REPORTINGPERIODS_SUCCESS';

export const FETCH_ACTIONPLAN_DATA_INIT = 'FETCH_ACTIONPLAN_DATA_INIT';
export const LOAD_ACTIONPLAN_DETAILS_SUCCESS =
  'LOAD_ACTIONPLAN_DETAILS_SUCCESS';
export const LOAD_SAM_ENTRYACTIONS_SUCCESS = 'LOAD_SAM_ENTRYACTIONS_SUCCESS';
export const SET_SELECTED_SAMENTRYACTION = 'SET_SELECTED_SAMENTRYACTION';
export const ADD_SAM_ACTIONPLAN = 'ADD_SAM_ACTIONPLAN';
export const DELETE_SAM_ACTIONPLAN = 'DELETE_SAM_ACTIONPLAN';
export const CHANGE_ACTION_PLAN_DUEDATE = 'CHANGE_ACTION_PLAN_DUEDATE';
export const CHANGE_ACTION_PLAN_SUBMETRIC = 'CHANGE_ACTION_PLAN_SUBMETRIC';
export const CHANGE_ACTION_PLAN_STATUS = 'CHANGE_ACTION_PLAN_STATUS';
export const CHANGE_ACTION_PLAN_ISAPPLICABLE =
  'CHANGE_ACTION_PLAN_ISAPPLICABLE';
export const CHANGE_ACTION_PLAN = 'CHANGE_ACTION_PLAN';
export const UPDATE_ACTIONPLAN_DETAILS_SUCCESS =
  'UPDATE_ACTIONPLAN_DETAILS_SUCCESS';
export const UPDATE_SAM_SELECTED_CATEGORY = 'UPDATE_SAM_SELECTED_CATEGORY';
export const SET_MONITOR_SUPPLIER_METRICS = 'SET_MONITOR_SUPPLIER_METRICS';
export const FETCH_SAM_SUMMARYDATA_INIT = 'FETCH_SAM_SUMMARYDATA_INIT';
export const LOAD_SAM_SUPPLIERSCORECARDDETAILS_SUCCESS =
  'LOAD_SAM_SUPPLIERSCORECARDDETAILS_SUCCESS';
export const RESET_DIRTY_ACTION_PLANS = 'RESET_DIRTY_ACTION_PLANS';
export const FETCH_SAM_SUPPLIER_METRIC_DATA_INIT = 'FETCH_SAM_SUPPLIER_METRIC_DATA_INIT';
export const LOAD_SAM_SUPPLIER_METRIC_DETAILS_SUCCESS = 'LOAD_SAM_SUPPLIER_METRIC_DETAILS_SUCCESS';
export const LOAD_SAM_SUPPLIER_METRIC_SUCCESS = 'LOAD_SAM_SUPPLIER_METRIC_SUCCESS';
export const FETCH_SAM_BOB_METRIC_DATA_INIT = 'FETCH_SAM_BOB_METRIC_DATA_INIT';
export const FETCH_SAM_BOB_METRIC_ID_DATA_INIT = 'FETCH_SAM_BOB_METRIC_ID_DATA_INIT';
export const LOAD_SAM_BOB_METRIC_DETAILS_SUCCESS = 'LOAD_SAM_BOB_METRIC_DETAILS_SUCCESS';
export const ADD_SAM_METRIC_ACTIONPLAN = 'ADD_SAM_METRIC_ACTIONPLAN';
export const GET_ACTIONPLAN_HISTORY_SUCCESS = 'GET_ACTIONPLAN_HISTORY_SUCCESS';
export const ACTIONPLAN_TOGGLE_CHANGE_SUCCESS =
  'ACTIONPLAN_TOGGLE_CHANGE_SUCCESS';
export const POLITE_ANNOUNCEMENT = 'POLITE_ANNOUNCEMENT';
export const ASSERTIVE_ANNOUNCEMENT = 'ASSERTIVE_ANNOUNCEMENT';
export const CLEAR_ANNOUNCEMENT = 'CLEAR_ANNOUNCEMENT';
export const CHANGE_ACTION_PLAN_ISVALUEACTIONPLAN = 'CHANGE_ACTION_PLAN_ISVALUEACTIONPLAN';
