/* eslint-disable import/prefer-default-export */
// import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';

function loadReportsSuccess(reports) {
  return { type: types.LOAD_REPORTS_SUCCESS, reports };
}

export function getReports() {
  return async dispatch => {
    try {
      // const reports = await performanceApi.getReports();
      const reports = [
        {
          id: 1,
          title: 'Dashboard',
          isExternal: true,
          link:
            'https://msit.powerbi.com/groups/a29e6305-64eb-41b8-b94e-ac553969c113/dashboards/9c150044-c891-4712-9a98-e0d15d39f5c5'
         },
        {
          id: 2,
          title: 'Download Report',
          isExternal: false,
          link: '/reports/ssrsWordDocument'
        }
      ];
      dispatch(loadReportsSuccess(reports));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}
