/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

export default function configureStore(initialState) {
  // add support for Redux dev tools
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
}
