import React, { Component } from 'react';
import './Footer.css';
import mslogo from '../../assets/images/mslogo.png';
import config from '../../config/appConfig';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="row">
          <div className="col-md-2">
            <input
              type="image"
              src={mslogo}
              alt="Microsoft logo"
              className="footerlogo"
            />
          </div>
          <div className="col-md-10">
            <div className="mslink">
              <span>Microsoft Confidential</span>
              <a
                className="link"
                href={config.DataProtectionNotice}
                target="_blank"
                rel="noopener noreferrer"
              >
                Data Privacy Notice
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
