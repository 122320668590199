/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import PropTypes from 'prop-types';
import './metricsScore.css';

export default class MetricsScore extends React.Component {
  render() {
    const { metricDetails, disabled } = this.props;
    function decimalPrecision(number) {
      return Math.trunc(number * 10) / 10;
    }
    let className = null;
    if (disabled) {
      className = 'outer-score disabled';
    } else {
      className = 'outer-score';
    }
    return (
      <>
        <section className={className}>
          <label className="metric-weight-label">
            <p className="metric-label-text">Weight</p>
            <p className="metric-weight input-border">{decimalPrecision(metricDetails.weight)}%</p>
          </label>
          {metricDetails.hasTarget && (
            <>
              <label className="metric-target-label">
                <p className="metric-label-text">Target</p>
                <p className="metric-target-condition input-border">
                  {metricDetails.targetCondition}
                </p>
                <p className="metric-target input-border">
                  {metricDetails.target}
                </p>
              </label>
            </>
          )}

          <label className="metric-points-label">
            <p className="metric-label-text">Points</p>
            <p className="metric-points input-border">{decimalPrecision(metricDetails.point)}</p>
          </label>
        </section>
      </>
    );
  }
}
MetricsScore.defaultProps = {
  disabled: false
};

MetricsScore.propTypes = {
  metricDetails: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};
