/* eslint-disable no-empty */
import { TelemetryExtensionsNg4 } from '@microsoftit/telemetry-extensions-ng4/src/telemetry-extensions-ng4';
import config from '../../config/appConfig';

const telemetryConfig = config.telemetry;

class TelemetryClient {
    static client = new TelemetryExtensionsNg4();

    static appInsights = this.client.InitializeTelemetry(
    telemetryConfig.environment,
    telemetryConfig.instrumentationKey,
    telemetryConfig.serviceOffering,
    telemetryConfig.serviceLine,
    telemetryConfig.service,
    telemetryConfig.componentName,
    telemetryConfig.componentId,
    telemetryConfig.ictoId
  );

  static trackFeatureUsageEvent(event, properties, measurements) {
    try {
      this.client.TrackFeatureUsageEvent(
        this.appInsights,
        event,
        properties,
        measurements
      );
    } catch (error) {}
  }

  static trackBusinessProcessEvent(event, properties, measurements) {
    try {
      this.client.TrackBusinessProcessEvent(
        this.appInsights,
        event,
        properties,
        measurements
      );
    } catch (error) {}
  }

  static trackEvent(eventName, properties, prop3) {
    try {
      this.client.trackEvent(eventName, properties, prop3);
    } catch (error) {}
  }

  static trackException(errorMessage, prop2, prop3, prop4, prop5) {
    try {
      this.client.trackException(errorMessage, prop2, prop3, prop4, prop5);
    } catch (error) {}
  }

  static trackTrace(traceMessage, traceObject, traceSeverity) {
    try {
      this.client.trackTrace(traceMessage, traceObject, traceSeverity);
    } catch (error) {}
  }


  static TelemetryConstants = {
    eventName: {
      myActions: {
        ActionLoadSuccess: 'My Actions Load Successfull',
        ActionLoadFailure: 'My Actions Load Failed',
        UIFailure: 'My Actions UI Failure'
      }
    }
  };
}

export default TelemetryClient;
