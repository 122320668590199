import React, { Component } from 'react';
import './unauthorized.css';

export default class UnauthorizedPage extends Component {
  render() {
    return (
      <div className="unauthorized">
        <h4>
          <span>Unauthorized Access. Please contact scorecard support </span>
          <a href="mailto:pscorecard@microsoft.com">pscorecard@microsoft.com</a> for
          role assignment.
        </h4>
      </div>
    );
  }
}
