import * as React from 'react';
import PropTypes from 'prop-types';
import CustomMetric from '../metric/Metric';

export default class PerformanceSubMetric extends React.Component {
  constructor(props) {
    super(props);
    this.onClickEditPerfSubMetric = this.onClickEditPerfSubMetric.bind(this);
    this.onClickSavePerfSubMetric = this.onClickSavePerfSubMetric.bind(this);
    this.onClickDeletePerfSubMetric = this.onClickDeletePerfSubMetric.bind(
      this
    );
    this.onClickUndoPerfSubMetric = this.onClickUndoPerfSubMetric.bind(this);
  }

  onClickEditPerfSubMetric(subMetricInfoIdToBeEdit) {
    const { onClickEdit } = this.props;
    onClickEdit(subMetricInfoIdToBeEdit);
  }

  onClickUndoPerfSubMetric(subMetricInfoIdToBeUndo) {
    const { onClickUndo } = this.props;
    onClickUndo(subMetricInfoIdToBeUndo);
  }

  onClickSavePerfSubMetric(subMetricInfoToBeSave) {
    const { onClickSave } = this.props;
    onClickSave(subMetricInfoToBeSave);
  }

  onClickDeletePerfSubMetric(subMetricInfoToBeDelete) {
    const { onClickDelete } = this.props;
    onClickDelete(subMetricInfoToBeDelete);
  }

  render() {
    const { performanceMetricInfo } = this.props;
    return (
      <CustomMetric
        key={performanceMetricInfo.metricInfoId}
        onClickEdit={this.onClickEditPerfSubMetric}
        onClickSave={this.onClickSavePerfSubMetric}
        onClickDelete={this.onClickDeletePerfSubMetric}
        performanceMetricInfo={performanceMetricInfo}
        onClickUndo={this.onClickUndoPerfSubMetric}
        isSubMetric
      />
    );
  }
}

PerformanceSubMetric.propTypes = {
  performanceMetricInfo: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickUndo: PropTypes.func.isRequired
};
