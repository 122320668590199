import * as types from './actionTypes';
import authContext from '../../infra/auth/aadAuth';
import config from '../../config/appConfig';
import * as userApi from '../../api/userApi';
import UserRoles from '../../common/consts/userRoles';
import { Logger } from '../../infra/logging/telemetryLogger';
import { getUserDetails } from '../../api/GraphService';

const aadConfig = config.aad;
const graphScope = config.api.graph.graphScopes;

export function signInUserSuccess(user) {
  return { type: types.SIGNIN_USER_SUCCESS, user };
}

export async function getUserInitials() {
  // Get the access token silently
  // If the cache contains a non-expired token, this function
  // will just return the cached token. Otherwise, it will
  // make a request to the Azure OAuth endpoint to get a token
  try {
  const accessToken = await authContext.acquireTokenSilent(graphScope);
    if (accessToken) {
    // Get the user's profile from Graph
    const user = await getUserDetails(accessToken);
    return user && user.givenName && user.surname ? (user.givenName[0] + user.surname[0]) : '';
    }
   } catch (error) {
      Logger.getInstance().logException(error);
    }
  return '';
}

export function signInUser() {
  return async dispatch => {
    const user = authContext.getUser();
    if (user == null) {
      authContext.loginRedirect(aadConfig.scope);
    } else {
      const loggedInUser = {
        name: user.name,
        alias: user.displayableId,
        initials: await getUserInitials()
      };

      try {
        const roles = await userApi.getUserRoles(loggedInUser.alias);
        if (roles.validationResult.isValid) {
          if (roles.data.find(role => role.id === UserRoles.Admin)) {
            loggedInUser.isAdmin = true;
          } else {
            loggedInUser.isAdmin = false;
          }
          if (roles.data.find(role => role.id === UserRoles.Supplier_Lead)) {
            loggedInUser.isSupplierLead = true;
          } else {
            loggedInUser.isSupplierLead = false;
          }
          if (roles.data.find(role => role.id === UserRoles.BoB_Owner)) {
            loggedInUser.isBobOwner = true;
          } else {
            loggedInUser.isBobOwner = false;
          }
          if (roles.data.find(role => role.id === UserRoles.FunctionalUser)) {
            loggedInUser.isFunctionalUser = true;
          } else {
            loggedInUser.isFunctionalUser = false;
          }
          if (roles.data.find(role => role.id === UserRoles.SRMSupplierLead)) {
            loggedInUser.isSRMSupplierLead = true;
          } else {
            loggedInUser.isSRMSupplierLead = false;
          }
          if (roles.data.find(role => role.id === UserRoles.Read_Only)) {
            loggedInUser.isReadOnly = true;
          } else {
            loggedInUser.isReadOnly = false;
          }
          dispatch(signInUserSuccess(loggedInUser));
          setInterval(async () => {
            await authContext.acquireTokenSilent(graphScope);
          }, aadConfig.idTokenRenewTimeSpan);
        } else {
          throw roles.validationResult.errors[0];
        }
      } catch (error) {
        Logger.getInstance().logException(error);
      }
    }
  };
}

export function signOutUser() {
  try {
    authContext.logout();
  } catch (error) {
    Logger.getInstance().logException(error);
  }
}
