import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SupplierScorecardSummaryShimmer from './supplierScorecardSummaryShimmer';
import * as myActionsReduxActions from '../../../redux/actions/myActionsActions';
import './supplierScorecardSummary.css';
import withErrorHandling from '../../hoc/withErrorHandling';

export class SupplierScorecardSummary extends Component {
  async componentDidMount() {
    const {
      supplierLeadEntryAction,
      loadSupplierScorecardSummary,
      onError
    } = this.props;
    if (!supplierLeadEntryAction.scorecardSummary.isloaded) {
      try {
        await loadSupplierScorecardSummary(
          supplierLeadEntryAction.supplierScorecardId
        );
      } catch (err) {
        onError('Error loading BoB Status.');
      }
    }
  }

  handleBobClick(bob, event) {
    const { onBobClick } = this.props;
    onBobClick(bob);
    event.stopPropagation();
  }

  handleKeyDown(bob, event) {
    if (event.keyCode === 13) {
      this.handleBobClick(bob, event);
    }
  }

  render() {
    const { supplierLeadEntryAction, selectedBobEntry } = this.props;

    const bobSummaries =
      supplierLeadEntryAction.scorecardSummary.summaryCollection;
    const supplierProcurementType = supplierLeadEntryAction.procurementTypeId;
    if (!supplierLeadEntryAction.scorecardSummary.isloaded) {
      return <SupplierScorecardSummaryShimmer />;
    }

    if (!bobSummaries) {
      return '';
    }
    return (
      <div className="scorecardSummaryDiv">
        {bobSummaries && bobSummaries.map(summary => (
          <div
            role="button"
            tabIndex="0"
            disabled={supplierProcurementType === 2}
            onClick={supplierProcurementType !== 2 ? this.handleBobClick.bind(this, {
              bobScoreCardId: summary.bobScoreCardId,
              id: summary.bob.id,
              name: summary.bob.name,
              owner: summary.bob.owner,
              ownerName: summary.bob.ownerName
            }) : 'disable()'}
            onKeyDown={supplierProcurementType !== 2 ? this.handleKeyDown.bind(this, {
              bobScoreCardId: summary.bobScoreCardId,
              id: summary.bob.id,
              name: summary.bob.name,
              owner: summary.bob.owner,
              ownerName: summary.bob.ownerName
            }) : 'disable()'}
            key={summary.bobScorecardId}
            className={`bobSummaryDiv ${
              selectedBobEntry != null &&
              summary.bobScoreCardId === selectedBobEntry.bobScoreCardId
                ? ' selectedSummary'
                : ''
            }`}
          >
           {!summary.isCompleted ? (
              <Button className="bobStatusToggleBtn bobLinkBtn" variant="link">
              {summary.bob.name}
              <span className="inComplete"> ! </span>
              </Button>
            ) : (
              <Button className="bobStatusToggleBtn bobLinkBtn" variant="link">
              {summary.bob.name}
              </Button>
            )}
            <div className="summariesDiv">
              (Owner: {summary.bob.owner})
              {summary.categories &&
               summary.categories.map(category => (
                <div key={summary.categories !== null ? summary.categories.name : ''}>
                  {category.name} :{' '}
                  <span
                    className={classNames('bobCategoryScore', {
                      zeroScore: category.score < 1 || category.score === 'N/A',
                      oneScore: category.score >= 1 && category.score < 2,
                      twoScore: category.score >= 2 && category.score < 3,
                      threeScore: category.score >= 3 && category.score < 4,
                      fourScore: category.score >= 4 && category.score < 5,
                      fiveScore: category.score >= 5
                    })}
                  >
                    {category.score !== 'N/A'
                    ? (Math.trunc(category.score * 10) / 10 || 0) : 'N/A'}
                  </span>{' '}
                  {!category.isCompleted ? (
                    <span className="inComplete"> ! </span>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    selectedBobEntry: state.myActions.selectedBobEntry
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSupplierScorecardSummary: bindActionCreators(
      myActionsReduxActions.loadSupplierScorecardSummary,
      dispatch
    )
  };
}

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SupplierScorecardSummary)
);

SupplierScorecardSummary.defaultProps = {
  selectedBobEntry: null
};

SupplierScorecardSummary.propTypes = {
  supplierLeadEntryAction: PropTypes.object.isRequired,
  selectedBobEntry: PropTypes.object,
  loadSupplierScorecardSummary: PropTypes.func.isRequired,
  onBobClick: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};
