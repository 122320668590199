import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import './MessageBar.css';
import { MessageBar } from '@fluentui/react/lib/MessageBar';
import withErrorHandling from '../hoc/withErrorHandling';
import resetMessage from '../../redux/actions/messageBarActions';

export class ScorecardMessageBar extends React.Component {
  constructor(props) {
    super(props);
    this.onClickClose = this.onClickClose.bind(this);
    this.state = {
      showMsg: true
    };
  }

  componentDidMount() {
    const { messageData } = this.props;
    if (messageData.type === 4) {
      this.timeId = setTimeout(() => {
        this.onClickClose();
      }, 3000);
    }
  }

  componentWillUnmount() {
    const { messageData } = this.props;
    if (messageData.type === 4) clearTimeout(this.timeId);
  }

  onClickClose() {
    const { showMsg } = this.state;
    const { hideMessageBar } = this.props;
    this.setState({ showMsg: !showMsg });
    hideMessageBar();
  }

  render() {
    const { showMsg } = this.state;
    const { messageData } = this.props;
    return (
      showMsg && (
          <MessageBar
            messageBarType={messageData.type}
            isMultiline={false}
            role="alert"
            onDismiss={this.onClickClose}
            dismissButtonAriaLabel="Closed Message bar"
            aria-label={messageData.displayMessage}
            aria-live="assertive"
            className={
              messageData.type === 4 ? 'sucessMessageBar' : 'errorMessageBar'
            }
          >
            {messageData.displayMessage}
            {messageData.tcv && messageData.tcv !== '' ? (
              <>
                <br />
                <span>Tracking Id : {messageData.tcv} </span>
              </>
            ) : null}
          </MessageBar>
      )
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideMessageBar: bindActionCreators(resetMessage, dispatch)
  };
}

export default withErrorHandling(
  connect(
    null,
    mapDispatchToProps
  )(ScorecardMessageBar)
);

ScorecardMessageBar.propTypes = {
  messageData: PropTypes.object.isRequired,
  hideMessageBar: PropTypes.func.isRequired
};
