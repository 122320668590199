import * as types from '../actions/actionTypes';
import initialState from './initialState';

const normalizeOnboardedSuppiers = (onboardedSuppliersArray = []) => {
  const normalizedResponse = onboardedSuppliersArray.reduce((acc, supplier) => {
    const key = 'scorecardSupplierId';
    let found = false;
    for (let i = 0; i < acc.length; i += 1) {
      if (acc[i][key] === supplier[key]) {
         found = acc[i];
      }
   }
    const supplierCopy = { ...supplier };
    const contractData = {};
    if (supplierCopy?.contractId) {
      contractData.masterAgreementContractID = supplierCopy?.contractId;
      contractData.contractTitle = supplierCopy?.contractTitle;
      contractData.supplierID = supplierCopy?.supplierId;
      contractData.supplierName = supplierCopy?.supplierName;
      contractData.companyCode = supplierCopy?.companyCode;
      contractData.effectiveDate = supplierCopy?.effectiveDate;
      contractData.expirationDate = supplierCopy?.expirationDate;
      delete supplierCopy.contractId;
      delete supplierCopy.contractTitle;
      delete supplierCopy.companyCode;
      delete supplierCopy.effectiveDate;
      delete supplierCopy.expirationDate;
    }
    if (!found) {
        const item = { ...supplierCopy };
        if (Object.keys(contractData).length !== 0) { item.contracts = [contractData]; }
        acc.push(item);
    } else if (Object.keys(contractData).length !== 0) { found.contracts.push(contractData); }
    return acc;
}, []);
  return normalizedResponse;
};

export function onboardedSuppliers(
  state = initialState.onboardedSuppliers,
  action
) {
  switch (action.type) {
    case types.LOAD_ONBOARDEDSUPPLIERS_SUCCESS:
      return normalizeOnboardedSuppiers(action.onboardedSuppliers);
    case types.ADD_ONBOARDSUPPLIER_SUCCESS: {
      return state.concat([action.onboardedSupplier]);
    }
    case types.UPDATE_ONBOARDEDSUPPLIER_SUCCESS: {
      const newOnboardedSupplier = normalizeOnboardedSuppiers(action.onboardedSupplier);
      const index = state.findIndex(
        (item) =>
          item.scorecardSupplierId ===
          newOnboardedSupplier[0].scorecardSupplierId
      );
      if (index > -1) {
        return (
          state &&
          state.map((item) => {
            if (
              item.scorecardSupplierId ===
              newOnboardedSupplier[0].scorecardSupplierId
            ) {
              return newOnboardedSupplier[0];
            }
            return item;
          })
        );
      }
      return state;
    }
    case types.DELETE_ONBOARDEDSUPPLIER_SUCCESS: {
      return [
        ...state.slice(0, action.index),
        ...state.slice(parseInt(action.index, 10) + 1)
      ];
    }
    case types.SORTED_ONBOARDEDSUPPLIERS_SUCCESS: {
      return [...action.onboardedSuppliers];
    }
    case types.UPDATE_SUPPLIERONBOARDEDBOB_SUCCESS: {
      const index = state.findIndex(
        (item) => item.supplierId === action.OnboardedBOBSupplier.supplierId
      );
      if (index > -1) {
        return (
          state &&
          state.map((item) => {
            if (item.supplierId === action.OnboardedBOBSupplier.supplierId) {
              return { ...action.OnboardedBOBSupplier };
            }
            return item;
          })
        );
      }
      return state;
    }
    default:
      return state;
  }
}

export function cadenceReducer(state = initialState.cadences, action) {
  switch (action.type) {
    case types.LOAD_CADENCE_SUCCESS:
      return action.cadences;
    default:
      return state;
  }
}

export function domainReducer(state = initialState.domains, action) {
  switch (action.type) {
    case types.LOAD_DOMAINS_SUCCESS:
      return action.domains;
    default:
      return state;
  }
}

export function supplierTypeReducer(
  state = initialState.supplierTypes,
  action
) {
  switch (action.type) {
    case types.LOAD_SUPPLIERTYPES_SUCCESS:
      return action.supplierTypes;
    default:
      return state;
  }
}

export function procurementTypeReducer(
  state = initialState.procurementTypes,
  action
) {
  switch (action.type) {
    case types.LOAD_PROCUREMENTTYPES_SUCCESS:
      return action.procurementTypes;
    default:
      return state;
  }
}

export function leadReducer(state = initialState.leads, action) {
  switch (action.type) {
    case types.LOAD_LEADS_SUCCESS:
      return action.leads;
    default:
      return state;
  }
}
