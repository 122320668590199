import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  FocusZone,
  FocusZoneDirection,
} from 'office-ui-fabric-react/lib/FocusZone';
import PropTypes from 'prop-types';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withErrorHandling from '../hoc/withErrorHandling';
import './Header.css';
import SubMenuOptions from './subMenus';
import MessageBar from './MessageBar';
import { giveFeedback, initialize } from './SupplierScorecardFeedback';
import PageTourList from './pageTour/pageTour';
import * as PageTourActions from '../../redux/actions/pageTourActions';
import config from '../../config/appConfig';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import { getEnvironment } from '../../config/appEnvConfig';

export class Header extends Component {
  togglePageTour() {
    const { showPageTourDialog, showPageTourDialogAction } = this.props;
    showPageTourDialogAction(!showPageTourDialog);
  }

  render() {
    const pagetourConfig = config.api.pageTour;
    const supportConfig = config.support;
    initialize();
    const {
      loggedInUser,
      validationMessage,
      showPageTourDialog,
      tours,
      onNavLinkClicked,
    } = this.props;
    const hasAnyRole = Object.keys(loggedInUser).length
      ? loggedInUser.isAdmin ||
        loggedInUser.isSupplierLead ||
        loggedInUser.isBobOwner ||
        loggedInUser.isFunctionalUser
      : null;
    const unreadPagetourCount = tours.reduce(
      (total, amount) => (!amount.isviewed ? total + 1 : total),
      0
    );
    let isPageTourEnabled = false;
    if (pagetourConfig.pageTourUsers) {
      isPageTourEnabled =
        pagetourConfig.pageTourUsers.find(
          user =>
            user.toLowerCase() ===
            (loggedInUser.alias ? loggedInUser.alias.toLowerCase() : '')
        ) !== undefined;
    }
    const emailBody = `${supportConfig.bodyUsername}${loggedInUser.name}\n\n${supportConfig.bodyTitle}\n\n${supportConfig.bodySupplier}\n${supportConfig.bodyBob}\n\n${supportConfig.bodyDescription}`;
    return (
      <header>
                <div className="bg">

                <nav className="navbar navbar-icon-top navbar-expand-lg navbar-dark">
          <div className="">
            <NavLink
              className="textsize14 navbar-brand navbar-header"
              to="/actions"
            >
              Microsoft Procurement
            </NavLink>
            <br />
            <NavLink to="/actions">
            <h1 className="textsize20 navbar-brand mlHeader"> Supplier Scorecard {getEnvironment() !== 0 ? `- ${process.env.REACT_APP_UI_ENV.trim()}` : ''}</h1>
            </NavLink>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {hasAnyRole ? (
            <FocusZone
              direction={FocusZoneDirection.bidirectional}
              isCircularNavigation
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto" />
              <ul className="navbar-nav">
                <li id="limyaction" className="nav-item">
                  <NavLink
                    className="nav-link textsize14"
                    activeClassName="nav-active active"
                    to="/actions"
                    onClick={onNavLinkClicked}
                  >
                    My Actions
                  </NavLink>
                </li>
                {loggedInUser.isAdmin || loggedInUser.isSRMSupplierLead ? (
                  <li id="lisamPortal" className="nav-item">
                    <NavLink
                      className="nav-link textsize14"
                      activeClassName="nav-active active"
                      to="/samPortal"
                      onClick={onNavLinkClicked}
                    >
                      SAM Portal
                    </NavLink>
                  </li>
                ) : null}
               {loggedInUser.isAdmin ||
                loggedInUser.isSupplierLead ||
                loggedInUser.isBobOwner ? (
                <li id="lisupplieronb" className="nav-item">
                  <NavLink
                    className="nav-link textsize14"
                    activeClassName="nav-active active"
                    to="/supplierOnboarding"
                    onClick={onNavLinkClicked}
                  >
                    Supplier Onboarding
                  </NavLink>
                </li>
                  ) : null}
                <li id="lirep" className="nav-item">
                  <SubMenuOptions tabName="Reports" isReports />
                </li>
                <li id="lires" className="nav-item">
                  <SubMenuOptions tabName="Resources" isReports={false} />
                </li>
              </ul>

              <ul className="navbar-nav">
                <li className="nav-item">
                  <div className="col-md-12">
                    {' '}
                    <span className="seperator" />
                  </div>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item ">
                  <button
                    className="rounded-circle fabric-emoji2"
                    aria-label="OCV menu button press space bar to provide feedback"
                    type="button"
                    onClick={() => giveFeedback()}
                  >
                    <i className="icon icon-smile" />
                  </button>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <div className="col-md-12">
                    {' '}
                    <span className="seperator" />
                  </div>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item ">
                  <NavLink
                    className="rounded-circle fabric-emoji2"
                    aria-label="Support menu button to raise an incident with Scorecard engineering team"
                    type="button"
                    to="#"
                    onClick={() => window.open(`mailto:${supportConfig.mailAddress}?cc=${supportConfig.ccAddress};${loggedInUser.alias}&subject=${supportConfig.subject}&body=${encodeURIComponent(emailBody)}`)}
                  >
                    <FontAwesomeIcon icon={faBug} />
                  </NavLink>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <div className="col-md-12">
                    {' '}
                    <span className="seperator" />
                  </div>
                </li>
              </ul>
              {isPageTourEnabled && (
                <>
                  <ul className="navbar-nav">
                    <li className="nav-item ">
                      <button
                        type="button"
                        id="pagetour-open"
                        className="pagetour__icon-container"
                        aria-label="Page tour menu button press space bar to activate"
                        onClick={() => this.togglePageTour()}
                      >
                        <i className="pagetour__icon icon-pagetour" />
                        <sup className="notification-count">
                          {unreadPagetourCount}
                        </sup>
                      </button>
                      {showPageTourDialog ? <PageTourList /> : null}
                    </li>
                  </ul>

                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <div className="col-md-12">
                          {' '}
                          <span className="seperator" />
                        </div>
                      </li>
                    </ul>
                </>
                )}
                <ul className="navbar-nav">
                  <li className="nav-item mt5">
                    <SubMenuOptions
                      tabName={loggedInUser.initials}
                      isReports={false}
                      alias={loggedInUser.alias}
                      userName={loggedInUser.name}
                      isUserProfile
                    />
                  </li>
                </ul>
            </FocusZone>
            ) : (
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto" />
                <ul className="navbar-nav">
                  <li id="lires" className="nav-link textsize14">
                    <SubMenuOptions tabName="Resources" isReports={false} />
                  </li>
                </ul>
              </div>
            )}
                </nav>
                </div>
        {validationMessage.shouldShowMessage && (
          <MessageBar messageData={validationMessage} />
        )}
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    validationMessage: state.message,
    showPageTourDialog: state.pageTour.showPagetourdialog,
    tours: state.pageTour.pageTours,
  };
}
const mapDispatchToProps = {
  showPageTourDialogAction: PageTourActions.showPageTourDialogAction,
};

Header.defaultProps = {
  showPageTourDialog: false,
  showPageTourDialogAction: null,
  onNavLinkClicked: null,
  tours: [],
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);

Header.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  validationMessage: PropTypes.object.isRequired,
  showPageTourDialog: PropTypes.bool,
  showPageTourDialogAction: PropTypes.func,
  tours: PropTypes.array,
  onNavLinkClicked: PropTypes.func,
};
