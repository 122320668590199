export const MetricValidationMessages = Object.freeze({
  MetricNameNotEntered: 'Please enter metric Name',
  MetricTargetNotEntered: 'Please enter metric Target',
  MetricTargetConditionNotEntered: 'Please enter metric Target condition',
  MetricTargetNoOptionsSelected:
    "Please enter either metric target/condition or select 'No target'",
  MetricWeigthNotEntered: 'Please enter metric weight',
  MetricWeightIsInMinus: 'Metric weight must be in positive',
  MetricWeightIsOutOfRange: 'Metric weight must be in between 1 and 100',
  MetricWeightIsNotValid: 'Metric weight must be valid number',
  MetricNameOutOfRange:
    'Metric description should not exceeds more than 400 characters',
  MetricOrderErrorMsg: 'Entered Order is incorrect, please enter a valid number'
});

export const SupplierOnboardingValidationMessage = Object.freeze({
  InvalidUser: 'Invalid User.',
  Lead1NotEntered: "Lead 1 can't be empty",
  Lead2NotEntered: "Lead 2 can't be empty",
  OnlyFTE: "Only FTE's are allowed"
});

export const CategoryCommentMessage = Object.freeze({
  commentRemark:
    '*Comments entered here will be shared with external Suppliers and non-Procurement Stakeholders'
});

export const BOBValidationMessage = Object.freeze({
  Max5ContrctIdAllowed: "Max 5 Contract Id's are Allowed",
  OnlyNumbersAndCommaAllowed: 'Only Numbers and Comma are allowed',
  Max5PurchaseOrdersAllowed: "Max 5 Purchase Order's are Allowed",
  OnlyFTFAllowed: "Only FTE's are allowed",
  DecimalNumberAllowed: 'Decimal number is allowed',
  DecimalNumberAndDotAllowed: 'Only Numbers and Dot are allowed',
  EffectveDateFuture: 'Please select an effective date < ',
  EffectveDatePast: 'Please select an effective date > ',
  EffectveDateFutureNonAdmin: 'Please select a future effective date',
  NumberFirst: 'Please enter No. first'
});

export const CAPValidationMessage = Object.freeze({
  descriptionRequired: 'Please enter a cap description',
  dueDateRequired: 'Please select a due date',
  closeDateRequired: 'Close date is mandatory for closing CAP'
});

export const Message = Object.freeze({
  RecordHasBeenUpdatedSucccessfully: 'Record has been updated successfully',
  RecordHasAddedSucccessfully: 'New record has been added successfully',
  RecordHasBeenDeletedSucccessfully: 'Record has been deleted successfully',
  FailedToProcessRequest: 'Something went wrong, please try again later',
  APINotFound: 'API does not exist',
  NotAuthorized: 'You are not authorized to perform this action',
  FailedToLoadMetrics: 'Failed to load metrics',
  NewRecordCreatedSuccessfully: 'new record created successfully',
  NonSrmLiteSupplierTypeHelpText: 'Only Performance and Risk sections are scored for with this supplier type',
  MonitorSupplierTypeHelpText: 'Available only in SAM portal and enabled for Risk monitoring',
  SupplierQuarterlyCadenceHelpText: 'Bobs under Quarterly supplier can have only Quarterly Cadence'

});
