/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';
import * as performanceManagerAPI from '../../api/performanceManagerApi';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function getReportingSuppliersSuccess(reportingSuppliers) {
  return { type: types.LOAD_REPORTINGSUPPLIERS_SUCCESS, reportingSuppliers };
}

function getReportingPeriodsSuccess(reportingPeriods) {
  return { type: types.LOAD_REPORTINGPERIODS_SUCCESS, reportingPeriods };
}

export function getReportingSuppliers() {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getReportingSuppliers();
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(getReportingSuppliersSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getReportingPeriods(supplierId, bobId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getScorecardPeriods(
        supplierId,
        bobId
      );
      if (response.validationResult.isValid) {
        dispatch(
          getReportingPeriodsSuccess(
            !response.data ? [{ cadences: [] }] : response.data
          )
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getDownloadableReport(selectedSupplier, selectedPeriod) {
  return async () => {
    try {
      const response = await performanceManagerAPI.getSSRSReport(
        selectedSupplier?.key,
        selectedPeriod?.key
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      let filename = `${selectedSupplier?.text}-${selectedPeriod?.text}`;
      filename += '.docx';
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}
