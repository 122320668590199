import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function ResourceReducer(
    state = initialState.resource,
    action
  ) {
    switch (action.type) {
      case types.LOAD_RESOURCE_SUCCESS:
        return action.resource;
      default:
        return state;
    }
  }
