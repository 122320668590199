/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';

export function addNewMetric(procurementTypeId) {
  return async dispatch => {
    dispatch({
      type: types.ADD_NEWMETRIC_SUCCESS,
      procurementTypeId
    });
  };
}

export function editMetric(metricInfoIdToBeEdit) {
  return async dispatch =>
    dispatch({
      type: types.EDIT_METRIC_SUCCESS,
      metricInfoIdToBeEdit
    });
}

export function saveMetric(metricInfoToBeSave) {
  return async dispatch =>
    dispatch({
      type: types.SAVE_METRIC_SUCCESS,
      metricInfoToBeSave
    });
}

export function deleteMetric(metricInfoToBeDelete, procurementTypeId) {
  return async dispatch =>
    dispatch({
      type: types.DELETE_METRIC_SUCCESS,
      metricInfoToBeDelete,
      procurementTypeId
    });
}

export function undoMetric(metricInfoIdToBeUndo) {
  return async dispatch =>
    dispatch({
      type: types.UNDO_METRIC_SUCCESS,
      metricInfoIdToBeUndo
    });
}
