const uiEnv = process.env.REACT_APP_UI_ENV.trim();
let environment;
export function getEnvironment() {
    switch (uiEnv) {
      case 'dit':
        environment = 1;
        return environment;
      case 'test':
        environment = 1;
        return environment;
      case 'uat':
        environment = 1;
        return environment;
      case 'prod':
        environment = 0;
        return environment;
      default:
        environment = 1;
        return environment;
    }
  }

  const env = getEnvironment();
export default env;
