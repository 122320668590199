/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import './withErrorHandling.css';
import Errordiv from './errordiv';

const withErrorHandling = WrappedComponent =>
  class ErrorHandlingHOC extends React.Component {
    state = {
      error: null
    };

    constructor(props) {
      super(props);
      this.onError = this.onError.bind(this);
      this.refresh = this.refresh.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
      // If the props are changing except the error try reloading the component
      // by setting error as null
      // This check here is crucial
      const { error } = this.state;
      if (prevState.error === error && error != null) {
        this.setState(() => ({ error: null }));
      }
    }

    onError(error) {
      this.setState(() => ({ error }));
    }

    refresh() {
      this.setState(() => ({ error: null }));
    }

    render() {
      const { error } = this.state;
      return error == null ? (
        <WrappedComponent onError={this.onError} {...this.props} />
      ) : (
        <Errordiv error={error} refresh={this.refresh} />
      );
    }
  };

export default withErrorHandling;
