import * as types from '../actions/actionTypes';
import initialState from './initialState';
import MessageType from '../../common/consts/messageType';

export default function MessageReducer(state = initialState.message, action) {
  switch (action.type) {
    case types.ADD_PROCESSEDMESSAGE_SUCCESS:
      return {
        ...state,
        shouldShowMessage: true,
        type: MessageType.Success,
        displayMessage: action.message,
        tcv: '',
        isSuccessSavingData: true
      };

    case types.ADD_UNPROCESSEDMESSAGE_SUCCESS: {
      const showMessage =
        action.message != null && action.message.match(/^$/) == null;
      return {
        ...state,
        shouldShowMessage: showMessage,
        type: MessageType.Error,
        displayMessage: action.message,
        tcv: action.tcvId,
        isErrorSavingData: true
      };
    }

    case types.RESET_MESSAGE_SUCCESS:
      return {
        ...state,
        shouldShowMessage: false,
        type: MessageType.None,
        displayMessage: '',
        tcv: '',
        isSuccessSavingData: false,
        isErrorSavingData: false
      };
    default:
      return state;
  }
}
