import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FocusZone,
  FocusZoneDirection,
} from 'office-ui-fabric-react/lib/FocusZone';
import PropTypes from 'prop-types';
import './BobEntryActionList.css';
import ActionFilters from '../../../common/consts/actionFilters';
import * as myActionsReduxActions from '../../../redux/actions/myActionsActions';
import BobEntryCard from '../bobEntryActionCard/BobEntryActionCard';
import BobEntryShimmer from '../bobEntryActionCard/BobEntryShimmerCard';
import withErrorHandling from '../../hoc/withErrorHandling';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

export class BobEntryActionList extends Component {
  async componentDidMount() {
    const {
      bobEntryActions,
      loggedInUser,
      loadBobEntryActions,
      onError,
      politeAnnouncement
    } = this.props;
    if (!bobEntryActions.isloaded) {
      try {
        politeAnnouncement('Loading data');
        await loadBobEntryActions(loggedInUser.alias);
      } catch (err) {
        onError('Unable to fetch actions.');
      }
    }
  }

  render() {
    const { bobEntryActions, selectedActionFilter } = this.props;

    let filteredActions = bobEntryActions ? bobEntryActions.actions : [];
    if (selectedActionFilter) {
      if (selectedActionFilter.id !== ActionFilters.All.id) {
        filteredActions = filteredActions.filter(
          (action) => action.statusId === selectedActionFilter.id
        );
      }
    }

    const shimmers = [];
    if (!bobEntryActions.isloaded) {
      for (let i = 0; i < 7; i += 1) {
        shimmers.push(<BobEntryShimmer key={i} />);
      }
    }

    return (
      <>
        {' '}
        {bobEntryActions.isloaded ? (
          <div className="bobEntryActionListDiv">
            {bobEntryActions.actions.length > 0 ? (
              <FocusZone
                direction={FocusZoneDirection.vertical}
                role="list"
                isCircularNavigation
              >
                {filteredActions &&
                  filteredActions.map((action) =>
                    (action === null ? (
                      ''
                    ) : (
                      <BobEntryCard
                        key={action.bobScoreCardId}
                        action={action}
                      />
                    )))}
              </FocusZone>
            ) : (
              <div className="noActionsDiv">- No actions assigned. -</div>
            )}
          </div>
        ) : (
          <div className="bobEntryActionListDiv">{shimmers}</div>
        )}
      </>
    );
  }
}

export function mapStateToProps(state) {
  return {
    bobEntryActions: state.myActions.bobEntryActions,
    selectedActionFilter: state.myActions.actionFilter,
    loggedInUser: state.loggedInUser,
  };
}

const mapDispatchToProps = {
    loadBobEntryActions: myActionsReduxActions.loadBobEntryActions,
    politeAnnouncement: PoliteAnnouncement
};


export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(BobEntryActionList)
);

BobEntryActionList.defaultProps = {
  politeAnnouncement: null
};

BobEntryActionList.propTypes = {
  selectedActionFilter: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  bobEntryActions: PropTypes.object.isRequired,
  loadBobEntryActions: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  politeAnnouncement: PropTypes.func
};
