import * as performanceManagerAPI from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Message } from '../../common/consts/validationMessages';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

export function loadBOBListSuccess(supplierBOBs, procurementTypeId) {
  return {
    type: types.LOAD_SUPPLIERBOB_SUCCESS,
    supplierBOBs,
    procurementTypeId,
  };
}

export function getStdCategoryGrpSuccess(stdcategorygroups) {
  return { type: types.LOAD_STANDARDCATEGORYGROUP_SUCCESS, stdcategorygroups };
}

export function getStdCategoryNameSuccess(stdcategorynames) {
  return { type: types.LOAD_STANDARDCATEGORYNAME_SUCCESS, stdcategorynames };
}

export function getExecOrgSuccess(execorgs) {
  return { type: types.LOAD_EXECORG_SUCCESS, execorgs };
}
export function getExecOrgSummarySuccess(execorgsummarydetails) {
  return { type: types.LOAD_EXECORGSUMMARYDETAILS_SUCCESS, execorgsummarydetails };
}
export function getExecOrgDetailsSuccess(execorgdetails) {
  return { type: types.LOAD_EXECORGDETAILS_SUCCESS, execorgdetails };
}

export function getOwnerSuccess(owners) {
  return { type: types.LOAD_OWNER_SUCCESS, owners };
}

export function getReportingCadenceSuccess(reportingCadenceList) {
  return { type: types.LOAD_REPORTINGCADENCE_SUCCESS, reportingCadenceList };
}

export function updateOnboardedBOBSuccess(onboardedBOB, procurementTypeId) {
  return {
    type: types.UPDATE_ONBOARDEDBOB_SUCCESS,
    onboardedBOB,
    procurementTypeId,
  };
}

export function addBoBSuccess(onboardedBOB, procurementTypeId) {
  return { type: types.ADD_NEWBOB_SUCCESS, onboardedBOB, procurementTypeId };
}

function requestProcessedMessageSuccess(message) {
  return { type: types.ADD_PROCESSEDMESSAGE_SUCCESS, message };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

// function deleteOnboardedSupplierBOBSuccess(deletedSupplierBOBId) {
//   return {
//     type: types.DELETE_ONBOARDEDSUPPLIER_BOB_SUCCESS,
//     deletedSupplierBOBId,
//   };
// }

// function activateOnboardedSupplierBOBSuccess(activatedBobId) {
//   return {
//     type: types.ACTIVATE_ONBOARDEDSUPPLIER_BOB_SUCCESS,
//     activatedBobId,
//   };
// }

// function deleteOnboardedBOBfromBobEntryActionSuccess(supplierId, bobId) {
//   return {
//     type: types.DELETE_ONBOARDEDSUPPLIERBOB_FROM_BOBENTRYACTION_SUCCESS,
//     supplierId,
//     bobId,
//   };
// }

// function activateOnboardedBOBfromBobEntryActionSuccess(supplierId, bobId) {
//   return {
//     type: types.ACTIVATE_ONBOARDEDSUPPLIERBOB_FROM_BOBENTRYACTION_SUCCESS,
//     supplierId,
//     bobId,
//   };
// }

// function deleteOnboardedBOBfromSupplierLeadEntryActionSuccess(
//   supplierId,
//   bobId
// ) {
//   return {
//     type:
//       types.DELETE_ONBOARDEDSUPPLIERBOB_FROM_SUPPLIERLEADENTRYACTION_SUCCESS,
//     supplierId,
//     bobId,
//   };
// }

// function activateOnboardedBOBfromSupplierLeadEntryActionSuccess(
//   supplierId,
//   bobId
// ) {
//   return {
//     type:
//       types.ACTIVATE_ONBOARDEDSUPPLIERBOB_FROM_SUPPLIERLEADENTRYACTION_SUCCESS,
//     supplierId,
//     bobId,
//   };
// }

export function getSupplierBOBs(scorecardSupplierId, procurementTypeId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getSupplierBOBs(
        scorecardSupplierId
      );
      if (response.validationResult.isValid) {
        if (!response.data || !response.data.bobResponseList) {
          response.data.bobResponseList = [];
        }
        dispatch(loadBOBListSuccess(response.data.bobResponseList, procurementTypeId));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function getStdCategoryGroup(supplierProcurementType) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getStdCategoryGroup(
        supplierProcurementType
      );
      response.data = !response.data ? [] : response.data;
      const stdcategorygroup = response.data;
      dispatch(getStdCategoryGrpSuccess(stdcategorygroup));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getStdCategoryName(supplierProcurementType) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getStdCategoryName(
        supplierProcurementType
      );
      response.data = !response.data ? [] : response.data;
      const stdcategoryname = response.data;
      dispatch(getStdCategoryNameSuccess(stdcategoryname));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getExecOrg() {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getExecOrg();
      response.data = !response.data ? [] : response.data;
      const execOrg = response.data;
      dispatch(getExecOrgSuccess(execOrg));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getExecOrgSummary() {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getExecOrgSummary();
      response.data = !response.data ? [] : response.data;
      const execOrgSummary = response.data;
      dispatch(getExecOrgSummarySuccess(execOrgSummary));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getExecOrgDetails() {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getExecOrgDetails();
      response.data = !response.data ? [] : response.data;
      const execOrgDetail = response.data;
      dispatch(getExecOrgDetailsSuccess(execOrgDetail));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getOwnerList() {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getOwnerList();
      response.data = !response.data ? [] : response.data;
      const owner = response.data;
      dispatch(getOwnerSuccess(owner));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function getReportingCadence() {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getReportingCadence();
      response.data = !response.data ? [] : response.data;
      dispatch(getReportingCadenceSuccess(response.data));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function addOnboardBOB(newOnboardedBOB, scorecardSupplierId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.addOnboardBOB(
        newOnboardedBOB,
        scorecardSupplierId
      );

      if (response.validationResult.isValid) {
        dispatch(
          addBoBSuccess(
            {
              ...response.data,
            },
            newOnboardedBOB.procurementTypeId
          )
        );
        dispatch(
          requestProcessedMessageSuccess(Message.RecordHasAddedSucccessfully)
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function updateOnboardedBOB(
  newOnboardedBOB,
  scorecardSupplierId,
  bobId
) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.updateOnboardedBOB(
        newOnboardedBOB,
        scorecardSupplierId,
        bobId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(
          updateOnboardedBOBSuccess({ ...response.data },
            newOnboardedBOB.procurementTypeId)
        );
        dispatch(
          requestProcessedMessageSuccess(
            Message.RecordHasBeenUpdatedSucccessfully
          )
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function sortedOnboardSupplierBOBs(supplierBOBs) {
  return async dispatch =>
    dispatch({
      type: types.SORTED_ONBOARDEDSUPPLIERBOBS_SUCCESS,
      supplierBOBs,
    });
}

export function deleteOnboardedSupplierBOB(supplierId, bobId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.deleteSupplierBOB(
        supplierId,
        bobId
      );
      if (response.validationResult.isValid) {
        // dispatch(deleteOnboardedSupplierBOBSuccess(bobId));
        // dispatch(
        //   deleteOnboardedBOBfromBobEntryActionSuccess(supplierId, bobId)
        // );
        // dispatch(
        //   deleteOnboardedBOBfromSupplierLeadEntryActionSuccess(
        //     supplierId,
        //     bobId
        //   )
        // );
        dispatch(
          requestProcessedMessageSuccess(
            Message.RecordHasBeenDeletedSucccessfully
          )
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function activateOnboardedSupplierBOB(supplierId, bobId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.activateSupplierBOB(
        supplierId,
        bobId
      );
      if (response.validationResult.isValid) {
        // dispatch(activateOnboardedSupplierBOBSuccess(bobId));
        // dispatch(
        //   activateOnboardedBOBfromBobEntryActionSuccess(supplierId, bobId)
        // );
        // dispatch(
        //   activateOnboardedBOBfromSupplierLeadEntryActionSuccess(
        //     supplierId,
        //     bobId
        //   )
        // );
        dispatch(
          requestProcessedMessageSuccess(
            Message.RecordHasBeenUpdatedSucccessfully
          )
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}
