/* eslint-disable no-param-reassign */
/* eslint-disable object-shorthand */
import * as React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'office-ui-fabric-react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import './DefaultMetric.css';
import MetricsRatingControl from '../../common/MetricsRatingControl/metricsRatingControl';

export default class DefaultMetric extends React.Component {
  constructor(props) {
    super(props);
    this.onClickAddMetric = this.onClickAddMetric.bind(this);
  }

  onClickAddMetric() {
    const { onClickAddMetric } = this.props;
    onClickAddMetric();
  }

  render() {
    const {
      hasConfiguredMetrics,
      areAllMetricsDeleted,
      metricsLength,
      hasReadOnlyAccess
    } = this.props;
    return (
      <div className="defaultContainer">
        <div
          className={
            hasConfiguredMetrics || !areAllMetricsDeleted
              ? 'row defaultPerfContainer defaultContainerHbar'
              : 'row defaultContainerHbar'
          }
        ><div className="nowrap">
          <ChoiceGroup
            name="default"
            selectedKey={
              hasConfiguredMetrics && !areAllMetricsDeleted
                ? 'DefaultPerfMetric1'
                : 'DefaultPerfMetric'
            }
            className={
              hasConfiguredMetrics && !areAllMetricsDeleted
                ? 'fadeOut colmd2 defaultPef nowrap'
                : 'radioButton colmd2 defaultPef nowrap'
            }
            options={[
              {
                key: 'DefaultPerfMetric',
                text: 'Default Performance',
                disabled: true
              }
            ]}
          />
          <div className="defaultMetricControl">
            <MetricsRatingControl
              isSelectable={false}
              isPerformanceMetricEnabled={
                !areAllMetricsDeleted && hasConfiguredMetrics
              }
            />
          </div>
          <PrimaryButton
            autoFocus
            allowDisabledFocus="true"
            disabled={hasReadOnlyAccess}
            className={
              // eslint-disable-next-line no-nested-ternary
              hasReadOnlyAccess ? 'addMetric fadeOut' : (!areAllMetricsDeleted && metricsLength >= 2
              ? 'hideAddMetric'
              : 'addMetric')
            }
            text="Metric"
            onClick={this.onClickAddMetric}
            ariaLabel="Add Metric"
            ariaDescription="Add New Custom Metric for Performance category"
          />
         </div>
        </div>
      </div>
    );
  }
}

DefaultMetric.propTypes = {
  hasConfiguredMetrics: PropTypes.func.isRequired,
  areAllMetricsDeleted: PropTypes.bool.isRequired,
  metricsLength: PropTypes.number.isRequired,
  onClickAddMetric: PropTypes.func.isRequired,
  hasReadOnlyAccess: PropTypes.object.isRequired
};
