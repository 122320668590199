import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter
} from 'office-ui-fabric-react/lib/Dialog';
import {
  PrimaryButton,
  DefaultButton
} from 'office-ui-fabric-react/lib/Button';

export default class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.undoEditedMetrics = this.undoEditedMetrics.bind(this);
    this.saveMetrics = this.saveMetrics.bind(this);
  }

  saveMetrics() {
    const { onClickYes } = this.props;
    onClickYes();
  }

  undoEditedMetrics() {
    const { onClickNo } = this.props;
    onClickNo();
  }

  render() {
    const { showDialog } = this.props;
    return (
      <>
        <Dialog
          hidden={!showDialog}
          onDismiss={this.saveMetrics}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Please confirm',
            subText:
              'You are exiting without saving your entries. Please select one of the options below.'
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.saveMetrics} text="Save" />
            <DefaultButton
              onClick={this.undoEditedMetrics}
              text="Exit without saving"
            />
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

ConfirmDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onClickNo: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired
};
