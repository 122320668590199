import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function correctiveActionPlanHistoryReducer(
  state = initialState.capHistory,
  action
) {
  switch (action.type) {
    case types.LOAD_BOBSCORECARDCAPHISTORY_SUCCESS:
      return action.capHistory;
    case types.LOAD_SUPPLIERSCORECARDCAPHISTORY_SUCCESS:
        return action.capHistory;
    default:
      return state;
  }
}
