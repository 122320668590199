import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function myActionsReducer(
  state = initialState.myActions,
  action
) {
  switch (action.type) {
    case types.LOAD_BOBENTRYACTIONS_SUCCESS: {
      // const selectedAction =
      //   action.actions.length > 0 ? action.actions[0] : null;
      return {
        ...state,
        bobEntryActions: {
          isloaded: true,
          actions: action.actions !== null && action.actions.length ?
           action.actions.map(act => act) : []
        }
        // selectedBobEntry: selectedAction,
        // selectedSupplierLeadEntry: null
      };
    }
    case types.SET_ACTIONS_FILTER:
      return { ...state, actionFilter: action.filter };
    case types.SET_SELECTED_BOBENTRYACTION:
      return {
        ...state,
        selectedBobEntry: action.bobEntryAction,
        selectedSupplierLeadEntry: null
      };
    case types.LOAD_SUPPLIERLEAD_ENTRYACTIONS_SUCCESS: {
      // const selectedAction =
      //   action.actions.length > 0 ? action.actions[0] : null;
      return {
        ...state,
        supplierLeadEntryActions: {
          isloaded: true,
          actions:
            action.actions !== null && action.actions.length
              ? action.actions.map(act => ({
                  ...act,
                  scorecardSummary: { isloaded: false }
                }))
              : []
        }
        // selectedSupplierLeadEntry: selectedAction,
        // selectedBobEntry: null
      };
    }
    case types.SET_SELECTED_SUPPLIERLEADENTRYACTION:
      return {
        ...state,
        selectedSupplierLeadEntry: action.supplierLeadEntryAction,
        selectedBobEntry: null
      };
    case types.SET_SELECTED_ACTIONMODE: {
      return {
        ...state,
        selectedActionMode: action.actionMode
      };
    }
    case types.LOAD_SUPPLIERSCORECARD_SUMMARY_SUCCESS: {
      const scorecardId = action.scorecardSummary.supplierScorecardId;
      const supplierleadactions = state.supplierLeadEntryActions.actions &&
       state.supplierLeadEntryActions.actions.map(
        act => {
          if (act.supplierScorecardId === scorecardId) {
            return {
              ...act,
              scorecardSummary: {
                isloaded: true,
                summaryCollection: [...action.scorecardSummary.summaryCollection]
              }
            };
          }
          return act;
        }
      );
      return {
        ...state,
        supplierLeadEntryActions: {
          isloaded: true,
          actions: supplierleadactions
        }
      };
    }
    case types.UPDATE_BOBSCORECARD_METRICS_SUCCESS: {
      return {
        ...state,
        bobEntryActions: {
          ...state.bobEntryActions,
          actions: state.bobEntryActions.actions ?
          state.bobEntryActions.actions.map(act => {
            if (act.bobScoreCardId !== action.payload.bobScorecardId) { return act; }
            return {
              ...act,
              percentage: action.payload.responseData.progressPercentage.toFixed(2),
              statusId: action.payload.responseData.status.id,
              statusName: action.payload.responseData.status.status
            };
          }) : []
        }
      };
    }
    case types.UPDATE_SUPPLIERSCORECARD_METRICS_SUCCESS: {
      return {
        ...state,
        supplierLeadEntryActions: {
          ...state.supplierLeadEntryActions,
          actions: state.supplierLeadEntryActions.actions ?
           state.supplierLeadEntryActions.actions.map(act => {
            if (act.supplierScorecardId !== action.payload.supplierScorecardId) { return act; }
            return {
              ...act,
              percentage: action.payload.responseData.progressPercentage.toFixed(2),
              statusId: action.payload.responseData.status.id,
              statusName: action.payload.responseData.status.status
            };
          }) : []
        }
      };
    }
    case types.LOAD_CURRENTCADENCEPERIOD_SUCCESS: {
      return { ...state, currentCadence: action.actions };
    }
    case types.DELETE_ONBOARDEDSUPPLIERBOB_FROM_BOBENTRYACTION_SUCCESS:
      {
        const updatedSupplierBobList = [...state.bobEntryActions.actions];
        const deletedBob = updatedSupplierBobList.filter(item => item.bob.id === action.bobId
           && item.supplier.id === action.supplierId);
        if (deletedBob.length) {
         const deletedBOBIndex = updatedSupplierBobList.findIndex(item => item.bob.id
           === deletedBob[0].bob.id);
        updatedSupplierBobList.splice(deletedBOBIndex, 1);
        }
        return {
          ...state,
          bobEntryActions: {
            isloaded: true,
            actions: updatedSupplierBobList,
            deletedBOBId: action.bobId
          }
        };
      }
      case types.DELETE_ONBOARDEDSUPPLIERBOB_FROM_SUPPLIERLEADENTRYACTION_SUCCESS:
      {
        let updatedSupplierBobList = [...state.supplierLeadEntryActions.actions];
        if (updatedSupplierBobList.length) {
          if (updatedSupplierBobList[0].scorecardSummary) {
            updatedSupplierBobList = updatedSupplierBobList.map(supplierBob => {
              if (supplierBob.scorecardSummary.isloaded === true &&
                 supplierBob.supplier.id === action.supplierId) {
                    return {
                      ...supplierBob,
                      scorecardSummary: {
                        ...supplierBob.scorecardSummary,
                        summaryCollection: supplierBob.scorecardSummary.summaryCollection
                        .filter(x => x.bob.id !== action.bobId)
                      }
                    };
                 }
                 return supplierBob;
            });
          }
       }
       return {
         ...state,
         supplierLeadEntryActions: {
           isloaded: true,
           actions: [...updatedSupplierBobList],
           deletedBOBId: action.bobId
          }
        };
      }
    case types.LOAD_FUTURECADENCE_SUCCESS: {
      return { ...state, futureCadence: action.cadence.data };
    }
    default:
      return state;
  }
}
