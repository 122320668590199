export class SupplierModel {
  constructor(id, name, supplierLeads, supplierLeadNames) {
    this.id = id;
    this.name = name;
    this.supplierLeads = supplierLeads;
    this.supplierLeadNames = supplierLeadNames;
  }
}

export class BoBModel {
  constructor(id, name, bobOwnerIds, bobOwners) {
    this.id = id;
    this.name = name;
    this.bobOwnerIds = bobOwnerIds;
    this.bobOwners = bobOwners;
  }
}

export class ScoreCardingPeriodModel {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export class ScoreCardFilterModel {
  constructor(supplier, bob, cadence) {
    this.supplier = supplier;
    this.bob = bob;
    this.cadence = cadence;
  }
}

export class BoBEntryModel {
  constructor(supplier, bob, cadence) {
    this.supplier = supplier;
    this.bob = bob;
    this.cadence = cadence;
  }
}

export class SupplierLeadModel {
  constructor(supplier, cadence) {
    this.supplier = supplier;
    this.cadence = cadence;
  }
}
