/* eslint-disable import/prefer-default-export */
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function loadScoreDefinitionSuccess(scoreDefinition) {
  return { type: types.LOAD_SCOREDEFINITION_SUCCESS, payload: scoreDefinition };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

export function getScoredefinition() {
  return async dispatch => {
    try {
      const response = await performanceApi.getScoreDefinition();
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        let scoreDefinition = [];
        scoreDefinition = response.data.reverse();
        dispatch(loadScoreDefinitionSuccess(scoreDefinition));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}
