/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import './ActionPlanFilter.css';
import {
  SamEntryActionModel,
  SupplierModel,
  PeriodModel
} from '../../models/model/SamEntryActionModel';
import * as scorecardFilterPanelReduxActions from '../../../redux/actions/scorecardFilterPanelActions';
import withErrorHandling from '../../hoc/withErrorHandling';
import SupplierType from '../../../common/consts/SupplierType';
import * as samReduxActions from '../../../redux/actions/samPortalActions';

export class ActionPlanFilter extends Component {
  state = {
    isSubmitDisable: true,
    selectedSupplier: null,
    selectedPeriod: null,
    selectedSupplierLead: null,
    samEntryActionModel: new SamEntryActionModel(
      null,
      new SupplierModel(),
      new PeriodModel()
    )
  };

  comboBoxStyles = {
    container: {
        display: 'flex',
        label: { fontSize: '14px !important', margin: '0 10px' }
      },
    optionsContainerWrapper: { maxHeight: '350px' },
    root: {
      '::after': {
      borderRadius: '4px'
      }
    }
  }

  constructor(props) {
    super(props);
    this.onActionPlanFilterSubmit = this.onActionPlanFilterSubmit.bind(this);
    this.onChangeSupplier = this.onChangeSupplier.bind(this);
    this.onChangePeriod = this.onChangePeriod.bind(this);
  }

  componentDidMount() {
    const {
      loadScorecardingSuppliers,
      selectedSamEntry
    } = this.props;
    const { selectedSupplierLead } = this.state;
    loadScorecardingSuppliers();
    if (selectedSamEntry !== null) {
      this.setState({
        samEntryActionModel: selectedSamEntry,
        selectedSupplier: {
          label: selectedSamEntry.supplier.name,
          value: selectedSamEntry.supplier.id
        },
        selectedPeriod: {
          label: selectedSamEntry.cadence.name,
          value: selectedSamEntry.cadence.id
        },
        selectedSupplierLead: selectedSamEntry.supplier.supplierLeadNames
          ? selectedSamEntry.supplier.supplierLeadNames[0]
          : selectedSupplierLead
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevSamEntry = prevProps.selectedSamEntry;
    const { samEntryActionModel, selectedSupplierLead } = this.state;
    const {
      loadScorecardingPeriods,
      selectedSamEntry,
      setSelectedSupplier,
    } = this.props;
    if (selectedSamEntry != null) {
      if (
        prevSamEntry === null ||
        ((prevSamEntry.supplier.id !== selectedSamEntry.supplier.id) &&
          (samEntryActionModel.supplier.id !== selectedSamEntry.supplier.id))
      ) {
        loadScorecardingPeriods(
          selectedSamEntry.supplier.id
        );
        setSelectedSupplier(selectedSamEntry.supplier);
        this.setState({
          samEntryActionModel: selectedSamEntry,
          selectedSupplier: {
            label: selectedSamEntry.supplier.name,
            value: selectedSamEntry.supplier.id
          },
          selectedPeriod: {
            label: selectedSamEntry.cadence.name,
            value: selectedSamEntry.cadence.id
          },
          isSubmitDisable: true,
          selectedSupplierLead: selectedSamEntry.supplier.supplierLeadNames
            ? selectedSamEntry.supplier.supplierLeadNames[0]
            : selectedSupplierLead
        });
      }
    }
  }

  onChangeSupplier(event, selectedOption) {
    if (selectedOption !== undefined || selectedOption !== null) {
       const { loadScorecardingPeriods, setSelectedSupplier } = this.props;
       loadScorecardingPeriods(selectedOption.key);
       setSelectedSupplier(new SupplierModel(selectedOption.key, selectedOption.text,
        selectedOption.leadId, selectedOption.lead, selectedOption.type));
      this.setState({
        samEntryActionModel: new SamEntryActionModel(
          null,
          new SupplierModel(selectedOption.key, selectedOption.text,
            selectedOption.leadId, selectedOption.lead, selectedOption.type),
          new PeriodModel(null, null)
        ),
        selectedSupplier: selectedOption,
        selectedPeriod: {
          label: '',
          value: 0
        },
        selectedSupplierLead: selectedOption.lead[0],
        isSubmitDisable: false
      });
    }
  }

  onChangePeriod(event, selectedOption) {
    if (selectedOption !== undefined || selectedOption !== null) {
        this.setState(prevState => ({
          samEntryActionModel: new SamEntryActionModel(
            null,
            new SupplierModel(
              prevState.samEntryActionModel.supplier.id,
              prevState.samEntryActionModel.supplier.name,
              null,
              null,
              prevState.samEntryActionModel.supplier.supplierType
            ),
            new PeriodModel(
              selectedOption.key,
              selectedOption.text
            )
          ),
          selectedPeriod: selectedOption,
          isSubmitDisable: true
        }));
    }
  }

  onActionPlanFilterSubmit = event => {
    event.preventDefault();
    const {
      selectedSupplier,
      selectedPeriod,
      samEntryActionModel
    } = this.state;
    const {
      setSelectedSamEntryAction
    } = this.props;
    if (
      (selectedSupplier.label && selectedPeriod.label) !==
      null
    ) {
        setSelectedSamEntryAction(samEntryActionModel);
    }
  };

  isNullOrUndefined = variable => {
    if (variable === null || variable === '' || variable === undefined) {
      return true;
    }
    return false;
  };

  render() {
    const {
      selectedSupplier,
      selectedPeriod,
      isSubmitDisable
    } = this.state;
    const {
      scorecardSuppliers,
      scorecardPeriods
      // actionPlanDetails
    } = this.props;
    // const {
    //   isLoading
    // } = actionPlanDetails;

    const samSuppliers = scorecardSuppliers
    .filter(x => x.supplierType === SupplierType.SRM || SupplierType.Monitor);

    return (
    <div className="col-md-12 col-lg-12 col-sm-12">
      <div className="row hdspec samScorecardFilter-selections">
        <div className="samScorecardFilter-selector samSupplierComboBox">
          {/* <label className={isLoading ?
            'samScorecardFilter-selector-label-spinner' : 'samScorecardFilter-selector-label'}> */}
              {/* <span className="samScorecardFilter-label">Supplier</span> */}
            {/* <span className="sr-only">combo box</span> */}
            <ComboBox
              value={selectedSupplier}
              label="Supplier"
              onChange={this.onChangeSupplier}
              styles={this.comboBoxStyles}
              useAriaLabelAsText
              allowFreeInput
              autoComplete="on"
              useComboBoxAsMenuWidth
              iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
              options={
                !this.isNullOrUndefined(samSuppliers)
                    ? samSuppliers.map(supplier => ({
                        key: supplier.supplierId,
                        label: supplier.supplierName,
                        text: supplier.supplierName,
                        leadId: supplier.supplierLeadIds,
                        lead: supplier.supplierLeads,
                        type: supplier.supplierType
                    }))
                    : null
                }
              placeholder="Select"
              comboBoxOptionStyles={{ maxHeight: '100px' }}
              calloutProps={{ doNotLayer: true }}
              disabled={this.isNullOrUndefined(samSuppliers)}
              ariaLabel="Supplier combo box select has autocomplete"
            />
            {/* </label> */}
        </div>
        <div className="samScorecardFilter-selector samPeriodComboBox">
          {/* <label className={isLoading ?
            'samScorecardFilter-selector-label-spinner' :
            'samScorecardFilter-selector-label'}> */}
            {/* <span className="samScorecardFilter-label">Period</span> */}
            {/* <span className="sr-only">combo box</span> */}
            <ComboBox
              value={selectedPeriod}
              label="Period"
              onChange={this.onChangePeriod}
              styles={this.comboBoxStyles}
              iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
              useAriaLabelAsText
              allowFreeInput
              autoComplete="on"
              options={
                !this.isNullOrUndefined(scorecardPeriods)
                    ? scorecardPeriods.cadences
                        .map(per => ({
                        key: per.cadenceId,
                        text: per.cadenceName
                        }))
                    : null
                }
              placeholder="Select"
              disabled={
                scorecardPeriods.cadences === 'undefined' ||
                scorecardPeriods.cadences === null ||
                scorecardPeriods.cadences.length === 0
              }
              calloutProps={{ doNotLayer: true }}
              useComboBoxAsMenuWidth
              ariaLabel="Cadence combo box select has autocomplete"
            />
          {/* </label> */}
        </div>
        <div className="textsize14 samSubmitContainer">
            {isSubmitDisable ? (
            <PrimaryButton
              aria-label="Search Action Plan"
              allowDisabledFocus={false}
              onClick={this.onActionPlanFilterSubmit}
              className="samSubmit "
              type="Submit"
              text="Search"
            />
            ) : (
            <PrimaryButton
              allowDisabledFocus={false}
              aria-label="Search Action Plan"
              onClick={this.onActionPlanFilterSubmit}
              className="samSubmit samDisabledSubmitButton"
              type="Submit"
              text="Search"
            />
            )}
        </div>
      </div>
    </div>
  );
}
}

function mapStateToProps(state) {
    return {
      scorecardSuppliers:
        state.scorecardsuppliers === null || state.scorecardsuppliers.length === 0
          ? []
          : state.scorecardsuppliers,
      scorecardPeriods: state.scorecardSupplierPeriods
        ? state.scorecardSupplierPeriods
        : { cadences: [] },
      selectedSamEntry: state.samActionPlanData.selectedSamEntry,
      actionPlanDetails: state.samActionPlanData.actionPlanDetails,
    };
}

function mapDispatchToProps(dispatch) {
  return {
    loadScorecardingSuppliers: bindActionCreators(
      scorecardFilterPanelReduxActions.getScorecardSuppliers,
      dispatch
    ),
    loadScorecardingPeriods: bindActionCreators(
      scorecardFilterPanelReduxActions.getScorecardSupplierPeriods,
      dispatch
    ),
    setSelectedSamEntryAction: bindActionCreators(
      samReduxActions.setSelectedSamEntryAction,
      dispatch
    ),
    setSelectedSupplier: bindActionCreators(
      samReduxActions.setSelectedSupplier,
      dispatch
    )
  };
}

ActionPlanFilter.defaultProps = {
  setSelectedSamEntryAction: null,
  setSelectedSupplier: null,
  selectedSamEntry: null
  // actionPlanDetails: null,
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(ActionPlanFilter)
);

ActionPlanFilter.propTypes = {
  loadScorecardingSuppliers: PropTypes.func.isRequired,
  loadScorecardingPeriods: PropTypes.func.isRequired,
  scorecardSuppliers: PropTypes.array.isRequired,
  scorecardPeriods: PropTypes.object.isRequired,
  setSelectedSamEntryAction: PropTypes.func,
  setSelectedSupplier: PropTypes.func,
  selectedSamEntry: PropTypes.object
  // actionPlanDetails: PropTypes.object
};
