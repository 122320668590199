/* eslint-disable import/prefer-default-export */
import * as pagetourService from '../../api/pagetourService';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';

function loadpageTourSuccess(tours) {
  return { type: types.LOAD_PAGETOUR_SUCCESS, payload: tours };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

function showPageTourDialog(action) {
  return { type: types.SHOW_PAGETOUR_DIALOG, payload: action };
}

export function getPageTours() {
  return async dispatch => {
    try {
      const response = await pagetourService.getTours();
        dispatch(loadpageTourSuccess(response));
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function showPageTourDialogAction(action) {
  return async dispatch => {
        dispatch(showPageTourDialog(action));
  };
}
  export function PlayTour(tour) {
    return async dispatch => {
      dispatch(showPageTourDialog(false));
      try {
        await pagetourService.playTour(tour);
      } catch (error) {
        dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
        Logger.getInstance().logException(error);
      }
    };
  }

  export function editTour(tour) {
    return async dispatch => {
      dispatch(showPageTourDialog(false));
      try {
        await pagetourService.openPageTourEditDialog(tour);
      } catch (error) {
        dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
        Logger.getInstance().logException(error);
      }
    };
  }
