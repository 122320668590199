import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import './ActionsPanel.css';
import {
  Pivot,
  PivotItem,
  PivotLinkFormat
} from 'office-ui-fabric-react/lib/Pivot';
import ActionFilters from '../../../common/consts/actionFilters';
import * as myActionsReduxActions from '../../../redux/actions/myActionsActions';
import BobEntryActions from '../bobEntryActionList/BobEntryActionList';
import SupplierLeadEntryActions from '../supplierLeadEntryActionList/SupplierLeadEntryActionList';
import ActionModes from '../../../common/consts/actionModes';

export class ActionsPanel extends Component {
  constructor(props) {
    super(props);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
  }

  async componentDidMount() {
    const { loadCurrentCadencePeriod } = this.props;
    loadCurrentCadencePeriod();
  }

  onFilterClick(ev, item) {
    const { setActionFilter } = this.props;
    setActionFilter({ id: item.key, name: item.text });
  }

  onTabClick(item) {
    const { selectedActionMode, setActionMode } = this.props;
    if (selectedActionMode !== item.props.itemKey) {
      setActionMode(item.props.itemKey);
    }
  }

  render() {
    const {
      selectedActionFilter,
      selectedActionMode,
      loggedInUser,
      currentCadence
    } = this.props;
    const filters = Object.values(ActionFilters);
    return (
      <div className="mt-3 col-md-3 col-lg-3 col-sm-3 actionParentDiv">
        <div className="myActionsFilterContainer">
          <span className="mlMyAction mtMyAction">
            <h2 className="textsize20 myActionsHeader">My Actions </h2>
            <span className="cadence-heading"> {currentCadence}</span>
          </span>{' '}
          <CommandBarButton
            text={selectedActionFilter ? selectedActionFilter.name : ''}
            className="filterBtn"
            menuProps={{
              shouldFocusOnMount: true,
              className: 'menu',
              items: filters && filters.map(filter => (filter === null ? '' : ({
                key: filter.id,
                text: filter.name,
                canCheck: true,
                isChecked: selectedActionFilter ? selectedActionFilter.id === filter.id : false,
                onClick: this.onFilterClick
              })))
            }}
          />
        </div>
        <div className="actionPanelDiv">
          <Pivot
            className="actionPanelDiv"
            linkFormat={PivotLinkFormat.links}
            onLinkClick={this.onTabClick}
            selectedKey={selectedActionMode}
          >
            {loggedInUser.isFunctionalUser
             && !loggedInUser.isSupplierLead
             && !loggedInUser.isBobOwner
             && !loggedInUser.isAdmin ? (
              <PivotItem
                headerText="Functional User Entry"
                itemKey={ActionModes.BoBEntry}
              >
              <BobEntryActions />
              </PivotItem>
            ) : (
              <PivotItem headerText="BOB Entry" itemKey={ActionModes.BoBEntry}>
              <BobEntryActions />
              </PivotItem>)}

                       {loggedInUser.isAdmin || loggedInUser.isSupplierLead ? (
              <PivotItem
                headerText="Supplier Lead Entry"
                itemKey={ActionModes.SupplierLeadEntry}
              >
                <SupplierLeadEntryActions />
              </PivotItem>
            ) : (
              <></>
            )}
          </Pivot>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    loggedInUser: state.loggedInUser,
    selectedActionFilter: state.myActions.actionFilter,
    selectedActionMode: state.myActions.selectedActionMode,
    currentCadence: state.myActions.currentCadence
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setActionFilter: bindActionCreators(
      myActionsReduxActions.setActionFilter,
      dispatch
    ),
    setActionMode: bindActionCreators(
      myActionsReduxActions.setSelectedActionMode,
      dispatch
    ),
    loadCurrentCadencePeriod: bindActionCreators(
      myActionsReduxActions.loadCurrentCadencePeriod,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionsPanel);

ActionsPanel.defaultProps = {
  currentCadence: null
};

ActionsPanel.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  selectedActionFilter: PropTypes.object.isRequired,
  setActionFilter: PropTypes.func.isRequired,
  selectedActionMode: PropTypes.number.isRequired,
  setActionMode: PropTypes.func.isRequired,
  currentCadence: PropTypes.string,
  loadCurrentCadencePeriod: PropTypes.func.isRequired
};
