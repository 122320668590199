import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Pivot,
  PivotItem,
  PivotLinkFormat
} from 'office-ui-fabric-react/lib/Pivot';
import SamEntryActions from '../samEntryActionList/SamEntryActionList';

export class SamEntryPanel extends Component {
  render() {
    const {
      selectedActionMode,
      loggedInUser,
      samEntryActions
    } = this.props;

    const samEntryCadence = (samEntryActions.actions && samEntryActions.actions.length > 0) ? samEntryActions.actions[0].cadence.name : '';
    return (
      <div className="mt-3 col-md-3 col-lg-3 col-sm-3 actionParentDiv">
        <div className="myActionsFilterContainer">
          <span className="mlMyAction mtMyAction">
            <h2 className="textsize20 myActionsHeader">My Actions </h2>
            <p className="cadence-heading"> {samEntryCadence}</p>
          </span>{' '}
        </div>
        <div className="actionPanelDiv">
          <Pivot
            className="actionPanelDiv"
            linkFormat={PivotLinkFormat.links}
            onLinkClick={this.onTabClick}
            selectedKey={selectedActionMode}
          >
            {loggedInUser.isAdmin || loggedInUser.isSupplierLead ? (
              <PivotItem
                headerText="SAM Action Plan Entry"
              >
                <SamEntryActions />
              </PivotItem>
            ) : (
              <></>
            )}
          </Pivot>
        </div>
      </div>
    );
  }
}


export function mapStateToProps(state) {
  return {
    loggedInUser: state.loggedInUser,
    samEntryActions: state.samActionPlanData.samEntryActions
  };
}

export default connect(
  mapStateToProps,
  null
)(SamEntryPanel);

SamEntryPanel.defaultProps = {
  samEntryActions: null
};

SamEntryPanel.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  selectedActionMode: PropTypes.number.isRequired,
  samEntryActions: PropTypes.object
};
