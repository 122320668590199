import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CorrectiveActionPlanHistory.css';
import { connect } from 'react-redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import withErrorHandling from '../../hoc/withErrorHandling';
import * as correctiveActionPlanHistoryActions from '../../../redux/actions/correctiveActionPlanHistoryActions';
import ErrorDiv from '../../hoc/errordiv';
import { Logger } from '../../../infra/logging/telemetryLogger';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

class CorrectiveActionPlanHistory extends Component {
  state = {
    isCategoryHistoryLoadSuccessfull: true,
    isLoadedSuccessfully: false
  };

  constructor(props) {
    super(props);
    this.logger = Logger.getInstance();
  }

  async componentDidMount() {
    const {
      selectedBobEntry,
      selectedSupplierLeadEntry,
      loadBobScoreCardCapHistory,
      loadSupplierScoreCardCapHistory,
      selectedScorecardCategory,
      politeAnnouncement
    } = this.props;

    if (selectedBobEntry !== null) {
      try {
        politeAnnouncement('Loading data');
        await loadBobScoreCardCapHistory(selectedScorecardCategory);
        this.setState({ isLoadedSuccessfully: true });
      } catch (err) {
        this.logger.logException(err);
        this.setState({
          isLoadedSuccessfully: false,
          isCategoryHistoryLoadSuccessfull: false
        });
      }
    }

    if (selectedSupplierLeadEntry !== null) {
      try {
        politeAnnouncement('Loading data');
        await loadSupplierScoreCardCapHistory(selectedScorecardCategory);
        this.setState({ isLoadedSuccessfully: true });
      } catch (err) {
        this.logger.logException(err);
        this.setState({
          isLoadedSuccessfully: false,
          isCategoryHistoryLoadSuccessfull: false
        });
      }
    }
  }

  isNullOrUndefined = variable => {
    if (variable === null || variable === '' || variable === undefined) {
      return true;
    }
    return false;
  };

  renderCAPPanel() {
    const {
      isCategoryHistoryLoadSuccessfull,
      isLoadedSuccessfully
    } = this.state;

    const { isPastCAPToggleOn, capHistory } = this.props;

    if (isPastCAPToggleOn) {
      if (!isCategoryHistoryLoadSuccessfull && !isLoadedSuccessfully) {
        return (
          <ErrorDiv
            error="Error Loading Past CAP's. Please toggle the Past CAP's again to reload."
            hideRetry
          />
        );
      }

      if (!isLoadedSuccessfully) {
        return (
          <Spinner
            className="mt-3"
            size={SpinnerSize.large}
            label="Loading Past CAP's..."
          />
        );
      }
      if (!capHistory) {
        return (
          <ErrorDiv
            className="mt-5"
            error="Error Loading Past Comments. Please toggle the Past Comments again to reload."
            hideRetry
          />
        );
      }
      if (!capHistory.length) {
        return (
          <>
            {' '}
            <span className="error pastCAPDetails text-muted">
              - No past CAPs available. -
            </span>{' '}
          </>
        );
      }

      if (
        capHistory && isCategoryHistoryLoadSuccessfull
      ) {
        return (
          <>
            <span className="header">Past CAPs</span>
            { capHistory.map((item, i) => (item === null ? '' : (
              <div className="pastCAPDetails mt-2">
                <div className="col-md-12">
                  <span className="title">Open Period: </span>
                  <span className="period">
                    {item.openDate}
                  </span>
                  <span className="title pl-15">Due Period: </span>
                  <span className="period">
                    {item.dueDate}
                  </span>
                  {!this.isNullOrUndefined(item.completedDate) ?
                  <span className="title pl-15">Close Period: </span>
                  : '' }
                  {!this.isNullOrUndefined(item.completedDate) ?
                  <span className="period">
                    {item.completedDate}
                  </span> : ''
                  }
                </div>
                <div className="col-md-12">
                  <span className="title">{item.description}</span>
                </div>
                {!(i === capHistory.length - 1) ?
                <div className="CAPsHistorySeparator" />
                : '' }
              </div>
            )))}
          </>
        );
      }
    }
    return <></>;
  }

  render() {
    return (
      <div id="CAPsHistory" className="col-md-12">
        <div className="CAPsHistoryContainer mt-3">
          {this.renderCAPPanel()}
          <br />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    capHistory: state.capHistory === null || state.capHistory.length === 0 ? [] : state.capHistory,
    selectedScorecardCategory:
      state.myActionsScorecard.selectedScorecardCategory,
    selectedSupplierLeadEntry: state.myActions.selectedSupplierLeadEntry,
    selectedBobEntry: state.myActions.selectedBobEntry,
    bobScorecardCategoryDetails:
      state.myActionsScorecard.bobScorecardCategoryDetails,
    supplierScorecardCategoryDetails:
      state.myActionsScorecard.supplierScorecardDetails
  };
}

const mapDispatchToProps = {
  loadBobScoreCardCapHistory: correctiveActionPlanHistoryActions.getBobScorecardCapHistory,
  loadSupplierScoreCardCapHistory:
    correctiveActionPlanHistoryActions.getSupplierScorecardCapHistory,
  politeAnnouncement: PoliteAnnouncement
};

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CorrectiveActionPlanHistory)
);

CorrectiveActionPlanHistory.defaultProps = {
  selectedBobEntry: null,
  selectedSupplierLeadEntry: null,
  politeAnnouncement: null
};

CorrectiveActionPlanHistory.propTypes = {
  capHistory: PropTypes.array.isRequired,
  loadBobScoreCardCapHistory: PropTypes.func.isRequired,
  loadSupplierScoreCardCapHistory: PropTypes.func.isRequired,
  selectedScorecardCategory: PropTypes.number.isRequired,
  selectedBobEntry: PropTypes.object,
  selectedSupplierLeadEntry: PropTypes.object,
  isPastCAPToggleOn: PropTypes.bool.isRequired,
  politeAnnouncement: PropTypes.func
};
