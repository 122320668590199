/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {
  MetricsInfoModel,
  MetricModal
} from '../../components/models/model/PerformanceMetricModal';

function generateMetricsInfoModal(metrics) {
  const metricsInfo = [];
  metrics.sort((metric1, metric2) => metric1.orderNumber - metric2.orderNumber);
  metrics.forEach(metric => {
    const subMetricsInfo = [];
    if (metric.subMetrics != null && metric.subMetrics !== 'undefined') {
      metric.subMetrics.sort((subMetric1, subMetric2) =>
        subMetric1?.orderNumber - subMetric2?.orderNumber);
      metric.subMetrics.forEach(subMetric => {
        const nextMetricInfoId =
          // eslint-disable-next-line no-nested-ternary
          subMetricsInfo.length > 0
            ? subMetricsInfo[subMetricsInfo.length - 1].metricInfoId + 1
            : metricsInfo.length > 0
            ? (metricsInfo[metricsInfo.length - 1].metricInfoId + 1) * 100
            : 100;

        const subMetricInfo = new MetricsInfoModel(
          nextMetricInfoId,
          false,
          true,
          true,
          new MetricModal(
            subMetric.metricId,
            subMetric.metricName,
            subMetric.metricDescription,
            subMetric.metricTarget,
            subMetric.metricConditionTypeId,
            subMetric.isTargetApplicable,
            subMetric.metricWeight,
            [],
            false,
            subMetric.orderNumber,
            '',
            false,
            false,
            `${nextMetricInfoId}_${subMetric.orderNumber}`
          )
        );
        subMetricInfo.metric.isDeleted = false;
        subMetricsInfo.push(subMetricInfo);
      });
    }

    const metricInfo = new MetricsInfoModel(
      metricsInfo.length + 1,
      false,
      true,
      true,
      new MetricModal(
        metric.metricId,
        metric.metricName,
        metric.metricDescription,
        metric.metricTarget,
        metric.metricConditionTypeId,
        metric.isTargetApplicable,
        metric.metricWeight,
        subMetricsInfo,
        false,
        metric.orderNumber,
        '',
        false,
        false,
        `${metricsInfo.length + 1}_${metric.orderNumber}`
      )
    );
    metricsInfo.push(metricInfo);
  });
  return metricsInfo;
}

function addNewMetricInfoModal(metricsInfo, isParent, parentMetricInfoId) {
  const nextOrderNumber =
    metricsInfo.length > 0
      ? metricsInfo[metricsInfo.length - 1].metric.orderNumber + 1
      : 1;
  let nextMetricInfoId;
  if (isParent) {
    nextMetricInfoId =
      metricsInfo.length > 0
        ? metricsInfo[metricsInfo.length - 1].metricInfoId + 1
        : 1;
  } else {
    nextMetricInfoId =
      metricsInfo.length > 0
        ? metricsInfo[metricsInfo.length - 1].metricInfoId + 1
        : parentMetricInfoId * 100;
  }

  return [
    ...metricsInfo,
    new MetricsInfoModel(
      nextMetricInfoId,
      true,
      false,
      false,
      new MetricModal(
        isParent ? -Math.trunc(Math.random() * 1000) : 0,
        '',
        null,
        null,
        null,
        true,
        null,
        [],
        false,
        nextOrderNumber,
        '',
        false,
        true,
        `${nextMetricInfoId}_${nextOrderNumber}`
      )
    )
  ];
}

function editMetricHelper(metricsInfo, metricInfoIdToBeEdit) {
  const metrics = metricsInfo ? metricsInfo.map(metricInfo => {
    if (metricInfo.metricInfoId === metricInfoIdToBeEdit) {
      return {
        ...metricInfo,
        hasSave: false,
        isEditing: true
      };
    }
    return metricInfo;
  }) : [];
  return metrics;
}

function saveMetricHepler(metricsInfo, metricInfoToBeSave) {
  const metricInfoId = metricInfoToBeSave.metricInfoId;
  // const orderNumber = metricInfoToBeSave.metric.orderNumber;
  const metrics = metricsInfo && metricsInfo?.map(metricInfo => {
    if (
      metricInfo?.metricInfoId === metricInfoId
    ) {
      return {
        ...metricInfo,
        metric: {
          ...metricInfoToBeSave.metric,
          subMetrics: metricInfo.metric?.subMetrics &&
          metricInfo.metric.subMetrics?.map(metric => metric)
        },
        hasSave: metricInfoToBeSave?.hasSave,
        isEditing: metricInfoToBeSave?.isEditing,
        isProcessed: false
      };
    }
    return metricInfo;
  });
  return metrics;
}

function deleteMetricHelper(metricsInfo, metricInfoToBeDelete) {
  const metricInfoId = metricInfoToBeDelete.metricInfoId;
  const orderNumber = metricInfoToBeDelete.metric.orderNumber;
  const isNewMetric = metricInfoToBeDelete.metric.isNew;
  let newOrderNumber = metricsInfo[0].metric.orderNumber;
  const updatedMetricsInfo = metricsInfo && metricsInfo.map(metricInfo => {
    if (
      !isNewMetric &&
      metricInfo.metricInfoId === metricInfoId &&
      metricInfo.metric.orderNumber === orderNumber
    ) {
      return {
        ...metricInfo,
        metric: {
          ...metricInfoToBeDelete.metric,
          subMetrics: metricInfo.metric.subMetrics &&
           metricInfo.metric.subMetrics.map(subMetricInfo => ({
            ...subMetricInfo,
            metric: { ...subMetricInfo.metric, isDeleted: true },
            isProcessed: false
          }))
        },
        hasSave: metricInfoToBeDelete.hasSave,
        isEditing: metricInfoToBeDelete.isEditing,
        isProcessed: false
      };
    }
    if (isNewMetric && metricInfo.metric.orderNumber !== orderNumber) {
      return {
        ...metricInfo,
        // eslint-disable-next-line no-plusplus
        metric: { ...metricInfo.metric, orderNumber: newOrderNumber++ }
      };
    }
    if (isNewMetric && metricInfo.metric.orderNumber === orderNumber) {
      return {
        ...metricInfo,
        metric: {
          ...metricInfoToBeDelete.metric,
          orderNumber: 0
        }
      };
    }
    return { ...metricInfo };
  });

  const metrics = updatedMetricsInfo.filter(
    metricInfo => metricInfo.metric.orderNumber !== 0
  );

  return [...metrics];
}

function submitMetricshelper(metricsInfo, responseData) {
  let udpatedMetricsInfo = [...metricsInfo];
  responseData.forEach(data => {
    const key = data.key;
    const metricId = data.id;
    udpatedMetricsInfo = udpatedMetricsInfo && udpatedMetricsInfo.map(metricInfo => {
      if (metricInfo.metric.key === key) {
        return {
          ...metricInfo,
          metric: {
            ...metricInfo.metric,
            metricId: metricId,
            isUpdated: false,
            isNew: false,
            subMetrics: [...metricInfo.metric.subMetrics]
          },
          isProcessed: true
        };
      }

      const configuredSubMetrics = [...metricInfo.metric.subMetrics];
      const updatedSubMetrics = configuredSubMetrics &&
      configuredSubMetrics.map(subMetricInfo => {
        if (subMetricInfo.metric.key === key) {
          return {
            ...subMetricInfo,
            metric: {
              ...subMetricInfo.metric,
              metricId: metricId,
              isUpdated: false,
              isNew: false
            },
            isProcessed: true
          };
        }
        return subMetricInfo;
      });

      metricInfo = {
        ...metricInfo,
        metric: {
          ...metricInfo.metric,
          subMetrics: updatedSubMetrics && updatedSubMetrics.map(subMetric => subMetric)
        }
      };
      return metricInfo;
    });
  });

  return udpatedMetricsInfo;
}

function addSubMetricInfoHelper(metricsInfo, parentMetricInfoId) {
  let parentMetricInfo;
  metricsInfo.forEach(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfoId) {
      parentMetricInfo = metricInfo;
    }
  });

  const updatedSubMetrics = addNewMetricInfoModal(
    parentMetricInfo.metric.subMetrics,
    false,
    parentMetricInfoId
  );

  parentMetricInfo = {
    ...parentMetricInfo,
    metric: {
      ...parentMetricInfo.metric,
      subMetrics: updatedSubMetrics && updatedSubMetrics.map(subMetricInfo => subMetricInfo)
    }
  };

  return metricsInfo && metricsInfo.map(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfo.metricInfoId) {
      return {
        ...parentMetricInfo
      };
    }
    return metricInfo;
  });
}

function editSubMetricHelper(
  metricsInfo,
  parentMetricInfoId,
  subMetricInfoIdToBeEdit
) {
  let parentMetricInfo;
  metricsInfo.forEach(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfoId) {
      parentMetricInfo = metricInfo;
    }
  });

  const updatedSubMetrics = editMetricHelper(
    parentMetricInfo.metric.subMetrics,
    subMetricInfoIdToBeEdit
  );

  parentMetricInfo = {
    ...parentMetricInfo,
    metric: {
      ...parentMetricInfo.metric,
      subMetrics: updatedSubMetrics && updatedSubMetrics.map(subMetricInfo => subMetricInfo)
    }
  };

  return metricsInfo && metricsInfo.map(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfo.metricInfoId) {
      return {
        ...parentMetricInfo
      };
    }
    return metricInfo;
  });
}

function saveSubMetricHelper(
  metricsInfo,
  subMetricInfoToBeSave,
  parentMetricInfoId
) {
  let parentMetricInfo;
  metricsInfo.forEach(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfoId) {
      parentMetricInfo = metricInfo;
    }
  });

  const updatedSubMetrics = saveMetricHepler(
    parentMetricInfo.metric.subMetrics,
    subMetricInfoToBeSave
  );

  parentMetricInfo = {
    ...parentMetricInfo,
    metric: {
      ...parentMetricInfo.metric,
      subMetrics: updatedSubMetrics && updatedSubMetrics.map(subMetricInfo => subMetricInfo)
    }
  };

  return metricsInfo && metricsInfo.map(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfo.metricInfoId) {
      return {
        ...parentMetricInfo
      };
    }
    return metricInfo;
  });
}

function deleteSubMetricHelper(
  metricsInfo,
  subMetricInfoToBeDelete,
  parentMetricInfoId
) {
  let parentMetricInfo;
  metricsInfo.forEach(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfoId) {
      parentMetricInfo = metricInfo;
    }
  });

  const updatedSubMetrics = deleteMetricHelper(
    parentMetricInfo.metric.subMetrics,
    subMetricInfoToBeDelete
  );

  parentMetricInfo = {
    ...parentMetricInfo,
    metric: {
      ...parentMetricInfo.metric,
      subMetrics: updatedSubMetrics && updatedSubMetrics.map(subMetricInfo => subMetricInfo)
    }
  };

  return metricsInfo && metricsInfo.map(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfo.metricInfoId) {
      return {
        ...parentMetricInfo
      };
    }
    return metricInfo;
  });
}

function undoMetricHelper(metricsInfo, metricInfoIdToBeUndo) {
  const metrics = metricsInfo && metricsInfo.map(metricInfo => {
    if (metricInfo.metricInfoId === metricInfoIdToBeUndo) {
      return {
        ...metricInfo,
        hasSave: true,
        isEditing: false
      };
    }
    return metricInfo;
  });
  return metrics;
}

function undoSubMetricHelper(
  metricsInfo,
  parentMetricInfoId,
  subMetricInfoIdToBeUndo
) {
  let parentMetricInfo;
  metricsInfo.forEach(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfoId) {
      parentMetricInfo = metricInfo;
    }
  });

  const updatedSubMetrics = undoMetricHelper(
    parentMetricInfo.metric.subMetrics,
    subMetricInfoIdToBeUndo
  );

  parentMetricInfo = {
    ...parentMetricInfo,
    metric: {
      ...parentMetricInfo.metric,
      subMetrics: updatedSubMetrics && updatedSubMetrics.map(subMetricInfo => subMetricInfo)
    }
  };

  return metricsInfo && metricsInfo.map(metricInfo => {
    if (metricInfo.metricInfoId === parentMetricInfo.metricInfoId) {
      return {
        ...parentMetricInfo
      };
    }
    return metricInfo;
  });
}

function prepareMetricsByCategory(metrics) {
  const modifiedMetrics =
    metrics && metrics.length > 0
      ? metrics.map(metricObj => {
          metricObj.metricsInfo = generateMetricsInfoModal(metricObj.metrics);
          return metricObj;
        })
      : [];

  return modifiedMetrics;
}

export default function MetricConfigurationReducer(
  state = initialState.configuredMetrics,
  action
) {
  switch (action.type) {
    case types.LOAD_PERFORMANCEMETRICS_SUCCESS: {
      const filteredMetrics = action.metricsByCategory;
      const metricsInfo = generateMetricsInfoModal(
        filteredMetrics[0].metrics
      );
      return {
        metricCategoryId: filteredMetrics[0].metricCategoryId,
        metricsInfo: metricsInfo,
        isDefaultMetric: filteredMetrics[0].isDefaultMetric,
        shouldRenderModal: true,
        metricsByCategory:
          action.procurementTypeId === 2
            ? prepareMetricsByCategory(filteredMetrics)
            : []
      };
    }

    case types.UPDATE_METRIC_CATEGORY: {
      const categoryMetric = state.metricsByCategory.filter(
        metric => metric.metricCategoryId === action.categoryId
      );
      return {
        ...state,
        metricCategoryId: action.categoryId,
        metricsInfo: categoryMetric[0].metricsInfo,
        isDefaultMetric: categoryMetric[0].isDefaultMetric
      };
    }

    case types.ADD_NEWMETRIC_SUCCESS: {
      let metricsInfo = state.metricsInfo;
      const isDefaultMetric = state.isDefaultMetric;
      if (
        metricsInfo.length === 1 &&
        isDefaultMetric
      ) {
        metricsInfo =
          metricsInfo &&
          metricsInfo.map(metricInfo => ({
            ...metricInfo,
            metric: {
              ...metricInfo.metric,
              isDeleted: action.procurementTypeId === 1
            },
            isProcessed: false
          }));
      } else {
        const areAllMetricsDeleted = metricsInfo.every(
          metricInfo => metricInfo.metric.isDeleted === true
        );
        if (
          areAllMetricsDeleted &&
          isDefaultMetric &&
          action.procurementTypeId === 1
        ) {
          metricsInfo =
            metricsInfo &&
            metricsInfo.map((metricInfo, index) => {
              if (index === 0) {
                return {
                  ...metricInfo,
                  metric: {
                    ...metricInfo.metric,
                    isDeleted: true
                  },
                  isProcessed: false
                };
              }
              return metricInfo;
            });
        }
      }

      metricsInfo = addNewMetricInfoModal(metricsInfo, true, 0);
      return {
        ...state,
        metricsInfo: metricsInfo,
        submitResponse: null
      };
    }

    case types.EDIT_METRIC_SUCCESS: {
      const metrics = editMetricHelper(
        state.metricsInfo,
        action.metricInfoIdToBeEdit
      );
      return {
        ...state,
        metricsInfo: metrics && metrics.map(metric => metric),
        submitResponse: null
      };
    }

    case types.UNDO_METRIC_SUCCESS: {
      const metrics = undoMetricHelper(
        state.metricsInfo,
        action.metricInfoIdToBeUndo
      );
      return {
        ...state,
        metricsInfo: metrics && metrics.map(metric => metric)
      };
    }

    case types.SAVE_METRIC_SUCCESS: {
      const metrics = saveMetricHepler(
        state.metricsInfo,
        action.metricInfoToBeSave
      );
      return {
        ...state,
        metricsInfo: metrics && metrics.map(metric => metric)
      };
    }

    case types.DELETE_METRIC_SUCCESS: {
      const metrics = deleteMetricHelper(
        state.metricsInfo,
        action.metricInfoToBeDelete
      );
      return {
        ...state,
        metricsInfo: metrics && metrics.map(metric => metric),
        submitResponse: null
      };
    }

    case types.SUBMET_METRIC_SUCCESS: {
      const udpatedMetricsInfo = submitMetricshelper(
        state.metricsInfo,
        action.response.data
      );
      const categoryMetrics = state.metricsByCategory.map(metric => {
        if (metric.metricCategoryId === state.metricCategoryId) {
          return {
            ...metric,
            metricsInfo: state.metricsInfo
          };
        }
        return metric;
      });
      return {
        ...state,
        metricsInfo:
          udpatedMetricsInfo &&
          udpatedMetricsInfo.map(metricInfo => metricInfo),
        metricsByCategory: categoryMetrics,
        submitResponse: { ...action.response.validationResult }
      };
    }

    case types.SUBMET_METRIC_FAILURE: {
      return {
        ...state,
        submitResponse: { ...action.response }
      };
    }

    case types.ADD_NEWSUBMETRIC_SUCCESS: {
      const metricsInfo = [...state.metricsInfo];
      const parentMetricInfoId = action.parentMetricInfoId;
      const updatedMetrics = addSubMetricInfoHelper(
        metricsInfo,
        parentMetricInfoId
      );

      return {
        ...state,
        metricsInfo:
          updatedMetrics && updatedMetrics.map(metricInfo => metricInfo),
        submitResponse: null
      };
    }

    case types.EDIT_SUBMETRIC_SUCCESS: {
      const metricsInfo = [...state.metricsInfo];
      const subMetricInfoIdToBeEdit = action.subMetricInfoIdToBeEdit;
      const parentMetricInfoId = action.parentMetricInfoId;

      const updatedMetrics = editSubMetricHelper(
        metricsInfo,
        parentMetricInfoId,
        subMetricInfoIdToBeEdit
      );

      return {
        ...state,
        metricsInfo:
          updatedMetrics && updatedMetrics.map(metricInfo => metricInfo),
        submitResponse: null
      };
    }

    case types.UNDO_SUBMETRIC_SUCCESS: {
      const metricsInfo = [...state.metricsInfo];
      const subMetricInfoToBeUndo = action.subMetricInfoToBeUndo;
      const parentMetricInfoId = action.parentMetricInfoId;

      const updatedMetrics = undoSubMetricHelper(
        metricsInfo,
        parentMetricInfoId,
        subMetricInfoToBeUndo
      );

      return {
        ...state,
        metricsInfo:
          updatedMetrics && updatedMetrics.map(metricInfo => metricInfo)
      };
    }

    case types.SAVE_SUBMETRIC_SUCCESS: {
      const metricsInfo = [...state.metricsInfo];
      const parentMetricInfoId = action.parentMetricInfoId;
      const subMetricInfoToBeSave = action.subMetricInfoToBeSave;
      const updatedMetrics = saveSubMetricHelper(
        metricsInfo,
        subMetricInfoToBeSave,
        parentMetricInfoId
      );
      return {
        ...state,
        metricsInfo:
          updatedMetrics && updatedMetrics.map(metricInfo => metricInfo)
      };
    }

    case types.DELETE_SUBMETRIC_SUCCESS: {
      const metricsInfo = [...state.metricsInfo];
      const parentMetricInfoId = action.parentMetricInfoId;
      const subMetricInfoToBeDelete = action.subMetricInfoToBeDelete;
      const updatedMetrics = deleteSubMetricHelper(
        metricsInfo,
        subMetricInfoToBeDelete,
        parentMetricInfoId
      );
      return {
        ...state,
        metricsInfo:
          updatedMetrics && updatedMetrics.map(metricInfo => metricInfo),
        submitResponse: null
      };
    }

    case types.LOAD_PERFORMANCEMETRICS_FAILURE:
      return {
        ...state,
        shouldRenderModal: false,
        metricCategoryId: 0,
        metricsInfo: [],
        isDefaultMetric: false,
        submitResponse: null
      };

    default:
      return state;
  }
}
