/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withErrorHandling from '../../hoc/withErrorHandling';
import './pageTour.css';
import * as pagetourService from '../../../api/pagetourService';
import * as PageTourActions from '../../../redux/actions/pageTourActions';

export class pageTour extends Component {
  async componentDidMount() {
    const { tours } = this.props;
    const autoplayableTour = tours.find(
      tour => !tour.isviewed && tour.isautoplayenabled
    );

    if (autoplayableTour) this.runTour(autoplayableTour);
  }

  onEditTour(tour) {
    const { editTour } = this.props;
    editTour(tour);
  }

  async startRecording() {
    await pagetourService.openAuthorTourDialog();
  }

  async showFullList() {
    await pagetourService.openManageTourDialog();
  }


  runTour(tour) {
    const { PlayTour } = this.props;
    PlayTour(tour);
  }


  render() {
    const { tours, loggedInUser } = this.props;
    return (
      <div className="pagetour-list__items active">
        <div className="pageTour-header">
          <div className="pageTour-sub-header">
            <p className="font-subtitle">Page Tours on this page</p>
            {loggedInUser.isAdmin ? (
              <button
                className="pagetour-actions"
                onClick={this.startRecording}
                type="button"
              >
                <i className="icon icon-plus" /> Add Tour
              </button>
            ) : null}
          </div>
          <div>
            <button
              className="pagetour-actions"
              onClick={() => this.showFullList()}
              type="button"
            >
              <i className="icon icon-bullet-list"> Go to full list </i>
            </button>
          </div>
        </div>
        <div className="pagetour-items">
          {tours.map(tour => (
            <section className="pagetour-list__item">
              <div className="tour">
                <button
                  className="tourTitle"
                  onClick={() => this.runTour(tour)}
                  type="button"
                >
                  {tour.title}
                </button>
                <p>{tour.description}</p>
              </div>
              <div className="actions">
                <button
                  className="pagetour-actions"
                  aria-label="Click here to edit tour"
                  onClick={() => this.onEditTour(tour)}
                  type="button"
                >
                  <i className="icon icon-pencil" aria-hidden="true" />
                </button>
                <button
                  className="pagetour-actions"
                  aria-label="Click here to delete tour"
                  onClick={() => this.onDeleteTour(tour)}
                  type="button"
                >
                  <i className="icon icon-trash" aria-hidden="true" />
                </button>
              </div>
            </section>
          ))}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  tours: state.pageTour.pageTours,
  loggedInUser: state.loggedInUser
});

const mapDispatchToProps = {
  PlayTour: PageTourActions.PlayTour,
  editTour: PageTourActions.editTour
};
export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(pageTour)
);
pageTour.defaultProps = {
  tours: []
};
pageTour.propTypes = {
  tours: PropTypes.array,
  loggedInUser: PropTypes.object.isRequired,
  PlayTour: PropTypes.func.isRequired,
  editTour: PropTypes.func.isRequired
};
