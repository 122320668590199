import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function PageTourReducer(state = initialState.pageTour, action) {
  switch (action.type) {
    case types.LOAD_PAGETOUR_SUCCESS:
      return { ...state, pageTours: action.payload };
      case types.SHOW_PAGETOUR_DIALOG:
        return { ...state, showPagetourdialog: action.payload };
    default:
      return state;
  }
}
