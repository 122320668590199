/* eslint-disable global-require */
const uiEnv = process.env.REACT_APP_UI_ENV.trim();
function getConfig() {
  switch (uiEnv) {
    case 'dit':
      return require('./appConfig.dit.json');
    case 'test':
      return require('./appConfig.test.json');
    case 'uat':
      return require('./appConfig.uat.json');
    case 'prod':
      return require('./appConfig.prod.json');
    default:
      return require('./appConfig.json');
  }
}

const appConfig = getConfig();
export default appConfig;
