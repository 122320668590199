// @ts-check
import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class AnnounceComponent extends React.Component {
  render() {
    const { assertiveAnnouncement, politeAnnouncement } = this.props;
    return (
      <div className="sr-only">
        <p aria-live="assertive">{assertiveAnnouncement}</p>
        <p aria-live="polite">{politeAnnouncement}</p>
      </div>
    );
  }
}
AnnounceComponent.defaultProps = {
  assertiveAnnouncement: null,
  politeAnnouncement: null,
};
AnnounceComponent.propTypes = {
  assertiveAnnouncement: PropTypes.string,
  politeAnnouncement: PropTypes.string,
};

const mapStateToProps = state => ({
  assertiveAnnouncement: state.announcement.assertive,
  politeAnnouncement: state.announcement.polite,
});
export default connect(mapStateToProps, null)(AnnounceComponent);
