const UserRoles = Object.freeze({
  FunctionalUser: 6,
  SRMSupplierLead: 5,
  Read_Only: 4,
  Admin: 3,
  Supplier_Lead: 2,
  BoB_Owner: 1
});

export default UserRoles;
