import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';

function loadMetricConditionsSuccess(metricConditions) {
  return { type: types.LOAD_METRICCONDITIONS_SUCCESS, metricConditions };
}

export default function loadMetricConditions() {
  return async dispatch => {
    try {
      const response = await performanceApi.getMetricConditions();
      dispatch(loadMetricConditionsSuccess(response));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}
