// @ts-check

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function announcementReducer(
  state = initialState.announcement,
  action = { type: types.POLITE_ANNOUNCEMENT }
) {
  switch (action.type) {
    case types.POLITE_ANNOUNCEMENT:
      return { polite: action.payload };
    case types.ASSERTIVE_ANNOUNCEMENT:
      return { assertive: action.payload };
    case types.CLEAR_ANNOUNCEMENT:
      return { assertive: '', polite: '' };
    default:
      return state;
  }
}
