export class OnboardedBOBModel {
  constructor(
    bobName,
    procurementTypeId,
    functionalUsers,
    contractIds,
    reportingCadence,
    standardCategoryId,
    execOrgDetailsId,
    annualContractValue,
    purchaseOrders,
    bobPrimaryOwnerAlias,
    BOBPrimaryOwnerDisplayName,
    bobSecondaryOwnerAlias,
    BOBSecondaryOwnerDisplayName,
    effectivePeriod,
    createdBy,
    modifiedBy,
    ebcmCritical,
    IsActive
  ) {
    this.bobName = bobName;
    this.procurementTypeId = procurementTypeId;
    this.functionalUsers = functionalUsers;
    this.contractIds = contractIds;
    this.reportingCadence = reportingCadence;
    this.standardCategoryId = standardCategoryId;
    this.execOrgDetailsId = execOrgDetailsId;
    this.annualContractValue = annualContractValue;
    this.purchaseOrders = purchaseOrders;
    this.bobPrimaryOwnerAlias = bobPrimaryOwnerAlias;
    this.bobSecondaryOwnerAlias = bobSecondaryOwnerAlias;
    this.effectivePeriod = effectivePeriod;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
    this.BOBPrimaryOwnerDisplayName = BOBPrimaryOwnerDisplayName;
    this.BOBSecondaryOwnerDisplayName = BOBSecondaryOwnerDisplayName;
    this.ebcmCritical = ebcmCritical;
    this.IsActive = IsActive;
  }
}

export class BoBNameModel {
  constructor(bobName) {
    this.bobName = bobName;
  }
}

export class ProcurementTypeIdModel {
  constructor(procurementTypeId) {
    this.procurementTypeId = procurementTypeId;
  }
}
export class StdCategoryGroupModel {
  constructor(standardCategoryGroupId, standardCategoryGroupName) {
    this.standardCategoryGroupId = standardCategoryGroupId;
    this.standardCategoryGroupName = standardCategoryGroupName;
  }
}

export class StdCategoryNameModel {
  constructor(standardCategoryId, standardCategoryName) {
    this.standardCategoryId = standardCategoryId;
    this.standardCategoryName = standardCategoryName;
  }
}

export class ExecOrgModel {
  constructor(executiveOrgId, executiveOrgName) {
    this.executiveOrgId = executiveOrgId;
    this.executiveOrgName = executiveOrgName;
  }
}

export class ExecOrgDetailsModel {
  constructor(executiveOrgDetailsId, executiveOrgDetailsValue) {
    this.executiveOrgDetailsId = executiveOrgDetailsId;
    this.executiveOrgDetailsValue = executiveOrgDetailsValue;
  }
}
export class FunctionalUserModel {
  constructor(bobId, userAlias, userDisplayName) {
    this.bobId = bobId;
    this.userAlias = userAlias;
    this.userDisplayName = userDisplayName;
  }
}
export class ContractIdModel {
  constructor(contractId) {
    this.contractId = contractId;
  }
}

export class PONumberModel {
  constructor(poNumber) {
    this.poNumber = poNumber;
  }
}

export class BOBOwnerModel {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export class ReportingCadenceModel {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export class AnnualContractValueModel {
  constructor(annualContractValue) {
    this.annualContractValue = annualContractValue;
  }
}

/* export class WeightModel {
  constructor(weight) {
    this.weight = weight;
  }
} */

export class EffectivePeriodModel {
  constructor(period) {
    this.period = period;
  }
}
