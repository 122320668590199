/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './PerformanceMetric.css';
import { MessageBar } from '@fluentui/react';
import CustomMetric from '../metric/Metric';
import PerformanceSubMetric from '../performanceSubMetric/PerformanceSubMetric';
import withErrorHandling from '../../hoc/withErrorHandling';
import {
  addNewSubMetric,
  editSubMetric,
  saveSubMetric,
  deleteSubMetric,
  undoSubMetric
} from '../../../redux/actions/performanceSubMetricActions';

export class PerformanceMetric extends React.Component {
  constructor(props) {
    super(props);
    this.onClickEditPerfMetric = this.onClickEditPerfMetric.bind(this);
    this.onClickSavePerfMetric = this.onClickSavePerfMetric.bind(this);
    this.onClickDeletePerfMetric = this.onClickDeletePerfMetric.bind(this);
    this.onClickAddSubMetric = this.onClickAddSubMetric.bind(this);
    this.onClickUndoPerfMetric = this.onClickUndoPerfMetric.bind(this);
    this.onClickEditPerfSubMetric = this.onClickEditPerfSubMetric.bind(this);
    this.onClickSavePerfSubMetric = this.onClickSavePerfSubMetric.bind(this);
    this.onClickDeletePerfSubMetric = this.onClickDeletePerfSubMetric.bind(
      this
    );
    this.onClickUndoPerfSubMetric = this.onClickUndoPerfSubMetric.bind(this);
  }

  state = {
    AreNoErrorsInSave: false
  };

  onClickEditPerfMetric(metricInfoId) {
    const { onClickEdit } = this.props;
    this.setState({ AreNoErrorsInSave: false });
    onClickEdit(metricInfoId);
  }

  onClickUndoPerfMetric(metricInfoId) {
    const { onUndoMetric } = this.props;
    onUndoMetric(metricInfoId);
  }

  onClickSavePerfMetric(metricInfo) {
    const { onClickSave } = this.props;
    this.setState({ AreNoErrorsInSave: true });
    onClickSave(metricInfo);
  }

  onClickDeletePerfMetric(metricInfo) {
    const { onClickDelete } = this.props;
    // eslint-disable-next-line array-callback-return
    const sub = metricInfo.metric.subMetrics &&
    metricInfo.metric.subMetrics.map(eachSubMetricInfo => ({
      ...eachSubMetricInfo,
      metric: {
        ...eachSubMetricInfo.metric,
        isDeleted: true
      }
    }));

    metricInfo.metric = { ...metricInfo.metric, subMetrics: sub };
    onClickDelete(metricInfo);
  }

  onClickAddSubMetric() {
    const {
      performanceMetricInfo,
      addSubMetricInfoAction,
      enableSubmit
    } = this.props;
     addSubMetricInfoAction(performanceMetricInfo.metricInfoId);
      enableSubmit();
    this.setState({
      AreNoErrorsInSave: false
    });
  }

  onClickEditPerfSubMetric(subMetricInfoIdToBeEdit) {
    const {
      editSubMetricInfoAction,
      performanceMetricInfo,
      enableSubmit
    } = this.props;
    editSubMetricInfoAction(
      subMetricInfoIdToBeEdit,
      performanceMetricInfo.metricInfoId
    );
    enableSubmit();
    this.setState({
      AreNoErrorsInSave: false
    });
  }

  onClickUndoPerfSubMetric(subMetricInfoIdToBeUndo) {
    const { undoSubMetricInfoAction, performanceMetricInfo } = this.props;
    undoSubMetricInfoAction(
      subMetricInfoIdToBeUndo,
      performanceMetricInfo.metricInfoId
    );
    this.setState({
      AreNoErrorsInSave: false
    });
  }

  onClickSavePerfSubMetric(subMetricInfoToBeSave) {
    const {
      saveSubMetricInfoAction,
      performanceMetricInfo,
      enableSubmit
    } = this.props;
    subMetricInfoToBeSave.metric = {
      ...subMetricInfoToBeSave.metric,
      isUpdated: !subMetricInfoToBeSave.metric.isNew
    };
    subMetricInfoToBeSave = {
      ...subMetricInfoToBeSave,
      hasSave: true,
      isEditing: false
    };
    saveSubMetricInfoAction(
      subMetricInfoToBeSave,
      performanceMetricInfo.metricInfoId
    );
    this.setState({
      AreNoErrorsInSave: true
    });
    enableSubmit();
  }

  onClickDeletePerfSubMetric(subMetricInfoToBeDelete) {
    const {
      deleteSubMetricInfoAction,
      performanceMetricInfo,
      enableSubmit
    } = this.props;
    subMetricInfoToBeDelete.metric = {
      ...subMetricInfoToBeDelete.metric,
      isDeleted: true,
      isUpdated: false
    };
    subMetricInfoToBeDelete = {
      ...subMetricInfoToBeDelete,
      isEditing: false,
      hasSave: true
    };
    deleteSubMetricInfoAction(
      subMetricInfoToBeDelete,
      performanceMetricInfo.metricInfoId
    );
    this.setState({
      AreNoErrorsInSave: true
    });
    enableSubmit();
  }

  render() {
    const { AreNoErrorsInSave } = this.state;
    const {
       performanceMetricInfo, wasDefaultMetric,
       isErrorInSubMetricWeightCalculation, errorMessage
      } = this.props;
    let ContainerBgClass;
    if (performanceMetricInfo.metricInfoId % 2 === 0) {
      ContainerBgClass = wasDefaultMetric
        ? 'metricContainer whiteBg'
        : 'metricContainer blackBg';
    } else {
      ContainerBgClass = wasDefaultMetric
        ? 'metricContainer blackBg'
        : 'metricContainer whiteBg';
    }
    return (
      <div className={ContainerBgClass}>
        <CustomMetric
          key={performanceMetricInfo.metric.metricId}
          onClickEdit={this.onClickEditPerfMetric}
          onClickSave={this.onClickSavePerfMetric}
          onClickDelete={this.onClickDeletePerfMetric}
          onClickAddSubMetric={this.onClickAddSubMetric}
          performanceMetricInfo={performanceMetricInfo}
          onClickUndo={this.onClickUndoPerfMetric}
          isSubMetric={false}
        />
        {performanceMetricInfo.metric.subMetrics ?
          performanceMetricInfo.metric.subMetrics.map(subMetricInfo => (
            <>
              {subMetricInfo.metric.isDeleted === false && (
                <PerformanceSubMetric
                  key={subMetricInfo.metric.metricId}
                  onClickSave={this.onClickSavePerfSubMetric}
                  onClickEdit={this.onClickEditPerfSubMetric}
                  onClickDelete={this.onClickDeletePerfSubMetric}
                  onClickUndo={this.onClickUndoPerfSubMetric}
                  performanceMetricInfo={subMetricInfo}
                />
              )}
            </>
          )) : []}

        <div>
        {AreNoErrorsInSave && !isErrorInSubMetricWeightCalculation && (
            <MessageBar
              delayedRender={false}
              isMultiline={false}
              role="alert"
              className="successMsg mb-2"
              messageBarIconProps={{ iconName: 'Completed' }}
            >
              Metric was saved successfully
            </MessageBar>
        )}
        {isErrorInSubMetricWeightCalculation && errorMessage.length > 0 &&
         (
            <MessageBar
              delayedRender={false}
              isMultiline={false}
              role="alert"
              className="errorMessage mb-2"
              messageBarIconProps={{
                iconName: (errorMessage.filter(each => each.id ===
             performanceMetricInfo.metric.metricId && each.aggregateSubMetricWeight !== 100).length) ? 'error' : null
           }}
            >
              {errorMessage.length > 0 ? errorMessage.filter(each => each.id ===
                performanceMetricInfo.metric.metricId &&
                each.aggregateSubMetricWeight !== 100).map(each => each.subMetricWeight) : null}
            </MessageBar>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    wasDefaultMetric: state.configuredMetrics.isDefaultMetric
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSubMetricInfoAction: bindActionCreators(addNewSubMetric, dispatch),
    editSubMetricInfoAction: bindActionCreators(editSubMetric, dispatch),
    saveSubMetricInfoAction: bindActionCreators(saveSubMetric, dispatch),
    deleteSubMetricInfoAction: bindActionCreators(deleteSubMetric, dispatch),
    undoSubMetricInfoAction: bindActionCreators(undoSubMetric, dispatch)
  };
}

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PerformanceMetric)
);

PerformanceMetric.propTypes = {
  performanceMetricInfo: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  addSubMetricInfoAction: PropTypes.func.isRequired,
  editSubMetricInfoAction: PropTypes.func.isRequired,
  saveSubMetricInfoAction: PropTypes.func.isRequired,
  deleteSubMetricInfoAction: PropTypes.func.isRequired,
  wasDefaultMetric: PropTypes.bool.isRequired,
  onUndoMetric: PropTypes.func.isRequired,
  enableSubmit: PropTypes.func.isRequired,
  undoSubMetricInfoAction: PropTypes.func.isRequired,
  isErrorInSubMetricWeightCalculation: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
