import config from '../config/appConfig';
import HttpClient from '../infra/http/httpClient';

const performanceApiConfigLocal = config.api.performanceApi_Local;
const performanceApiConfig = config.api.performanceApi;

const httpClientLocal = new HttpClient(
  performanceApiConfigLocal.baseUrl,
  performanceApiConfigLocal.scope
);
const httpClient = new HttpClient(
  performanceApiConfig.baseUrl,
  performanceApiConfig.scope
);

function handleresponse(response) {
  return response.data;
}

export async function getBobEntryActions() {
  const url = performanceApiConfig.endpoints.getBobEntryActions;
  return httpClient.get(url);
}

export async function getSupplierLeadEntryActions() {
  const url = performanceApiConfig.endpoints.getSupplierLeadEntryActions;
  return httpClient.get(url);
}

export async function getSSRSReport(supplierId, cadenceId) {
  let url = performanceApiConfig.endpoints.getSsrsReport;
  url = url.replace('{0}', supplierId);
  url = url.replace('{1}', cadenceId);
  return httpClient.getBlobResponse(url);
}

export async function getCurrentCadencePeriod() {
  const url = performanceApiConfig.endpoints.getCurrentCadencePeriod;
  return httpClient.get(url);
}

export async function getSupplierScorecardSummary(supplierScorecardId) {
  const url =
    performanceApiConfig.endpoints.getSupplierScorecardSummary +
    supplierScorecardId;
  return httpClient.get(url);
}

export async function getMetrics() {
  const url = performanceApiConfig.endpoints.getMetrics;
  return httpClientLocal.get(url);
}

export async function getScorecardSuppliers() {
  const url = performanceApiConfig.endpoints.getScorecardSuppliers;
  return httpClient.get(url);
}

export async function getReportingSuppliers() {
  const url = performanceApiConfig.endpoints.getReportingSuppliers;
  return httpClient.get(url);
}

export async function getScorecardBOBs(supplierId) {
  let url = performanceApiConfig.endpoints.getScorecardBOBs;
  url = url.replace('{0}', supplierId);
  return httpClient.get(url);
}

export async function getBobScorecardCapHistory(categoryId) {
  let url = performanceApiConfig.endpoints.getBobScorecardCapHistory;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function getSupplierScorecardCapHistory(categoryId) {
  let url = performanceApiConfig.endpoints.getSupplierScorecardCapHistory;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function getBobScorecardCommentHistory(categoryId) {
  let url = performanceApiConfig.endpoints.getBobScorecardCommentHistory;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function getSupplierScorecardCommentHistory(categoryId) {
  let url = performanceApiConfig.endpoints.getSupplierScorecardCommentHistory;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function getScorecardPeriods(supplierId, bobId) {
  let url = performanceApiConfig.endpoints.getScorecardPeriods;
  url = url.replace('{0}', supplierId);
  url = url.replace('{1}', bobId);
  return httpClient.get(url);
}

export async function getScorecardSupplierPeriods(supplierId) {
  let url = performanceApiConfig.endpoints.getScorecardSupplierPeriods;
  url = url.replace('{0}', supplierId);
  return httpClient.get(url);
}

export async function getSuppliers(searchText) {
  let url = performanceApiConfig.endpoints.getSupplier;
  url = url.replace('{0}', searchText);
  return httpClient.get(url);
}

export async function getDomain() {
  const url = performanceApiConfig.endpoints.getDomain;
  return httpClient.get(url);
}

export async function getCadence() {
  const url = performanceApiConfig.endpoints.getCadence;
  return httpClient.get(url);
}

export async function getFutureCadence(supplierId, cadenceTypeId, bobId,
  cadencePeriodId, isPastAndFutureCadence) {
  let url = performanceApiConfig.endpoints.getFutureCadence;
  url = url.replace('{0}', supplierId);
  url = url.replace('{1}', cadenceTypeId);
  url = url.replace('{2}', bobId);
  url = url.replace('{3}', cadencePeriodId);
  url = url.replace('{4}', isPastAndFutureCadence);
  return httpClient.get(url);
}

export async function getSupplierType() {
  const url = performanceApiConfig.endpoints.getSupplierType;
  return httpClient.get(url);
}

export async function getProcurementType() {
  const url = performanceApiConfig.endpoints.getProcurementType;
  return httpClient.get(url);
}

export async function getOnboardedSuppliers() {
  const url = performanceApiConfig.endpoints.getOnboardedSuppliers;
  return httpClient.get(url);
}

export async function getSupplierBOBs(supplierId) {
  let url = performanceApiConfig.endpoints.getSupplierBOBs;
  url = url.replace('{0}', supplierId);
  return httpClient.get(url);
}

export async function getStdCategoryGroup(supplierProcurementType) {
  let url = performanceApiConfig.endpoints.getStdCategoryGroup;
  url += `?procurementTypeId=${supplierProcurementType}`;

  return httpClient.get(url);
}
export async function getStdCategoryName(supplierProcurementType) {
  let url = performanceApiConfig.endpoints.getStdCategoryName;
  url += `?procurementTypeId=${supplierProcurementType}`;

  return httpClient.get(url);
}

export async function getExecOrg() {
  const url = performanceApiConfig.endpoints.getExecOrg;
  return httpClient.get(url);
}

export async function getExecOrgSummary() {
  const url = performanceApiConfig.endpoints.getExecOrgSummary;
  return httpClient.get(url);
}

export async function getExecOrgDetails() {
  const url = performanceApiConfig.endpoints.getExecOrgDetails;
  return httpClient.get(url);
}

export function getOwnerList() {
  const url = performanceApiConfigLocal.endpoints.getOwnerList;
  return httpClientLocal.get(url);
}

export async function addOnboardBOB(newOnboardBOB, supplierId) {
  const addBOB = newOnboardBOB;
  let url = performanceApiConfig.endpoints.addOnboardBOB;
  url = url.replace('{0}', supplierId);
  delete addBOB.modifiedBy;
  const data = JSON.stringify(addBOB);
  return httpClient.post(url, data).then(handleresponse);
}

export async function updateOnboardedBOB(newOnboardBOB, supplierId, bobId) {
  const updatedBOB = newOnboardBOB;
  let url = performanceApiConfig.endpoints.updateOnboardedBOB;
  url = url.replace('{0}', supplierId);
  url = url.replace('{1}', bobId);
  delete updatedBOB.createdBy;
  const data = JSON.stringify(updatedBOB);
  return httpClient.patch(url, data).then(handleresponse);
}

export async function getReportingCadence() {
  const url = performanceApiConfig.endpoints.getReportingCadence;
  return httpClient.get(url);
}

export async function addOnboardSupplier(newOnboardSupplier) {
  const addOnboardedSupplier = newOnboardSupplier;
  const url = performanceApiConfig.endpoints.addOnboardSupplier;
  delete addOnboardedSupplier.modifiedBy;
  const data = JSON.stringify(addOnboardedSupplier);
  return httpClient.post(url, data).then(handleresponse);
}

export async function getContractDetails(contractId) {
  let url = performanceApiConfig.endpoints.getContractDetails;
  url = url.replace('{0}', contractId);
  return httpClient.get(url);
}

export async function updateOnboardedSupplier(
  updateOnboardSupplier,
  scorecardSupplierId
) {
  const updatedOnboardSupplier = updateOnboardSupplier;
  let url = performanceApiConfig.endpoints.updateOnboardSupplier;
  url += scorecardSupplierId;
  delete updatedOnboardSupplier.supplierId;
  delete updatedOnboardSupplier.supplierName;
  delete updatedOnboardSupplier.uvpAnnualSpend;
  delete updatedOnboardSupplier.createdBy;
  const data = JSON.stringify(updatedOnboardSupplier);
  return httpClient.patch(url, data).then(handleresponse);
}

export async function retrieveMetrics(bobId) {
  let url = performanceApiConfig.endpoints.getPerfMetrics;
  url = url.replace('{0}', bobId);
  return httpClient.get(url);
}

export async function manageMetrics(metrics) {
  const url = performanceApiConfig.endpoints.manageMetrics;
  const data = JSON.stringify(metrics);
  return httpClient.patch(url, data);
}

export async function getReports() {
  const url = performanceApiConfigLocal.endpoints.getReports;
  return httpClientLocal.get(url);
}

export async function getResource() {
  const url = performanceApiConfigLocal.endpoints.getResource;
  return httpClientLocal.get(url);
}

export async function getMetricConditions() {
  const url = performanceApiConfig.endpoints.getMetricConditions;
  return httpClient.get(url).then((response) => response.data);
}

export async function getScoreDefinition() {
  const url = performanceApiConfig.endpoints.getScoreDefinition;
  return httpClient.get(url);
}
export async function getBobScorecardDetails(bobId, supplierId, cadenceId) {
  let url = performanceApiConfig.endpoints.getBobScorecardDetails;
  url = url.replace('{0}', supplierId);
  url = url.replace('{1}', bobId);
  if (cadenceId) {
    url += `?cadenceId=${cadenceId}`;
  }
  return httpClient.get(url);
}

export async function getSupplierScorecardDetails(supplierId, cadenceId) {
  let url = performanceApiConfig.endpoints.getSupplierScorecardDetails;
  url = url.replace('{0}', supplierId);
  if (cadenceId) {
    url += `?cadenceId=${cadenceId}`;
  }
  return httpClient.get(url);
}

export async function getBobScorecardCategoryDetails(categoryId) {
  let url = performanceApiConfig.endpoints.getBobScorecardMetrics;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function getSupplierScorecardCategoryDetails(categoryId) {
  let url = performanceApiConfig.endpoints.getSupplierScorecardMetrics;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function getBobScorecardMetricHistory(categoryId, metricId) {
  let url = performanceApiConfig.endpoints.getBobScorecardMetricHistory;
  url = url.replace('{0}', metricId);
  return httpClient.get(url);
}

export async function getSupplierScorecardMetricHistory(categoryId, metricId) {
  let url = performanceApiConfig.endpoints.getSupplierScorecardMetricHistory;
  url = url.replace('{0}', metricId);
  return httpClient.get(url);
}

export async function getBobScorecardCategoryHistory(categoryId) {
  let url = performanceApiConfig.endpoints.getBobScorecardCategoryHistory;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function getSupplierScorecardCategoryHistory(categoryId) {
  let url = performanceApiConfig.endpoints.getSupplierScorecardCategoryHistory;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}
export async function getAvgBobScoreHistoryForCategory() {
  const url =
    performanceApiConfigLocal.endpoints.getSupplierScorecardAvgBobScoreHistory;
  return httpClientLocal.get(url);
}
export async function updateBobScorecardMetricDetails(
  categoryId,
  bobScorecardUpdateRequest
) {
  const updatedBobScorecard = bobScorecardUpdateRequest;
  let url = performanceApiConfig.endpoints.updateBobScorecardRating;
  url = url.replace('{0}', categoryId);
  const data = JSON.stringify(updatedBobScorecard);
  return httpClient.patch(url, data).then(handleresponse);
}

export async function updateSupplierScorecardMetricDetails(
  categoryId,
  supplierScorecardUpdateRequest
) {
  const updatedSupplierScorecard = supplierScorecardUpdateRequest;
  let url = performanceApiConfig.endpoints.updateSupplierScorecardRating;
  url = url.replace('{0}', categoryId);
  const data = JSON.stringify(updatedSupplierScorecard);
  return httpClient.patch(url, data).then(handleresponse);
}

export async function getSupplierScorecardAvgBOBScoreHistory(categoryId) {
  let url =
    performanceApiConfig.endpoints.getSupplierScorecardAvgBobScoreHistory;
  url = url.replace('{0}', categoryId);
  return httpClient.get(url);
}

export async function deleteSupplierBOB(supplierId, bobId) {
  let url = performanceApiConfig.endpoints.deleteSupplierBOB;
  url = url.replace('{0}', supplierId);
  url = url.replace('{1}', bobId);
  return httpClient.delete(url).then(handleresponse);
}

export async function activateSupplierBOB(supplierId, bobId) {
  let url = performanceApiConfig.endpoints.activateSupplierBOB;
  url = url.replace('{0}', supplierId);
  url = url.replace('{1}', bobId);
  return httpClient.get(url);
}

export async function getSamActionPlanDetails(scorecardCategoryId, supplierId, cadenceId) {
  let url = performanceApiConfig.endpoints.getSamActionPlanDetails;
  url = url.replace('{0}', scorecardCategoryId);
  if (supplierId) {
    url += `?supplierId=${supplierId}`;
  }
  if (supplierId && cadenceId) {
    url += `&cadenceId=${cadenceId}`;
  }
  return httpClient.get(url);
}

export async function getSamEntryActions() {
  const url = performanceApiConfig.endpoints.getSamEntryActions;
  return httpClient.get(url);
}

export async function updateActionPlanDetails(categoryId, plans) {
  let url = performanceApiConfig.endpoints.updateActionPlanDetails;
  url = url.replace('{0}', categoryId);
  const data = JSON.stringify(plans);
  return httpClient.patch(url, data).then(handleresponse);
}

export async function getSamActionPlanHistory(actionType, scorecardId) {
  let url = performanceApiConfig.endpoints.getSamActionPlanHistory;
  url = url.replace('{0}', actionType);
  url = url.replace('{1}', scorecardId);
  return httpClient.get(url);
}

export async function getSamSupplierMetrics(categoryId, supplierMetricScorecardId, supplierId) {
  let url = performanceApiConfig.endpoints.getSamSupplierMetrics;
  url = url.replace('{0}', categoryId);
  url = url.replace('{1}', supplierMetricScorecardId);
  if (supplierId) {
    url += `&supplierId=${supplierId}`;
  }
  return httpClient.get(url);
}
