import '@ms-ofb/officebrowserfeedback/scripts/officebrowserfeedback';
import '@ms-ofb/officebrowserfeedback/intl/en/officebrowserfeedbackstrings';
import '@ms-ofb/officebrowserfeedback/styles/officebrowserfeedback.css';
import '@ms-ofb/officebrowserfeedback/styles/officebrowserfeedback.min.css';
import { Logger } from '../../infra/logging/telemetryLogger';
import { getEnvironment } from '../../config/appEnvConfig';
import config from '../../config/appConfig';
import authContext from '../../infra/auth/aadAuth';
import './SupplierScorecardFeedback.css';

export function initialize() {
    const env = getEnvironment();
    const configuration = config.OCV;
    window.OfficeBrowserFeedback.initOptions = {
            appId: configuration.appId,
            stylesUrl: configuration.stylesUrl,
            intlUrl: configuration.intlUrl,
            environment: env, // 0 - Prod, 1 - Int
            userEmailConsentDefault: false,
            bugForm: true,
            applicationGroup: {
             feedbackTenant: configuration.feedbackTenant
            },
            webGroup: {
                sourcePageURI: window.location.href,
            },
            onError: {
                err: 'An error has occurred. Please try again in a few minutes.'
            }
        };
}

export function giveFeedback() {
    const user = authContext.getUser();
    window.OfficeBrowserFeedback.initOptions.userEmail = user == null ? '' : user.displayableId;
    window.OfficeBrowserFeedback.multiFeedback().then(
        () => { Logger.getInstance().log('Feedback Load succeeded'); },
        (err) => {
            Logger.getInstance().logException(err);
            Logger.getInstance().log('Feedback Load failed');
        }
);
}
