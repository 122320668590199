import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function requestUnprocessedMessageSuccess(message, tcvId) {
    return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
  }

function FetchCategoryDetailsInit(isLoading) {
    return {
      type: types.FETCH_ACTIONPLAN_DATA_INIT,
      payload: isLoading
    };
  }

  function loadSamActionPlanDetailsSuccess(
    samdata
  ) {
    return {
      type: types.LOAD_ACTIONPLAN_DETAILS_SUCCESS,
      payload: samdata
    };
  }

  function loadSamSupplierScorecardDetailsSuccess(samSupplierScorecardDetails) {
    return {
      type: types.LOAD_SAM_SUPPLIERSCORECARDDETAILS_SUCCESS,
      payload: samSupplierScorecardDetails
    };
  }

  function FetchSummaryDetailsInit(isLoading) {
    return {
      type: types.FETCH_SAM_SUMMARYDATA_INIT,
      payload: isLoading
    };
  }

export function loadSamActionPlanDetails(categoryId, supplierId, cadenceId) {
    return async dispatch => {
      try {
        dispatch(FetchCategoryDetailsInit(true));
        const response = await performanceApi.getSamActionPlanDetails(
          categoryId,
          supplierId,
          cadenceId
        );
        if (response.validationResult.isValid) {
          response.data = !response.data ? [] : response.data;
          dispatch(loadSamActionPlanDetailsSuccess(response.data));
        } else {
          dispatch(handleValidationError(response.validationResult.errors));
          Logger.getInstance().logValidationError(response.validationResult);
        }
      } catch (error) {
        dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
        Logger.getInstance().logException(error);
      } finally {
        dispatch(FetchCategoryDetailsInit(false));
      }
    };
  }


function loadSamEntryActionsSuccess(actions) {
    return {
      type: types.LOAD_SAM_ENTRYACTIONS_SUCCESS,
      actions: actions.data
    };
  }

export function loadSamEntryActions() {
    return async dispatch => {
      try {
        const response = await performanceApi.getSamEntryActions();
        if (response.validationResult.isValid) {
          dispatch(loadSamEntryActionsSuccess(response));
        } else {
          dispatch(handleValidationError(response.validationResult.errors));
          Logger.getInstance().logValidationError(response.validationResult);
        }
      } catch (error) {
        dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
        Logger.getInstance().logException(error);
      }
    };
  }

export function setSelectedSamEntryAction(samEntryAction) {
  return async dispatch => {
    dispatch({
      type: types.SET_SELECTED_SAMENTRYACTION,
      samEntryAction
    });
  };
}

export function setSelectedSupplier(selectedSupplier) {
  return async dispatch => {
    dispatch({
      type: types.SET_SELECTED_SUPPLIERENTRY,
      selectedSupplier
    });
  };
}

export const updateSamSelectedCategory = key => ({
  type: types.UPDATE_SAM_SELECTED_CATEGORY,
  payload: { key }
});

export function loadSamSupplierScorecardDetails(supplierId, cadenceId) {
  return async dispatch => {
    try {
      dispatch(FetchSummaryDetailsInit(true));
      const response = await performanceApi.getSupplierScorecardDetails(
        supplierId,
        cadenceId
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadSamSupplierScorecardDetailsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
      throw error;
    } finally {
      dispatch(FetchSummaryDetailsInit(false));
    }
  };
}

export default loadSamActionPlanDetails;
