/* eslint-disable import/prefer-default-export */
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function loadBobEntryActionsSuccess(actions) {
  return { type: types.LOAD_BOBENTRYACTIONS_SUCCESS, actions: actions.data };
}

function loadSupplierLeadEntryActionsSuccess(actions) {
  return {
    type: types.LOAD_SUPPLIERLEAD_ENTRYACTIONS_SUCCESS,
    actions: actions.data
  };
}
function loadCurrentCadencePeriodSuccess(cadencePeriod) {
  return {
    type: types.LOAD_CURRENTCADENCEPERIOD_SUCCESS,
    actions: cadencePeriod
  };
}

function loadSupplierScorecardSummarySuccess(scorecardSummary) {
  return {
    type: types.LOAD_SUPPLIERSCORECARD_SUMMARY_SUCCESS,
    scorecardSummary
  };
}

function loadFutureCadenceSuccess(cadence) {
  return { type: types.LOAD_FUTURECADENCE_SUCCESS, cadence };
}

export function setSelectedBobEntryAction(bobEntryAction) {
  return async dispatch => {
    dispatch({ type: types.SET_SELECTED_BOBENTRYACTION, bobEntryAction });
  };
}

export function setSelectedSupplierLeadEntryAction(supplierLeadEntryAction) {
  return async dispatch => {
    dispatch({
      type: types.SET_SELECTED_SUPPLIERLEADENTRYACTION,
      supplierLeadEntryAction
    });
  };
}

export function setSelectedActionMode(actionMode) {
  return async dispatch => {
    dispatch({
      type: types.SET_SELECTED_ACTIONMODE,
      actionMode
    });
  };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

export function loadBobEntryActions(userAlias) {
  return async dispatch => {
    try {
      const response = await performanceApi.getBobEntryActions(userAlias);
      if (response.validationResult.isValid) {
        dispatch(loadBobEntryActionsSuccess(response));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function loadSupplierLeadEntryActions(userAlias) {
  return async dispatch => {
    try {
      const response = await performanceApi.getSupplierLeadEntryActions(
        userAlias
      );
      if (response.validationResult.isValid) {
        dispatch(loadSupplierLeadEntryActionsSuccess(response));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function loadCurrentCadencePeriod() {
  return async dispatch => {
    try {
      const response = await performanceApi.getCurrentCadencePeriod();
      if (response.validationResult.isValid) {
        let cadencePeriod = null;
        cadencePeriod = response.data[0].cadenceName;
        dispatch(loadCurrentCadencePeriodSuccess(cadencePeriod));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function setActionFilter(filter) {
  return async dispatch => {
    dispatch({ type: types.SET_ACTIONS_FILTER, filter });
  };
}

export function loadSupplierScorecardSummary(supplierScorecardId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getSupplierScorecardSummary(
        supplierScorecardId
      );
      response.data = !response.data ? [] : response.data;
      if (response.validationResult.isValid) {
        dispatch(
          loadSupplierScorecardSummarySuccess({
            summaryCollection: response.data,
            supplierScorecardId
          })
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function loadFutureCadence(supplierId, cadenceTypeId, bobId,
  cadencePeriodId, isPastAndFutureCadence = false) {
  return async dispatch => {
    try {
      const futureCadence = await performanceApi.getFutureCadence(
        supplierId,
        cadenceTypeId,
        bobId,
        cadencePeriodId,
        isPastAndFutureCadence
      );
      dispatch(loadFutureCadenceSuccess(futureCadence));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}
