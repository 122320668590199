/* eslint-disable no-confusing-arrow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './samActionPlanHistory.css';
import { connect } from 'react-redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import withErrorHandling from '../../hoc/withErrorHandling';
import * as actionPlanContainerActions from '../../../redux/actions/actionPlanContainerActions';
import ErrorDiv from '../../hoc/errordiv';
import { Logger } from '../../../infra/logging/telemetryLogger';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

export class SamActionPlanHistory extends Component {
  state = {
    isCategoryHistoryLoadSuccessfull: true,
    isLoadedSuccessfully: false,
  };

  constructor(props) {
    super(props);
    this.logger = Logger.getInstance();
  }

  async componentDidMount() {
    const {
      loadActionPlanHistory,
      scorecardId,
      actionPlanType,
      samActionPlanHistory,
      politeAnnouncement,
    } = this.props;
    const historyIndex = samActionPlanHistory.findIndex(
      (m) => m.scorecardId === scorecardId
    );
    if (historyIndex < 0) {
      try {
        politeAnnouncement('Loading action plans history');
        await loadActionPlanHistory(actionPlanType, scorecardId);
        this.setState({
          isLoadedSuccessfully: true,
        });
      } catch (err) {
        this.logger.logException(err);
        this.setState({
          isLoadedSuccessfully: false,
          isCategoryHistoryLoadSuccessfull: false,
        });
      }
    } else {
      this.setState({
        isLoadedSuccessfully: true,
      });
    }
  }

  renderHistoryPanel() {
    const {
      isCategoryHistoryLoadSuccessfull,
      isLoadedSuccessfully,
    } = this.state;

    const { isHistoryToggleOn, scorecardId, samActionPlanHistory } = this.props;
    const apshistory = samActionPlanHistory.find(
      (m) => m.scorecardId === scorecardId
    );
    if (isHistoryToggleOn) {
      if (!isCategoryHistoryLoadSuccessfull && !isLoadedSuccessfully) {
        return (
          <ErrorDiv
            error="Error Loading Past Action Plans. Please toggle the History again to reload."
            hideRetry
          />
        );
      }

      if (!isLoadedSuccessfully) {
        return (
          <Spinner
            className="mt-3"
            size={SpinnerSize.large}
            label="Loading Past Action Plans..."
          />
        );
      }
      if (!apshistory) {
        return (
          <ErrorDiv
            className="mt-5"
            error="Error Loading Past Action Plans. Please toggle the History again to reload."
            hideRetry
          />
        );
      }
      if (
        (apshistory && !apshistory.history.length) ||
        !apshistory.history.some((item) => item.score)
      ) {
        return (
          <>
            {' '}
            <span className="error pastAPDetails text-muted">
              - No past Action Plans available. -
            </span>{' '}
          </>
        );
      }
      if (apshistory && isCategoryHistoryLoadSuccessfull) {
        return (
          <>
            <span className="header">Past Action Plans</span>
            {apshistory.history.map(
              (item) =>
                item === null || !item.score ? (
                  ''
                ) : (
                  <div className="pastAPDetails mt-2">
                    <div className="col-md-12">
                      <span className="period">{item.openPeriod}</span>
                      <span className="title pl-15">Score: </span>
                      <span className="period">
                        {item.score.toString().split('.').length === 1
                          ? Number(item.score).toFixed(1)
                          : Math.trunc(item.score * 10) / 10}
                      </span>
                      {item.completedPeriod ? (
                        <span className="title pl-15">Closed: </span>
                      ) : (
                        ''
                      )}
                      {item.completedPeriod ? (
                        <span className="period">{item.completedPeriod}</span>
                      ) : (
                        ''
                      )}
                      <span className="title pl-15">Status: </span>
                      {item.status === 'OverDue' ? (
                        <span className="redStatus">{item.status}</span>
                      ) : (
                        <span className="period">{item.status}</span>
                      )}
                    </div>
                    <div className="col-md-12">
                      <span className="title">{item.description}</span>
                    </div>
                  </div>
                )
              // eslint-disable-next-line function-paren-newline
            )}
          </>
        );
      }
    }
    return <></>;
  }

  render() {
    return (
      <div id="ActionPlanHistory" className="col-md-12">
        <div className="APsHistoryContainer mt-3">
          {this.renderHistoryPanel()}
          <br />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    samActionPlanHistory:
      state.samActionPlanData.actionPlanDetails.samActionPlanHistory === null ||
      state.samActionPlanData.actionPlanDetails.samActionPlanHistory.length ===
        0
        ? []
        : state.samActionPlanData.actionPlanDetails.samActionPlanHistory,
  };
}

const mapDispatchToProps = {
  loadActionPlanHistory: actionPlanContainerActions.loadActionPlanHistory,
  politeAnnouncement: PoliteAnnouncement,
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(SamActionPlanHistory)
);

SamActionPlanHistory.defaultProps = {
  samActionPlanHistory: [],
  politeAnnouncement: null,
};

SamActionPlanHistory.propTypes = {
  samActionPlanHistory: PropTypes.array,
  loadActionPlanHistory: PropTypes.func.isRequired,
  isHistoryToggleOn: PropTypes.bool.isRequired,
  scorecardId: PropTypes.number.isRequired,
  actionPlanType: PropTypes.number.isRequired,
  politeAnnouncement: PropTypes.func,
};
