import './polyfill';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Customizer, mergeStyles } from 'office-ui-fabric-react';
import { FluentCustomizations } from '@uifabric/fluent-theme';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import '@pagetour/sdk/dist/css/pagetour.css';
import './index.css';
import { Provider as ReduxProvider } from 'react-redux';
import Application from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/configureStore';

mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0
    }
  }
});
initializeIcons();
const store = configureStore();
window.store = store;
render(
  <ReduxProvider store={store}>
    <Router>
    <Customizer {...FluentCustomizations}>
        <Application />
    </Customizer>
    </Router>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
