import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CommentHistory.css';
import { connect } from 'react-redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import withErrorHandling from '../../hoc/withErrorHandling';
import * as commentHistoryActions from '../../../redux/actions/commentHistoryActions';
import ErrorDiv from '../../hoc/errordiv';
import { Logger } from '../../../infra/logging/telemetryLogger';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

class CommentHistory extends Component {
  state = {
    isPastCommentsHistoryLoadSuccessfull: true,
    isLoadedSuccessfully: false
  };

  constructor(props) {
    super(props);
    this.logger = Logger.getInstance();
  }

  async componentDidMount() {
    const {
      selectedBobEntry,
      selectedSupplierLeadEntry,
      loadBobScorecardCommentHistory,
      loadSupplierScorecardCommentHistory,
      selectedScorecardCategory,
      isPastCommentToggleOn,
      politeAnnouncement
    } = this.props;

    if (isPastCommentToggleOn) {
      if (selectedBobEntry !== null) {
        try {
          politeAnnouncement('Loading data');
          await loadBobScorecardCommentHistory(selectedScorecardCategory);
          this.setState({ isLoadedSuccessfully: true });
        } catch (err) {
          this.logger.logException(err);
          this.setState({
            isLoadedSuccessfully: false,
            isPastCommentsHistoryLoadSuccessfull: false
          });
        }
      }

      if (selectedSupplierLeadEntry !== null) {
        try {
          politeAnnouncement('Loading data');
          await loadSupplierScorecardCommentHistory(selectedScorecardCategory);
          this.setState({ isLoadedSuccessfully: true });
        } catch (err) {
          this.logger.logException(err);
          this.setState({
            isLoadedSuccessfully: false,
            isPastCommentsHistoryLoadSuccessfull: false
          });
        }
      }
    }
  }

  renderCommentPanel() {
    const {
      isPastCommentsHistoryLoadSuccessfull,
      isLoadedSuccessfully
    } = this.state;
    const { isPastCommentToggleOn, commentHistory } = this.props;

    if (isPastCommentToggleOn) {
      if (!isPastCommentsHistoryLoadSuccessfull && !isLoadedSuccessfully) {
        return (
          <ErrorDiv
            error="Error Loading Past Comments. Please toggle the Past Comments again to reload."
            hideRetry
          />
        );
      }

      if (!isLoadedSuccessfully) {
        return (
          <Spinner
            className="mt-3"
            size={SpinnerSize.large}
            label="Loading Past Comments..."
          />
        );
      }

      if (!commentHistory) {
        return (
          <ErrorDiv
            error="Error Loading Past Comments. Please toggle the Past Comments again to reload."
            hideRetry
          />
        );
      }
      if (!commentHistory.length) {
        return (
          <>
            {' '}
            <span className="error text-muted">
              - No past comments available. -
            </span>{' '}
          </>
        );
      }

      if (commentHistory && isPastCommentsHistoryLoadSuccessfull) {
        return (
          <>
            <span className="header">Past Comments</span>
            {commentHistory.map(item => (item === null ? '' : (
              <div className="pastCommentsDetails">
                <div className="col-md-12 mt-2">
                  <span className="title">{item.cadence.name}</span>
                </div>
                <div className="col-md-12">
                  <span className="subTitle">{item.comments}</span>
                </div>
              </div>
            )))}
          </>
        );
      }
      return (
        <Spinner
          className="mt-3"
          size={SpinnerSize.large}
          label="Loading Past Comments..."
        />
      );
    }
    return <></>;
  }

  render() {
    return (
      <div id="CommentsHistory" className="col-md-12 mb-2">
        <div className="CommentsHistoryContainer">
          {this.renderCommentPanel()}
          <br />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    commentHistory: state.comments === null || state.comments.length === 0 ? [] : state.comments,
    selectedScorecardCategory:
      state.myActionsScorecard.selectedScorecardCategory,
    selectedSupplierLeadEntry: state.myActions.selectedSupplierLeadEntry,
    selectedBobEntry: state.myActions.selectedBobEntry
  };
}

const mapDispatchToProps = {
  loadBobScorecardCommentHistory: commentHistoryActions.getBobScorecardCommentHistory,
  loadSupplierScorecardCommentHistory: commentHistoryActions.getSupplierScorecardCommentHistory,
  politeAnnouncement: PoliteAnnouncement
};


export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CommentHistory)
);

CommentHistory.defaultProps = {
  selectedBobEntry: null,
  selectedSupplierLeadEntry: null,
  politeAnnouncement: null
};

CommentHistory.propTypes = {
  commentHistory: PropTypes.array.isRequired,
  loadBobScorecardCommentHistory: PropTypes.func.isRequired,
  loadSupplierScorecardCommentHistory: PropTypes.func.isRequired,
  selectedScorecardCategory: PropTypes.number.isRequired,
  selectedBobEntry: PropTypes.object,
  selectedSupplierLeadEntry: PropTypes.object,
  isPastCommentToggleOn: PropTypes.bool.isRequired,
  politeAnnouncement: PropTypes.func
};
