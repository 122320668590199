/* eslint-disable import/prefer-default-export */
import config from '../config/appConfig';
import HttpClient from '../infra/http/httpClient';

const performanceApiConfig = config.api.performanceApi;

const httpClient = new HttpClient(
  performanceApiConfig.baseUrl,
  performanceApiConfig.scope
);

// TODO replace below line with method call at API integration
// export async function getUserRoles(userAlias) {
export async function getUserRoles() {
  try {
    const url = performanceApiConfig.endpoints.getUserRoles;
    return httpClient.get(url);
  } catch (error) {
    // TODO log error.
    throw error;
  }
}
