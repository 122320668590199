import * as types from '../actions/actionTypes';
import initialState from './initialState';

const CommentHistoryReducer = (state = initialState.comments, action) => {
  switch (action.type) {
    case types.LOAD_BOBSCORECARDCOMMENTHISTORY_SUCCESS:
      return action.comments;
    case types.LOAD_SUPPLIERSCORECARDCOMMENTHISTORY_SUCCESS:
        return action.comments;
    default:
      return state;
  }
};

export default CommentHistoryReducer;
