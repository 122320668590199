const samPortalActionTypes = Object.freeze({
    SupplierActionPlan: 0,
    BobActionPlan: 1
  });

export const samPortalPlanStatusTypes = Object.freeze({
  NotStarted: 1,
  InProgress: 2,
  Completed: 3
});
  export default samPortalActionTypes;
