import React, { Component } from 'react';
import { Route } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import DownloadWordReport from './downloadWordReport/DownloadWordReport';

class ReportsPage extends Component {
  render() {
    return (
      <Route path="/reports/ssrsWordDocument" component={DownloadWordReport} />
    );
  }
}

export default ReportsPage;
