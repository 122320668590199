/* eslint-disable import/prefer-default-export */
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function loadBobScorecardCommentHistorySuccess(comments) {
  return { type: types.LOAD_BOBSCORECARDCOMMENTHISTORY_SUCCESS, comments };
}

function loadSupplierScorecardCommentHistorySuccess(comments) {
  return { type: types.LOAD_SUPPLIERSCORECARDCOMMENTHISTORY_SUCCESS, comments };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

export function getBobScorecardCommentHistory(categoryId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getBobScorecardCommentHistory(
        categoryId
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadBobScorecardCommentHistorySuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function getSupplierScorecardCommentHistory(categoryId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getSupplierScorecardCommentHistory(
        categoryId
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadSupplierScorecardCommentHistorySuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}
