/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner, ActionButton } from 'office-ui-fabric-react';
import { Nav } from 'react-bootstrap';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import * as samPortalReduxActions from '../../redux/actions/samPortalActions';
import * as cadenceActions from '../../redux/actions/myActionsActions';
import * as actionPlanContainerActions from '../../redux/actions/actionPlanContainerActions';
import './actionPlanContainer.css';
import ActionPlanTablePanel from './actionPlanTable/actionPlanTable';
import '../myactions/metricsContainerPanel/MetricScorecardDetailsPanel/metricScorecardDetailsPanel.css';
import samPortalActionTypes from '../../common/consts/samPortalActionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';
import { PoliteAnnouncement } from '../../redux/actions/announcementActions';
import SupplierType from '../../common/consts/SupplierType';
import { SupplierMetric } from '../models/consts';

export class ActionPlanContainer extends Component {
  state = {
    savecategoryDetailsLoading: false,
    AreNoErrorsInSave: false,
    FailedToSave: false,
    addSupplierMetricPlanClicked: false,
    addBobMetricPlanClicked: false,
    isSelectOpen: false,
    selectOpenId: null
  };

  constructor(props) {
    super(props);
    this.saveActionPlans = this.saveActionPlans.bind(this);
    this.logger = Logger.getInstance();
    this.ActionPlanRegion = React.createRef();
  }

  static getDerivedStateFromProps(props) {
    const { samSupplierScorecardDetails } = props;
    return {
      scorecardDetails: samSupplierScorecardDetails
    };
  }

  async componentDidMount() {
    const {
      loadSamActionPlanDetails,
      samSupplierScorecardDetails,
      actionPlanDetails,
      samSelectedScorecardCategory,
      loadFutureCadence,
      loadFutureCadenceForValueActionPlan,
      selectedSamEntry,
      setMonitorSupplierMetrics
    } = this.props;

    const { scorecardDetails } = this.state;
    const {
      supplierMetricActionPlans,
      bobActionPlans,
      actionPlanParentScorecardId
    } = actionPlanDetails;

    if (
      samSupplierScorecardDetails &&
      ((!bobActionPlans && !supplierMetricActionPlans) ||
        actionPlanParentScorecardId !== samSelectedScorecardCategory)
    ) {
      loadSamActionPlanDetails(samSelectedScorecardCategory);
      loadFutureCadence(-1, 1, -1, scorecardDetails.cadence.id);
      loadFutureCadenceForValueActionPlan(0, 1, 0, scorecardDetails.cadence.id);
      PoliteAnnouncement('action plans loaded');
    }
    if (selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor
       && !supplierMetricActionPlans) {
      loadSamActionPlanDetails(0, selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id);
      loadFutureCadence(-1, 1, -1, selectedSamEntry?.cadence?.id);
      loadFutureCadenceForValueActionPlan(0, 1, 0, selectedSamEntry?.cadence?.id);
      setMonitorSupplierMetrics();
        PoliteAnnouncement('action plans loaded');
        const monitorSupplierMetrics = [{
          id: 1,
          name: SupplierMetric.ContractualAdherence
        }];
        setMonitorSupplierMetrics(monitorSupplierMetrics);
    }
    this.ActionPlanRegion.current.focus();
  }

  async componentDidUpdate(prevProps) {
    const {
      loadSamActionPlanDetails,
      samSupplierScorecardDetails,
      samSelectedScorecardCategory,
      loadSamSupplierMetricsToAdd,
      selectedSamEntry,
      samDefaultSupplierMetricsDetails
    } = this.props;
    if (samSupplierScorecardDetails) {
      if (
        prevProps.samSelectedScorecardCategory !==
          samSelectedScorecardCategory ||
        prevProps.samSupplierScorecardDetails.id !==
          samSupplierScorecardDetails.id
      ) {
        loadSamActionPlanDetails(samSelectedScorecardCategory,
           selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id);
        loadSamSupplierMetricsToAdd(samSelectedScorecardCategory, 0,
           selectedSamEntry?.supplier?.id);
        PoliteAnnouncement('action plans loaded');
      }
    }

    if (selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor
      && prevProps.selectedSamEntry !== selectedSamEntry) {
      loadSamActionPlanDetails(0, selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id);
        loadSamSupplierMetricsToAdd(0, 0, selectedSamEntry?.supplier?.id);
        PoliteAnnouncement('action plans loaded');
    }
    if (selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor
      && samDefaultSupplierMetricsDetails?.supplierDefaultMetrics === null) {
        loadSamSupplierMetricsToAdd(0, 0, selectedSamEntry?.supplier?.id);
        PoliteAnnouncement('Default supplier metric loaded');
    }
  }

  onAddPlanMetricClick = type => {
    const {
      loadSamSupplierActionPlansToAdd,
      loadSamBobActionPlansToAdd,
      samSelectedScorecardCategory,
      prevSamSelectedScorecardCategoryForSupplier,
      prevSamSelectedScorecardCategoryForBob,
      politeAnnouncement
    } = this.props;
    const {
      addSupplierMetricPlanClicked,
      addBobMetricPlanClicked,
      scorecardDetails
    } = this.state;
    if (type === samPortalActionTypes.SupplierActionPlan) {
      this.setState(prevState => ({
        addSupplierMetricPlanClicked: !prevState.addSupplierMetricPlanClicked
      }));
      this.setState({
        AreNoErrorsInSave: false
      });
      if (
        prevSamSelectedScorecardCategoryForSupplier !==
          samSelectedScorecardCategory &&
        !addSupplierMetricPlanClicked
      ) {
        politeAnnouncement('loading details');
        loadSamSupplierActionPlansToAdd(samSelectedScorecardCategory);
        politeAnnouncement('details loaded');
      }
    } else {
      this.setState(prevState => ({
        addBobMetricPlanClicked: !prevState.addBobMetricPlanClicked
      }));
      if (
        prevSamSelectedScorecardCategoryForBob !==
          samSelectedScorecardCategory &&
        !addBobMetricPlanClicked
      ) {
        politeAnnouncement('loading details');
        loadSamBobActionPlansToAdd(
          samSelectedScorecardCategory,
          scorecardDetails.cadence.id
        );
        politeAnnouncement('details loaded');
      }
    }
  };

  onSelectOpen = (isOpen, id) => {
    this.setState({
      isSelectOpen: isOpen,
      selectOpenId: id
    });
  };

  onChangeDueDate = (type, scorecardId, actionPlanId, item) => {
    const { actionPlanDuePeriodChanged, selectedSamEntry } = this.props;
    if (item !== undefined) {
      this.setState({
        AreNoErrorsInSave: false
      });
      actionPlanDuePeriodChanged(type, scorecardId, actionPlanId, item.value,
        selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id,
        selectedSamEntry?.supplier?.supplierType);
    }
  };

  onChangeSubMetric = (type, scorecardId, actionPlanId, item) => {
    const { actionPlanSubMetricChanged, selectedSamEntry } = this.props;
    if (item !== undefined) {
      this.setState({
        AreNoErrorsInSave: false
      });
      actionPlanSubMetricChanged(type, scorecardId, actionPlanId, item.value,
         selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id,
         selectedSamEntry?.supplier?.supplierType);
    }
  };

  onChangeStatus = (type, scorecardId, actionPlanId, item) => {
    const { actionPlanStatusChanged, selectedSamEntry } = this.props;
    if (item !== undefined) {
      this.setState({
        AreNoErrorsInSave: false
      });
      actionPlanStatusChanged(type, scorecardId, actionPlanId, item.value,
        selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id,
        selectedSamEntry?.supplier?.supplierType);
    }
  };

  onChangeIsApplicable = (type, scorecardId, actionPlanId, event) => {
    const { actionPlanIsApplicableChanged, selectedSamEntry } = this.props;
    if (event !== undefined) {
      this.setState({
        AreNoErrorsInSave: false
      });
      actionPlanIsApplicableChanged(
        type,
        scorecardId,
        actionPlanId,
        event.target.checked,
        selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id,
        selectedSamEntry?.supplier?.supplierType
      );
    }
  };

  onChangeActionPlan = (type, scorecardId, actionPlanId, event) => {
    const { actionPlanChanged, selectedSamEntry } = this.props;
    if (event !== undefined) {
      this.setState({
        AreNoErrorsInSave: false
      });
      actionPlanChanged(type, scorecardId, actionPlanId, event.target.value,
        selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id,
        selectedSamEntry?.supplier?.supplierType);
    }
  };

  onActionPlanAddClick = (type, scorecardId) => {
    const { onActionPlanAddClick } = this.props;
    this.setState({
      AreNoErrorsInSave: false
    });
    onActionPlanAddClick(type, scorecardId);
  };

  onActionPlanDeleteClick = (
    type,
    scorecardId,
    actionPlanId,
    isBaseActionPlan
  ) => {
    const { onActionPlanDeleteClick, selectedSamEntry } = this.props;
    this.setState({
      AreNoErrorsInSave: false
    });
    onActionPlanDeleteClick(type, scorecardId, actionPlanId, isBaseActionPlan,
      selectedSamEntry?.supplier?.supplierType);
  };

  onClickActionPlanHistoryToggle = (type, scorecardId, actionPlanId) => {
    const { onClickActionPlanHistoryToggle } = this.props;
    this.setState({
      AreNoErrorsInSave: false
    });
    onClickActionPlanHistoryToggle(type, scorecardId, actionPlanId);
  };

  onChangeIsValueActionPlan = (type, scorecardId, actionPlanId, event) => {
    const { actionPlanIsValueActionPlanChanged } = this.props;
    if (event !== undefined) {
      actionPlanIsValueActionPlanChanged(
        type,
        scorecardId,
        actionPlanId,
        event.target.checked
      );
    }
  };

  onMetricActionPlanAddOptionClick = (type, item) => {
    const {
      onMetricActionPlanAddOptionClick,
      samSelectedScorecardCategory,
      handleBobMetricActionPlanAddOptionClick,
      samSupplierPlanMetricDetails,
      samBobPlanMetricDetails,
      loadSamSupplierMetricsToAdd,
      selectedSamEntry
    } = this.props;
    const { supplierMetrics } = samSupplierPlanMetricDetails;
    const { bobMetrics } = samBobPlanMetricDetails;
    const { scorecardDetails } = this.state;
    const { monitorSupplierMetrics } = samSupplierPlanMetricDetails;
    if (
      type === samPortalActionTypes.SupplierActionPlan &&
      item !== undefined
    ) {
      this.setState({
        AreNoErrorsInSave: false
      });
      if (selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor) {
        const suppliermetricIndex = monitorSupplierMetrics.findIndex(
          metric => metric.id === item.value
        );
        if (suppliermetricIndex !== -1) {
        onMetricActionPlanAddOptionClick(type, 0, 0, SupplierMetric.ContractualAdherence,
          selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id,
          selectedSamEntry?.supplier?.supplierType);
          this.setState(prevState => ({
            addSupplierMetricPlanClicked: !prevState.addSupplierMetricPlanClicked
          }));
        }
      } else {
        const suppliermetricIndex = supplierMetrics.findIndex(
          metric => metric.id === item.value
        );
        if (suppliermetricIndex !== -1) {
          const { id, score, name } = supplierMetrics[suppliermetricIndex];
          this.setState(prevState => ({
            addSupplierMetricPlanClicked: !prevState.addSupplierMetricPlanClicked
          }));
          loadSamSupplierMetricsToAdd(samSelectedScorecardCategory, id);
          onMetricActionPlanAddOptionClick(type, id, score, name,
            selectedSamEntry?.supplier?.id, selectedSamEntry?.cadence?.id,
          selectedSamEntry?.supplier?.supplierType);
        }
      }
    } else if (
      type === samPortalActionTypes.BobActionPlan &&
      item !== undefined
    ) {
      const categoryIndex = scorecardDetails.categories.findIndex(
        category => category.id === samSelectedScorecardCategory
      );
      const bobmetricIndex = bobMetrics.findIndex(
        metric => metric.id === item.value
      );
      if (categoryIndex !== -1 && bobmetricIndex !== -1) {
        PoliteAnnouncement('updating action plans');
        const {
 id, score, name, isBobMetricId
} = bobMetrics[bobmetricIndex];
        const categoryName =
          scorecardDetails.categories[categoryIndex].category.name;
        handleBobMetricActionPlanAddOptionClick(
          type,
          scorecardDetails.supplier.id,
          id,
          scorecardDetails.cadence.id,
          categoryName,
          score,
          name,
          isBobMetricId
        );
      }
      this.setState(prevState => ({
        addBobMetricPlanClicked: !prevState.addBobMetricPlanClicked
      }));
      PoliteAnnouncement('action plans updated');
    }
  };

  setCategory(category) {
    const { updateSamSelectedCategory } = this.props;
    updateSamSelectedCategory(category.id);
  }

  async saveActionPlanDetails(actionPlanDetails, supplierType) {
    const {
      updateActionPlanDetails,
      samSelectedScorecardCategory,
      samSupplierScorecardDetails,
      politeAnnouncement
    } = this.props;
    const supplierScorecardId = supplierType === 3 ? null : samSupplierScorecardDetails.id;
    politeAnnouncement('Saving data');
    if (samSelectedScorecardCategory) {
    await updateActionPlanDetails(
      actionPlanDetails,
      supplierScorecardId,
      samSelectedScorecardCategory
    );
    } else {
      await updateActionPlanDetails(
        actionPlanDetails,
        supplierScorecardId,
        0
      );
    }
  }

  async saveActionPlans() {
    this.setState({
      savecategoryDetailsLoading: true
    });

    const {
      actionPlanDetails,
      samSupplierScorecardDetails,
      politeAnnouncement,
      selectedSamEntry
    } = this.props;

    if (samSupplierScorecardDetails && actionPlanDetails) {
      try {
          politeAnnouncement('Saving Data');
          await this.saveActionPlanDetails(
            actionPlanDetails
          );
        this.setState({ AreNoErrorsInSave: true });
        this.setState({
          FailedToSave: false,
          savecategoryDetailsLoading: false
        });
      } catch (err) {
        this.logger.logException(err);
        this.setState({ AreNoErrorsInSave: false });
        this.setState({ FailedToSave: true });
        this.setState({
          savecategoryDetailsLoading: false
        });
      }
    } else if (actionPlanDetails) {
      try {
        politeAnnouncement('Saving Data');
        await this.saveActionPlanDetails(
          actionPlanDetails,
          selectedSamEntry?.supplier?.supplierType
        );
      this.setState({ AreNoErrorsInSave: true });
      this.setState({
        FailedToSave: false,
        savecategoryDetailsLoading: false
      });
      } catch (err) {
        this.logger.logException(err);
        this.setState({ AreNoErrorsInSave: false });
        this.setState({ FailedToSave: true });
        this.setState({
          savecategoryDetailsLoading: false
        });
      }
    } else {
      this.setState({
        savecategoryDetailsLoading: false
      });
    }
  }

  render() {
    const {
      actionPlanDetails,
      samSupplierScorecardDetails,
      samSelectedScorecardCategory,
      futureCadences,
      futureCadencesForValueActionPlan,
      samSupplierPlanMetricDetails,
      samBobPlanMetricDetails,
      samDefaultSupplierMetricsDetails,
      selectedSamEntry
    } = this.props;
    const {
      savecategoryDetailsLoading,
      AreNoErrorsInSave,
      FailedToSave,
      addSupplierMetricPlanClicked,
      addBobMetricPlanClicked,
      isSelectOpen,
      selectOpenId,
      scorecardDetails
    } = this.state;

    let categories;
    if (selectedSamEntry?.supplier?.supplierType !== SupplierType.Monitor) {
      categories = samSupplierScorecardDetails?.categories;
    }
    if (!categories
      && selectedSamEntry?.supplier?.supplierType !== SupplierType.Monitor) return null;
    const {
      supplierMetricActionPlans,
      bobActionPlans,
      isLoading,
      isReadOnly
    } = actionPlanDetails;
    const {
      supplierMetrics,
      isSamSupplierMetricsLoading,
      monitorSupplierMetrics
    } = samSupplierPlanMetricDetails;
    const { supplierDefaultMetrics } = samDefaultSupplierMetricsDetails;
    const { bobMetrics, isSamBobMetricsLoading } = samBobPlanMetricDetails;

    const nextCategoryFound = categories ? categories.find(
      c => c.id === samSelectedScorecardCategory + 1
    ) : null;
    const nextCategory = nextCategoryFound || '';
    const prevCategoryFound = categories ? categories.find(
      c => c.id === samSelectedScorecardCategory - 1
    ) : null;

    const prevCategory = prevCategoryFound || '';
    const categoryIndex = scorecardDetails ? scorecardDetails.categories.findIndex(
      category => category.id === samSelectedScorecardCategory
    ) : null;
    let categoryName;
    categoryName =
      categoryIndex !== -1 && scorecardDetails
        ? scorecardDetails.categories[categoryIndex].category.name
        : '';
    if (selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor) {
      categoryName = 'Risk';
    }
    if (isLoading) return <Spinner className="spinner" />;
    return (
      <div className="ss-action-plan-container" ref={this.ActionPlanRegion}>
        { (supplierMetricActionPlans && supplierMetricActionPlans.length > 0)
        || (categoryName !== 'Performance')
        || (selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor) ?
        <ActionPlanTablePanel
          actionPlanTypeForId="Supplier"
          // {categoryName === 'Performance' ? 'Custom supplier' : 'Supplier'}
          metricActionPlans={
            supplierMetricActionPlans && supplierMetricActionPlans.length > 0
              ? supplierMetricActionPlans
              : []
          }
          title="Supplier Score < 4"
          // {categoryName === 'Performance' ? 'Performance' : 'Supplier Score < 4'}
          categoryName={categoryName}
          onActionPlanAddClick={this.onActionPlanAddClick.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          onActionPlanDeleteClick={this.onActionPlanDeleteClick.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          futureCadences={futureCadences}
          futureCadencesForValueActionPlan={futureCadencesForValueActionPlan}
          onChangeDueDate={this.onChangeDueDate.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          onChangeSubMetric={this.onChangeSubMetric.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          onChangeStatus={this.onChangeStatus.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          onChangeIsApplicable={this.onChangeIsApplicable.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          onChangeActionPlan={this.onChangeActionPlan.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          isReadOnly={actionPlanDetails.isReadOnly}
          onAddPlanMetricClick={this.onAddPlanMetricClick.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          addPlanMetricButtonState={addSupplierMetricPlanClicked}
          isMetricsLoading={isSamSupplierMetricsLoading}
          addPlanMetricsOptions={selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor
            ? monitorSupplierMetrics : supplierMetrics}
          addPlanSubMetricOptions={supplierDefaultMetrics}
          onMetricActionPlanAddOptionClick={this.onMetricActionPlanAddOptionClick.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          onClickActionPlanHistoryToggle={this.onClickActionPlanHistoryToggle.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          actionPlanType={samPortalActionTypes.SupplierActionPlan}
          supplierType={selectedSamEntry?.supplier?.supplierType}
          onSelectOpen={this.onSelectOpen.bind(this)}
          isSelectOpen={isSelectOpen}
          selectOpenId={selectOpenId}
        /> : null }
        {selectedSamEntry?.supplier?.supplierType !== SupplierType.Monitor ?
        <ActionPlanTablePanel
          actionPlanTypeForId="Bob"
          metricActionPlans={
            bobActionPlans && bobActionPlans.length > 0 ? bobActionPlans : []
          }
          title={categoryName === 'Value' ? 'Book of Business' : 'Book of Business < 4'}
          categoryName={categoryName}
          onActionPlanAddClick={this.onActionPlanAddClick.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          onActionPlanDeleteClick={this.onActionPlanDeleteClick.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          futureCadences={futureCadences}
          futureCadencesForValueActionPlan={futureCadencesForValueActionPlan}
          onChangeDueDate={this.onChangeDueDate.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          onChangeSubMetric={this.onChangeSubMetric.bind(
            this,
            samPortalActionTypes.SupplierActionPlan
          )}
          onChangeStatus={this.onChangeStatus.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          onChangeIsApplicable={this.onChangeIsApplicable.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          onChangeIsValueActionPlan={this.onChangeIsValueActionPlan.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          onChangeActionPlan={this.onChangeActionPlan.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          isReadOnly={actionPlanDetails.isReadOnly}
          onAddPlanMetricClick={this.onAddPlanMetricClick.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          addPlanMetricButtonState={addBobMetricPlanClicked}
          addPlanSubMetricOptions={null}
          isMetricsLoading={isSamBobMetricsLoading}
          addPlanMetricsOptions={bobMetrics}
          onMetricActionPlanAddOptionClick={this.onMetricActionPlanAddOptionClick.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          onClickActionPlanHistoryToggle={this.onClickActionPlanHistoryToggle.bind(
            this,
            samPortalActionTypes.BobActionPlan
          )}
          actionPlanType={samPortalActionTypes.BobActionPlan}
          supplierType={selectedSamEntry?.supplier?.supplierType}
          onSelectOpen={this.onSelectOpen.bind(this)}
          isSelectOpen={isSelectOpen}
          selectOpenId={selectOpenId}
        /> : null}
        {selectedSamEntry?.supplier?.supplierType === SupplierType.Monitor ?
          <div className="savescorecarddiv">
            <div>
              <PrimaryButton
                onClick={this.saveActionPlans}
                aria-label={`Save ${categoryName} actionPlan details`}
                text={`Save ${categoryName}`}
                disabled={isReadOnly}
                className="save-metrics"
                tabIndex="0"
              />
            </div>
          </div>
        :
        <div className="savescorecarddiv">
          {prevCategory ? (
            <div
              alt="categoryname"
              role="presentation"
              className="prevTab2"
              onClick={this.setCategory.bind(this, prevCategory)}
              // disabled={!isEditable}
            >
              <Nav.Link href="#" className="prevTab2">
                {`< ${prevCategory.category.name} `}
              </Nav.Link>
            </div>
          ) : (
            <div />
          )}

          <div>
            <PrimaryButton
              onClick={this.saveActionPlans}
              aria-label={`Save ${
                categories.find(c => c.id === samSelectedScorecardCategory)
                  .category.name
              } actionPlan details`}
              text={`Save ${
                categories.find(c => c.id === samSelectedScorecardCategory)
                  .category.name
              }`}
              disabled={isReadOnly}
              className="save-metrics"
              tabIndex="0"
            />
          </div>

          {nextCategory ? (
            <div
              alt="categoryname"
              role="presentation"
              className="nexttab1"
              onClick={this.setCategory.bind(this, nextCategory)}
              // disabled={!isEditable}
            >
              <Nav.Link href="#" className="nexttab1">
                {`${nextCategory.category.name} >`}
              </Nav.Link>
            </div>
          ) : (
            <div />
          )}
        </div> }

        <div className="submit-response">
          {AreNoErrorsInSave && (
            <ActionButton
              iconProps={{ iconName: 'Completed' }}
              allowDisabledFocus={false}
              text="Action Plans were saved successfully"
              ariaLabel="Action Plans were saved successfully"
              className="successMsg"
              disabled
            />
          )}
          {FailedToSave && (
            <ActionButton
              iconProps={{ iconName: 'Error' }}
              allowDisabledFocus={false}
              className="ErrorMsg"
              text="Failed to save ActionPlans"
              ariaLabel="Failed to save ActionPlans"
              disabled
            />
          )}
        </div>
        {savecategoryDetailsLoading ? <Spinner className="spinner" /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  samSupplierScorecardDetails:
    state.samActionPlanData.samSupplierScorecardDetails,
  actionPlanDetails: state.samActionPlanData.actionPlanDetails,
  samSelectedScorecardCategory:
    state.samActionPlanData.samSelectedScorecardCategory,
  prevSamSelectedScorecardCategoryForSupplier:
    state.samActionPlanData.prevSamSelectedScorecardCategoryForSupplier,
  prevSamSelectedScorecardCategoryForBob:
    state.samActionPlanData.prevSamSelectedScorecardCategoryForBob,
  futureCadences: state.samActionPlanData.actionPlanFutureCadences,
  futureCadencesForValueActionPlan:
    state.samActionPlanData.actionPlanFutureCadencesForValueActionPlan,
  samSupplierPlanMetricDetails:
    state.samActionPlanData.samSupplierPlanMetricDetails,
  samBobPlanMetricDetails: state.samActionPlanData.samBobPlanMetricDetails,
  samDefaultSupplierMetricsDetails:
    state.samActionPlanData.samDefaultSupplierMetricsDetails,
  selectedSamEntry: state.samActionPlanData.selectedSamEntry,
});

const mapDispatchToProps = {
  loadSamActionPlanDetails: samPortalReduxActions.loadSamActionPlanDetails,
  updateSamSelectedCategory: samPortalReduxActions.updateSamSelectedCategory,
  onActionPlanAddClick: actionPlanContainerActions.onActionPlanAddClick,
  onActionPlanDeleteClick: actionPlanContainerActions.onActionPlanDeleteClick,
  loadFutureCadence: cadenceActions.loadFutureCadence,
  loadFutureCadenceForValueActionPlan:
    actionPlanContainerActions.loadFutureCadenceForValueActionPlan,
  actionPlanDuePeriodChanged:
    actionPlanContainerActions.actionPlanDuePeriodChanged,
  actionPlanStatusChanged: actionPlanContainerActions.actionPlanStatusChanged,
  actionPlanIsApplicableChanged:
    actionPlanContainerActions.actionPlanIsApplicableChanged,
  actionPlanChanged: actionPlanContainerActions.actionPlanChanged,
  updateActionPlanDetails: actionPlanContainerActions.updateActionPlanDetails,
  loadSamSupplierMetricsToAdd:
    actionPlanContainerActions.loadSamSupplierMetricsToAdd,
  loadSamSupplierActionPlansToAdd:
    actionPlanContainerActions.loadSamSupplierActionPlansToAdd,
  loadSamBobActionPlansToAdd:
    actionPlanContainerActions.loadSamBobActionPlansToAdd,
  onMetricActionPlanAddOptionClick:
    actionPlanContainerActions.onMetricActionPlanAddOptionClick,
  handleBobMetricActionPlanAddOptionClick:
    actionPlanContainerActions.handleBobMetricActionPlanAddOptionClick,
  onClickActionPlanHistoryToggle:
    actionPlanContainerActions.onClickActionPlanHistoryToggle,
  politeAnnouncement: PoliteAnnouncement,
  actionPlanIsValueActionPlanChanged:
    actionPlanContainerActions.actionPlanIsValueActionPlanChanged,
  actionPlanSubMetricChanged:
    actionPlanContainerActions.actionPlanSubMetricChanged,
  setMonitorSupplierMetrics:
    actionPlanContainerActions.setMonitorSupplierMetrics
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(ActionPlanContainer);

ActionPlanContainer.defaultProps = {
  loadSamActionPlanDetails: null,
  samSupplierScorecardDetails: null,
  actionPlanDetails: null,
  onActionPlanAddClick: null,
  onActionPlanDeleteClick: null,
  futureCadences: null,
  futureCadencesForValueActionPlan: null,
  loadFutureCadence: null,
  loadFutureCadenceForValueActionPlan: null,
  actionPlanDuePeriodChanged: null,
  actionPlanStatusChanged: null,
  actionPlanIsApplicableChanged: null,
  actionPlanChanged: null,
  loadSamSupplierActionPlansToAdd: null,
  loadSamBobActionPlansToAdd: null,
  samSupplierPlanMetricDetails: null,
  samDefaultSupplierMetricsDetails: null,
  samBobPlanMetricDetails: null,
  onMetricActionPlanAddOptionClick: null,
  handleBobMetricActionPlanAddOptionClick: null,
  prevSamSelectedScorecardCategoryForSupplier: null,
  prevSamSelectedScorecardCategoryForBob: null,
  onClickActionPlanHistoryToggle: null,
  politeAnnouncement: null,
  actionPlanIsValueActionPlanChanged: null,
  loadSamSupplierMetricsToAdd: null,
  actionPlanSubMetricChanged: null,
  selectedSamEntry: null,
  setMonitorSupplierMetrics: null
};

ActionPlanContainer.propTypes = {
  loadSamActionPlanDetails: PropTypes.func,
  samSupplierScorecardDetails: PropTypes.object,
  actionPlanDetails: PropTypes.object,
  samSelectedScorecardCategory: PropTypes.number.isRequired,
  prevSamSelectedScorecardCategoryForSupplier: PropTypes.number,
  prevSamSelectedScorecardCategoryForBob: PropTypes.number,
  updateSamSelectedCategory: PropTypes.func.isRequired,
  onActionPlanAddClick: PropTypes.func,
  onActionPlanDeleteClick: PropTypes.func,
  futureCadences: PropTypes.array,
  futureCadencesForValueActionPlan: PropTypes.array,
  loadFutureCadence: PropTypes.func,
  loadFutureCadenceForValueActionPlan: PropTypes.func,
  actionPlanDuePeriodChanged: PropTypes.func,
  actionPlanStatusChanged: PropTypes.func,
  actionPlanIsApplicableChanged: PropTypes.func,
  actionPlanChanged: PropTypes.func,
  updateActionPlanDetails: PropTypes.func.isRequired,
  loadSamSupplierActionPlansToAdd: PropTypes.func,
  loadSamBobActionPlansToAdd: PropTypes.func,
  samSupplierPlanMetricDetails: PropTypes.object,
  samDefaultSupplierMetricsDetails: PropTypes.object,
  samBobPlanMetricDetails: PropTypes.object,
  onMetricActionPlanAddOptionClick: PropTypes.func,
  handleBobMetricActionPlanAddOptionClick: PropTypes.func,
  onClickActionPlanHistoryToggle: PropTypes.func,
  politeAnnouncement: PropTypes.func,
  actionPlanIsValueActionPlanChanged: PropTypes.func,
  loadSamSupplierMetricsToAdd: PropTypes.func,
  actionPlanSubMetricChanged: PropTypes.func,
  selectedSamEntry: PropTypes.object,
  setMonitorSupplierMetrics: PropTypes.func
};
