/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable object-shorthand */

import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Metric.css';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import {
  ActionButton,
  PrimaryButton,
  DefaultButton,
  Label
} from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import {
  MetricsInfoModel,
  MetricModal
} from '../../models/model/PerformanceMetricModal';
import { MetricValidationMessages } from '../../../common/consts/validationMessages';
import withErrorHandling from '../../hoc/withErrorHandling';

export class Metric extends React.Component {
  constructor(props) {
    super(props);
    this.onClickEditMetric = this.onClickEditMetric.bind(this);
    this.onClickSaveMetric = this.onClickSaveMetric.bind(this);
    this.onClickDeleteMetric = this.onClickDeleteMetric.bind(this);
    this.onClickAddSubMetric = this.onClickAddSubMetric.bind(this);
    this.onChangeMetricDescription = this.onChangeMetricDescription.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeTargetCondition = this.onChangeTargetCondition.bind(this);
    this.onChangeNoTarget = this.onChangeNoTarget.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.validateMetric = this.validateMetric.bind(this);
    this.onClickUndo = this.onClickUndo.bind(this);

    this.metricConditionsOptions = props.metricConditions.length
      ? props.metricConditions.map(eachmetricCondition => ({
          key: eachmetricCondition.metricTargetConditionTypeId,
          text: eachmetricCondition.conditionName
        }))
      : '';
  }

  state = {
    isUndoDisabled: true,
    isAddSubMetricDisabled: true,
    isEditMetricDisabled: true,
    metricInfo: Metric.getDefaultMetricModel(),
    isTargetDisabled: false,
    isTargetConditionDisabled: false,
    shouldDisabledAllControls: false,
    hasErrorInName: false,
    hasErrorInTarget: false,
    hasErrorInTargetCondition: false,
    hasErrorInMetricWt: false,
    hasOrderError: { state: false, msg: '' },
    isUndoClicked: false,
    errorMsgForMetricWt: ''
  };

  componentDidMount() {
    const { performanceMetricInfo } = this.props;

    this.setState({
      metricInfo: performanceMetricInfo,
      isAddSubMetricDisabled: !performanceMetricInfo.hasSave,
      isEditMetricDisabled: performanceMetricInfo.isEditing,
      shouldDisabledAllControls: performanceMetricInfo.hasSave,
      isTargetDisabled: performanceMetricInfo.hasSave,
      isTargetConditionDisabled: performanceMetricInfo.hasSave
    });
  }

  componentDidUpdate() {
    const { metricInfo, isUndoClicked } = this.state;
    const { performanceMetricInfo } = this.props;

    if (
      performanceMetricInfo.metric.metricId !== metricInfo.metric.metricId ||
      (isUndoClicked &&
        performanceMetricInfo.metricInfoId === metricInfo.metricInfoId)
    ) {
      this.setState({
        metricInfo: performanceMetricInfo,
        isUndoClicked: false
      });
      if (!performanceMetricInfo.metric.isTargetApplicable) {
        this.setState({
          isTargetDisabled: true,
          isTargetConditionDisabled: true
        });
      }
    }
  }

  onClickEditMetric() {
    const { metricInfo } = this.state;
    const { onClickEdit, currentUser } = this.props;
    this.setState(prevState => ({
      isUndoDisabled: false,
      isAddSubMetricDisabled: true,
      isEditMetricDisabled: true,
      isTargetDisabled: !metricInfo.metric.isTargetApplicable,
      isTargetConditionDisabled: !metricInfo.metric.isTargetApplicable,
      shouldDisabledAllControls: false,
      metricInfo: new MetricsInfoModel(
        prevState.metricInfo.metricInfoId,
        true,
        false,
        false,
        new MetricModal(
          prevState.metricInfo.metric.metricId,
          prevState.metricInfo.metric.metricName,
          prevState.metricInfo.metric.metricDescription,
          prevState.metricInfo.metric.metricTarget,
          prevState.metricInfo.metric.metricConditionTypeId,
          prevState.metricInfo.metric.isTargetApplicable,
          prevState.metricInfo.metric.metricWeight,
          prevState.metricInfo.metric.subMetrics,
          prevState.metricInfo.metric.isDeleted,
          prevState.metricInfo.metric.orderNumber,
          currentUser,
          prevState.metricInfo.metric.isUpdated,
          prevState.metricInfo.metric.isNew,
          prevState.metricInfo.metric.key
        )
      )
    }));
    onClickEdit(metricInfo.metricInfoId);
  }

  onClickUndo() {
    const { metricInfo } = this.state;
    const { onClickUndo } = this.props;
    onClickUndo(metricInfo.metricInfoId);
    this.setState({ isUndoClicked: true });
  }

  onClickSaveMetric() {
    const { metricInfo } = this.state;
    const { onClickSave, currentUser } = this.props;
    const metricList = metricInfo.metric.metricName.split('\n').map((item) => `${item}\\n`);
    const metricValue = '';
    metricInfo.metric.metricName = metricValue.concat(metricList).replace(/,/g, '');
    if (this.validateMetric(metricInfo.metric)) {
      this.setState(prevState => ({
        isUndoDisabled: true,
        isAddSubMetricDisabled: false,
        isEditMetricDisabled: false,
        isTargetDisabled: true,
        isTargetConditionDisabled: true,
        metricInfo: new MetricsInfoModel(
          prevState.metricInfo.metricInfoId,
          false,
          true,
          false,
          new MetricModal(
            prevState.metricInfo.metric.metricId,
            prevState.metricInfo.metric.metricName,
            prevState.metricInfo.metric.metricDescription,
            prevState.metricInfo.metric.metricTarget,
            prevState.metricInfo.metric.metricConditionTypeId,
            prevState.metricInfo.metric.isTargetApplicable,
            prevState.metricInfo.metric.metricWeight,
            prevState.metricInfo.metric.subMetrics,
            false,
            prevState.metricInfo.metric.orderNumber,
            currentUser,
            !prevState.metricInfo.metric.isNew,
            prevState.metricInfo.metric.isNew,
            prevState.metricInfo.metric.key
          )
        ),
        shouldDisabledAllControls: true
      }));
      onClickSave({ ...metricInfo });
    } else {
      this.setState({ isEditMetricDisabled: true });
    }
  }

  onClickDeleteMetric() {
    let { metricInfo } = this.state;
    const { onClickDelete, currentUser } = this.props;

    metricInfo = {
      ...metricInfo,
      metric: {
        ...metricInfo.metric,
        createdBy: currentUser
      }
    };

    this.setState({ metricInfo: { ...metricInfo } });
    onClickDelete({ ...metricInfo });
  }

  onClickAddSubMetric() {
    const { onClickAddSubMetric } = this.props;
    onClickAddSubMetric();
  }

  onChangeMetricDescription(event) {
    const { currentUser } = this.props;
    this.setState(prevState => ({
      metricInfo: new MetricsInfoModel(
        prevState.metricInfo.metricInfoId,
        prevState.metricInfo.isEditing,
        prevState.metricInfo.hasSave,
        prevState.metricInfo.isProcessed,
        new MetricModal(
          prevState.metricInfo.metric.metricId,
          event.target.value,
          prevState.metricInfo.metric.metricDescription,
          prevState.metricInfo.metric.metricTarget,
          prevState.metricInfo.metric.metricConditionTypeId,
          prevState.metricInfo.metric.isTargetApplicable,
          prevState.metricInfo.metric.metricWeight,
          prevState.metricInfo.metric.subMetrics,
          prevState.metricInfo.metric.isDeleted,
          prevState.metricInfo.metric.orderNumber,
          currentUser,
          prevState.metricInfo.metric.isUpdated,
          prevState.metricInfo.metric.isNew,
          prevState.metricInfo.metric.key
        )
      )
    }));
  }

  onChangeTarget(event) {
    const { currentUser } = this.props;
    this.setState(prevState => ({
      metricInfo: new MetricsInfoModel(
        prevState.metricInfo.metricInfoId,
        prevState.metricInfo.isEditing,
        prevState.metricInfo.hasSave,
        prevState.metricInfo.isProcessed,
        new MetricModal(
          prevState.metricInfo.metric.metricId,
          prevState.metricInfo.metric.metricName,
          prevState.metricInfo.metric.metricDescription,
          event.target.value,
          prevState.metricInfo.metric.metricConditionTypeId,
          prevState.metricInfo.metric.isTargetApplicable,
          prevState.metricInfo.metric.metricWeight,
          prevState.metricInfo.metric.subMetrics,
          prevState.metricInfo.metric.isDeleted,
          prevState.metricInfo.metric.orderNumber,
          currentUser,
          prevState.metricInfo.metric.isUpdated,
          prevState.metricInfo.metric.isNew,
          prevState.metricInfo.metric.key
        )
      )
    }));
  }

  onChangeTargetCondition(event, newVal) {
    const { currentUser } = this.props;
    this.setState(prevState => ({
      metricInfo: new MetricsInfoModel(
        prevState.metricInfo.metricInfoId,
        prevState.metricInfo.isEditing,
        prevState.metricInfo.hasSave,
        prevState.metricInfo.isProcessed,
        new MetricModal(
          prevState.metricInfo.metric.metricId,
          prevState.metricInfo.metric.metricName,
          prevState.metricInfo.metric.metricDescription,
          prevState.metricInfo.metric.metricTarget,
          newVal.key,
          prevState.metricInfo.metric.isTargetApplicable,
          prevState.metricInfo.metric.metricWeight,
          prevState.metricInfo.metric.subMetrics,
          prevState.metricInfo.metric.isDeleted,
          prevState.metricInfo.metric.orderNumber,
          currentUser,
          prevState.metricInfo.metric.isUpdated,
          prevState.metricInfo.metric.isNew,
          prevState.metricInfo.metric.key
        )
      )
    }));
  }

  onChangeNoTarget(event) {
    const { currentUser } = this.props;
    const isChecked = event.currentTarget.checked;
    this.setState(prevState => ({
      metricInfo: new MetricsInfoModel(
        prevState.metricInfo.metricInfoId,
        prevState.metricInfo.isEditing,
        prevState.metricInfo.hasSave,
        prevState.metricInfo.isProcessed,
        new MetricModal(
          prevState.metricInfo.metric.metricId,
          prevState.metricInfo.metric.metricName,
          prevState.metricInfo.metric.metricDescription,
          isChecked ? null : prevState.metricInfo.metric.metricTarget,
          isChecked ? null : prevState.metricInfo.metric.metricConditionTypeId,
          !isChecked,
          prevState.metricInfo.metric.metricWeight,
          prevState.metricInfo.metric.subMetrics,
          prevState.metricInfo.metric.isDeleted,
          prevState.metricInfo.metric.orderNumber,
          currentUser,
          prevState.metricInfo.metric.isUpdated,
          prevState.metricInfo.metric.isNew,
          prevState.metricInfo.metric.key
        )
      ),
      isTargetDisabled: isChecked,
      isTargetConditionDisabled: isChecked
    }));
  }

  onChangeWeight(event) {
    const { currentUser } = this.props;
    this.setState(prevState => ({
      metricInfo: new MetricsInfoModel(
        prevState.metricInfo.metricInfoId,
        prevState.metricInfo.isEditing,
        prevState.metricInfo.hasSave,
        prevState.metricInfo.isProcessed,
        new MetricModal(
          prevState.metricInfo.metric.metricId,
          prevState.metricInfo.metric.metricName,
          prevState.metricInfo.metric.metricDescription,
          prevState.metricInfo.metric.metricTarget,
          prevState.metricInfo.metric.metricConditionTypeId,
          prevState.metricInfo.metric.isTargetApplicable,
          event.target.value,
          prevState.metricInfo.metric.subMetrics,
          prevState.metricInfo.metric.isDeleted,
          prevState.metricInfo.metric.orderNumber,
          currentUser,
          prevState.metricInfo.metric.isUpdated,
          prevState.metricInfo.metric.isNew,
          prevState.metricInfo.metric.key
        )
      )
    }));
  }

  onChangeOrder = (event, newValue) => {
    const regex = new RegExp('^[1-9]+');
    const orderValue = regex.test(newValue) ? newValue : '';
    const { currentUser } = this.props;
    this.setState(prevState => ({
      metricInfo: new MetricsInfoModel(
        prevState.metricInfo.metricInfoId,
        prevState.metricInfo.isEditing,
        prevState.metricInfo.hasSave,
        prevState.metricInfo.isProcessed,
        new MetricModal(
          prevState.metricInfo.metric.metricId,
          prevState.metricInfo.metric.metricName,
          prevState.metricInfo.metric.metricDescription,
          prevState.metricInfo.metric.metricTarget,
          prevState.metricInfo.metric.metricConditionTypeId,
          prevState.metricInfo.metric.isTargetApplicable,
          prevState.metricInfo.metric.metricWeight,
          prevState.metricInfo.metric.subMetrics,
          prevState.metricInfo.metric.isDeleted,
          orderValue,
          currentUser,
          prevState.metricInfo.metric.isUpdated,
          prevState.metricInfo.metric.isNew,
          prevState.metricInfo.metric.key
        )
      )
    }));
  };

  static getDefaultMetricModel() {
    return new MetricsInfoModel(
      0,
      true,
      false,
      false,
      new MetricModal(
        0,
        '',
        null,
        null,
        null,
        true,
        '',
        [],
        false,
        null,
        null,
        false,
        false,
        ''
      )
    );
  }

  validateMetric(metric) {
    const { isTargetDisabled, isTargetConditionDisabled } = this.state;
    const isErrorInMetricName =
      metric.metricName == null ||
      metric.metricName === '' ||
      metric.metricName.length > 400;
    const isErrorInTarget =
      (metric.metricTarget == null || metric.metricTarget === '') &&
      !isTargetDisabled;
    const isErrorInTargetCondition =
      metric.metricConditionTypeId == null && !isTargetConditionDisabled;
    let errorMsgForMetricWt = '';
    let hasErrorInMetricWt = false;
    const hasOrderError = { state: false, msg: '' };
    if (metric.orderNumber < 0 || metric.orderNumber === '' || !Number.isInteger(metric.orderNumber)) {
      hasOrderError.state = true;
      hasOrderError.msg = MetricValidationMessages.MetricOrderErrorMsg;
    }
    const regex = new RegExp('^[0-9.]*$');
    if (regex.test(metric.metricWeight)) {
      if (metric.metricWeight == null || metric.metricWeight === '') {
        hasErrorInMetricWt = true;
        errorMsgForMetricWt = MetricValidationMessages.MetricWeigthNotEntered;
      } else if (metric.metricWeight < 0) {
        hasErrorInMetricWt = true;
        errorMsgForMetricWt = MetricValidationMessages.MetricWeightIsInMinus;
      } else if (!(metric.metricWeight > 0 && metric.metricWeight <= 100)) {
        hasErrorInMetricWt = true;
        errorMsgForMetricWt = MetricValidationMessages.MetricWeightIsOutOfRange;
      }
    } else {
      hasErrorInMetricWt = true;
      errorMsgForMetricWt = MetricValidationMessages.MetricWeightIsNotValid;
    }

    this.setState({
      hasErrorInName: isErrorInMetricName,
      hasErrorInTarget: isErrorInTarget,
      hasErrorInTargetCondition: isErrorInTargetCondition,
      hasErrorInMetricWt: hasErrorInMetricWt,
      errorMsgForMetricWt: errorMsgForMetricWt,
      hasOrderError: hasOrderError
    });

    return !(
      isErrorInMetricName ||
      isErrorInTarget ||
      isErrorInTargetCondition ||
      hasOrderError.state ||
      hasErrorInMetricWt
    );
  }

  render() {
    const {
      isAddSubMetricDisabled,
      isEditMetricDisabled,
      metricInfo,
      isTargetDisabled,
      isTargetConditionDisabled,
      shouldDisabledAllControls,
      hasErrorInName,
      hasErrorInTarget,
      hasErrorInTargetCondition,
      hasErrorInMetricWt,
      errorMsgForMetricWt,
      isUndoDisabled,
      hasOrderError
    } = this.state;
    const { isSubMetric, hasReadOnlyAccess } = this.props;
    const comboBoxStyles = {
      container: {
          input: { maxWidth: '40px' }
        },
      optionsContainerWrapper: { maxHeight: '350px' },
      root: {
        '::after': {
        border: '1px solid rgb(138, 136, 134)',
        borderRadius: '4px'
        }
      }
    };

    return (
      <>
        <div
          className={
            isSubMetric
              ? 'parentMetricContainer subMetricOuterContainer'
              : 'parentMetricContainer'
          }
        >
            <Label
              className={
              isSubMetric ? 'metricLabel subMetricLabel' : 'metricLabel'
            }
            >
              {isSubMetric ? 'Sub Metric' : 'Metric'}
            </Label>
          <div
            aria-label={isSubMetric ? 'Sub Metric' : 'Metric'}
            className={
              isSubMetric ? 'subMetricDesContainer' : 'metricDesContainer'
            }
          >
            {}
            <TooltipHost
              content={
                hasErrorInName
                  ? metricInfo.metric.metricName.length > 400
                    ? MetricValidationMessages.MetricNameOutOfRange
                    : MetricValidationMessages.MetricNameNotEntered
                  : ''
              }
            >
              <TextField
                value={metricInfo.metric.metricName.replace(/\\n/g, '\n')}
                onChange={this.onChangeMetricDescription}
                disabled={shouldDisabledAllControls}
                errorMessage={hasErrorInName ? ' ' : ''}
                label={isSubMetric ? 'SUBMETRIC DESCRIPTION' : 'METRIC DESCRIPTION'}
                className={
                  isSubMetric
                    ? 'metricDescTextArea subMetricDescTextArea'
                    : 'metricDescTextArea'
                }
                ariaLabel={isSubMetric ? 'SUBMETRIC DESCRIPTION' : 'METRIC DESCRIPTION'}
                aria-roledescription={isSubMetric ? 'enter submetric name' : 'enter metric name'}
                multiline
              />
            </TooltipHost>
          </div>
          <div className="metricTargetLabelContainer">
            <div className="metricTargetLabel">
              <div className="metricTargetLabelRow1">
                <div>
                  <TooltipHost
                    content={
                      hasErrorInTargetCondition
                        ? MetricValidationMessages.MetricTargetConditionNotEntered
                        : ''
                    }
                  >
                    <ComboBox
                      value={
                        metricInfo &&
                        metricInfo.metric &&
                        metricInfo.metric.metricConditionTypeId
                          ? this.metricConditionsOptions.find(
                              o =>
                                o?.key ===
                                metricInfo.metric.metricConditionTypeId
                            )
                          : null
                      }
                      label="ABOVE/BELOW"
                      useAriaLabelAsText
                      allowFreeInput
                      useComboBoxAsMenuWidth
                      autoComplete="on"
                      aria-labelledby={`aboveBelow-${metricInfo.metric.metricId}`}
                      placeholder="value"
                      title="aboveBelowDropDown"
                      options={this.metricConditionsOptions}
                      onChange={this.onChangeTargetCondition}
                      styles={comboBoxStyles}
                      iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                      id={`aboveBelow-${metricInfo.metric.metricId}`}
                      className="aboveBelowDropDown"
                      aria-label="aboveBelow"
                      comboBoxOptionStyles={{ maxHeight: '100px' }}
                      calloutProps={{ doNotLayer: true }}
                      disabled={isTargetConditionDisabled}
                    />
                  </TooltipHost>
                </div>
                <div className="metricAboveLabel">
                  <TooltipHost
                    content={
                      hasErrorInTarget && !isTargetDisabled
                        ? MetricValidationMessages.MetricTargetNotEntered
                        : ''
                    }
                  >
                    <TextField
                      disabled={isTargetDisabled}
                      label="TARGET"
                      value={metricInfo.metric.metricTarget}
                      onChange={this.onChangeTarget}
                      errorMessage={
                        hasErrorInTarget && !isTargetDisabled ? ' ' : ''
                      }
                      ariaLabel="Enter metric target"
                      className="target"
                    />
                  </TooltipHost>
                </div>
              </div>
              <div className="metricTargetLabelRow2">
                <Checkbox
                  label="No Target"
                  onChange={this.onChangeNoTarget}
                  checked={!metricInfo.metric.isTargetApplicable}
                  disabled={shouldDisabledAllControls}
                  ariaLabel="No Target checkbox"
                  title="No Target checkbox"
                />
              </div>
            </div>
          </div>
          <div className="metricWeightContainer">
            <TooltipHost
              content={hasErrorInMetricWt ? errorMsgForMetricWt : ''}
            >
              <TextField
                label="WEIGHT"
                value={metricInfo.metric.metricWeight}
                onChange={this.onChangeWeight}
                disabled={shouldDisabledAllControls}
                errorMessage={hasErrorInMetricWt ? ' ' : ''}
                className="metricWt"
                ariaLabel="Metric Weight"
                suffix="%"
              />
            </TooltipHost>
          </div>
          <div className="metricOrderContainer">
            <TooltipHost
              content={hasOrderError.state ? hasOrderError.msg : ''}
            >
              <TextField
                label="ORDER"
                value={metricInfo.metric.orderNumber}
                onChange={this.onChangeOrder}
                disabled={shouldDisabledAllControls}
                errorMessage={hasOrderError.state ? ' ' : ''}
                className="metricOrder"
                ariaLabel="Metric Order"
              />
            </TooltipHost>
          </div>
          <div className="actionableEleContainer">
            <div className="metricActionableElement1">
              <DefaultButton
                allowDisabledFocus={false}
                text="Save"
                onClick={this.onClickSaveMetric}
                className={isEditMetricDisabled ? 'saveMetric' : 'hideButton'}
                ariaLabel="Save"
              />
              <PrimaryButton
                allowDisabledFocus={false}
                disabled={isAddSubMetricDisabled || hasReadOnlyAccess}
                text="Sub Metric"
                onClick={this.onClickAddSubMetric}
                className={hasReadOnlyAccess ? 'addSubMetric fadeOut' :
                  (isSubMetric ? 'addSubMetric hideButton' : 'addSubMetric')
                }
                ariaLabel="Add Sub Metric"
              />
            </div>
            <div className="metricActionableElement2">
              <ActionButton
                iconProps={{ iconName: 'Undo' }}
                allowDisabledFocus={false}
                disabled={isUndoDisabled || hasReadOnlyAccess}
                onClick={this.onClickUndo}
                ariaLabel="Undo"
                className={isUndoDisabled ? 'hideButton' : ''}
              />

              <ActionButton
                iconProps={{ iconName: 'Edit' }}
                allowDisabledFocus={false}
                onClick={this.onClickEditMetric}
                disabled={hasReadOnlyAccess}
                ariaLabel="Edit"
                className={hasReadOnlyAccess ? 'editButton fadeOut' :
                  (isEditMetricDisabled ? 'editButton hideButton' : 'editButton')
                }
              />
              <ActionButton
                iconProps={{ iconName: 'Delete' }}
                allowDisabledFocus={false}
                disabled={hasReadOnlyAccess}
                onClick={this.onClickDeleteMetric}
                ariaLabel="Delete"
                className={hasReadOnlyAccess ? 'deleteButton fadeOut' : 'deleteButton'}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    metricConditions: state.metricConditions === null ||
      state.metricConditions.length === 0 ? [] : state.metricConditions,
    currentUser: state.loggedInUser.alias.split('@')[0],
    hasReadOnlyAccess: state.loggedInUser.isReadOnly
  };
}

export default withErrorHandling(connect(mapStateToProps, null)(Metric));

Metric.propTypes = {
  performanceMetricInfo: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickAddSubMetric: PropTypes.func.isRequired,
  isSubMetric: PropTypes.bool.isRequired,
  metricConditions: PropTypes.array.isRequired,
  currentUser: PropTypes.string.isRequired,
  onClickUndo: PropTypes.func.isRequired,
  hasReadOnlyAccess: PropTypes.object.isRequired
};
