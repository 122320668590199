import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import './errordiv.css';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Errordiv extends Component {
  render() {
    const { error, refresh, hideRetry } = this.props;
    return (
      <Alert variant="warning mt-3">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {'   '}
        <span> {error} </span>
        {!hideRetry && (
          <Alert.Link href="#" onClick={refresh}>
            Click to Retry.
          </Alert.Link>
        )}{' '}
      </Alert>
    );
  }
}

Errordiv.defaultProps = {
  hideRetry: false
};

Errordiv.propTypes = {
  error: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  hideRetry: PropTypes.bool
};
