import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function MetricConditionsReducer(
  state = initialState.metricConditions,
  action
) {
  switch (action.type) {
    case types.LOAD_METRICCONDITIONS_SUCCESS:
      return action.metricConditions;
    default:
      return state;
  }
}
