import axios from 'axios';
import authContext from '../auth/aadAuth';
import { Message } from '../../common/consts/validationMessages';

const uuidv4 = require('uuid/v4');

class HttpClient {
  constructor(baseUrl, scope) {
    this.baseUrl = baseUrl;
    this.scope = scope;
    this.httpClient = axios.create({
      baseURL: this.baseUrl
    });
  }

  async get(url) {
    const token = await authContext.acquireTokenSilent(this.scope);
    const reqheaders = this.getheaders(token);
    try {
      const response = await this.httpClient.get(url, { headers: reqheaders });
      return response.data;
    } catch (error) {
      const response = this.handleErrorResponse(
        error.response.status,
        error,
        reqheaders.Tcv,
        url
      );
      throw response;
    }
  }

  async getBlobResponse(url) {
    const token = await authContext.acquireTokenSilent(this.scope);
    const reqheaders = this.getheaders(token);
    try {
      const response = await this.httpClient.get(url, {
        headers: reqheaders,
        responseType: 'blob'
      });
      return response;
    } catch (error) {
      const response = this.handleErrorResponse(
        error.response.status,
        error,
        reqheaders.Tcv,
        url
      );
      throw response;
    }
  }

  async post(url, data) {
    const token = await authContext.acquireTokenSilent(this.scope);
    const reqheaders = this.getheaders(token);
    try {
      return await this.httpClient.post(url, data, { headers: reqheaders });
    } catch (error) {
      const response = this.handleErrorResponse(
        error.response.status,
        error,
        reqheaders.Tcv,
        url
      );
      throw response;
    }
  }

  async patch(url, data) {
    const token = await authContext.acquireTokenSilent(this.scope);
    const reqheaders = this.getheaders(token);

    try {
      return await this.httpClient.patch(url, data, { headers: reqheaders });
    } catch (error) {
      const response = this.handleErrorResponse(
        error.response.status,
        error,
        reqheaders.Tcv,
        url
      );
      throw response;
    }
  }

  async delete(url) {
    const token = await authContext.acquireTokenSilent(this.scope);
    const reqheaders = this.getheaders(token);

    try {
      return await this.httpClient.delete(url, { headers: reqheaders });
    } catch (error) {
      const response = this.handleErrorResponse(
        error.response.status,
        error,
        reqheaders.Tcv,
        url
      );
      throw response;
    }
  }

  handleErrorResponse(statusCode, error, tcv, url) {
    switch (statusCode) {
      case 400:
        return this.get400ErrorResponse(statusCode, error, tcv, url);
      case 404:
        return this.get404ErrorResponse(statusCode, error, tcv, url);
      case 500:
        return this.get500ErrorResponse(statusCode, error, tcv, url);
      case 401:
        return this.get401ErrorResponse(statusCode, error, tcv, url);
      default:
        return error;
    }
  }

  getheaders = accesstoken => ({
    'content-type': 'application/json',
    'access-control-allow-origin': '*',
    Authorization: `Bearer ${accesstoken}`,
    Tcv: uuidv4(),
    Xcv: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed' // To be changed once session is implemented
  });

  get400ErrorResponse = (statusCode, error, tcv, url) => ({
    status: statusCode,
    errormessage: error.response.data.error.details.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.errorMessage.concat(' '),
      ''
    ),
    detailederror: error.response.data.error.details,
    tcv,
    requestUrl: url
  });

  get401ErrorResponse = (statusCode, error, tcv, url) => ({
    status: statusCode,
    errormessage: Message.NotAuthorized,
    detailederror: [],
    tcv,
    requestUrl: url
  });

  get500ErrorResponse = (statusCode, error, tcv, url) => ({
    status: statusCode,
    errormessage: Message.FailedToProcessRequest,
    detailederror: error.response,
    tcv,
    requestUrl: url
  });

  get404ErrorResponse = (statusCode, error, tcv, url) => ({
    status: statusCode,
    errormessage: Message.APINotFound, // 404 error: not found
    detailederror: [],
    tcv,
    url
  });
}

export default HttpClient;
