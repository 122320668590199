/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  AddSupplierMetricActionPlanOptionModel,
  AddBobMetricActionPlanOptionModel
} from '../../components/models/model/SamActionPlanModel';
import samPortalActionTypes, { samPortalPlanStatusTypes } from '../../common/consts/samPortalActionTypes';
import { SupplierMetric } from '../../components/models/consts';
import SupplierType from '../../common/consts/SupplierType';

function prepareActionPlans(actionPlans) {
  if (actionPlans) {
    const updatedActionPlans = actionPlans.map(plan => {
      // eslint-disable-next-line no-param-reassign
      plan.isDirty = false;
      plan.isHistoryToggleOn = false;
      return plan;
    });
    return updatedActionPlans;
  }
  return null;
}
function filterMetricOptions(state, action, categoryId) {
  if (
    action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
  ) {
    if (categoryId !== 4 && state.samSupplierPlanMetricDetails.supplierMetrics) {
      const relevantMetrics = [];
      const metricsToAdd = state.samSupplierPlanMetricDetails.supplierMetrics.filter(
        metric =>
          state.actionPlanDetails.supplierMetricActionPlans.findIndex(
            plans => plans.scorecardId === metric.id
          ) === -1
      );
      relevantMetrics.push(...metricsToAdd);
      state.samSupplierPlanMetricDetails.supplierMetrics = relevantMetrics;
    }
    if (categoryId === 0) {
      const relevantMetrics = [];
      const metricsToAdd = state.samSupplierPlanMetricDetails.monitorSupplierMetrics;
      relevantMetrics.push(...metricsToAdd);
      state.samSupplierPlanMetricDetails.supplierMetrics = relevantMetrics;
    }
  } else if (
    action.payload.actionPlanType === samPortalActionTypes.BobActionPlan
  ) {
    if (categoryId !== 3) {
      const relevantMetrics = [];
      const metricsToAdd = state.samBobPlanMetricDetails.bobMetrics.filter(
        metric =>
          state.actionPlanDetails.bobActionPlans.findIndex(
            plans => plans.actionTitle === metric.name
          ) === -1
      );
      relevantMetrics.push(...metricsToAdd);
      state.samBobPlanMetricDetails.bobMetrics = relevantMetrics;
    }
  }
}
function addRemovedMetricOption(state, action, parentPlan) {
  if (
    action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
  ) {
    if (state.actionPlanDetails.category.id !== 4) {
      const metricToAdd = new AddSupplierMetricActionPlanOptionModel(
        parentPlan.scorecardId,
        parentPlan.score,
        parentPlan.actionTitle
      );
      state.samSupplierPlanMetricDetails.supplierMetrics.unshift(metricToAdd);
    }
  } else if (
    action.payload.actionPlanType === samPortalActionTypes.BobActionPlan
  ) {
    const metricToAdd = new AddBobMetricActionPlanOptionModel(
      parentPlan.scorecardId,
      parentPlan.score,
      parentPlan.actionTitle,
      true
    );
    state.samBobPlanMetricDetails.bobMetrics.unshift(metricToAdd);
  }
}
function prepareSupplierMetricActionPlansToAdd(state, allMetrics) {
  const relevantMetrics = [];
  if (state.actionPlanDetails.category.id !== 4) {
    const highRatedMetrics = allMetrics.filter(
      metric =>
        metric.score >= 4.0 &&
        state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.scorecardId === metric.id
        ) === -1
    );
    highRatedMetrics.forEach(metric => {
      const metricToAdd = new AddSupplierMetricActionPlanOptionModel(
        metric.id,
        metric.score,
        metric.name
      );
      relevantMetrics.push(metricToAdd);
    });
  } else {
    const metrics = allMetrics.filter(
      metric => metric.name.toLowerCase() === SupplierMetric.ContractualAdherence.toLowerCase()
    );
    metrics.forEach(metric => {
      const metricToAdd = new AddSupplierMetricActionPlanOptionModel(
        metric.id,
        metric.score,
        metric.name
      );
      relevantMetrics.push(metricToAdd);
    });
  }
  return relevantMetrics;
}
function prepareBobMetricActionPlansToAdd(state, heatMapData, cadenceId, categoryId) {
  const relevantMetrics = [];
  const newBobs = categoryId === 3
  ? heatMapData.bobs.filter(
    bob => !bob.name.includes('[deactivated]')
  )
  : heatMapData.bobs.filter(
    bob =>
      state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.actionTitle === bob.name
      ) === -1
  );
  newBobs.forEach(bob => {
    const bobId = bob.id;
    let index = heatMapData.itemHistoryDic[bobId].findIndex(
      item => item.cadencePeriodId === cadenceId
      && (categoryId !== 3 ? Number(item.score) >= 4.0 : true)
    );
    if (index === -1) { // code to handle Quarterly Bob
      const lastIndex = heatMapData.itemHistoryDic[bobId].length - 1;
      if (Number(heatMapData.itemHistoryDic[bobId][lastIndex].score)
      >= (categoryId === 3 ? 0.0 : 4.0)) { index = lastIndex; }
    }
    if (index !== -1) {
      const metricToAdd = new AddBobMetricActionPlanOptionModel(
        bob.id,
        heatMapData.itemHistoryDic[bobId][index].score,
        bob.name,
        false
      );
      relevantMetrics.push(metricToAdd);
    }
  });
  return relevantMetrics;
}
function prepareMetricActionPlanToAdd(
  state,
  metricScorecardId,
  metricScore,
  actionPlanTitle,
  actionPlanType,
  categoryId,
  supplierId,
  cadenceId
) {
  const newActionPlans = [];
  let newPlan;
  if (actionPlanType === 1 && categoryId === 3) {
    newPlan = {
      actionPlanId: (Math.floor(Math.random() * 1000) + 1) * -1,
      actionPlan: '',
      dueCadenceId:
        state.actionPlanFutureCadencesForValueActionPlan &&
        state.actionPlanFutureCadencesForValueActionPlan.length > 0
          ? state.actionPlanFutureCadencesForValueActionPlan[0].cadenceId
          : 0,
      status: 1,
      isApplicable: true,
      isActive: true,
      isValueActionPlan: false
    };
  } else if (actionPlanType === 0
    && actionPlanTitle.toLowerCase() === SupplierMetric.ContractualAdherence.toLowerCase()) {
    newPlan = {
      actionPlanId: (Math.floor(Math.random() * 1000) + 1) * -1,
      actionPlan: state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics &&
      state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics.length > 0
      ? state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics[0].name
      : '',
      dueCadenceId:
        state.actionPlanFutureCadences &&
        state.actionPlanFutureCadences.length > 0
          ? state.actionPlanFutureCadences[0].cadenceId
          : 0,
      status: 1,
      isApplicable: true,
      isActive: true,
      subMetricId:
        state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics &&
        state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics.length > 0
        ? state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics[0].id
        : 0,
      supplierId,
      cadenceId
    };
  } else {
    newPlan = {
      actionPlanId: (Math.floor(Math.random() * 1000) + 1) * -1,
      actionPlan: '',
      dueCadenceId:
        state.actionPlanFutureCadences &&
        state.actionPlanFutureCadences.length > 0
          ? state.actionPlanFutureCadences[0].cadenceId
          : 0,
      status: 1,
      isApplicable: true,
      isActive: true
    };
  }
  newActionPlans.push(newPlan);
  const parentPlan = {
    scorecardId: metricScorecardId === 0 ? null : metricScorecardId,
    actionTitle: actionPlanTitle,
    score: Number(metricScore),
    actionPlans: newActionPlans,
    isDirty: true
  };
  return parentPlan;
}
const samPortalReducer = createReducer(initialState.samActionPlanData, {
  LOAD_SAM_ENTRYACTIONS_SUCCESS: (state, action) => {
    state.samEntryActions.isloaded = true;
    state.samEntryActions.actions =
      action.actions !== null && action.actions.length
        ? action.actions.map(act => ({
            ...act,
            scorecardSummary: { isloaded: false }
          }))
        : [];
  },
  SET_SELECTED_SAMENTRYACTION: (state, action) => {
    state.selectedSamEntry = action.samEntryAction;
  },
  SET_SELECTED_SUPPLIERENTRY: (state, action) => {
    state.selectedSupplier = action.selectedSupplier;
  },
  FETCH_ACTIONPLAN_DATA_INIT: (state, action) => {
    state.actionPlanDetails.isLoading = action.payload;
  },
  GET_ACTIONPLAN_HISTORY_SUCCESS: (state, action) => {
    state.actionPlanDetails.samActionPlanHistory = [
      ...state.actionPlanDetails.samActionPlanHistory,
      action.history
    ];
  },
  ACTIONPLAN_TOGGLE_CHANGE_SUCCESS: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const index = state.actionPlanDetails.category.id === 3
        ? state.actionPlanDetails.bobActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
      if (index !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[index];
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToChangeHistoryToggle = plans.actionPlans[childIndex];
        if (actionPlanToChangeHistoryToggle.isValueActionPlan === null) {
          state.actionPlanDetails.bobActionPlans[index].isHistoryToggleOn = !state
            .actionPlanDetails.bobActionPlans[index].isHistoryToggleOn;
        } else {
          actionPlanToChangeHistoryToggle.isHistoryToggleOn =
              !actionPlanToChangeHistoryToggle.isHistoryToggleOn;
        }
      }
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      const index = state.actionPlanDetails.category.id === 4
      ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        && plans.actionPlans
          .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
      )
      : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
      plans => plans.scorecardId === action.payload.scorecardId
      );
      state.actionPlanDetails.supplierMetricActionPlans[
        index
      ].isHistoryToggleOn = !state.actionPlanDetails.supplierMetricActionPlans[
        index
      ].isHistoryToggleOn;
    }
  },
  LOAD_ACTIONPLAN_DETAILS_SUCCESS: (state, action) => {
    state.actionPlanDetails.bobActionPlans = prepareActionPlans(
      action.payload.bobActionPlans
        .slice()
        .sort((a, b) =>
          (a.actionTitle.toLowerCase() > b.actionTitle.toLowerCase()
            ? 1
            : b.actionTitle.toLowerCase() > a.actionTitle.toLowerCase()
            ? -1
            : 0))
    );
    state.actionPlanDetails.supplierMetricActionPlans = prepareActionPlans(
      action.payload.supplierMetricActionPlans
        .slice()
        .sort((a, b) =>
          (a.actionTitle.toLowerCase() > b.actionTitle.toLowerCase()
            ? 1
            : b.actionTitle.toLowerCase() > a.actionTitle.toLowerCase()
            ? -1
            : 0))
    );
    state.actionPlanDetails.actionPlanParentScorecardId =
      action.payload.actionPlanParentScorecardId;
    state.actionPlanDetails.category = action.payload.category;
    state.actionPlanDetails.isReadOnly = action.payload.isReadonly;
    state.actionPlanDetails.isCompleted = action.payload.isCompleted;
  },
  LOAD_FUTURECADENCE_SUCCESS: (state, action) => {
    state.actionPlanFutureCadences = action.cadence.data;
  },
  LOAD_FUTURECADENCEFORVALUEACTIONPLAN_SUCCESS: (state, action) => {
    state.actionPlanFutureCadencesForValueActionPlan = action.cadence.data;
  },
  SET_MONITOR_SUPPLIER_METRICS: (state, action) => {
    state.samSupplierPlanMetricDetails.monitorSupplierMetrics = action.metrics;
  },
  ADD_SAM_ACTIONPLAN: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const index = state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
      );
      if (index !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[index];
        plans.isDirty = true;
        const newPlan = {
          actionPlanId: (Math.floor(Math.random() * 1000) + 1) * -1,
          actionPlan: '',
          dueCadenceId:
            state.actionPlanFutureCadences &&
            state.actionPlanFutureCadences.length > 0
              ? state.actionPlanFutureCadences[0].cadenceId
              : plans.actionPlans[0].dueCadenceId,
          status: 1,
          isApplicable: true,
          isActive: true,
          isValueActionPlan: null
        };
        plans.actionPlans.push(newPlan);
        if (state.actionPlanDetails.category.id === 3) {
          plans.actionPlans.sort(
            (a, b) =>
              (a.isValueActionPlan != null) - (b.isValueActionPlan != null)
              || a - b
          );
}
      }
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      const index = state.actionPlanDetails.supplierMetricActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
      );
      if (index !== -1) {
        const plans = state.actionPlanDetails.supplierMetricActionPlans[index];
        plans.isDirty = true;
        const newPlan = {
          actionPlanId: (Math.floor(Math.random() * 1000) + 1) * -1,
          actionPlan: '',
          dueCadenceId: plans.actionPlans[0].dueCadenceId,
          status: 1,
          isApplicable: true,
          isActive: true
        };
        plans.actionPlans.push(newPlan);
      }
    }
  },
  DELETE_SAM_ACTIONPLAN: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const parentIndex = state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        && plans.actionPlans
          .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
      );
      if (parentIndex !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[parentIndex];
        plans.isDirty = true;
        const activePlans = plans.actionPlans.filter(
          plan => plan.isActive === true
        );
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToDelete = plans.actionPlans[childIndex];
        actionPlanToDelete.isActive = false;
        if (action.payload.isBaseActionPlan && activePlans.length === 1) {
          addRemovedMetricOption(state, action, plans);
          state.actionPlanDetails.bobActionPlans.splice(parentIndex, 1);
        }
      }
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      /* Since there can be multiple action plans for same supplier metric for
      Risk Category, which means there can be multiple action plans linked to
      same scorecardId. Therefore find parentIndex based on actionPlanId
      (unique for multiple action plans linked to same scorecardId) which
      will return single and required actionplan */
      const parentIndex = state.actionPlanDetails.category.id === 4
      || action.payload.supplierType === 3
        ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
      if (parentIndex !== -1) {
        const plans =
          state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
        plans.isDirty = true;
        const activePlans = plans.actionPlans.filter(
          plan => plan.isActive === true
        );
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToDelete = plans.actionPlans[childIndex];
        actionPlanToDelete.isActive = false;
        if (action.payload.isBaseActionPlan && activePlans.length === 1
          && actionPlanToDelete.subMetricId === null) {
          addRemovedMetricOption(state, action, plans);
          state.actionPlanDetails.supplierMetricActionPlans.splice(
            parentIndex,
            1
          );
        }
      }
    }
  },
  CHANGE_ACTION_PLAN_DUEDATE: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const parentIndex = state.actionPlanDetails.category.id === 3
        ? state.actionPlanDetails.bobActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
      if (parentIndex !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[parentIndex];
        plans.isDirty = true;
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToChangeDueDate = plans.actionPlans[childIndex];
        actionPlanToChangeDueDate.dueCadenceId = action.payload.dueCadenceId;
      }
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      if (action.payload.supplierType !== SupplierType.Monitor) {
        const parentIndex = state.actionPlanDetails.category.id === 4
        ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );
          const actionPlanToChangeDueDate =
            childIndex === -1 && state.actionPlanDetails.category.id === 4
            ? plans
            : plans.actionPlans[childIndex];
          actionPlanToChangeDueDate.dueCadenceId = action.payload.dueCadenceId;
        }
      } else {
        const parentIndex = state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.actionPlans
           .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );
          const actionPlanToChangeDueDate =
            childIndex === -1 && state.actionPlanDetails.category.id === 4
            ? plans
            : plans.actionPlans[childIndex];
          actionPlanToChangeDueDate.dueCadenceId = action.payload.dueCadenceId;
          actionPlanToChangeDueDate.supplierId = action.payload.supplierId;
          actionPlanToChangeDueDate.cadenceId = action.payload.cadenceId;
        }
      }
    }
  },
  CHANGE_ACTION_PLAN_SUBMETRIC: (state, action) => {
    if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
      ) {
        if (action.payload.supplierType !== SupplierType.Monitor) {
          const parentIndex = state.actionPlanDetails.category.id === 4
            ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
              plans => plans.scorecardId === action.payload.scorecardId
              && plans.actionPlans
                .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
            )
            : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
            plans => plans.scorecardId === action.payload.scorecardId
            );
          if (parentIndex !== -1) {
            const plans =
              state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
            plans.isDirty = true;
            const childIndex = plans.actionPlans.findIndex(
              plan => plan.actionPlanId === action.payload.actionPlanId
            );
            const actionPlanToChangeActionPlan =
              childIndex === -1 && state.actionPlanDetails.category.id === 4
              ? plans
              : plans.actionPlans[childIndex];

            actionPlanToChangeActionPlan.subMetricId = action.payload.subMetricId;
            actionPlanToChangeActionPlan.actionPlan =
              state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics
              && state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics.length > 0
              ? state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics
                .find((plan) => plan.id === action.payload.subMetricId).name
              : '';
          }
        } else {
          const parentIndex = state.actionPlanDetails.supplierMetricActionPlans.findIndex(
            plans => plans.actionPlans
             .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
          );
          if (parentIndex !== -1) {
            const plans =
              state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
            plans.isDirty = true;
            const childIndex = plans.actionPlans.findIndex(
              plan => plan.actionPlanId === action.payload.actionPlanId
            );
            const actionPlanToChangeActionPlan = plans.actionPlans[childIndex];

            actionPlanToChangeActionPlan.subMetricId = action.payload.subMetricId;
            actionPlanToChangeActionPlan.supplierId = action.payload.supplierId;
            actionPlanToChangeActionPlan.cadenceId = action.payload.cadenceId;
            actionPlanToChangeActionPlan.actionPlan =
              state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics
              && state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics.length > 0
              ? state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics
                .find((plan) => plan.id === action.payload.subMetricId).name
              : '';
          }
        }
      }
  },
  CHANGE_ACTION_PLAN_STATUS: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const parentIndex = state.actionPlanDetails.category.id === 3
        ? state.actionPlanDetails.bobActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
      if (parentIndex !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[parentIndex];
        plans.isDirty = true;
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToChangeStatus = plans.actionPlans[childIndex];
        actionPlanToChangeStatus.status = action.payload.statusId;
      }
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      if (action.payload.supplierType !== SupplierType.Monitor) {
        const parentIndex = state.actionPlanDetails.category.id === 4
          ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
            plans => plans.scorecardId === action.payload.scorecardId
            && plans.actionPlans
              .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
          )
          : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );
          const actionPlanToChangeStatus =
          childIndex === -1 && state.actionPlanDetails.category.id === 4
          ? plans
          : plans.actionPlans[childIndex];
          actionPlanToChangeStatus.status = action.payload.statusId;
        }
      } else {
        const parentIndex = state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.actionPlans
           .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );
          const actionPlanToChangeStatus =
          childIndex === -1 && state.actionPlanDetails.category.id === 4
          ? plans
          : plans.actionPlans[childIndex];
          actionPlanToChangeStatus.status = action.payload.statusId;
          actionPlanToChangeStatus.supplierId = action.payload.supplierId;
          actionPlanToChangeStatus.cadenceId = action.payload.cadenceId;
        }
      }
    }
  },
  CHANGE_ACTION_PLAN_ISAPPLICABLE: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const parentIndex = state.actionPlanDetails.category.id === 3
        ? state.actionPlanDetails.bobActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
      if (parentIndex !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[parentIndex];
        plans.isDirty = true;
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToChangeStatus = plans.actionPlans[childIndex];
        actionPlanToChangeStatus.isApplicable = !action.payload.checked;
          if (action.payload.checked) {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.Completed;
             // marking action plan as completed (5/21/2020 by sain)
          } else {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.InProgress;
          }
        }
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      if (action.payload.supplierType !== SupplierType.Monitor) {
        const parentIndex = state.actionPlanDetails.category.id === 4
          ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
            plans => plans.scorecardId === action.payload.scorecardId
            && plans.actionPlans
              .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
          )
          : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
        );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );
          const actionPlanToChangeStatus =
          childIndex === -1 && state.actionPlanDetails.category.id === 4
          ? plans
          : plans.actionPlans[childIndex];
          actionPlanToChangeStatus.isApplicable = !action.payload.checked;
          if (action.payload.checked) {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.Completed;
            // marking action plan as completed (5/21/2020 by sain)
          } else {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.InProgress;
          }
        }
      } else {
        const parentIndex = state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.actionPlans
           .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );
          const actionPlanToChangeStatus =
          childIndex === -1 && state.actionPlanDetails.category.id === 4
          ? plans
          : plans.actionPlans[childIndex];
          actionPlanToChangeStatus.isApplicable = !action.payload.checked;
          if (action.payload.checked) {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.Completed;
            // marking action plan as completed (5/21/2020 by sain)
          } else {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.InProgress;
          }
          actionPlanToChangeStatus.supplierId = action.payload.supplierId;
          actionPlanToChangeStatus.cadenceId = action.payload.cadenceId;
        }
      }
    }
  },
  CHANGE_ACTION_PLAN_ISVALUEACTIONPLAN: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const parentIndex = state.actionPlanDetails.category.id === 3
        ? state.actionPlanDetails.bobActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
      if (parentIndex !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[parentIndex];
        plans.isDirty = true;
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToChangeDueDate =
          childIndex === -1 && state.actionPlanDetails.category.id === 3
          ? plans
          : plans.actionPlans[childIndex];
        actionPlanToChangeDueDate.isValueActionPlan = action.payload.checked;
        actionPlanToChangeDueDate.dueCadenceId =
          state.actionPlanFutureCadencesForValueActionPlan &&
          state.actionPlanFutureCadencesForValueActionPlan.length > 0
          ? (actionPlanToChangeDueDate.dueCadenceId !== null
          && state.actionPlanFutureCadencesForValueActionPlan
            .some(data => data.cadenceId === actionPlanToChangeDueDate.dueCadenceId))
          ? actionPlanToChangeDueDate.dueCadenceId
          : state.actionPlanFutureCadencesForValueActionPlan[0].cadenceId
          : 0;
      }
    }
  },
  CHANGE_ACTION_PLAN: (state, action) => {
    if (action.payload.actionPlanType === samPortalActionTypes.BobActionPlan) {
      const parentIndex = state.actionPlanDetails.category.id === 3
        ? state.actionPlanDetails.bobActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          && plans.actionPlans
            .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        )
        : state.actionPlanDetails.bobActionPlans.findIndex(
        plans => plans.scorecardId === action.payload.scorecardId
        );
      if (parentIndex !== -1) {
        const plans = state.actionPlanDetails.bobActionPlans[parentIndex];
        plans.isDirty = true;
        const childIndex = plans.actionPlans.findIndex(
          plan => plan.actionPlanId === action.payload.actionPlanId
        );
        const actionPlanToChangeStatus = plans.actionPlans[childIndex];
        actionPlanToChangeStatus.actionPlan = action.payload.actionPlan;
        if (
          actionPlanToChangeStatus.status === samPortalPlanStatusTypes.NotStarted
        ) {
          actionPlanToChangeStatus.status = samPortalPlanStatusTypes.InProgress;
        } else if (
          actionPlanToChangeStatus.actionPlan === '' &&
          (actionPlanToChangeStatus.status === samPortalPlanStatusTypes.InProgress
             || actionPlanToChangeStatus.status === samPortalPlanStatusTypes.Completed)
        ) {
          actionPlanToChangeStatus.status = samPortalPlanStatusTypes.NotStarted;
        }
      }
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      if (action.payload.supplierType !== SupplierType.Monitor) {
        const parentIndex = state.actionPlanDetails.category.id === 4
          ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
            plans => plans.scorecardId === action.payload.scorecardId
            && plans.actionPlans
              .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
          )
          : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.scorecardId === action.payload.scorecardId
          );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );

          const actionPlanToChangeStatus =
            childIndex === -1 && state.actionPlanDetails.category.id === 4
            ? plans
            : plans.actionPlans[childIndex];
          actionPlanToChangeStatus.actionPlan = action.payload.actionPlan;
          if (
            actionPlanToChangeStatus.status === samPortalPlanStatusTypes.NotStarted
          ) {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.InProgress;
          } else if (
            actionPlanToChangeStatus.actionPlan === '' &&
            (actionPlanToChangeStatus.status === samPortalPlanStatusTypes.InProgress
              || actionPlanToChangeStatus.status === samPortalPlanStatusTypes.Completed)
          ) {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.NotStarted;
          }
        }
      } else {
        const parentIndex = state.actionPlanDetails.supplierMetricActionPlans.findIndex(
          plans => plans.actionPlans
           .findIndex(ap => ap.actionPlanId === action.payload.actionPlanId) !== -1
        );
        if (parentIndex !== -1) {
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          plans.isDirty = true;
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === action.payload.actionPlanId
          );

          const actionPlanToChangeStatus =
            childIndex === -1 && state.actionPlanDetails.category.id === 4
            ? plans
            : plans.actionPlans[childIndex];
          actionPlanToChangeStatus.actionPlan = action.payload.actionPlan;
          if (
            actionPlanToChangeStatus.status === samPortalPlanStatusTypes.NotStarted
          ) {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.InProgress;
          } else if (
            actionPlanToChangeStatus.actionPlan === '' &&
            (actionPlanToChangeStatus.status === samPortalPlanStatusTypes.InProgress
              || actionPlanToChangeStatus.status === samPortalPlanStatusTypes.Completed)
          ) {
            actionPlanToChangeStatus.status = samPortalPlanStatusTypes.NotStarted;
          }
          actionPlanToChangeStatus.supplierId = action.payload.supplierId;
          actionPlanToChangeStatus.cadenceId = action.payload.cadenceId;
        }
      }
    }
  },
  UPDATE_ACTIONPLAN_DETAILS_SUCCESS: (state, action) => {
    if (action.payload.supplierActionPlansIdMappings.length > 0) {
      Array.prototype.forEach.call(
        action.payload.supplierActionPlansIdMappings,
        mapping => {
          const parentIndex = state.actionPlanDetails.category.id === 4
            ? state.actionPlanDetails.supplierMetricActionPlans.findIndex(
              plans => plans.scorecardId === mapping.supplierMetricScorecardId
              && plans.actionPlans
                .findIndex(ap => ap.actionPlanId === mapping.requestedActionPlanId) !== -1
            )
            : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
              plans => plans.scorecardId === mapping.supplierMetricScorecardId
            );
          const plans =
            state.actionPlanDetails.supplierMetricActionPlans[parentIndex];
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === mapping.requestedActionPlanId
          );
          const actionPlanToChangeId = plans.actionPlans[childIndex];
          actionPlanToChangeId.actionPlanId = mapping.generatedActionPlanId;
        }
      );
    }
    if (action.payload.bobActionPlansIdMappings.length > 0) {
      Array.prototype.forEach.call(
        action.payload.bobActionPlansIdMappings,
        mapping => {
          const parentIndex = state.actionPlanDetails.category.id === 3
            ? state.actionPlanDetails.bobActionPlans.findIndex(
              plans => plans.scorecardId === mapping.bobMetricCategoryScorecardId
              && plans.actionPlans
                .findIndex(ap => ap.actionPlanId === mapping.requestedActionPlanId) !== -1
            )
            : state.actionPlanDetails.bobActionPlans.findIndex(
              plans => plans.scorecardId === mapping.bobMetricCategoryScorecardId
            );
          const plans = state.actionPlanDetails.bobActionPlans[parentIndex];
          const childIndex = plans.actionPlans.findIndex(
            plan => plan.actionPlanId === mapping.requestedActionPlanId
          );
          const actionPlanToChangeId = plans.actionPlans[childIndex];
          actionPlanToChangeId.actionPlanId = mapping.generatedActionPlanId;
        }
      );
    }
    // update Sam Entry status from api response
    const samEntryIndex = state.samEntryActions.actions.findIndex(
      entry => entry.supplierScorecardId === action.payload.supplierScorecardId
    );
    if (samEntryIndex !== -1) {
      const samEntryToModify = state.samEntryActions.actions[samEntryIndex];
      samEntryToModify.statusId = action.payload.statusId;
      samEntryToModify.percentage = action.payload.progressPercentage;
    }
  },
  RESET_DIRTY_ACTION_PLANS: (state, action) => {
    Array.prototype.forEach.call(action.payload.supplierScorecardIds, id => {
      if (state.actionPlanDetails.category.id === 4) {
        const index = state.actionPlanDetails.supplierMetricActionPlans.map(
          (plan, i) => (plan.scorecardId === id ? i : -1)
        ).filter(planIndex => planIndex !== -1);
        for (let i = 0; i < index.length; i += 1) {
          state.actionPlanDetails.supplierMetricActionPlans[index[i]].isDirty = false;
        }
      } else {
      const parentIndex = state.actionPlanDetails.supplierMetricActionPlans.findIndex(
        plans => plans.scorecardId === id
      );
      if (parentIndex !== -1) {
        state.actionPlanDetails.supplierMetricActionPlans[
          parentIndex
        ].isDirty = false;
      }
    }
    });
    Array.prototype.forEach.call(action.payload.bobScorecardIds, id => {
      if (state.actionPlanDetails.category.id === 3) {
        const index = state.actionPlanDetails.bobActionPlans.map(
          (plan, i) => (plan.scorecardId === id ? i : -1)
        ).filter(planIndex => planIndex !== -1);
        for (let i = 0; i < index.length; i += 1) {
          state.actionPlanDetails.bobActionPlans[index[i]].isDirty = false;
        }
      } else {
        const parentIndex = state.actionPlanDetails.bobActionPlans.findIndex(
          plans => plans.scorecardId === id
        );
        if (parentIndex !== -1) {
          state.actionPlanDetails.bobActionPlans[parentIndex].isDirty = false;
        }
      }
    });
  },
  FETCH_SAM_SUMMARYDATA_INIT: (state, action) => {
    state.samSummaryDetailsLoading = action.payload;
    state.samSupplierScorecardDetails = action.payload
      ? null
      : state.samSupplierScorecardDetails;
  },
  UPDATE_SAM_SELECTED_CATEGORY: (state, action) => {
    state.prevSamSelectedScorecardCategoryForSupplier =
      state.samSelectedScorecardCategory;
    state.prevSamSelectedScorecardCategoryForBob =
      state.samSelectedScorecardCategory;
    state.samSelectedScorecardCategory = action.payload.key;
  },
  LOAD_SAM_SUPPLIERSCORECARDDETAILS_SUCCESS: (state, action) => {
    action.payload.categories.sort((a, b) => a.category.id - b.category.id);
    state.samSupplierScorecardDetails = { ...action.payload };
    state.prevSamSelectedScorecardCategoryForBob =
      state.samSelectedScorecardCategory;
    state.prevSamSelectedScorecardCategoryForSupplier =
      state.samSelectedScorecardCategory;
    state.samSelectedScorecardCategory =
      action.payload.categories && action.payload.categories.length > 0
      ? action.payload.categories[0].id
      : 0;
  },
  FETCH_SAM_SUPPLIER_METRIC_DATA_INIT: (state, action) => {
    state.samSupplierPlanMetricDetails.isSamSupplierMetricsLoading =
      action.payload;
    state.samSupplierPlanMetricDetails.supplierMetrics = action.payload
      ? null
      : state.samSupplierPlanMetricDetails.supplierMetrics;
  },
  LOAD_SAM_SUPPLIER_METRIC_DETAILS_SUCCESS: (state, action) => {
    const allMetrics = [...action.payload.metrics];
    if (allMetrics && allMetrics.length > 0) {
      const relevantMetrics = prepareSupplierMetricActionPlansToAdd(
        state,
        allMetrics
      );
      state.samSupplierPlanMetricDetails.supplierMetrics = relevantMetrics;
      state.prevSamSelectedScorecardCategoryForSupplier =
        state.samSelectedScorecardCategory;
    } else {
      state.samSupplierPlanMetricDetails.supplierMetrics = null;
      state.prevSamSelectedScorecardCategoryForSupplier =
        state.samSelectedScorecardCategory;
    }
  },
  FETCH_SAM_BOB_METRIC_DATA_INIT: (state, action) => {
    state.samBobPlanMetricDetails.isSamBobMetricsLoading = action.payload;
    state.samBobPlanMetricDetails.bobMetrics = action.payload
      ? null
      : state.samBobPlanMetricDetails.bobMetrics;
  },
  FETCH_SAM_BOB_METRIC_ID_DATA_INIT: (state, action) => {
    state.samBobPlanMetricDetails.isSamBobMetricsLoading = action.payload;
  },
  LOAD_SAM_BOB_METRIC_DETAILS_SUCCESS: (state, action) => {
    const { heatMapData } = action.payload.supplierScorecardCategoryDetails;
    if (heatMapData && heatMapData.bobs && heatMapData.bobs.length > 0) {
      const relevantMetrics = prepareBobMetricActionPlansToAdd(
        state,
        heatMapData,
        action.payload.cadenceId,
        action.payload.supplierScorecardCategoryDetails.category.id
      );
      state.samBobPlanMetricDetails.bobMetrics = relevantMetrics;
      state.prevSamSelectedScorecardCategoryForBob =
        state.samSelectedScorecardCategory;
    } else {
      state.samBobPlanMetricDetails.bobMetrics = null;
      state.prevSamSelectedScorecardCategoryForBob =
        state.samSelectedScorecardCategory;
    }
  },
  ADD_SAM_METRIC_ACTIONPLAN: (state, action) => {
    if (
      action.payload.actionPlanType === samPortalActionTypes.SupplierActionPlan
    ) {
      const parentIndex = state.actionPlanDetails.category.id === 4
      || action.payload.supplierType === 3
      ? -1
      : state.actionPlanDetails.supplierMetricActionPlans.findIndex(
        plan => plan.scorecardId === action.payload.scorecardId
      );

      if (parentIndex === -1) {
        const parentPlan = prepareMetricActionPlanToAdd(
          state,
          action.payload.scorecardId,
          action.payload.score,
          action.payload.actionPlanTitle,
          action.payload.actionPlanType,
          state.actionPlanDetails.category.id,
          action.payload.supplierId,
          action.payload.cadenceId
        );
        state.actionPlanDetails.supplierMetricActionPlans.unshift(parentPlan);
      } else {
        state.actionPlanDetails.supplierMetricActionPlans.splice(
          parentIndex,
          1
        );
      }
      filterMetricOptions(state, action, state.actionPlanDetails.category.id);
    } else if (
      action.payload.actionPlanType === samPortalActionTypes.BobActionPlan
    ) {
      const parentIndex = state.actionPlanDetails.category.id === 3
      ? -1
      : state.actionPlanDetails.bobActionPlans.findIndex(
        plan => plan.scorecardId === action.payload.scorecardId
      );
      if (parentIndex === -1) {
        const parentPlan = prepareMetricActionPlanToAdd(
          state,
          action.payload.scorecardId,
          action.payload.score,
          action.payload.actionPlanTitle,
          action.payload.actionPlanType,
          state.actionPlanDetails.category.id
        );
        state.actionPlanDetails.bobActionPlans.unshift(parentPlan);
      } else {
        state.actionPlanDetails.bobActionPlans.splice(parentIndex, 1);
      }
      filterMetricOptions(state, action, state.actionPlanDetails.category.id);
    }
  },
  LOAD_SAM_SUPPLIER_METRIC_SUCCESS: (state, action) => {
    const supplierMetrics = [...action.payload];
    if (supplierMetrics && supplierMetrics.length > 0) {
      state.samDefaultSupplierMetricsDetails.supplierDefaultMetrics = supplierMetrics;
    }
  }
});
export default samPortalReducer;
