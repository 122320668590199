/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import './actionPlanTable.css';
import Select from 'react-select';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Spinner } from 'office-ui-fabric-react';
import actionPlanStatus from '../../../common/consts/actionPlanStatus';
import SamHistory from '../SamActionPlanHistory/samActionPlanHistory';
import '../../myactions/metricsContainerPanel/MetricsContainerPanel.css';
import { SupplierMetric } from '../../models/consts';
import SupplierType from '../../../common/consts/SupplierType';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 34,
    minHeight: 34,
    borderColor: '#8a8886',
    '&:hover': {
      borderColor: '#201f1e',
    },
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isSelected
      ? '#004FB3'
      : isFocused
      ? '#ADD7FF'
      : undefined,
      border: isFocused ? '1px dotted black' : undefined
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    top: '44%',
    color: '#707070',
  }),
  singleValue: (provided) => ({
    ...provided,
    top: '44%',
  }),
};

export const ActionPlanTable = ({
  actionPlanTypeForId,
  metricActionPlans,
  title,
  categoryName,
  onClickActionPlanHistoryToggle,
  onActionPlanAddClick,
  onActionPlanDeleteClick,
  actionPlanType,
  supplierType,
  futureCadences,
  futureCadencesForValueActionPlan,
  onChangeDueDate,
  onChangeSubMetric,
  onChangeStatus,
  onChangeIsApplicable,
  onChangeIsValueActionPlan,
  onChangeActionPlan,
  isReadOnly,
  onAddPlanMetricClick,
  addPlanMetricButtonState,
  isMetricsLoading,
  addPlanMetricsOptions,
  onMetricActionPlanAddOptionClick,
  onSelectOpen,
  isSelectOpen,
  selectOpenId,
  addPlanSubMetricOptions
}) => (
  <section className="ss-smap-table">
    <section
      className="ss-tr ss-th"
    >
      <div className="addMetricPlanContainer">
        <button
          className="SamAddMetricButton"
          onClick={() => {
            onAddPlanMetricClick();
          }}
          disabled={isReadOnly}
          aria-label={`open drop down to add ${actionPlanTypeForId} action plan`}
        >
          { actionPlanTypeForId !== 'Custom supplier' ?
          <Icon
            iconName="BoxAdditionSolid"
            title={`Add Action Plan for ${actionPlanTypeForId} Metric`}
            role="presentation"
            id={`AddNewMetricButton${actionPlanTypeForId}`}
            alt="add actionPlan"
            className="SamAddMetricIcon"
          /> : null }
        </button>
      </div>
      <div className="ss-th-performance-header">{title}</div>
      <div className="ss-th-buttons-header" />
      { actionPlanTypeForId === 'Custom supplier' ?
      <div className="ss-th-score">%</div>
        : <div className="ss-th-score">Score</div> }
      <div
        id={`ActionPlanHeader${actionPlanTypeForId}`}
        className="ss-th-action-plan"
      >
        Action Plan
      </div>
      <div id={`ActionPlanDueDate${actionPlanTypeForId}`} className="ss-th-due">
        Due Date
      </div>
      <div
        id={`ActionPlanStatus${actionPlanTypeForId}`}
        className="ss-th-status"
      >
        Status
      </div>
    </section>
    <div
      id={`AddMetricActionPlan${actionPlanTypeForId}`}
      className={
        isSelectOpen &&
        selectOpenId ===
          `AddMetricActionPlan${actionPlanTypeForId}`
          ? 'ss-th-metricplan-open'
          : 'ss-th-metricplan'
      }
    >
      {isMetricsLoading ? (
        <Spinner className="spinner" />
      ) : (
        addPlanMetricButtonState && (
          <Select
            styles={customStyles}
            aria-label={`drop down to add Action Plan for ${actionPlanTypeForId} Metric`}
            placeholder={
              title.slice(0, title.indexOf(' ')) === 'Supplier'
                ? 'Select Supplier Metric to add'
                : 'Select Book of Business to add'
            }
            options={
              addPlanMetricsOptions
                ? addPlanMetricsOptions.map(data => ({
                    value: data.id,
                    label: data.name
                  }))
                : []
            }
            onChange={item => onMetricActionPlanAddOptionClick(item)}
            isDisabled={isReadOnly}
            onMenuOpen={() =>
              onSelectOpen(
                true,
                `AddMetricActionPlan${actionPlanTypeForId}`
              )
            }
            onMenuClose={() =>
              onSelectOpen(
                false,
                `AddMetricActionPlan${actionPlanTypeForId}`
              )
            }
          />
        )
      )}
    </div>
    <section className="ss-smap-table-body">
      {metricActionPlans &&
        metricActionPlans.map((metricActionPlan, index) => (
          <div
            key={index}
            className="ss-tr-group"
            tabIndex="0"
          >
            {metricActionPlan.actionPlans &&
              metricActionPlan.actionPlans
                .filter(a => a.isActive)
                .map((actionPlan, actionPlanIndex) => (
                  <div className="ss-tr" key={actionPlan.actionPlanId}>
                    <div className="ss-td ss-th-performance" id={`actionPlanTitle:${actionPlan.actionPlanId}`}>
                      {actionPlanIndex === 0 ||
                       (categoryName === 'Value' && actionPlanTypeForId === 'Bob' && actionPlan.isValueActionPlan !== null)
                        ? metricActionPlan.actionTitle
                        : null}
                    { (actionPlanTypeForId === 'Supplier' && categoryName === 'Risk'
                    && metricActionPlan.actionTitle.toLowerCase() ===
                      SupplierMetric.ContractualAdherence.toLowerCase())
                     ? (
                      <div
                        id={`ActionPlanSubMetric:${actionPlan.actionPlanId}`}
                        className={
                        isSelectOpen &&
                        selectOpenId ===
                          `ActionPlanSubMetric:${actionPlan.actionPlanId}`
                          ? 'ss-th-submetric-value-loading'
                          : 'ss-th-submetric-value'
                      }
                      >
                      <span className="sr-only">{actionPlanIndex === 0
                        ? 'submetric combo box'
                        : `submetric combo box for ${ metricActionPlan.actionTitle}` }
                      </span>
                        <Select
                          styles={customStyles}
                          aria-label="drop down to add Action Plan for SubMetric"
                          placeholder="Subsection"
                          value={
                            addPlanSubMetricOptions
                              ? addPlanSubMetricOptions
                              .filter(
                                data => data.id === actionPlan.subMetricId
                              )
                              .map(data => ({
                                  value: data.id,
                                  label: data.name
                                }))
                              : []
                          }
                          options={
                            addPlanSubMetricOptions
                              ? addPlanSubMetricOptions
                              .map(data => ({
                                  value: data.id,
                                  label: data.name
                                }))
                              : []
                          }
                          isDisabled={isReadOnly}
                          onChange={item =>
                            onChangeSubMetric(
                              metricActionPlan.scorecardId,
                              actionPlan.actionPlanId,
                              item
                            )
                          }
                          onMenuOpen={() =>
                            onSelectOpen(
                              true,
                              `ActionPlanSubMetric:${actionPlan.actionPlanId}`
                            )
                          }
                          onMenuClose={() =>
                            onSelectOpen(
                              false,
                              `ActionPlanSubMetric:${actionPlan.actionPlanId}`
                            )
                          }
                        />
                      </div>)
                     : null}
                    { (actionPlan.actionPlanId < 0 && actionPlanTypeForId === 'Bob' && categoryName === 'Value' && actionPlan.isValueActionPlan !== null) ||
                    (actionPlan.isValueActionPlan !== null && actionPlanTypeForId === 'Bob' && categoryName === 'Value')
                     ? (
                     <div className="ssth-valueactionplan-value">
                       <label
                         title={
                           actionPlan.actionPlan === ''
                             ? 'Please provide an action plan for Value Action Plan'
                             : ''
                         }
                       >
                         <input
                           type="checkbox"
                           name="vap"
                           title={
                             actionPlan.actionPlan === ''
                               ? 'Please provide an action plan for Value Action Plan'
                               : ''
                           }
                           checked={actionPlan.isValueActionPlan}
                           disabled={isReadOnly}
                           onChange={event =>
                             onChangeIsValueActionPlan(
                               metricActionPlan.scorecardId,
                               actionPlan.actionPlanId,
                               event
                             )
                           }
                         />
                         Value Action Plan
                       </label>
                     </div>)
                     : null}
                    </div>
                    <div className="ss-td ss-th-buttons ss-th-buttons-value">
                      {(actionPlanIndex === 0 && categoryName !== 'Value'
                        && metricActionPlan.actionTitle.toLowerCase()
                        !== SupplierMetric.ContractualAdherence.toLowerCase())
                      || (actionPlanIndex === 0 && actionPlanTypeForId === 'Supplier'
                         && metricActionPlan.actionTitle.toLowerCase()
                         !== SupplierMetric.ContractualAdherence.toLowerCase())
                      || (actionPlanIndex === 0 && actionPlan.isValueActionPlan === null) ? (
                        <button
                          onClick={() => {
                            onActionPlanAddClick(metricActionPlan.scorecardId);
                          }}
                          disabled={isReadOnly}
                          aria-label={`${ metricActionPlan.actionTitle} add child action plan`}
                        >
                          <Icon
                            iconName="BoxAdditionSolid"
                            title="Add ActionPlan"
                            role="presentation"
                            id={`addActionPlan:${actionPlan.actionPlanId}`}
                            alt="add actionPlan"
                            className="SamdeleteIcon"
                          />
                        </button>
                      ) : (
                        ''
                      )}
                      {(actionPlanIndex !== 0 && categoryName !== 'Value')
                      || (actionPlan.actionPlanId < 0 && categoryName !== 'Value')
                      || (actionPlanIndex !== 0 && actionPlan.isValueActionPlan === null && categoryName === 'Value')
                      || (actionPlan.actionPlanId < 0 && actionPlan.isValueActionPlan === null && categoryName === 'Value')
                      || (actionPlan.actionPlanId < 0 && actionPlan.isValueActionPlan !== null && categoryName === 'Value')
                      || (actionPlan.actionPlanId < 0 && actionPlanTypeForId === 'Supplier')
                      || (actionPlanIndex !== 0 && actionPlanTypeForId === 'Supplier')
                      || (actionPlan.actionPlanId > 0
                        && metricActionPlan.actionTitle.toLowerCase() ===
                        SupplierMetric.ContractualAdherence.toLowerCase()
                        && actionPlan.subMetricId !== null
                        && (supplierType && supplierType !== SupplierType.Monitor))
                      ? (
                        <button
                          onClick={() => {
                            onActionPlanDeleteClick(
                              metricActionPlan.scorecardId,
                              actionPlan.actionPlanId,
                              actionPlanIndex === 0
                            );
                          }}
                          disabled={isReadOnly}
                          aria-label={`${ metricActionPlan.actionTitle} delete action plan`}
                        >
                          <Icon
                            iconName="Delete"
                            title="Delete"
                            role="presentation"
                            id={`deleteActionPlan:${actionPlan.actionPlanId}`}
                            alt="delete actionPlan"
                            className="SamdeleteIcon"
                          />
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="ss-td ss-th-score">
                      {(actionPlanIndex === 0 && categoryName !== 'Value' && supplierType !== SupplierType.Monitor)
                      || (actionPlanIndex === 0 && actionPlan.isValueActionPlan === null && categoryName === 'Value'
                         && supplierType !== SupplierType.Monitor)
                      || (actionPlanIndex === 0 && actionPlan.isValueActionPlan === false && categoryName === 'Value'
                        && supplierType !== SupplierType.Monitor)
                      || (actionPlanIndex !== 0 && actionPlan.isValueActionPlan === false && categoryName === 'Value'
                        && supplierType !== SupplierType.Monitor)
                      || (actionPlanIndex === 0 && actionPlanTypeForId === 'Supplier' && supplierType !== SupplierType.Monitor)
                      ? (
                        <span
                          className={`score-${Math.floor(
                            metricActionPlan.score
                          )}`}
                          tabIndex="0"
                        >
                          <span className="sr-only">score</span>
                          {metricActionPlan.score.toString().split('.')
                            .length === 1
                            ? Number(metricActionPlan.score).toFixed(1)
                            : Math.trunc(metricActionPlan.score * 10) / 10}
                        </span>
                      ) : null}
                    </div>
                    <div
                      id={`ActionPlanTextArea:${actionPlan.actionPlanId}`}
                      className="ss-td ss-th-action-plan"
                    >
                      <span className="sr-only">{actionPlanIndex === 0
                        ? 'Action plan'
                        : `Action plan for ${ metricActionPlan.actionTitle}` }
                      </span>
                      <textarea
                        aria-labelledby={`ActionPlanTextArea:${actionPlan.actionPlanId} actionPlanTitle:${actionPlan.actionPlanId}`}
                        aria-label="Action Plan Text Area"
                        rows="4"
                        defaultValue={actionPlan.actionPlan}
                        value={actionPlan.actionPlan}
                        disabled={!actionPlan.isApplicable || isReadOnly}
                        onChange={(event, item) =>
                          onChangeActionPlan(
                            metricActionPlan.scorecardId,
                            actionPlan.actionPlanId,
                            event,
                            item
                          )
                        }
                      />
                    </div>
                    <div className="ss-td ss-th-due-container">
                      <div className="ss-th-group-container__top">
                        <div
                          id={`ActionPlanDueDate:${actionPlan.actionPlanId}`}
                          className={
                            isSelectOpen &&
                            selectOpenId ===
                              `ActionPlanDueDate:${actionPlan.actionPlanId}`
                              ? 'ss-th-due-value-loading'
                              : 'ss-th-due-value'
                          }
                        >
                          <span className="sr-only">{actionPlanIndex === 0
                            ? 'Due date combo box'
                            : `Due date combo box for ${ metricActionPlan.actionTitle}` }
                          </span>
                          <Select
                            aria-labelledby={`ActionPlanDueDate:${actionPlan.actionPlanId} actionPlanTitle:${actionPlan.actionPlanId}`}
                            aria-label="Action Plan Due Date combo box"
                            styles={customStyles}
                            value={
                              (actionPlan.actionPlanId < 0 && actionPlanTypeForId === 'Bob' && categoryName === 'Value' && actionPlan.isValueActionPlan !== null) ||
                              (actionPlan.isValueActionPlan !== null && actionPlanTypeForId === 'Bob' && categoryName === 'Value') ||
                              (metricActionPlan.actionTitle.toLowerCase()
                                === SupplierMetric.ContractualAdherence.toLowerCase()
                                && actionPlan.subMetricId !== null)
                              ?
                                futureCadencesForValueActionPlan
                                ? futureCadencesForValueActionPlan.filter(
                                    data =>
                                      data.cadenceId ===
                                      actionPlan.dueCadenceId
                                  )
                                  .map(data => ({
                                    value: data.cadenceId,
                                    label: data.cadenceName
                                  }))
                                :
                                null
                              : futureCadences
                                ? futureCadences
                                    .filter(
                                      data =>
                                        data.cadenceId ===
                                        actionPlan.dueCadenceId
                                    )
                                    .map(data => ({
                                      value: data.cadenceId,
                                      label: data.cadenceName
                                    }))
                                : null
                            }
                            placeholder="Due Cadence"
                            options={
                              (actionPlan.actionPlanId < 0 && actionPlanTypeForId === 'Bob' && categoryName === 'Value' && actionPlan.isValueActionPlan !== null) ||
                              (actionPlan.isValueActionPlan !== null && actionPlanTypeForId === 'Bob' && categoryName === 'Value') ||
                              (metricActionPlan.actionTitle.toLowerCase()
                                === SupplierMetric.ContractualAdherence.toLowerCase()
                                && actionPlan.subMetricId !== null)
                              ?
                              futureCadencesForValueActionPlan
                                ? futureCadencesForValueActionPlan.map(data => ({
                                    value: data.cadenceId,
                                    label: data.cadenceName
                                  }))
                                :
                                []
                              : futureCadences
                                ? futureCadences.map(data => ({
                                    value: data.cadenceId,
                                    label: data.cadenceName
                                  }))
                                : []
                            }
                            onChange={item =>
                              onChangeDueDate(
                                metricActionPlan.scorecardId,
                                actionPlan.actionPlanId,
                                item
                              )
                            }
                            isDisabled={!actionPlan.isApplicable || isReadOnly}
                            onMenuOpen={() =>
                              onSelectOpen(
                                true,
                                `ActionPlanDueDate:${actionPlan.actionPlanId}`
                              )
                            }
                            onMenuClose={() =>
                              onSelectOpen(
                                false,
                                `ActionPlanDueDate:${actionPlan.actionPlanId}`
                              )
                            }
                          />
                        </div>
                        <div
                          id={`ActionPlanStatus:${actionPlan.actionPlanId}`}
                          className={
                            isSelectOpen &&
                            selectOpenId ===
                              `ActionPlanStatus:${actionPlan.actionPlanId}`
                              ? 'ss-th-status-value-loading'
                              : 'ss-th-status-value'
                          }
                        >
                          <span className="sr-only">{actionPlanIndex === 0
                            ? 'status combo box'
                            : `status combo box for ${ metricActionPlan.actionTitle}` }
                          </span>
                          <Select
                            aria-labelledby={`ActionPlanStatus:${actionPlan.actionPlanId} actionPlanTitle:${actionPlan.actionPlanId}`}
                            aria-label="Action Plan Status combo box"
                            styles={customStyles}
                            value={
                              actionPlanStatus
                                ? actionPlanStatus
                                    .filter(
                                      data =>
                                        data.StatusId === actionPlan.status
                                    )
                                    .map(data => ({
                                      value: data.StatusId,
                                      label: data.Status
                                    }))
                                : null
                            }
                            placeholder="Plan Status"
                            options={
                              actionPlanStatus
                                ? actionPlanStatus.map(data => ({
                                    value: data.StatusId,
                                    label: data.Status
                                  }))
                                : []
                            }
                            onChange={item =>
                              onChangeStatus(
                                metricActionPlan.scorecardId,
                                actionPlan.actionPlanId,
                                item
                              )
                            }
                            onMenuOpen={() =>
                              onSelectOpen(
                                true,
                                `ActionPlanStatus:${actionPlan.actionPlanId}`
                              )
                            }
                            onMenuClose={() =>
                              onSelectOpen(
                                false,
                                `ActionPlanStatus:${actionPlan.actionPlanId}`
                              )
                            }
                            isDisabled={!actionPlan.isApplicable || isReadOnly}
                            isOptionDisabled={option =>
                              option.value === 3 && actionPlan.actionPlan === ''
                            }
                          />
                        </div>
                      </div>
                      <div className="ss-th-group-container__bottom">
                        <div className="ssth-applicable-value">
                          <label
                            title={
                              actionPlan.actionPlan === ''
                                ? 'Please provide an action plan before selecting Not Applicable'
                                : ''
                            }
                          >
                            <input
                              type="checkbox"
                              name="na"
                              title={
                                actionPlan.actionPlan === ''
                                  ? 'Please provide an action plan before selecting Not Applicable'
                                  : ''
                              }
                              checked={actionPlan.isApplicable === false}
                              disabled={
                                actionPlan.actionPlan === '' || isReadOnly
                              }
                              onChange={event =>
                                onChangeIsApplicable(
                                  metricActionPlan.scorecardId,
                                  actionPlan.actionPlanId,
                                  event
                                )
                              }
                            />
                            Not Applicable
                          </label>
                        </div>
                        <div className="ss-th-history-value">
                          {(actionPlanIndex === 0 && categoryName !== 'Value')
                          || (actionPlanIndex === 0 && categoryName === 'Value' && actionPlanTypeForId === 'Supplier')
                          || (actionPlanIndex === 0 && categoryName === 'Value'
                          && actionPlanTypeForId === 'Bob' && actionPlan.isValueActionPlan === null)
                          ? (
                            <Toggle
                              id={`history:${actionPlan.actionPlanId}`}
                              className="pastcomments"
                              defaultChecked={false}
                              inlineLabel
                              offAriaLabel="Past Action Plans Off"
                              onAriaLabel="Past Action Plans On"
                              onText="History"
                              offText="History"
                              onChange={() =>
                                onClickActionPlanHistoryToggle(
                                  metricActionPlan.scorecardId,
                                  actionPlan.actionPlanId
                                )
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {metricActionPlan.isHistoryToggleOn ? (
              <div className="historyBorder">
                <SamHistory
                  isHistoryToggleOn={metricActionPlan.isHistoryToggleOn}
                  scorecardId={metricActionPlan.scorecardId}
                  actionPlanType={actionPlanType}
                />
              </div>
            ) : null}
          </div>
        ))}
    </section>
  </section>
);
export default ActionPlanTable;
