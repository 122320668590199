import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter
} from 'office-ui-fabric-react/lib/Dialog';
import {
  PrimaryButton,
  DefaultButton
} from 'office-ui-fabric-react/lib/Button';

export default class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.undoEditedMetrics = this.undoEditedMetrics.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  undoEditedMetrics() {
    const { onClickYes } = this.props;
    onClickYes();
  }

  closeDialog() {
    const { onClickNo } = this.props;
    onClickNo();
  }

  render() {
    const { showDialog } = this.props;
    return (
      <>
        <Dialog
          hidden={!showDialog}
          onDismiss={this.closeDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Warning',
            subText:
              'You are exiting without saving your metrices and you will lose all unsaved entries. Click ‘Cancel’ to stay on the current page or  ‘Exit’ to proceed without saving. '
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.closeDialog} text="Cancel" />
            <DefaultButton onClick={this.undoEditedMetrics} text="Exit" />
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

ConfirmDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onClickNo: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired
};
