export class SupplierModel {
  constructor(supplierId, supplierName, annualSpend) {
    this.supplierId = supplierId;
    this.supplierName = supplierName;
    this.annualSpend = annualSpend;
  }
}
export class LeadModel {
  constructor(name, alias) {
    this.name = name;
    this.alias = alias;
  }
}

export class SupplierDomainModel {
  constructor(supplierDomainID, supplierDomainName) {
    this.supplierDomainID = supplierDomainID;
    this.supplierDomainName = supplierDomainName;
  }
}

export class CadenceModel {
  constructor(cadenceID, cadenceTypeName) {
    this.cadenceID = cadenceID;
    this.cadenceTypeName = cadenceTypeName;
  }
}

export class SupplierTypeModel {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export class ContractsModel {
  constructor(contractsList) {
    this.contractsList = contractsList;
  }
}

export class OnboardedSupplierModel {
  constructor(
    supplierId,
    supplierName,
    uvpAnnualSpend,
    lead1Alias,
    lead1DisplayName,
    lead2Alias,
    lead2DisplayName,
    cadence,
    supplierType,
    procurementType,
    domains,
    contracts = [],
    createdBy,
    modifiedBy
  ) {
    this.supplierId = supplierId;
    this.supplierName = supplierName;
    this.uvpAnnualSpend = uvpAnnualSpend;
    this.lead1Alias = lead1Alias;
    this.lead2Alias = lead2Alias;
    this.lead1DisplayName = lead1DisplayName;
    this.lead2DisplayName = lead2DisplayName;
    this.cadence = cadence;
    this.supplierType = supplierType;
    this.procurementType = procurementType;
    this.domains = domains;
    this.contracts = contracts;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}
