export default class MetricsScorecardModel {
  constructor(metrics, comments, cap, categoryScorecardId, score) {
    this.metrics = metrics;
    this.comments = comments;
    this.cap = cap;
    this.categoryScorecardId = categoryScorecardId;
    this.score = score;
  }
}

export class MetricsModel {
  constructor(id, isApplicable, score, weight, subMetrics) {
    this.id = id;
    this.isApplicable = isApplicable;
    this.score = score;
    this.weight = weight;
    this.subMetrics = subMetrics;
  }
}

export class CapModel {
  constructor(
    capId,
    description,
    openDate,
    dueDate,
    isCompleted,
    completedDate,
    isDeleted
  ) {
    this.capId = capId;
    this.description = description;
    this.openDate = openDate;
    this.dueDate = dueDate;
    this.isCompleted = isCompleted;
    this.completedDate = completedDate;
    this.isDeleted = isDeleted;
  }
}
