import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import withErrorHandling from '../hoc/withErrorHandling';
import './samPortalPage.css';
import ActionsTabPanel from './actionsPanel/SamEntryPanel';
import resetMessagebarAction from '../../redux/actions/messageBarActions';
import ScorecardFilterComponent from './ActionPlanFilter/ActionPlanFilter';
import MetricsPanel from '../myactions/metricsContainerPanel/MetricsContainerPanel';

export class SAMPortalPage extends Component {
  componentDidMount() {
    const { resetMessageBar } = this.props;
    resetMessageBar();
  }

  render() {
    const { summaryDetailsLoading } = this.props;
    return (
      <div className="zoom">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="row">
            <ActionsTabPanel />
            <div className="mt-3 col-md-9 col-lg-9 col-sm-9">
              <div>
                <ScorecardFilterComponent />
              </div>
              <div>
                {/* eslint-disable-next-line no-nested-ternary */}
                {summaryDetailsLoading ? (
                  <Spinner className="spinner" />
                ) : (
                  <MetricsPanel isSamPortalRequest />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    summaryDetailsLoading: state.samActionPlanData.samSummaryDetailsLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetMessageBar: bindActionCreators(resetMessagebarAction, dispatch)
  };
}

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(SAMPortalPage)
);

SAMPortalPage.propTypes = {
  resetMessageBar: PropTypes.func.isRequired,
  summaryDetailsLoading: PropTypes.bool.isRequired
};
