import React, { Component } from 'react';
import { connect } from 'react-redux';
import './BobEntryActionCard.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ActionStatus from '../../../common/consts/actionStatus';
import ActionFilters from '../../../common/consts/actionFilters';
import * as myActionsReduxActions from '../../../redux/actions/myActionsActions';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

export class BobEntryActionCard extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleClick() {
    const { action, setSelectedBobEntryAction, politeAnnouncement } = this.props;
    setSelectedBobEntryAction(action);
    politeAnnouncement(`${action.bob.name} selected`);
  }

  handleKeyDown(key) {
    if (key.keyCode === 13) this.handleClick();
  }

  render() {
    const { action, selectedBobEntryAction } = this.props;

    let actionStatusName;
    const statuses = Object.values(ActionFilters);
    for (let i = 0; i < statuses.length; i += 1) {
      const value = statuses[i];
      if (value.id === action.statusId) actionStatusName = value.name;
    }

    return (
      <div
        className={classNames('bb', {
            actionselected:
              selectedBobEntryAction != null &&
              selectedBobEntryAction.bobScoreCardId === action.bobScoreCardId
          })}
        role="listitem"
        data-is-focusable
      >
      <div
        className={`actionCard${
          action.statusId === ActionStatus.Completed
            ? ' bbCompleted'
            : ' bbInCompleted'
        }`}
        role="button"
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        tabIndex="-1"
      >
          <div className="row">
            <span className="bbdetails ellipse">{action.bob.name}</span>
          </div>
          <div className="row">
            <span className="bbname ellipse">{action.supplier.name}</span>
          </div>
          <div className="row">
            <span
              className={
                action.statusId === ActionStatus.Completed
                  ? 'dot dotCompletionStatus'
                  : 'dot dotInCompletionStatus'
              }
            />
            <p className="mt10">
              <span className="bbstatus"> {actionStatusName}</span>
            </p>
            <p className="ml-auto">
              <span className="bbperdetails">{action.percentage}%</span>
            </p>
          </div>
      </div>
      </div>
    );
  }
}

BobEntryActionCard.defaultProps = {
  selectedBobEntryAction: null,
  politeAnnouncement: null,
};

BobEntryActionCard.propTypes = {
  action: PropTypes.object.isRequired,
  selectedBobEntryAction: PropTypes.object,
  setSelectedBobEntryAction: PropTypes.func.isRequired,
  politeAnnouncement: PropTypes.func,
};

export function mapStateToProps(state) {
  return {
    selectedBobEntryAction: state.myActions.selectedBobEntry
  };
}

const mapDispatchToProps = {
  setSelectedBobEntryAction: myActionsReduxActions.setSelectedBobEntryAction,
  politeAnnouncement: PoliteAnnouncement,
};

export default connect(mapStateToProps, mapDispatchToProps)(BobEntryActionCard);
