/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';
import * as performanceManagerAPI from '../../api/performanceManagerApi';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function getScorecardSuppliersSuccess(scorecardsuppliers) {
  return { type: types.LOAD_SCORECARDSUPPLIERS_SUCCESS, scorecardsuppliers };
}
function getScorecardBOBsSuccess(scorecardbobs) {
  return { type: types.LOAD_SCORECARDBOBS_SUCCESS, scorecardbobs };
}

function getScorecardPeriodsSuccess(scorecardperiods) {
  return { type: types.LOAD_SCORECARDPERIODS_SUCCESS, scorecardperiods };
}

function getScorecardSupplierPeriodsSuccess(scorecardSupplierPeriods) {
  return { type: types.LOAD_SCORECARDSUPPLIERPERIODS_SUCCESS, scorecardSupplierPeriods };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

export function getScorecardSuppliers() {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getScorecardSuppliers();
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(getScorecardSuppliersSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
    }
  };
}

export function getScorecardBOBs(supplierId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getScorecardBOBs(supplierId);
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(getScorecardBOBsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
    }
  };
}

export function getScorecardPeriods(supplierId, bobId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getScorecardPeriods(
        supplierId,
        bobId
      );
      if (response.validationResult.isValid) {
        dispatch(
          getScorecardPeriodsSuccess(
            !response.data ? { cadences: [] } : response.data
          )
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
    }
  };
}

export function getScorecardSupplierPeriods(supplierId) {
  return async dispatch => {
    try {
      const response = await performanceManagerAPI.getScorecardSupplierPeriods(
        supplierId
      );
      if (response.validationResult.isValid) {
        dispatch(
          getScorecardSupplierPeriodsSuccess(response.data)
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      throw error;
    }
  };
}
