import ActionStatus from './actionStatus';

const ActionFilters = Object.freeze({
  All: { id: 1000, name: 'All' },
  YetToStart: { id: ActionStatus.YetToStart, name: 'Yet to start' },
  Inprogress: { id: ActionStatus.Inprogress, name: 'In-progress' },
  Completed: { id: ActionStatus.Completed, name: 'Completed' }
});

export default ActionFilters;
