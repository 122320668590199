/* eslint-disable import/prefer-default-export */
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function loadSupplierScorecardCapHistorySuccess(capHistory) {
  return { type: types.LOAD_SUPPLIERSCORECARDCAPHISTORY_SUCCESS, capHistory };
}

function loadBobScorecardCapHistorySuccess(capHistory) {
  return { type: types.LOAD_BOBSCORECARDCAPHISTORY_SUCCESS, capHistory };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

export function getBobScorecardCapHistory(selectedScorecardCategory) {
  return async dispatch => {
    try {
      const response = await performanceApi.getBobScorecardCapHistory(
        selectedScorecardCategory
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadBobScorecardCapHistorySuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function getSupplierScorecardCapHistory(selectedScorecardCategory) {
  return async dispatch => {
    try {
      const response = await performanceApi.getSupplierScorecardCapHistory(
        selectedScorecardCategory
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadSupplierScorecardCapHistorySuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}
