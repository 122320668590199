import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SamEntryActionList.css';
import {
  FocusZone,
  FocusZoneDirection,
} from 'office-ui-fabric-react/lib/FocusZone';
import ActionFilters from '../../../common/consts/actionFilters';
import * as samReduxActions from '../../../redux/actions/samPortalActions';
import SamEntryCard from '../samEntryActionCard/SamEntryActionCard';
import withErrorHandling from '../../hoc/withErrorHandling';
import SamEntryShimmer from '../samEntryActionCard/SamEntryShimmerCard';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

export class SamEntryActionList extends Component {
  async componentDidMount() {
    const {
      samEntryActions,
      loggedInUser,
      loadSamEntryActions,
      onError,
      politeAnnouncement
    } = this.props;
    if (!samEntryActions.isloaded) {
      try {
        politeAnnouncement('Loading Sam entry Actions');
        await loadSamEntryActions(loggedInUser.alias);
        politeAnnouncement('Succesfully loaded Sam entry Actions');
      } catch (err) {
        politeAnnouncement('Unable to fetch Sam entry Actions');
        onError('Unable to fetch actions.');
      }
    }
  }

  render() {
    const { samEntryActions, selectedActionFilter } = this.props;

    let filteredActions = samEntryActions ? samEntryActions.actions : [];
    if (selectedActionFilter) {
    if (selectedActionFilter.id !== ActionFilters.All.id) {
      filteredActions = filteredActions.filter(
        action => action.statusId === selectedActionFilter.id
      );
    }
  }

    const shimmers = [];
    if (!samEntryActions.isloaded) {
      for (let i = 0; i < 7; i += 1) {
        shimmers.push(<SamEntryShimmer key={i} />);
      }
    }

    return (
      <>
        {samEntryActions.isloaded ? (
          <div className="samEntryActionListDiv">
            {samEntryActions.actions.length > 0 ? (

               <FocusZone
                 direction={FocusZoneDirection.vertical}
                 role="list"
                 isCircularNavigation
               >
                {filteredActions && filteredActions.map(action => (
                  <SamEntryCard
                    key={action.supplierScoreCardId}
                    action={action}
                  />
                ))}
               </FocusZone>
            ) : (
              <div className="noActionsDiv">- No actions assigned. -</div>
            )}
          </div>
        ) : (
          <div className="samEntryActionListDiv">{shimmers}</div>
        )}
      </>
    );
  }
}

export function mapStateToProps(state) {
  return {
    samEntryActions: state.samActionPlanData.samEntryActions,
    selectedActionFilter: state.samActionPlanData.actionFilter,
    loggedInUser: state.loggedInUser
  };
}

const mapDispatchToProps = {
  loadSamEntryActions: samReduxActions.loadSamEntryActions,
  politeAnnouncement: PoliteAnnouncement
};

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SamEntryActionList)
);

SamEntryActionList.defaultProps = {
  politeAnnouncement: null
};

SamEntryActionList.propTypes = {
  selectedActionFilter: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  samEntryActions: PropTypes.object.isRequired,
  loadSamEntryActions: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  politeAnnouncement: PropTypes.func
};
