/* eslint-disable no-nested-ternary */
// import graph from '@microsoft/microsoft-graph-client';
import authContext from '../infra/auth/aadAuth';
import config from '../config/appConfig';

const graph = require('@microsoft/microsoft-graph-client');

const graphConfig = config.api.graph;

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: done => {
      done(null, accessToken);
    }
  });

  return client;
}

export default async function validateUser(userAlias) {
  let userAliasDetails = '';
  const accessToken = await authContext.acquireTokenSilent(
    graphConfig.graphScopes
  );
  const client = getAuthenticatedClient(accessToken);
  const url = `users?$filter=startswith(displayName,'${userAlias}') or startswith(givenName,'${userAlias}') or startswith(surname,'${userAlias}') or startswith(mail,'${userAlias}') or startswith(userPrincipalName,'${userAlias}')`;
  try {
    await client.api(url).get((error, response) => {
      userAliasDetails =
        error !== null
          ? ''
          : response && response.value.length
          // eslint-disable-next-line array-callback-return
          ? response.value.filter((result) => (result.userPrincipalName.endsWith('microsoft.com') || result.mail.endsWith('linkedin.com') || result.mail.endsWith('linkedin.biz')))
          : [];
    });
    return userAliasDetails;
  } catch (error) {
    return userAliasDetails;
  }
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api('/me').get();
  return user;
}
