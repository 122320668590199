import { isNullOrUndefined } from 'util';
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import { Message } from '../../common/consts/validationMessages';
import { Logger } from '../../infra/logging/telemetryLogger';

import handleValidationError from './validationErrorHandling';

function loadSupplierSuccess(suppliers) {
  return { type: types.LOAD_SUPPLIERS_SUCCESS, suppliers };
}

function loadOnboardedSupplierSuccess(onboardedSuppliers) {
  return {
    type: types.LOAD_ONBOARDEDSUPPLIERS_SUCCESS,
    onboardedSuppliers
  };
}

function loadDomainSuccess(domains) {
  return { type: types.LOAD_DOMAINS_SUCCESS, domains };
}

function loadCadenceSuccess(cadences) {
  return { type: types.LOAD_CADENCE_SUCCESS, cadences };
}

function loadSupplierTypeSuccess(supplierTypes) {
  return { type: types.LOAD_SUPPLIERTYPES_SUCCESS, supplierTypes };
}

function loadProcurementTypeSuccess(procurementTypes) {
  return { type: types.LOAD_PROCUREMENTTYPES_SUCCESS, procurementTypes };
}

function addOnBoardSupplierSuccess(onboardedSupplier) {
  return { type: types.ADD_ONBOARDSUPPLIER_SUCCESS, onboardedSupplier };
}

function updateOnBoardSupplierSuccess(onboardedSupplier) {
  return { type: types.UPDATE_ONBOARDEDSUPPLIER_SUCCESS, onboardedSupplier };
}
function deleteOnBoardSupplierSuccess(index) {
  return { type: types.DELETE_ONBOARDEDSUPPLIER_SUCCESS, index };
}

function requestProcessedMessageSuccess(message) {
  return { type: types.ADD_PROCESSEDMESSAGE_SUCCESS, message };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

export function loadSuppliers() {
  return async (dispatch) => {
    try {
      const supplierdata = await performanceApi.getSuppliers();
      let suppliers = [];
      supplierdata.data = !supplierdata.data ? [] : supplierdata.data;
      suppliers = supplierdata.data;
      dispatch(loadSupplierSuccess(suppliers));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function loadOnboardedSuppliers() {
  return async (dispatch) => {
    try {
      const response = await performanceApi.getOnboardedSuppliers();
      if (response.validationResult.isValid) {
        if (!response.data) {
          response.data = [];
        }
        const onboardedSuppliers = response.data;
        if (!isNullOrUndefined(onboardedSuppliers)) {
          for (let iCnt = 0; iCnt < onboardedSuppliers.length; iCnt += 1) {
            const domains = Object.values(
              onboardedSuppliers[iCnt].domainsByIds
            ).join('@@');
            onboardedSuppliers[iCnt].domainsByIds = domains;
            if (
              onboardedSuppliers[iCnt].lead1DisplayName != null &&
              onboardedSuppliers[iCnt].lead2DisplayName != null
            ) {
              onboardedSuppliers[
                iCnt
              ].leads = `${onboardedSuppliers[iCnt].lead1DisplayName} , ${onboardedSuppliers[iCnt].lead2DisplayName}`;
            } else {
              onboardedSuppliers[
                iCnt
              ].leads = `${onboardedSuppliers[iCnt].lead1Alias} , ${onboardedSuppliers[iCnt].lead2Alias}`;
            }
          }
        }
        dispatch(loadOnboardedSupplierSuccess(onboardedSuppliers));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function loadDomains() {
  return async (dispatch) => {
    try {
      const domains = await performanceApi.getDomain();
      if (!domains.validationResult.isValid) {
        throw domains.validationResult.errors[0];
      }
      if (domains.validationResult.isValid && !domains.data) {
        domains.data = [];
      }
      const domain = domains.data;
      dispatch(loadDomainSuccess(domain));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function loadCadence() {
  return async (dispatch) => {
    try {
      const cadences = await performanceApi.getCadence();
      if (!cadences.validationResult.isValid) {
        throw cadences.validationResult.errors[0];
      }
      if (cadences.validationResult.isValid && !cadences.data) {
        cadences.data = [];
      }
      const cadence = cadences.data;
      dispatch(loadCadenceSuccess(cadence));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function loadSupplierTypes() {
  return async (dispatch) => {
    try {
      const supplierType = await performanceApi.getSupplierType();
      if (!supplierType.validationResult.isValid) {
        throw supplierType.validationResult.errors[0];
      }
      if (supplierType.validationResult.isValid && !supplierType.data) {
        supplierType.data = [];
      }
      const supplierTypes = supplierType.data;
      dispatch(loadSupplierTypeSuccess(supplierTypes));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function loadProcurementTypes() {
  return async (dispatch) => {
    try {
      const procurementType = await performanceApi.getProcurementType();
      if (!procurementType.validationResult.isValid) {
        throw procurementType.validationResult.errors[0];
      }
      if (procurementType.validationResult.isValid && !procurementType.data) {
        procurementType.data = [];
      }
      const procurementTypes = procurementType.data;
      dispatch(loadProcurementTypeSuccess(procurementTypes));
    } catch (error) {
      Logger.getInstance().logException(error);
      throw error;
    }
  };
}

export function addContract(contractId) {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    try {
      const response = await performanceApi.getContractDetails(contractId);
      return response;
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function addOnboardSupplier(newOnboardedSupplier) {
  return async (dispatch) => {
    try {
      const response = await performanceApi.addOnboardSupplier(
        newOnboardedSupplier
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response?.data[0];
        response.data.domainsByIds = Object.values(
          response?.data?.domainsByIds
        ).join('@@');
        dispatch(addOnBoardSupplierSuccess(response.data));
        dispatch(
          requestProcessedMessageSuccess(Message.RecordHasAddedSucccessfully)
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function updateOnboardedSupplier(
  updatedOnboardedSupplier,
  scorecardSupplierId,
  unmanagedPerformanceSpend
) {
  return async (dispatch) => {
    try {
      const response = await performanceApi.updateOnboardedSupplier(
        updatedOnboardedSupplier,
        scorecardSupplierId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        response.data.forEach((res) => {
          res.domainsByIds = Object.values(
            res.domainsByIds
          ).join('@@');
          res.leads = `${res.lead1Alias} , ${res.lead2Alias}`;
          res.unmanagedPerformanceSpend = unmanagedPerformanceSpend;
        });
        dispatch(updateOnBoardSupplierSuccess(response.data));
        dispatch(
          requestProcessedMessageSuccess(
            Message.RecordHasBeenUpdatedSucccessfully
          )
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function deleteOnboardedSupplier(index) {
  return async (dispatch) => {
    try {
      dispatch(deleteOnBoardSupplierSuccess(index));
      dispatch(
        requestProcessedMessageSuccess(
          Message.RecordHasBeenDeletedSucccessfully
        )
      );
    } catch (error) {
      dispatch(
        requestUnprocessedMessageSuccess(Message.FailedToProcessRequest)
      );
      Logger.getInstance().logException(error);
    }
  };
}

export function sortedOnboardSuppliers(onboardedSuppliers) {
  return async (dispatch) =>
    dispatch({
      type: types.SORTED_ONBOARDEDSUPPLIERS_SUCCESS,
      onboardedSuppliers
    });
}

export function updateSupplierOnboardedBOBSuccess(OnboardedBOBSupplier) {
  return {
    type: types.UPDATE_SUPPLIERONBOARDEDBOB_SUCCESS,
    OnboardedBOBSupplier
  };
}

export function updateSupplierOnboardedBOB(OnboardedBOBSupplier) {
  return (dispatch) => {
    try {
      dispatch(updateSupplierOnboardedBOBSuccess(OnboardedBOBSupplier));
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}
