/* eslint-disable import/prefer-default-export */
import telemetryClient from './telemetryClient';

export class Logger {
  static userDetails = null;

  static instance;

  static initialize(userDetails) {
    telemetryClient.appInsights.setAuthenticatedUserContext(userDetails.alias);
    Logger.userDetails = userDetails;
  }

  static getInstance() {
    if (Logger.instance) return Logger.instance;
    Logger.instance = new Logger();
    return Logger.instance;
  }

  logException = error => {
    const customDimensions = {
      tcv: error.tcv,
      loggedInUser: Logger.userDetails.alias,
      request_object: error.requestUrl,
      detailedError: JSON.stringify(error.detailederror)
    };
    telemetryClient.trackException(
      error.errormessage || error,
      error.tcv,
      customDimensions
    );
  };

  logValidationError = validationResult => {
    const customDimensions = {
      tcv: validationResult.tcv ? validationResult.tcv : null,
      loggedInUser: Logger.userDetails.alias,
      request_object: validationResult.requestUrl
        ? validationResult.requestUrl
        : null,
      detailedError: JSON.stringify(
        validationResult.errors.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.errorMessage.concat(' '),
          ''
        )
      )
    };
    telemetryClient.trackException(
      validationResult,
      validationResult.tcv ? validationResult.tcv : null,
      customDimensions
    );
  };
}
export const TraceLevel = Object.freeze({
  Verbose: 0,
  Information: 1,
  Warning: 2,
  Error: 3,
  Critical: 4,
  Off: 5
});
