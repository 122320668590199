import * as types from '../actions/actionTypes';
import initialState from './initialState';

function getBOBListHelper(BOBList, procurementTypeId) {
  const newSupplierBOBs =
    BOBList &&
    BOBList.map(BOB => {
      if (BOB.contractIds !== null && BOB.purchaseOrders !== null) {
        const contractIdsAsString = Object.values(BOB.contractIds).join(',');
        const purchaseOrdersAsString = Object.values(BOB.purchaseOrders).join(
          ','
        );
        return {
          ...BOB,
          contractIds: contractIdsAsString,
          purchaseOrders: purchaseOrdersAsString,
          procurementTypeId
        };
      }
      return BOB;
    });
  return newSupplierBOBs;
}

export function BOBReducer(state = initialState.boblist, action) {
  switch (action.type) {
    case types.LOAD_SUPPLIERBOB_SUCCESS: {
      const newSupplierBOBs = getBOBListHelper(action.supplierBOBs, action.procurementTypeId);
      return [...newSupplierBOBs];
    }
    case types.ADD_NEWBOB_SUCCESS: {
      const newBOBList = state.concat([action.onboardedBOB]);
      const { procurementTypeId } = action;
      let allBOBAnnualContractValueTotal = 0;
      allBOBAnnualContractValueTotal = newBOBList.reduce(
        (previousValue, currentValue) => ({
          annualContractValue:
            previousValue.annualContractValue + currentValue.annualContractValue
        })
      ).annualContractValue;
      const bobList = newBOBList.map((item, index, itemList) => ({
        ...item,
        weight: (procurementTypeId !== 2) ? ((
          (item.annualContractValue * 100) /
          allBOBAnnualContractValueTotal
        ).toFixed(2)) : ((100 / itemList.length).toFixed(2))
      }));
      return [...bobList];
    }
    case types.UPDATE_ONBOARDEDBOB_SUCCESS: {
      const { procurementTypeId } = action;
      let allBOBAnnualContractValueTotal = 0;
      allBOBAnnualContractValueTotal =
        state.reduce((previousValue, currentValue) => ({
          annualContractValue:
            previousValue.annualContractValue + currentValue.annualContractValue
        })).annualContractValue -
        state.filter(item => item.bobId === action.onboardedBOB.bobId)[0]
          .annualContractValue +
        action.onboardedBOB.annualContractValue;

      const index = state.findIndex(
        item => item.bobId === action.onboardedBOB.bobId
      );
      if (index > -1) {
        return (
          state &&
          state.map((item, _index, itemList) => {
            if (item.bobId === action.onboardedBOB.bobId) {
              if (procurementTypeId === 2) {
                return {
                  ...action.onboardedBOB,
                  weight: (100 / itemList.length).toFixed(2),
                };
              }
              return action.onboardedBOB;
            }
            return {
              ...item,
              weight: (procurementTypeId !== 2) ? (
                (item.annualContractValue * 100) /
                allBOBAnnualContractValueTotal
              ).toFixed(2) : (item.weight)
            };
          })
        );
      }

      return state;
    }
    case types.SORTED_ONBOARDEDSUPPLIERBOBS_SUCCESS: {
      return [...action.supplierBOBs];
    }
    case types.DELETE_ONBOARDEDSUPPLIER_BOB_SUCCESS: {
      let updatedSupplierBobList = [...state];
      const deletedBob = updatedSupplierBobList.filter(bobList1 => !action.deletedSupplierBOBs
      .find(bobList2 => bobList1.bobId === bobList2.bobId));
      const index = updatedSupplierBobList.findIndex(item => item.bobId === deletedBob[0].bobId);
      updatedSupplierBobList.splice(index, 1);
      updatedSupplierBobList = updatedSupplierBobList.map(item =>
         ({
          ...item,
          weight: action.deletedSupplierBOBs
          .find(deleteBob => deleteBob.bobId === item.bobId).weight
        }));
      return [...updatedSupplierBobList];
    }
    default:
      return state;
  }
}

export function StdCategoryGroupReducer(
  state = initialState.stdcategorygroups,
  action
) {
  switch (action.type) {
    case types.LOAD_STANDARDCATEGORYGROUP_SUCCESS:
      return action.stdcategorygroups;
    default:
      return state;
  }
}

export function StdCategoryNameReducer(
  state = initialState.stdcategorynames,
  action
) {
  switch (action.type) {
    case types.LOAD_STANDARDCATEGORYNAME_SUCCESS:
      return action.stdcategorynames;
    default:
      return state;
  }
}

export function ExecOrgReducer(state = initialState.execorgs, action) {
  switch (action.type) {
    case types.LOAD_EXECORG_SUCCESS:
      return action.execorgs;
    default:
      return state;
  }
}

export function ExecOrgSummaryReducer(state = initialState.execorgSummary, action) {
  switch (action.type) {
    case types.LOAD_EXECORGSUMMARYDETAILS_SUCCESS:
      return action.execorgsummarydetails;
    default:
      return state;
  }
}

export function ExecOrgDetailsReducer(
  state = initialState.execorgdetails,
  action
) {
  switch (action.type) {
    case types.LOAD_EXECORGDETAILS_SUCCESS:
      return action.execorgdetails;
    default:
      return state;
  }
}

export function OwnerReducer(state = initialState.owners, action) {
  switch (action.type) {
    case types.LOAD_OWNER_SUCCESS:
      return action.owners;
    default:
      return state;
  }
}

export function ReportingCadenceListReducer(
  state = initialState.reportingCadenceList,
  action
) {
  switch (action.type) {
    case types.LOAD_REPORTINGCADENCE_SUCCESS:
      return action.reportingCadenceList;
    default:
      return state;
  }
}
