
export class SupplierModel {
    constructor(id, name, supplierLeads, supplierLeadNames, supplierType) {
      this.id = id;
      this.name = name;
      this.supplierLeads = supplierLeads;
      this.supplierLeadNames = supplierLeadNames;
      this.supplierType = supplierType;
    }
}

export class PeriodModel {
    constructor(id, name) {
      this.id = id;
      this.name = name;
    }
}

export class SamEntryActionModel {
    constructor(supplierScorecardId, supplier,
        cadence, totalActionPlanCount,
        completedActionPlanCount, statusId,
        percentage, scorecardSummary,
        isloaded) {
        this.supplierScorecardId = supplierScorecardId;
        this.supplier = supplier;
        this.cadence = cadence;
        this.totalActionPlanCount = totalActionPlanCount;
        this.completedActionPlanCount = completedActionPlanCount;
        this.statusId = statusId;
        this.percentage = percentage;
        this.scorecardSummary = scorecardSummary;
        this.isloaded = isloaded;
    }
}
