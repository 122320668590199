import React, { Component } from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import {
  Shimmer,
  ShimmerElementType
} from 'office-ui-fabric-react/lib/Shimmer';

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    '& > *': {
      margin: '2px 0'
    }
  }
});

class SupplierScorecardSummaryShimmer extends Component {
  render() {
    return (
      <div className="scorecardSummaryDiv">
        <Fabric className={wrapperClass}>
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '25%' },
              { type: ShimmerElementType.gap, width: '73%' }
            ]}
          />
          <Shimmer
            width="100%"
            shimmerElements={[
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '55%' },
              { type: ShimmerElementType.gap, width: '43%' }
            ]}
          />

          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '45%' },
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '10%' },
              { type: ShimmerElementType.gap, width: '41%' }
            ]}
          />
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '45%' },
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '10%' },
              { type: ShimmerElementType.gap, width: '41%' }
            ]}
          />
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '45%' },
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '10%' },
              { type: ShimmerElementType.gap, width: '41%' }
            ]}
          />
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '45%' },
              { type: ShimmerElementType.gap, width: '2%' },
              { type: ShimmerElementType.line, height: 10, width: '10%' },
              { type: ShimmerElementType.gap, width: '41%' }
            ]}
          />
        </Fabric>
      </div>
    );
  }
}

export default SupplierScorecardSummaryShimmer;
