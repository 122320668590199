import * as types from '../actions/actionTypes';
import initialState from './initialState';

function sumOfPoints(subMetrics, currentRating, changedSubMetricId) {
  const result = subMetrics && subMetrics.length ? subMetrics.map(item => {
    if (item.id === changedSubMetricId) {
      return ((currentRating * item.weight) / 100);
    }

    return (item.point);
  }) : [];
  return result.reduce((total, amount) => Number(total) + Number(amount));
}

function getCategoryScore(state, rating, metricId, submetricId) {
  const categoryDetails = state.bobScorecardCategoryDetails
    ? state.bobScorecardCategoryDetails
    : state.supplierScorecardCategoryDetails;
  if (submetricId == null) {
    const metricPoints =
      categoryDetails.metrics && categoryDetails.metrics.map(metric => {
        if (metric.isApplicable) {
          if (metric.id !== metricId) return (metric.point);
          return (rating * metric.weight) / 100;
        }
        return 0;
      });
    const totalMetricScore = metricPoints.reduce(
      (total, amount) => Number(total) + Number(amount)
    );

    let summaryPoint = 0;
    if (categoryDetails.bobCategorySummary) {
      if (categoryDetails.bobCategorySummary.score === 'N/A') {
        summaryPoint = 0;
      } else {
        summaryPoint = (Number(categoryDetails.bobCategorySummary.score) *
          Number(categoryDetails.bobCategorySummary.weight)) / 100;
      }
    }
    const summaryWeight = categoryDetails.bobCategorySummary
      ? categoryDetails.bobCategorySummary.weight
      : 0;
    const metricWeight = 100 - Number(summaryWeight);
    return (
      Number(summaryPoint) + (metricWeight * Number(totalMetricScore)) / 100
    );
  }
  const metricPoints = categoryDetails.metrics &&
    categoryDetails.metrics.map(metric => {
      if (metric.id !== metricId) return metric.point;

      return (
        (metric.subMetrics && metric.subMetrics
          .map(submetric => {
            if (submetric.isApplicable) {
              if (submetric.id !== submetricId) return (submetric.point);
              return (rating * submetric.weight) / 100;
            }
            return 0;
          })
          .reduce((total, amount) => Number(total) + Number(amount)) *
          metric.weight) /
        100
      );
    });
  return metricPoints.reduce((total, amount) => Number(total) + Number(amount));
}
function updateSupplierCategoryScore(
  updateAllMetricDetails,
  supplierScorecardCategoryDetails
) {
  const totalMetricScore = updateAllMetricDetails.reduce(
    (total, amount) =>
      (amount.isApplicable ? total + Number(amount.point) : total),
    0
  );
  let summaryPoint = 0;
  if (supplierScorecardCategoryDetails.bobCategorySummary) {
    if (supplierScorecardCategoryDetails.bobCategorySummary.score === 'N/A') {
      summaryPoint = 0;
    } else {
      summaryPoint = (Number(supplierScorecardCategoryDetails.bobCategorySummary.score) *
        Number(supplierScorecardCategoryDetails.bobCategorySummary.weight)) / 100;
    }
  }
  const summaryWeight = supplierScorecardCategoryDetails.bobCategorySummary
    ? supplierScorecardCategoryDetails.bobCategorySummary.weight
    : 0;
  const metricWeight = 100 - Number(summaryWeight);
  if (updateAllMetricDetails.find(item => item.isApplicable) || supplierScorecardCategoryDetails.bobCategorySummary.score !== 'N/A') {
    return Number(summaryPoint) + (metricWeight * Number(totalMetricScore)) / 100;
  }
  return 'N/A';
}

function UpdateSubMetricsMetricsApplicability(
  Metrics,
  itemId,
  subItemId,
  isNotApplicableChecked
) {
  if (subItemId !== null) {
    return Metrics && Metrics.length ?
    Metrics.map(metric => {
      if (metric.id !== itemId) {
        return metric;
      }
      return {
        ...metric,
        subMetrics: metric.subMetrics && metric.subMetrics.length ? metric.subMetrics
        .map(submetric => {
          if (submetric.id !== subItemId) {
            return submetric;
          }
          return {
            ...submetric,
            isApplicable: !isNotApplicableChecked
          };
        }) : []
      };
    }) : [];
  }
  let returnObj = null;
  return Metrics && Metrics.length ? Metrics.map(metric => {
    if (itemId !== null && metric.id !== itemId) {
      return metric;
    }
    returnObj = {
      ...metric,
      isApplicable: !isNotApplicableChecked,
      weight: 0
    };
  if (itemId !== null) {
    returnObj.score = 0;
    returnObj.point = 0;
  } return returnObj;
  }) : [];
}

function updateSubMetricsWeight(Metrics, metricId, isNotApplicableChecked) {
  return Metrics && Metrics.length ? Metrics.map(metric => {
    if (metric.id !== metricId || !metric.subMetrics) return metric;
    return {
      ...metric,
      isApplicable: metricId !== null ? true : !isNotApplicableChecked,
      subMetrics: metric.subMetrics &&
        metric.subMetrics.map((submetric, index, submetricList) => {
          if (submetric.isApplicable) {
            return {
              ...submetric,
              weight: (
                submetric.initialWeight +
                (submetricList.reduce(
                  (total, amount) =>
                    (!amount.isApplicable
                      ? total + Number(amount.initialWeight)
                      : total),
                  0
                ) *
                  submetric.initialWeight) /
                submetricList.reduce(
                  (total, amount) =>
                    (amount.isApplicable
                      ? total + amount.initialWeight
                      : total),
                  0
                )
              ),
              score: 0,
              point: (
                ((submetric.initialWeight +
                  (submetricList.reduce(
                    (total, amount) =>
                      (!amount.isApplicable
                        ? total + Number(amount.initialWeight)
                        : total),
                    0
                  ) *
                    submetric.initialWeight) /
                  submetricList.reduce(
                    (total, amount) =>
                      (amount.isApplicable
                        ? total + amount.initialWeight
                        : total),
                    0
                  )) *
                  (typeof submetric.score !== 'number' ? 0 : submetric.score)) /
                100
              ).toFixed(10)
            };
          }
          return {
            ...submetric,
            weight: 0,
            point: 0,
            score: submetric.isApplicable ? 0 : 'N/A'
          };
        })
    };
  }) : [];
}

function UpdateAffectedMetricDetails(metrics, metricId, isNotApplicableChecked) {
  return metrics && metrics.length ? metrics.map((metric, index, metricsList) => {
    if (metricId !== null && metric.id !== metricId) {
      return metric;
    }
    if (
      metric.subMetrics &&
      !metric.subMetrics.some(submetric => submetric.isApplicable)
    ) {
      return {
        ...metric,
        isApplicable: metricId !== null ? false : !isNotApplicableChecked,
        weight: 0,
        point: 0,
        score: 0
      };
    }
    if (metric.subMetrics) {
    return {
      ...metric,
      weight: !metric.subMetrics
        ? 0
        : (metricsList.reduce(
            (total, amount) =>
              (!amount.isApplicable
                ? total + Number(amount.initialWeight)
                : total),
            0
          ) *
            metric.initialWeight) /
          metricsList.reduce(
            (total, amount) =>
              (amount.isApplicable
                ? total + Number(amount.initialWeight)
                : total),
            0
          ),

      score: !metric.subMetrics
        ? 0
        : metric.subMetrics.reduce(
            (metricScore, submetric) =>
              (submetric.isApplicable
                ? metricScore + Number(submetric.point)
                : metricScore),
            0
          ),

      point: !metric.subMetrics
        ? 0
        : (
            (metric.subMetrics.reduce(
              (metricScore, submetric) =>
                (submetric.isApplicable
                  ? metricScore + Number(submetric.point)
                  : metricScore),
              0
            ) *
              Number(metric.weight)) /
              100
          ).toFixed(10)
    };
  }
  return {
    ...metric,
    weight: 0,
};
}) : [];
}

function UpdateAllMetricDetails(metrics) {
  return metrics && metrics.length ? metrics.map((metric, index, metricList) => {
    if (!metric.isApplicable) return { ...metric, score: 'N/A' };
    return {
      ...metric,
      weight:
        Number(
          (
            (metricList.reduce(
              (total, amount) =>
                (!amount.isApplicable
                  ? total + Number(amount.initialWeight)
                  : total),
              0
            ) *
              metric.initialWeight) /
            metricList.reduce(
              (total, amount) =>
                (amount.isApplicable
                  ? total + Number(amount.initialWeight)
                  : total),
              0
            )
          )
        ) + Number(metric.initialWeight),
      point: (
        ((Number(
          (
            (metricList.reduce(
              (total, amount) =>
                (!amount.isApplicable
                  ? total + Number(amount.initialWeight)
                  : total),
              0
            ) *
              metric.initialWeight) /
            metricList.reduce(
              (total, amount) =>
                (amount.isApplicable
                  ? total + Number(amount.initialWeight)
                  : total),
              0
            )
          )
        ) +
          Number(metric.initialWeight)) *
          Number(metric.score)) /
        100
      ).toFixed(10)
    };
  }) : [];
}
export default function scoreCardReducer(
  state = initialState.myActionsScorecard,
  action
) {
  switch (action.type) {
    case types.LOAD_BOBSCORECARDDETAILS_SUCCESS: {
      return {
        ...state,
        bobScorecardDetails: { ...action.payload },
        supplierScorecardDetails: null,
        selectedScorecardCategory: action.payload ?
          action.payload.categories[0].id : 0
      };
    }
    case types.LOAD_SUPPLIERSCORECARDDETAILS_SUCCESS: {
      return {
        ...state,
        supplierScorecardDetails: { ...action.payload },
        bobScorecardDetails: null,
        selectedScorecardCategory: action.payload ?
          action.payload.categories[0].id : 0
      };
    }
    case types.LOAD_BOBSCORECARD_CATEGORYDETAILS_SUCCESS: {
      return {
        ...state,
        bobScorecardCategoryDetails: {
          ...action.payload,
          metrics: action.payload.metrics
            ? [...action.payload.metrics]
              .sort(
                (a, b) =>
                  Number(a.displayOrderNumber) - Number(b.displayOrderNumber)
              )
              .map(metric => ({
                ...metric,
                subMetrics: metric.subMetrics
                  ? [...metric.subMetrics].sort(
                    (a, b) =>
                      Number(a.displayOrderNumber) -
                      Number(b.displayOrderNumber)
                  )
                  : null
              }))
            : null
        },
        supplierScorecardCategoryDetails: null
      };
    }
    case types.LOAD_SUPPLIERSCORECARD_CATEGORYDETAILS_SUCCESS: {
      return {
        ...state,
        supplierScorecardCategoryDetails: {
          ...action.payload,
          metrics: action.payload.metrics
            ? [...action.payload.metrics]
              .sort(
                (a, b) =>
                  Number(a.displayOrderNumber) - Number(b.displayOrderNumber)
              )
              .map(metric => ({
                ...metric,
                subMetrics: metric.subMetrics
                  ? [...metric.subMetrics].sort(
                    (a, b) =>
                      Number(a.displayOrderNumber) -
                      Number(b.displayOrderNumber)
                  )
                  : null
              }))
            : null
        },
        bobScorecardCategoryDetails: null
      };
    }
    case types.UPDATE_SELECTED_CATEGORY: {
      return {
        ...state,
        selectedScorecardCategory: action.payload.key
      };
    }
    case types.LOAD_BOBSCORECARD_METRICHISTORY_SUCCESS: {
      return {
        ...state,
        bobScorecardMetricHistory: [
          ...state.bobScorecardMetricHistory,
          action.history
        ]
      };
    }
    case types.LOAD_SUPPLIERSCORECARD_METRICHISTORY_SUCCESS: {
      return {
        ...state,
        supplierScorecardMetricHistory: [
          ...state.supplierScorecardMetricHistory,
          action.history
        ]
      };
    }
    case types.LOAD_BOBSCORECARD_CATEGORYHISTORY_SUCCESS: {
      return {
        ...state,
        bobScorecardCategoryHistory: [
          ...state.bobScorecardCategoryHistory,
          action.history
        ]
      };
    }
    case types.LOAD_SUPPLIERSCORECARD_CATEGORYHISTORY_SUCCESS: {
      return {
        ...state,
        supplierScorecardCategoryHistory: [
          ...state.supplierScorecardCategoryHistory,
          action.history
        ]
      };
    }
    case types.LOAD_SUPPLIERSCORECARD_CATEGORY_AVGBOBSCOREHISTORY_SUCCESS: {
      return {
        ...state,
        supplierScorecardCategoryAvgBobScoreHistory: [
          ...state.supplierScorecardCategoryAvgBobScoreHistory,
          action.history
        ]
      };
    }
    case types.METRIC_APPLICABILITY_CHANGE: {
      const categorymetrics = state.bobScorecardCategoryDetails
      ? state.bobScorecardCategoryDetails.metrics
      : state.supplierScorecardCategoryDetails.metrics;
      const updatedSubMetricsApplicability = UpdateSubMetricsMetricsApplicability(
        categorymetrics,
        action.payload.itemId,
        action.payload.subItemId,
        action.payload.isChecked
      );

      const updatedWeight = updateSubMetricsWeight(
        updatedSubMetricsApplicability,
        action.payload.itemId,
        false
      );

      const updatedMetricDetails = UpdateAffectedMetricDetails(
        updatedWeight,
        action.payload.itemId,
        false
      );

      const updateAllMetricDetails = UpdateAllMetricDetails(
        updatedMetricDetails
      );
      return {
        ...state,
        bobScorecardCategoryDetails: state.bobScorecardCategoryDetails
          ? {
              ...state.bobScorecardCategoryDetails,
              isDirty: true,
              score: updateAllMetricDetails.find(item => item.isApplicable)
              ? updateAllMetricDetails.reduce(
               (total, amount) =>
                (amount.isApplicable ? total + Number(amount.point) : total),
               0
           ) : 'N/A',
              metrics: updateAllMetricDetails
            }
          : null,
        supplierScorecardCategoryDetails: state.supplierScorecardCategoryDetails
          ? {
              ...state.supplierScorecardCategoryDetails,
              isDirty: true,
              score:
               updateSupplierCategoryScore(
               updateAllMetricDetails,
               state.supplierScorecardCategoryDetails
             ),
              metrics: updateAllMetricDetails
            }
          : null
      };
    }
    case types.METRIC_APPLICABILITY_ALL_CHANGE: {
      // eslint-disable-next-line no-nested-ternary
      const categorymetrics = action.payload.isChecked ? (state.bobScorecardCategoryDetails
        ? state.bobScorecardCategoryDetails.metrics
        : state.supplierScorecardCategoryDetails.metrics) :
        action.payload.prevCategoryDetails.metrics;

      const updatedAllSubMetricsApplicability = UpdateSubMetricsMetricsApplicability(
        categorymetrics,
        null,
        null,
        action.payload.isChecked
      );

      const updatedAllWeight = updateSubMetricsWeight(
        updatedAllSubMetricsApplicability,
        null,
        action.payload.isChecked
      );

      const updatedAllMetricDetails = UpdateAffectedMetricDetails(
        updatedAllWeight,
        null,
        action.payload.isChecked
      );

      const updateMetricDetails = UpdateAllMetricDetails(
        updatedAllMetricDetails
      );
      return {
        ...state,
        bobScorecardCategoryDetails: state.bobScorecardCategoryDetails
          ? {
              ...state.bobScorecardCategoryDetails,
              isDirty: true,
              score: updateMetricDetails.find(item => item.isApplicable)
              ? updateMetricDetails.reduce(
               (total, amount) =>
                (amount.isApplicable ? total + Number(amount.point) : total),
               0
           ) : 'N/A',
              metrics: updateMetricDetails
            }
          : null,
        supplierScorecardCategoryDetails: state.supplierScorecardCategoryDetails
          ? {
              ...state.supplierScorecardCategoryDetails,
              isDirty: true,
              score:
               updateSupplierCategoryScore(
                updateMetricDetails,
               state.supplierScorecardCategoryDetails
             ),
              metrics: updateMetricDetails
            }
          : null
      };
    }
    case types.METRIC_RATING_CHANGE: {
      return {
        ...state,
        bobScorecardDetails: state.bobScorecardDetails ? {
          ...state.bobScorecardDetails,
          categories: state.bobScorecardDetails.categories &&
            state.bobScorecardDetails.categories.map(category => {
              if (category.id === action.payload.selectedScorecardCategory) {
                return {
                  ...category,
                  score: action.payload.rating
                };
              }
              return { ...category };
            })
        } : null,
        bobScorecardCategoryDetails: state.bobScorecardCategoryDetails
          ? {
            ...state.bobScorecardCategoryDetails,
            isDirty: true,
            score: getCategoryScore(
              state,
              action.payload.rating,
              action.payload.itemId,
              action.payload.subItemId
            ),
            metrics: state.bobScorecardCategoryDetails.metrics &&
              state.bobScorecardCategoryDetails.metrics.map(metric => {
                if (metric.id !== action.payload.itemId) return metric;
                if (action.payload.subItemId === null) {
                  return {
                    ...metric,
                    score: action.payload.rating,
                    point: (
                      (action.payload.rating * metric.weight) / 100
                    ).toFixed(10)
                  };
                }
                return {
                  ...metric,
                  subMetrics:
                    metric.subMetrics !== null && metric.subMetrics.length
                      ? metric.subMetrics.map(submetric => {
                        if (submetric.id !== action.payload.subItemId) {
                          return submetric;
                        }
                        return {
                          ...submetric,
                          score: action.payload.rating,
                          point:
                            ((action.payload.rating * submetric.weight) / 100).toFixed(10)
                        };
                      })
                      : null,
                  score: (sumOfPoints(
                    metric.subMetrics,
                    action.payload.rating,
                    action.payload.subItemId
                  )),

                  point: (
                    (sumOfPoints(
                      metric.subMetrics,
                      action.payload.rating,
                      action.payload.subItemId
                    ) *
                      metric.weight) /
                    100
                  ).toFixed(10)
                };
              })
          }
          : null,
        supplierScorecardDetails: state.supplierScorecardDetails ? {
          ...state.supplierScorecardDetails,
          categories: state.supplierScorecardDetails.categories &&
            state.supplierScorecardDetails.categories.map(
              category => {
                if (category.id === action.payload.categoryScorecardId) {
                  return {
                    ...category,
                    score: action.payload.rating
                  };
                }
                return { ...category };
              }
            )
        } : null,
        supplierScorecardCategoryDetails: state.supplierScorecardCategoryDetails
          ? {
            ...state.supplierScorecardCategoryDetails,
            isDirty: true,
            score: getCategoryScore(
              state,
              action.payload.rating,
              action.payload.itemId,
              action.payload.subItemId
            ),
            metrics: state.supplierScorecardCategoryDetails.metrics &&
              state.supplierScorecardCategoryDetails.metrics.map(
                metric => {
                  if (metric.id !== action.payload.itemId) return metric;
                  if (action.payload.subItemId === null) {
                    return {
                      ...metric,
                      score: action.payload.rating,
                      point: (
                        (action.payload.rating * metric.weight) / 100
                      ).toFixed(10)
                    };
                  }
                  return {
                    ...metric,
                    subMetrics:
                      metric.subMetrics !== null && metric.subMetrics.length
                        ? metric.subMetrics.map(submetric => {
                          if (submetric.id !== action.payload.subItemId) {
                            return submetric;
                          }
                          return {
                            ...submetric,
                            score: action.payload.rating,
                            point:
                              ((action.payload.rating * submetric.weight) / 100).toFixed(10)
                          };
                        })
                        : null,
                    score: (sumOfPoints(
                      metric.subMetrics,
                      action.payload.rating,
                      action.payload.subItemId
                    )),

                    point: (
                      (sumOfPoints(
                        metric.subMetrics,
                        action.payload.rating,
                        action.payload.subItemId
                      ) *
                        metric.weight) /
                      100
                    ).toFixed(10)

                  };
                }
              )
          }
          : null
      };
    }

    case types.FETCH_CATEGORYDATA_INIT: {
      return {
        ...state,
        categoryDetailsLoading: action.payload,
        bobScorecardCategoryDetails: action.payload
          ? null
          : state.bobScorecardCategoryDetails,
        supplierScorecardCategoryDetails: action.payload
          ? null
          : state.supplierScorecardCategoryDetails
      };
    }

    case types.FETCH_SUMMARYDATA_INIT: {
      return {
        ...state,
        summaryDetailsLoading: action.payload,
        bobScorecardDetails: action.payload ? null : state.bobScorecardDetails,
        supplierScorecardDetails: action.payload
          ? null
          : state.supplierScorecardDetails,
        bobScorecardCategoryDetails: action.payload
          ? null
          : state.bobScorecardCategoryDetails,
        supplierScorecardCategoryDetails: action.payload
          ? null
          : state.supplierScorecardCategoryDetails
      };
    }

    case types.UPDATE_CAP_DETAILS: {
      return {
        ...state,
        bobScorecardCategoryDetails: state.bobScorecardCategoryDetails
          ? {
            ...state.bobScorecardCategoryDetails,
            isDirty: true,
            cap: {
              ...state.bobScorecardCategoryDetails.cap,
              [action.payload.key]: action.payload.value
            }
          }
          : null,
        supplierScorecardCategoryDetails: state.supplierScorecardCategoryDetails
          ? {
            ...state.supplierScorecardCategoryDetails,
            isDirty: true,
            cap: {
              ...state.supplierScorecardCategoryDetails.cap,
              [action.payload.key]: action.payload.value
            }
          }
          : null
      };
    }

    case types.CATEGORY_COMMENTS_CHANGE: {
      return {
        ...state,
        bobScorecardCategoryDetails: state.bobScorecardCategoryDetails
          ? {
            ...state.bobScorecardCategoryDetails,
            comments: action.payload,
            isDirty: true
          }
          : null,
        supplierScorecardCategoryDetails: state.supplierScorecardCategoryDetails
          ? {
            ...state.supplierScorecardCategoryDetails,
            comments: action.payload,
            isDirty: true
          }
          : null
      };
    }

    case types.UPDATE_BOBSCORECARD_METRICS_SUCCESS: {
      return {
        ...state,
        bobScorecardCategoryDetails: {
          ...state.bobScorecardCategoryDetails,
          isDirty: false,
          cap: {
            ...state.bobScorecardCategoryDetails.cap,
            id: action.payload.responseData.correctiveActionPlanId
          }
        },
        bobScorecardDetails: {
          ...state.bobScorecardDetails,
          categories: state.bobScorecardDetails.categories &&
            state.bobScorecardDetails.categories.map(category => {
              if (category.id === action.payload.categoryScorecardId) {
                return {
                  ...category,
                  score: action.payload.score,
                  isCompleted: action.payload.isCategoryCompleted
                };
              }
              return { ...category };
            })
        }
      };
    }
    case types.UPDATE_SUPPLIERSCORECARD_METRICS_SUCCESS: {
      return {
        ...state,
        supplierScorecardCategoryDetails: {
          ...state.supplierScorecardCategoryDetails,
          isDirty: false,
          cap: {
            ...state.supplierScorecardCategoryDetails.cap,
            id: action.payload.responseData.correctiveActionPlanId
          }
        },
        supplierScorecardDetails: {
          ...state.supplierScorecardDetails,
          categories: state.supplierScorecardDetails.categories
            && state.supplierScorecardDetails.categories.map(
              category => {
                if (category.id === action.payload.categoryScorecardId) {
                  return {
                    ...category,
                    score: action.payload.score,
                    isCompleted: action.payload.isCategoryCompleted
                  };
                }
                return { ...category };
              }
            )
        }
      };
    }
    default:
      return state;
  }
}
