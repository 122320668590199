import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userSigninReducer(
  state = initialState.loggedInUser,
  action
) {
  switch (action.type) {
    case types.SIGNIN_USER_SUCCESS:
      return { ...action.user, initials: action.user.initials };
    default:
      return state;
  }
}
