/* eslint-disable no-console */
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import {
  SupplierActionPlanModel,
  BobActionPlanModel
} from '../../components/models/model/SamActionPlanModel';
import { Message } from '../../common/consts/validationMessages';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function FetchSupplierCategoryDetailsInit(isLoading) {
  return {
    type: types.FETCH_SAM_SUPPLIER_METRIC_DATA_INIT,
    payload: isLoading
  };
}
function FetchBobCategoryDetailsInit(isLoading) {
  return {
    type: types.FETCH_SAM_BOB_METRIC_DATA_INIT,
    payload: isLoading
  };
}
function FetchBobCategoryIdDetailsInit(isLoading) {
  return {
    type: types.FETCH_SAM_BOB_METRIC_ID_DATA_INIT,
    payload: isLoading
  };
}

function loadSupplierScorecardCategoryDetailsSuccess(
  supplierScorecardCategoryDetails
) {
  return {
    type: types.LOAD_SAM_SUPPLIER_METRIC_DETAILS_SUCCESS,
    payload: supplierScorecardCategoryDetails
  };
}

function loadSamSupplierMetricsSuccess(
  samSupplierMetrics
) {
  return {
    type: types.LOAD_SAM_SUPPLIER_METRIC_SUCCESS,
    payload: samSupplierMetrics
  };
}

function loadSupplierScorecardCategoryDetailsForBobSuccess(
  supplierScorecardCategoryDetails,
  cadenceId
) {
  return {
    type: types.LOAD_SAM_BOB_METRIC_DETAILS_SUCCESS,
    payload: { supplierScorecardCategoryDetails, cadenceId }
  };
}
function requestProcessedMessageSuccess(message) {
  return { type: types.ADD_PROCESSEDMESSAGE_SUCCESS, message };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

function handleAddActionPlan(actionPlanType, scorecardId) {
  return {
    type: types.ADD_SAM_ACTIONPLAN,
    payload: { actionPlanType, scorecardId }
  };
}

function handleDeleteActionPlan(
  actionPlanType,
  scorecardId,
  actionPlanId,
  isBaseActionPlan,
  supplierType
) {
  return {
    type: types.DELETE_SAM_ACTIONPLAN,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId,
      isBaseActionPlan,
      supplierType
    }
  };
}

function handleMetricActionPlanAddClick(
  actionPlanType,
  scorecardId,
  score,
  actionPlanTitle,
  supplierId,
  cadenceId,
  supplierType
) {
  return {
    type: types.ADD_SAM_METRIC_ACTIONPLAN,
    payload: {
      actionPlanType,
      scorecardId,
      score,
      actionPlanTitle,
      supplierId,
      cadenceId,
      supplierType
    }
  };
}

function handleActionPlanDueDateChange(
  actionPlanType,
  scorecardId,
  actionPlanId,
  dueCadenceId,
  supplierId,
  cadenceId,
  supplierType
) {
  return {
    type: types.CHANGE_ACTION_PLAN_DUEDATE,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId,
      dueCadenceId,
      supplierId,
      cadenceId,
      supplierType
    }
  };
}

function handleActionPlanSubMetricChange(
  actionPlanType,
  scorecardId,
  actionPlanId,
  subMetricId,
  supplierId,
  cadenceId,
  supplierType
) {
  return {
    type: types.CHANGE_ACTION_PLAN_SUBMETRIC,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId,
      subMetricId,
      supplierId,
      cadenceId,
      supplierType
    }
  };
}

function handleActionPlanStatusChange(
  actionPlanType,
  scorecardId,
  actionPlanId,
  statusId,
  supplierId,
  cadenceId,
  supplierType
) {
  return {
    type: types.CHANGE_ACTION_PLAN_STATUS,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId,
      statusId,
      supplierId,
      cadenceId,
      supplierType
    }
  };
}

function handleActionPlanChange(
  actionPlanType,
  scorecardId,
  actionPlanId,
  actionPlan,
  supplierId,
  cadenceId,
  supplierType
) {
  return {
    type: types.CHANGE_ACTION_PLAN,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId,
      actionPlan,
      supplierId,
      cadenceId,
      supplierType
    }
  };
}

function handleActionPlanIsApplicableChange(
  actionPlanType,
  scorecardId,
  actionPlanId,
  checked,
  supplierId,
  cadenceId,
  supplierType
) {
  return {
    type: types.CHANGE_ACTION_PLAN_ISAPPLICABLE,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId,
      checked,
      supplierId,
      cadenceId,
      supplierType
    },
  };
}

function handleActionPlanIsValueActionPlanChange(
  actionPlanType,
  scorecardId,
  actionPlanId,
  checked
) {
  return {
    type: types.CHANGE_ACTION_PLAN_ISVALUEACTIONPLAN,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId,
      checked,
    },
  };
}

function loadActionPlanHistorySuccess(history) {
  return {
    type: types.GET_ACTIONPLAN_HISTORY_SUCCESS,
    history,
  };
}

function handleActionPlanToggleStateChange(actionPlanType, scorecardId, actionPlanId) {
  return {
    type: types.ACTIONPLAN_TOGGLE_CHANGE_SUCCESS,
    payload: {
      actionPlanType,
      scorecardId,
      actionPlanId
    },
  };
}

function resetDirtyActionPlans(actionPlanScorecardIds) {
  return {
    type: types.RESET_DIRTY_ACTION_PLANS,
    payload: {
      supplierScorecardIds: actionPlanScorecardIds.supplierScorecardIds,
      bobScorecardIds: actionPlanScorecardIds.bobScorecardIds
    }
  };
}

function buildActionPlansToBeSubmitted(actionPlans) {
  let dirtyBobActionPlans = [];
  let dirtySupplierActionPlans = [];
  if (actionPlans.bobActionPlans.length > 0) {
    dirtyBobActionPlans = actionPlans.bobActionPlans.filter(
      plans => plans.isDirty === true
    );
  }
  if (actionPlans.supplierMetricActionPlans.length > 0) {
    dirtySupplierActionPlans = actionPlans.supplierMetricActionPlans.filter(
      plans => plans.isDirty === true
    );
  }

  const bobActionPlans = [];
  dirtyBobActionPlans.forEach(parentplans => {
    const childActionPlans = parentplans.actionPlans.filter(
      x => !(x.actionPlanId < 0 && !x.isActive)
      // we don't need any new actionplan which is later deleted
    );
    childActionPlans.forEach(plans => {
      const actionPlanData = new BobActionPlanModel(
        parentplans.scorecardId,
        plans.actionPlanId,
        plans.actionPlan,
        plans.dueCadenceId,
        plans.status,
        plans.isApplicable,
        plans.isActive,
        plans.isValueActionPlan
      );
      bobActionPlans.push(actionPlanData);
    });
  });

  const supplierMetricActionPlans = [];
  dirtySupplierActionPlans.forEach(parentplans => {
    const childActionPlans = parentplans.actionPlans.filter(
      x => !(x.actionPlanId < 0 && !x.isActive)
      // we don't need any new actionplan which is later deleted
    );
    childActionPlans.forEach(plans => {
      const actionPlanData = new SupplierActionPlanModel(
        parentplans.scorecardId,
        plans.actionPlanId,
        plans.actionPlan,
        plans.dueCadenceId,
        plans.status,
        plans.isApplicable,
        plans.isActive,
        plans.subMetricId,
        plans.supplierId,
        plans.cadenceId
      );
      supplierMetricActionPlans.push(actionPlanData);
    });
  });

  const plans = {
    supplierMetricActionPlans,
    bobActionPlans,
    key: ''
  };

  return plans;
}

function getListofDirtyPlans(actionPlans) {
  const supplierScorecardIds = [
    ...new Set(
      actionPlans.supplierMetricActionPlans.map(
        x => x.supplierMetricScorecardId
      )
    )
  ];
  const bobScorecardIds = [
    ...new Set(
      actionPlans.bobActionPlans.map(x => x.bobMetricCategoryScorecardId)
    )
  ];

  const dirtPlanIds = {
    supplierScorecardIds,
    bobScorecardIds
  };
  return dirtPlanIds;
}

function updateActionPlanDetailsSuccess(responseData, supplierScorecardId) {
  return {
    type: types.UPDATE_ACTIONPLAN_DETAILS_SUCCESS,
    payload: {
      supplierActionPlansIdMappings: responseData.supplierActionPlansIdMappings,
      bobActionPlansIdMappings: responseData.bobActionPlansIdMappings,
      statusId: responseData.statusId,
      progressPercentage: responseData.progressPercentage,
      supplierScorecardId
    }
  };
}

export function onActionPlanAddClick(actionPlanType, scorecardId) {
  return dispatch => {
    dispatch(handleAddActionPlan(actionPlanType, scorecardId));
  };
}

export function onActionPlanDeleteClick(
  actionPlanType,
  scorecardId,
  actionPlanId,
  isBaseActionPlan,
  supplierType
) {
  return dispatch => {
    dispatch(
      handleDeleteActionPlan(
        actionPlanType,
        scorecardId,
        actionPlanId,
        isBaseActionPlan,
        supplierType
      )
    );
  };
}

export function onMetricActionPlanAddOptionClick(
  actionPlanType,
  scorecardId,
  score,
  actionTitle,
  supplierId,
  cadenceId,
  supplierType
) {
  return dispatch => {
    dispatch(
      handleMetricActionPlanAddClick(
        actionPlanType,
        scorecardId,
        score,
        actionTitle,
        supplierId,
        cadenceId,
        supplierType
      )
    );
  };
}
export function actionPlanDuePeriodChanged(
  actionPlanType,
  scorecardId,
  actionPlanId,
  dueCadenceId,
  supplierId,
  cadenceId,
  supplierType
) {
  return dispatch => {
    dispatch(
      handleActionPlanDueDateChange(
        actionPlanType,
        scorecardId,
        actionPlanId,
        dueCadenceId,
        supplierId,
        cadenceId,
        supplierType
      )
    );
  };
}

export function actionPlanSubMetricChanged(
  actionPlanType,
  scorecardId,
  actionPlanId,
  subMetricId,
  supplierId,
  cadenceId,
  supplierType
) {
  return dispatch => {
    dispatch(
      handleActionPlanSubMetricChange(
        actionPlanType,
        scorecardId,
        actionPlanId,
        subMetricId,
        supplierId,
        cadenceId,
        supplierType
      )
    );
  };
}

export function actionPlanStatusChanged(
  actionPlanType,
  scorecardId,
  actionPlanId,
  statusId,
  supplierId,
  cadenceId,
  supplierType
) {
  return dispatch => {
    dispatch(
      handleActionPlanStatusChange(
        actionPlanType,
        scorecardId,
        actionPlanId,
        statusId,
        supplierId,
        cadenceId,
        supplierType
      )
    );
  };
}

export function actionPlanIsApplicableChanged(
  actionPlanType,
  scorecardId,
  actionPlanId,
  checked,
  supplierId,
  cadenceId,
  supplierType
) {
  return dispatch => {
    dispatch(
      handleActionPlanIsApplicableChange(
        actionPlanType,
        scorecardId,
        actionPlanId,
        checked,
        supplierId,
        cadenceId,
        supplierType
      )
    );
  };
}

export function actionPlanIsValueActionPlanChanged(
  actionPlanType,
  scorecardId,
  actionPlanId,
  checked
) {
  return dispatch => {
    dispatch(
      handleActionPlanIsValueActionPlanChange(
        actionPlanType,
        scorecardId,
        actionPlanId,
        checked
      )
    );
  };
}
export function onClickActionPlanHistoryToggle(actionPlanType, scorecardId, actionPlanId) {
    return (dispatch) => {
        dispatch(handleActionPlanToggleStateChange(actionPlanType, scorecardId, actionPlanId));
    };
}

export function actionPlanChanged(
  actionPlanType,
  scorecardId,
  actionPlanId,
  actionPlan,
  supplierId,
  cadenceId,
  supplierType
) {
  return dispatch => {
    dispatch(
      handleActionPlanChange(
        actionPlanType,
        scorecardId,
        actionPlanId,
        actionPlan,
        supplierId,
        cadenceId,
        supplierType
      )
    );
  };
}

export function updateActionPlanDetails(
  actionPlanDetails,
  supplierScorecardId,
  selectedScorecardCategory
) {
  return async dispatch => {
    try {
      const plans = buildActionPlansToBeSubmitted(actionPlanDetails);
      const response = await performanceApi.updateActionPlanDetails(
        selectedScorecardCategory,
        plans
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(
          updateActionPlanDetailsSuccess(response.data, supplierScorecardId)
        );
        dispatch(
          requestProcessedMessageSuccess(
            Message.RecordHasBeenUpdatedSucccessfully
          )
        );
        const actionPlansToMarkNotDirty = getListofDirtyPlans(plans);
        dispatch(resetDirtyActionPlans(actionPlansToMarkNotDirty));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function loadSamSupplierActionPlansToAdd(selectedCategoryId) {
  return async dispatch => {
    try {
      dispatch(FetchSupplierCategoryDetailsInit(true));
      const response = await performanceApi.getSupplierScorecardCategoryDetails(
        selectedCategoryId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadSupplierScorecardCategoryDetailsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    } finally {
      dispatch(FetchSupplierCategoryDetailsInit(false));
    }
  };
}

export function loadSamSupplierMetricsToAdd(selectedCategoryId, supplierMetricScorecardId,
   supplierId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getSamSupplierMetrics(
        selectedCategoryId,
        supplierMetricScorecardId,
        supplierId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadSamSupplierMetricsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function loadSamBobActionPlansToAdd(selectedCategoryId, cadenceId) {
  return async dispatch => {
    try {
      dispatch(FetchBobCategoryDetailsInit(true));
      const response = await performanceApi.getSupplierScorecardCategoryDetails(
        selectedCategoryId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(
          loadSupplierScorecardCategoryDetailsForBobSuccess(
            response.data,
            cadenceId
          )
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    } finally {
      dispatch(FetchBobCategoryDetailsInit(false));
    }
  };
}

export function handleBobMetricActionPlanAddOptionClick(
  type,
  supplierId,
  bobId,
  cadenceId,
  categoryName,
  score,
  bobName,
  isBobCategoryIdAvailable
) {
  return async dispatch => {
    try {
      dispatch(FetchBobCategoryIdDetailsInit(true));
      if (isBobCategoryIdAvailable) {
        dispatch(onMetricActionPlanAddOptionClick(type, bobId, score, bobName));
      } else {
        const response = await performanceApi.getBobScorecardDetails(
          bobId,
          supplierId,
          cadenceId
        );
        if (response.validationResult.isValid) {
          response.data = !response.data ? [] : response.data;
          const index = response.data.categories.findIndex(
            item => item.category.name === categoryName
          );
          if (index !== -1) {
            const bobCategoryId = response.data.categories[index].id;
            dispatch(
              onMetricActionPlanAddOptionClick(
                type,
                bobCategoryId,
                score,
                bobName
              )
            );
          }
        } else {
          dispatch(handleValidationError(response.validationResult.errors));
          Logger.getInstance().logValidationError(response.validationResult);
        }
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    } finally {
      dispatch(FetchBobCategoryIdDetailsInit(false));
    }
  };
}

function loadFutureCadenceForValueActionPlanSuccess(cadence) {
  return { type: types.LOAD_FUTURECADENCEFORVALUEACTIONPLAN_SUCCESS, cadence };
}

export function loadFutureCadenceForValueActionPlan(supplierId, cadenceTypeId, bobId,
  cadencePeriodId, isPastAndFutureCadence = false) {
  return async dispatch => {
    try {
      const futureCadenceForValueActionPlan = await performanceApi.getFutureCadence(
        supplierId,
        cadenceTypeId,
        bobId,
        cadencePeriodId,
        isPastAndFutureCadence
      );
      dispatch(loadFutureCadenceForValueActionPlanSuccess(futureCadenceForValueActionPlan));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}

export function loadActionPlanHistory(actionPlanType, scorecardId) {
    return async (dispatch) => {
        try {
            const response = await performanceApi.getSamActionPlanHistory(
                actionPlanType,
                scorecardId
            );
            response.data = !response.data ? [] : response.data;
            if (response.validationResult.isValid) {
                dispatch(
                    loadActionPlanHistorySuccess({
                        history: response.data,
                        scorecardId,
                    })
                );
            } else {
                dispatch(handleValidationError(response.validationResult.errors));
                Logger.getInstance().logValidationError(response.validationResult);
            }
        } catch (error) {
            dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
            Logger.getInstance().logException(error);
        }
    };
}


export function setMonitorSupplierMetrics(metrics) {
  return async dispatch => {
    dispatch({
      type: types.SET_MONITOR_SUPPLIER_METRICS,
      metrics
    });
  };
}
