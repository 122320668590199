import * as types from './actionTypes';

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}

export default function handleValidationError(validationErrors) {
  const validationErrorMessage = validationErrors.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.errorMessage.concat(' '),
    ''
  );
  // Todo currently passing tcvId as null as API is not providing same in response.
  return requestUnprocessedMessageSuccess(validationErrorMessage, null);
}
